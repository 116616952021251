import React, {useEffect, useState} from "react";
import "./css/GiftCard.scss";
import {useTranslation} from "react-i18next";
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination} from "swiper";
import "swiper/css";
import "swiper/css/pagination";
// Common components
import Card from "../../components/common/others/Card";
import DatePickerInput from "../../components/common/others/DatePickerInput";
import TextInput from "../../components/common/others/TextInput";
// Components specific to gift card page
import AmountSelector from "../../components/home-related/gift-card/AmountSelector";
import SlideOne from "../../components/home-related/gift-card/SlideOne";
import SlideTwo from "../../components/home-related/gift-card/SlideTwo";
import DropdownMenu from "../../components/common/others/DropdownMenu";
import axios from "./../../utils/http";
import API from "./../../utils/api";
import RadioButton from "../../components/home-related/shopping-cart/payment-method/sub-components/RadioButton";
import CircleLoading from "../../components/common/others/CircleLoading";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import instance from "./../../utils/http";

const GiftCard = () => {
    const {i18n} = useTranslation();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [amount, setAmount] = useState(0);
    const client = useSelector(state => state.client);
    const [receiverEmailAddress, setReceiverEmailAddress] = useState("");
    const [receiverName, setReceiverName] = useState("");
    const [message, setMessage] = useState("");
    const [sendDate, setSendDate] = useState("");
    const [currencies, setCurrencies] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const [prices, setPrices] = useState([]);
    const [selectedPrice, setSelectedPrice] = useState(null);
    const [isWallet, setIsWallet] = useState(false);
    const [loading, setLoading] = useState(false);
    const paymentMethods = {
        IRR: {
            wallet: true, gateway: true
        }, IQD: {
            wallet: true, gateway: false
        }, USD: {
            wallet: true, gateway: true
        }
    };

    useEffect(() => {
        if (!client) {
            toast(t("Authentication Failed! Please Login Again"), {type: 'error'})
            navigate('/');
            return;
        }
        getPrices();
    }, []);

    const getPrices = async () => {
        try {
            const {data} = await axios.get(API.gift.prices);

            if (data.success) {
                setCurrencies(data.data.gift_prices);
            }

        } catch (e) {

        }
    };

    const handleSelectCurrency = (currency) => {
        setSelectedCurrency(currency);
        if (currency.currency === "IQD") {
            setIsWallet(true);
        }
        setSelectedPrice(null);
        setPrices(currency.prices?.map(p => ({
            key: p.id, value: `${p?.price} ${currency.title}`
        })));
    };

    const handleSendGift = async () => {
        if (loading)
            return;
        setLoading(true);
        try {
            const {data} = await axios.post(API.gift.store, {
                gift_price: selectedPrice.key,
                receiver_name: receiverName,
                receiver_email: receiverEmailAddress,
                message: message,
                currency: selectedCurrency.currency,
                send_at: sendDate,
                wallet: +isWallet
            });

            if (data.success) {
                if (data.data.link) {
                    toast(t("Your Request Submitted. You are now Redirecting to Payment Gateway!"), {type: "success"});
                    window.location.href = data.data.link;
                    return;
                } else {
                    toast(data.message, {type: "success"});
                }
            }
        } catch (e) {

        }
        setLoading(false);
    };

    return (<Card className={"gift-card"}>
        <div>
            <p>{t("Anobani gift card")}</p>
            <p>{t("The best gift for the best")}</p>
            <DropdownMenu value={selectedCurrency ? selectedCurrency.title : t("Select Currency")}>
                {currencies.map(currency => <div className={"currency"} key={currency.currency}
                                                 onClick={() => handleSelectCurrency(currency)}>
                    {currency.title}
                </div>)}
            </DropdownMenu>
            {prices.length ?
                <AmountSelector value={selectedPrice} updateValue={setSelectedPrice} values={prices}/> : null}

            {selectedCurrency && selectedPrice ? <div>
                {paymentMethods[selectedCurrency.currency]?.wallet && <RadioButton
                    group={"payment-method"}
                    id={"anobani-wallet"}
                    checked={isWallet}
                    toggleValue={() => setIsWallet(true)}
                />}
                {paymentMethods[selectedCurrency.currency]?.gateway && <RadioButton
                    group={"payment-method"}
                    id={selectedCurrency.currency === 'USD' ? 'paypal-gateway' : "internet-wallet"}
                    checked={!isWallet}
                    toggleValue={() => setIsWallet(false)}
                />}
            </div> : null}
            <TextInput
                placeholder={t("Receiver email address")}
                value={receiverEmailAddress}
                onChange={(e) => setReceiverEmailAddress(e.target.value)}
                ltr
            />
            <TextInput
                placeholder={t("Receiver name")}
                value={receiverName}
                onChange={(e) => setReceiverName(e.target.value)}
            />
            <textarea
                placeholder={t("Message with card")}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
            />
            {/*<DatePickerInput value={sendDate} />*/}
            <DatePickerInput
                placeholder={'YYYY/MM/DD'}
                value={sendDate}
                onChange={(date) => setSendDate(date)}
                clearable
            />
            {/*<TextInput*/}
            {/*    placeholder={"YYYY/MM/DD"}*/}
            {/*    value={sendDate}*/}
            {/*    onChange={(e) => setSendDate(e.target.value)}*/}
            {/*/>*/}
            <p>{t("You can specify the time of sending the gift card email.")}</p>
        </div>

        <div>
            <Swiper
                className={"gift-card-slider"}
                dir={i18n.language === "fa" ? "rtl" : "ltr"}
                loop={true}
                modules={[Pagination]}
                pagination
            >
                <SwiperSlide>
                    <SlideOne value={selectedPrice?.value}/>
                </SwiperSlide>

                <SwiperSlide>
                    <SlideTwo value={selectedPrice?.value}/>
                </SwiperSlide>
            </Swiper>

            <div>
                {/*<button type={"button"} className={"button ternary"}>*/}
                {/*  {t("Cancel")}*/}
                {/*</button>*/}
                <button onClick={handleSendGift} type={"button"} className={"button primary"}>
                    {loading ? <CircleLoading/> : t("Send")}
                </button>
            </div>
        </div>
    </Card>);
};

export default GiftCard;
