export default {
    fa:{
        currency:'IRR'
    },
    kuk:{
        currency:'USD'
    },
    ku:{
        currency:'IQD'
    },
}