import React from "react";
import "./RadioButton.scss";
// Utils
import images from "../../../../../utils/images";
import { useTranslation } from "react-i18next";

const RadioButton = (props) => {
  const { t } = useTranslation();
  return (
    <label
      className={`shopping-cart-radio-button ${props.disabled && "disabled"}`}
      htmlFor={`radio-button-${props.id}`}
    >
      <div style={{ flexGrow: 1 }}>
        <input
          type={"radio"}
          id={`radio-button-${props.id}`}
          checked={props.checked}
          onChange={props.toggleValue}
          disabled={props.disabled}
        />

        <div>
          <div />
        </div>

        <p>
          {props.id === "internet-wallet"
            ? t("Gateway Payment")
            : ""}
          &nbsp;
          {props.id === "paypal-gateway"
            ? t("Pay with Paypal")
            : ""}
          {props.id === "anobani-wallet"
            ? t("Pay with Anobani Wallet")
            : ""}
          &nbsp;
          {props.id === "internet-wallet" ? <span>{t("Pay with all Shetabit Cards")}</span> : null}
          {props.id === "anobani-wallet" && props?.subTitle ? <span> ({props?.subTitle}) </span> : null}
        </p>
      </div>

      <img alt={"Shetab logo"} src={images.shetabLogo} />
    </label>
  );
};

export default RadioButton;
