import React, { useEffect, useState } from "react";
import "./css/Library.scss";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import ProductSlider, {
    ProductSliderSkeleton,
} from "../../components/home-related/home/ProductSlider";
import axios from "./../../utils/http";
import API from "./../../utils/api";
import { getRouter } from "../../utils/helpers";

const Library = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [currentTab, setCurrentTab] = useState("my-books");
    const [loading, setLoading] = useState(false);

    const [libraryItem, setLibraryItem] = useState({
        electronic_book: [],
        audio_book: [],
        podcast: [],
        magazine: [],
    });

    useEffect(() => {
        getLibrary();
    }, []);

    const getLibrary = async () => {
        setLoading(true);
        try {
            const { data } = await axios.get(API.library.library);

            if (data.success) {
                setLibraryItem(data.data);
            }
        } catch (e) {}
        setLoading(false);
    };
    const removeFromLibrary = async (product) => {
        try {
            const { data } = await axios.delete(
                getRouter(API.library.removeFromLibrary, {
                    libraryItemId: product.library_id,
                })
            );

            if (data.success) {
                await getLibrary();
            }
        } catch (e) {}
    };

    const goToReaderPage = (product) => {
        console.log(product);
        return navigate("/reader", {
            state: {
                url: product?.main?.epub?.url,
                title: product?.title,
                slug: product?.slug,
                locale: product?.language,
                productType: product?.order_type,
                purchaseType: "main",
            },
        });
    };

    if (loading)
        return (
            <>
                <h1 className={"library-title"}>کتابخانه من</h1>
                <p className={"library-description"}>
                    می توانید لیست تمام کتاب های خود را ببینید و ویرایش های لازم
                    را انجام دهید
                </p>
                <div className={"library"}>
                    <div className={"tab-selector-container"}>
                        <p
                            className={
                                currentTab === "my-books" ? "active" : undefined
                            }
                        >
                            {t("My books")}
                        </p>
                        <p
                            className={
                                currentTab === "my-favorite-books"
                                    ? "active"
                                    : undefined
                            }
                            onClick={() => navigate("/bookmarks")}
                        >
                            {t("My favorite books")}
                        </p>
                    </div>
                    <div className={"library-products-container"}>
                        <div className={"title-container"}>
                            <p className={"title"}>{t("E-Books")}</p>
                            <p>{t("See all")}</p>
                        </div>
                        <ProductSliderSkeleton type={"electronic_book"} />
                        <div className={"title-container"}>
                            <p className={"title"}>{t("Magazines")}</p>
                            <p>{t("See all")}</p>
                        </div>
                        <ProductSliderSkeleton type={"magazine"} />
                        <div className={"title-container"}>
                            <p className={"title"}>{t("Podcasts")}</p>
                            <p>{t("See all")}</p>
                        </div>
                        <ProductSliderSkeleton type={"podcast"} />
                        <div className={"title-container"}>
                            <p className={"title"}>{t("Audio books")}</p>
                            <p>{t("See all")}</p>
                        </div>
                        <ProductSliderSkeleton type={"audio_book"} />
                    </div>
                </div>
            </>
        );

    return (
        <>
            <h1 className={"library-title"}>کتابخانه من</h1>
            <p className={"library-description"}>
                می توانید لیست تمام کتاب های خود را ببینید و ویرایش های لازم را
                انجام دهید
            </p>
            <div className={"library"}>
                <div className={"tab-selector-container"}>
                    <p
                        className={
                            currentTab === "my-books" ? "active" : undefined
                        }
                    >
                        {t("My books")}
                    </p>
                    <p
                        className={
                            currentTab === "my-favorite-books"
                                ? "active"
                                : undefined
                        }
                        onClick={() => navigate("/bookmarks")}
                    >
                        {t("My favorite books")}
                    </p>
                </div>

                <div className={"library-products-container"}>
                    {libraryItem.electronic_book.length ? (
                        <>
                            <div className={"title-container"}>
                                <p className={"title"}>{t("E-Books")}</p>
                                <Link to={"/library/electronic_book"}>
                                    {t("See all")}
                                </Link>
                            </div>
                            <ProductSlider
                                products={libraryItem.electronic_book}
                                type={"electronic_book"}
                                onRemoveLibrary={(libraryItem) =>
                                    removeFromLibrary(libraryItem)
                                }
                                onReadOnline={goToReaderPage}
                            />
                        </>
                    ) : null}

                    {libraryItem.magazine.length ? (
                        <>
                            <div className={"title-container"}>
                                <p className={"title"}>{t("Magazines")}</p>
                                <Link to={"/library/magazine"}>
                                    {t("See all")}
                                </Link>
                            </div>
                            <ProductSlider
                                products={libraryItem.magazine}
                                type={"magazine"}
                                onRemoveLibrary={(libraryItem) =>
                                    removeFromLibrary(libraryItem)
                                }
                                onReadOnline={goToReaderPage}
                            />
                        </>
                    ) : null}

                    {libraryItem.podcast.length ? (
                        <>
                            <div className={"title-container"}>
                                <p className={"title"}>{t("Podcasts")}</p>
                                <Link to={"/library/podcast"}>
                                    {t("See all")}
                                </Link>
                            </div>
                            <ProductSlider
                                products={libraryItem.podcast}
                                type={"podcast"}
                                onRemoveLibrary={(libraryItem) =>
                                    removeFromLibrary(libraryItem)
                                }
                            />
                        </>
                    ) : null}

                    {libraryItem.audio_book.length ? (
                        <>
                            <div className={"title-container"}>
                                <p className={"title"}>{t("Audio books")}</p>
                                <Link to={"/library/audio_book"}>
                                    {t("See all")}
                                </Link>
                            </div>
                            <ProductSlider
                                products={libraryItem.audio_book}
                                type={"audio_book"}
                                onRemoveLibrary={(libraryItem) =>
                                    removeFromLibrary(libraryItem)
                                }
                            />
                        </>
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default Library;
