import React from "react";
import "./css/CurrencyToggler.scss";
import { useTranslation } from "react-i18next";

const CurrencyToggle = ({ products, currencies, currency, onCurrencyChange }) => {
  const { t } = useTranslation();

  return (
    <div className={"currency-toggle"}>
      <p>{products.length < 1 && t("Empty cart")}</p>

      <div>
        {currencies.map(c => <p
          className={currency?.currency === c.currency ? "active" : undefined}
          onClick={() => onCurrencyChange(c)}
        >
          {c.title}
        </p>)}
      </div>
    </div>
  );
};

export default CurrencyToggle;
