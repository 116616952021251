import React, { useEffect, useState } from 'react';
import './IntroBanner.scss';
import { useTranslation } from 'react-i18next';
// Utils
import Skeleton from 'react-loading-skeleton';
import { Swiper, SwiperSlide } from 'swiper/react';
import SliderButtons from './common/SliderButtons';
import { Autoplay } from 'swiper';
// import { useLocation } from 'react-router-dom';

export const IntroBannerSkeleton = () => (
  <Skeleton height={452} style={{ borderRadius: 16, marginBottom: 32 }} />
);

const IntroBanner = ({ banner }) => {
  const { i18n } = useTranslation();
  const [mobileView, setMobileView] = useState();

  // const location = useLocation();

  useEffect(() => {
    if (window.innerWidth <= 768) setMobileView(true);
    else setMobileView(false);

    window.addEventListener('resize', () => {
      if (window.innerWidth <= 768) setMobileView(true);
      else setMobileView(false);
    });
  }, []);

  useEffect(() => {
    // console.log(mobileView);
  }, [mobileView]);

  // useEffect(() => {
  //   if (location.search == '?app_view=true') {
  //     setMobileView(true);
  //   }
  // }, [location]);

  return (
    <Swiper
      // className={`product-and-site-suggestions-slider electronic_book`}
      dir={i18n.language !== 'kuk' ? 'rtl' : 'ltr'}
      modules={[Autoplay]}
      // pagination={{clickable: true}}
      pagination={false}
      navigation={false}
      loop={true}
      spaceBetween={16}
      slidesPerView={1}
      autoplay={{
        delay: 3000,
        pauseOnMouseEnter: true,
      }}
      // breakpoints={productSliderBreakpointsConfig}
    >
      {banner.map((slide, index) => (
        <SwiperSlide style={{ alignSelf: 'flex-end' }} key={index}>
          <div
            className={`home-intro-slider ${i18n.language === 'kuk' && 'ltr'}`}
          >
            {slide.product || slide.link ? (
              <a
                style={{
                  display: 'block',
                  width: '100%',
                  height: 'auto',
                  maxHeight: '100%',
                }}
                target={'_blank'}
                href={
                  slide.product ? `/products/${slide.product.slug}` : slide.link
                }
              >
                {mobileView ? (
                  <img
                    alt={'Slide'}
                    className={'slider-image'}
                    src={slide.mobile_slide_url}
                  />
                ) : (
                  <img
                    alt={'Slide'}
                    className={'slider-image'}
                    src={slide.slide_url}
                  />
                )}
              </a>
            ) : mobileView ? (
              <img
                alt={'Slide'}
                className={'slider-image'}
                src={slide.mobile_slide_url}
              />
            ) : (
              <img
                alt={'Slide'}
                className={'slider-image'}
                src={slide.slide_url}
              />
            )}
          </div>
        </SwiperSlide>
      ))}

      <SliderButtons />
    </Swiper>
  );
};

export default IntroBanner;
