import React, { useState } from "react";
import "./css/WorkWithUs.scss";
import { useTranslation } from "react-i18next";
// Common components
import Card from "../../components/common/others/Card";
import DatePickerInput from "../../components/common/others/DatePickerInput";
import DropdownMenu from "../../components/common/others/DropdownMenu";
import PhoneNumberInput from "../../components/common/phone-number-input/PhoneNumberInput";
import RadioButton from "../../components/common/others/RadioButton";
import TextInput from "../../components/common/others/TextInput";
// Form validation
import { Formik } from "formik";
import { workWithUsSchemas } from "../../utils/formValidationSchemas";
// Utils
import icons from "../../utils/icons";
import workWithUsInputsConfigs from "../../utils/configs/workWithUsInputs";

const WorkWithUs = () => {
  const { t } = useTranslation();
  const [gender, setGender] = useState("male");
  const [field, setField] = useState(t("Publishing"));
  const [credentials, setCredentials] = useState([]);

  function submit(values) {}

  return (
    <Card className={"work-with-us"}>
      <div className={"header"}>
        <p>{t("Work with us")}</p>
        <p>
          {t(
            "In case of publishing publications or requesting cooperation, send us your information so that it can be confirmed after reviewing and completing the registration process for you and your team."
          )}
        </p>

        <div>
          <div>
            {icons.pdf}
            <p>{t("Publishers register file")}</p>
          </div>

          <div>
            {icons.pdf}
            <p>{t("Marketeer register guide file")}</p>
          </div>
        </div>
      </div>

      <div className={"form"}>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            nationalID: "",
            dialCode: "+98",
            phoneNumber: "",
            emailAddress: "",
            birthday: "",
            publishersName: "",
            inviteCode: "",
            centralOfficeAddress: "",
            publishersDialCode: "+98",
            publishersPhoneNumber: "",
            postalCode: "",
            publishersEmailAddress: "",
            publishersCode: "",
            publishersDetails: "",
          }}
          onSubmit={(values) => submit()}
        >
          {({
            errors,
            values,
            handleChange,
            setFieldTouched,
            touched,
            setFieldValue,
            handleSubmit,
          }) => (
            <>
              <div>
                {workWithUsInputsConfigs.rightSection.map(
                  ({ placeholder, fieldName }) => (
                    <TextInput
                      key={placeholder}
                      placeholder={t(placeholder)}
                      value={values[fieldName]}
                      onChange={handleChange(fieldName)}
                      onBlur={() => setFieldTouched(fieldName)}
                      error={{
                        message: errors[fieldName],
                        visibility: touched[fieldName],
                      }}
                    />
                  )
                )}
                <div>
                  <RadioButton
                    group={"gender"}
                    id={"male"}
                    checked={gender === "male"}
                    updateValue={() => setGender("male")}
                    title={t("Male")}
                  />
                  <RadioButton
                    group={"gender"}
                    id={"female"}
                    checked={gender === "female"}
                    updateValue={() => setGender("female")}
                    title={t("Female")}
                  />
                </div>
                <PhoneNumberInput
                  dialCode={values.dialCode}
                  onDialCodeChange={(dialCode) =>
                    setFieldValue("dialCode", dialCode)
                  }
                  phoneNumber={values.phoneNumber}
                  onPhoneNumberChange={handleChange("phoneNumber")}
                />
                <TextInput
                  placeholder={t("Email address")}
                  value={values.emailAddress}
                  onChange={handleChange("emailAddress")}
                  onBlur={() => setFieldTouched("emailAddress")}
                  error={{
                    message: errors.emailAddress,
                    visibility: touched.emailAddress,
                  }}
                  ltr
                />
                <DropdownMenu value={field}>
                  <p onClick={() => setField(t("Publishing"))}>
                    {t("Publishing")}
                  </p>
                  <p onClick={() => setField(t("Marketing"))}>
                    {t("Marketing")}
                  </p>
                </DropdownMenu>
                <DatePickerInput
                  value={values.birthday}
                  updateValue={(date) => setFieldValue("birthday", date)}
                />
                <div>
                  <p>{t("Upload file")}</p>
                  <p>
                    {t(
                      "Please upload the photos of your identity and the relevant publishing credentials."
                    )}
                  </p>
                  <div>
                    {credentials.map((item) => (
                      <img
                        key={item}
                        alt={"Credential"}
                        src={URL.createObjectURL(item)}
                      />
                    ))}
                  </div>
                  <div>
                    <input
                      type={"file"}
                      onChange={(e) =>
                        setCredentials([...credentials, e.target.files[0]])
                      }
                    />
                    <p>آپلود تصویر</p>
                  </div>
                </div>
              </div>

              <div>
                {workWithUsInputsConfigs.leftSection.partOne.map(
                  ({ placeholder, fieldName }) => (
                    <TextInput
                      key={placeholder}
                      placeholder={t(placeholder)}
                      value={values[fieldName]}
                      onChange={handleChange(fieldName)}
                      onBlur={() => setFieldTouched(fieldName)}
                      error={{
                        message: errors[fieldName],
                        visibility: touched[fieldName],
                      }}
                    />
                  )
                )}
                <PhoneNumberInput
                  dialCode={values.publishersDialCode}
                  onDialCodeChange={(dialCode) =>
                    setFieldValue("publishersDialCode", dialCode)
                  }
                  phoneNumber={values.publishersPhoneNumber}
                  onPhoneNumberChange={handleChange("publishersPhoneNumber")}
                  disabled={field !== t("Publishing")}
                />
                {workWithUsInputsConfigs.leftSection.partTwo.map(
                  ({ placeholder, fieldName }) => (
                    <TextInput
                      key={placeholder}
                      placeholder={t(placeholder)}
                      value={values[fieldName]}
                      onChange={handleChange(fieldName)}
                      onBlur={() => setFieldTouched(fieldName)}
                      error={{
                        message: errors[fieldName],
                        visibility: touched[fieldName],
                      }}
                    />
                  )
                )}
                <textarea
                  placeholder={t("Publisher's details")}
                  value={values.publishersDetails}
                  onChange={handleChange("publishersDetails")}
                />
                <button
                  type={"button"}
                  className={"button primary"}
                  onClick={() => handleSubmit()}
                >
                  {t("Confirm and send")}
                </button>
              </div>
            </>
          )}
        </Formik>
      </div>
    </Card>
  );
};

export default WorkWithUs;
