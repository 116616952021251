import React, {useEffect} from "react";
import "./css/Dashboard.scss";
import {Routes, Route, useParams, useNavigate} from "react-router-dom";
// Common components
import Card from "../../components/common/others/Card";
// Components specific to dashboard
import TabNavigator from "../../components/dashboard-related/tab-navigator/TabNavigator";
// // Common
import Profile from "../../components/dashboard-related/common/profile/Profile";
import Settings from "../../components/dashboard-related/common/others/Settings";
import Wallet from "../../components/dashboard-related/common/wallet/Wallet";
import Gadgets from "../../components/dashboard-related/common/others/Gadgets";
// // User specific
import PurchaseRecords from "../../components/dashboard-related/user-specific/PurchaseRecords";
import StudyTimeReport from "../../components/dashboard-related/user-specific/study-time-report/StudyTimeReport";
// // Publisher specific
import SalesRecords from "../../components/dashboard-related/publisher-specific/sales-records/SalesRecords";
// // Marketeer specific
import MyPublishers from "../../components/dashboard-related/marketeer-specific/MyPublishers";
import Profit from "../../components/dashboard-related/marketeer-specific/Profit";
import {useSelector} from "react-redux";
import PurchaseRecord from "../../components/dashboard-related/user-specific/PurchaseRecord";
import PublisherProfile from "../../components/dashboard-related/common/publisher-profile/PublisherProfile";
import PublisherRegister from "../auth/PublisherRegister";
import MarketerProfile from "../../components/dashboard-related/common/marketer-profile/MarketerProfile";
import Product from "../../components/dashboard-related/publisher-specific/product/Product";
import MyProducts from "../../components/dashboard-related/publisher-specific/my-product/MyProducts";
import MyProductsType from "../../components/dashboard-related/publisher-specific/my-products-type/MyProductsType";

const Dashboard = () => {
    const client = useSelector((state) => state.client);
    const {userType} = useParams();
    const navigate = useNavigate();

    if (
        !client ||
        (userType.toLowerCase() !== "user" &&
            userType.toLowerCase() !== "publisher" &&
            userType.toLowerCase() !== "marketer")
    ) {
        navigate("/404-not-found");
        return null;
    }
    return (
        <Card className={"dashboard"}>
            <TabNavigator/>

            <div>
                <Routes>
                    {/* Common */}
                    <Route
                        path={"/profile"}
                        element={(() => {
                            switch (userType.toLowerCase()) {
                                case "user":
                                    return <Profile/>;
                                case "publisher":
                                    return <PublisherProfile/>;
                                default:
                                    return <MarketerProfile/>;
                            }
                        })()}
                    />
                    <Route path={"/settings"} element={<Settings/>}/>
                    <Route path={"/wallet"} element={<Wallet/>}/>
                    <Route path={"/gadgets"} element={<Gadgets/>}/>
                    {/* User specific */}
                    {client.role === 'user' ? <Route path={"/purchase-records"} element={<PurchaseRecords/>}/> : null}
                    {client.role === 'user' ? <Route
                        path={"/purchase-records/:orderNumber"}
                        element={<PurchaseRecord/>}
                    /> : null}
                    {client.role === 'user' ? <Route path={"/study-time-report"} element={<StudyTimeReport/>}/> : null}
                    {/* Publisher specific */}
                    {client.role === 'publisher' ? <Route path={"/products"} element={<MyProducts/>}/> : null}
                    {client.role === 'publisher' ? <Route path={"/products/create"} element={<Product/>}/> : null}
                    {client.role === 'publisher' ? <Route path={"/products/:slug/edit"} element={<Product/>}/> : null}
                    {client.role === 'publisher' ? <Route path={"/products/:type"} element={<MyProductsType/>}/> : null}
                    {client.role === 'publisher' ? <Route path={"/sales-records"} element={<SalesRecords/>}/> : null}
                    {/* Marketeer specific */}
                    {client.role === 'marketer' ? <Route path={"/my-publishers"} element={<MyPublishers/>}/> : null}
                    {client.role === 'marketer' ? <Route path={"/profit"} element={<Profit/>}/> : null}
                </Routes>
            </div>
        </Card>
    );
};

export default Dashboard;
