import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import "./css/EmailLogin.scss";
// Common components
import TextInput from "../../../common/others/TextInput";
// Form validation
import { Formik } from "formik";
// Utils
import NavbarContext from "../../../../utils/contexts/Navbar";
import axios from "../../../../utils/http";
import { getRouter } from "../../../../utils/helpers";
import API from "../../../../utils/api";
import { logIn } from "../../../../redux/actions/clientActions";
import { useDispatch } from "react-redux";
import CircleLoading from "../../../common/others/CircleLoading";
import { useNavigate } from "react-router-dom";

const UsernameLogin = ({ type }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { modalsDisplayStatus, toggleModals, setMaxConnections } =
    useContext(NavbarContext);
  const [loading, setLoading] = useState(false);

  async function submit(values) {
    if (loading) return;

    setLoading(true);

    try {
      const { data } = await axios.post(
        getRouter(API.auth.marketerPublisherLogin, {
          userType: type,
        }),
        {
          username: values.username,
          password: values.password,
        }
      );

      if (data.success) {
        const { user, token } = data.data;
        localStorage.setItem("token", token);
        axios.setAuthorizationHeader(token);
        dispatch(logIn(user));
        toggleModals({ ...modalsDisplayStatus, auth: false });
      }
    } catch (e) {
      if (e.response?.status === 400 && e.response?.data?.data) {
        const data = e.response?.data?.data;
        setMaxConnections(data);
        toggleModals({
          ...modalsDisplayStatus,
          auth: false,
          maxConnection: true,
        });
      }
    }

    setLoading(false);
  }

  return (
    <div className={"username-login"}>
      <Formik
        initialValues={{ username: "", password: "" }}
        onSubmit={(values) => submit(values)}
      >
        {({
          errors,
          values,
          handleChange,
          touched,
          setFieldTouched,
          handleSubmit,
        }) => (
          <>
            <TextInput
              placeholder={t("Username")}
              value={values.username}
              onChange={handleChange("username")}
              onBlur={() => setFieldTouched("username")}
              error={{
                message: errors.username,
                visible: touched.username,
              }}
              ltr
            />
            <TextInput
              placeholder={t("Password")}
              value={values.password}
              onChange={handleChange("password")}
              error={{
                message: errors.password,
                visible: touched.password,
              }}
              isPassword
            />
            <p
              onClick={() =>
                toggleModals({
                  ...modalsDisplayStatus,
                  auth: false,
                  forgetPasswordRequest: true,
                })
              }
              className={"forget-password"}
            >
              {t("Forgot password")}
            </p>
            <button
              onClick={() => handleSubmit()}
              type={"button"}
              className={"button primary"}
            >
              {loading ? <CircleLoading /> : t("Log in")}
            </button>
            <button
              onClick={() => {
                if (type === "publisher") {
                  navigate("/auth/publisher/register");
                } else {
                  navigate("/auth/marketer/register");
                }
                toggleModals({ ...modalsDisplayStatus, auth: false });
              }}
              type={"button"}
              className={"button secondary"}
            >
              {t("Register")}
            </button>
          </>
        )}
      </Formik>
    </div>
  );
};

export default UsernameLogin;
