import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import "./PublisherProfile.scss";
import "./../../../../views/auth/PublisherRegister.scss";
import "./../../../modals/authentication/others/css/OTP.scss";

// Common components
// import DatePickerInput from "../../../common/others/DatePickerInput";
import RadioButton from "../../../common/others/RadioButton";
import TextInput from "../../../common/others/TextInput";
import PhoneNumberInput from "../../../common/phone-number-input/PhoneNumberInput";
// Sub components
// Utils
import { useSelector } from "react-redux";
import CircleLoading from "../../../common/others/CircleLoading";
import axios from "./../../../../utils/http";
import API from "../../../../utils/api";
import { getRouter } from "../../../../utils/helpers";
import { toast } from "react-toastify";
import icons from "../../../../utils/icons";
import OTPInput from "otp-input-react";
import ModalWrapper from "../../../common/others/ModalWrapper";
import { Formik } from "formik";
import { publisherRegisterSchemas } from "../../../../utils/formValidationSchemas";
import UploadZone from "../../../common/others/UploadZone";
import FileViewer from "../../../common/others/FileViewer";

const PublisherProfile = () => {
  const { t } = useTranslation();
  const client = useSelector((state) => state.client);
  const [loading, setLoading] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [otpType, setOtpType] = useState("EMAIL");
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(120);
  const [otpLoading, setOtpLoading] = useState(false);
  const [logo, setLogo] = useState("");
  const [documents, setDocuments] = useState([]);
  const [previewDocuments, setPreviewDocuments] = useState(client.documents);
  const [selectedDocumentPreview, setSelectedDocumentPreview] = useState(null);
  const [previewLogo, setPreviewLogo] = useState(client.publisher_logo_url);
  const [interval, setIntervalKey] = useState(null);
  const navigate = useNavigate();

  function renderTimer() {
    const minutes = Math.floor(timer / 60);
    const minutesToShow = minutes < 10 ? "0" + minutes : minutes;
    const seconds = timer % 60;
    const secondsToShow = seconds < 10 ? "0" + seconds : seconds;
    return minutesToShow + ":" + secondsToShow;
  }

  const [dialCode, setDialCode] = useState("+98");
  const [phoneNumber, setPhoneNumber] = useState("");

  const resendOTP = async (emailAddress) => {
    if (otpLoading || loading) return;
    setOtpLoading(true);
    if (!showOtpModal) setLoading(true);
    const putData = {};
    if (otpType === "EMAIL") {
      putData.email = emailAddress;
    } else {
      putData.mobile = phoneNumber;
      putData.country_code = dialCode;
    }
    try {
      const { data } = await axios.post(
        getRouter(API.auth.otp, {
          userType: "publisher"
        }),
        putData
      );

      if (data.success) {
        if (!showOtpModal) setShowOtpModal(true);
        toast(data.data?.code, { type: "success" });
        setTimer(120);
        let myTimer = 120;
        const myInterval = setInterval(() => {
          if (myTimer > 0) {
            myTimer--;
            setTimer(myTimer);
          } else {
            clearInterval(myInterval);
          }
        }, 1000);
        setIntervalKey(myInterval);
      }
    } catch (e) {
    }
    setLoading(false);
    setOtpLoading(false);
  };

  async function handleSubmit(values) {
    if (loading) return;
    setLoading(true);
    try {
      const requestData = {
        first_name: values.firstName,
        last_name: values.lastName,
        national_code: values.nationalID,
        gender: values.gender,
        country_code: dialCode.replace("+", ""),
        mobile: phoneNumber,
        email: values.emailAddress,
        otp: values?.otp || null,
        logo,
        phone: values.phone,
        publisher_name: values.publisherName,
        publisher_description: values.publisherDescription,
        publisher_email: values.publisherEmail,
        publisher_code: values.publisherCode,
        publisher_address: values.publisherAddress,
        post_box: values.postBox,
        postal_code: values.postalCode
      };
      const fd = new FormData();
      fd.append("_method", "PUT");
      for (const requestDataKey in requestData) {
        if (Object.hasOwnProperty.call(requestData, requestDataKey)) {
          fd.append(requestDataKey, requestData[requestDataKey]);
        }
      }

      const { data } = await axios.post(
        getRouter(API.auth.profile, { userType: "publisher" }),
        fd,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );
      if (data.success) {
        if (data.data?.status === "OTP") {
          toast(data.data.code, { type: "success" });
          setShowOtpModal(true);
          setTimer(120);
          setOtp("");
        } else {
          toast(t("Profile Updated Successfully!"), { type: "success" });
        }
      }
    } catch (e) {
    }
    setLoading(false);
  }

  const handleLogoSelected = (files, i) => {
    setLogo(files[0]);
    setPreviewLogo(URL.createObjectURL(files[0]));
  };

  const handleLogoRemoved = () => {
    setLogo("");
  };

  const handleUploadDocumentSuccess = (response) => {
    setDocuments([...documents, response.data.path]);
    setPreviewDocuments([
      ...previewDocuments,
      {
        id: response.data.id,
        url: response.data.url
      }
    ]);
  };

  const handleDocumentFileSelected = (files) => {
    setSelectedDocumentPreview(URL.createObjectURL(files[0]));
  };

  const handleDocumentRemoved = (file, index) => {
    setDocuments(documents.filter((d, i) => i !== index));
  };

  const handleRemoveDocument = async (item) => {
    const previewDocumentsClone = [...previewDocuments];
    setPreviewDocuments(previewDocuments?.filter((d) => d.id != item.id));
    try {
      const { data } = await axios.delete(
        getRouter(API.publisher.removeDocuments, { id: item.id })
      );
      if (data.success) {
        toast(data.message, { type: "success" });
      }
    } catch (e) {
      setPreviewDocuments(previewDocumentsClone);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          firstName: client.first_name,
          lastName: client.last_name,
          nationalID: client.national_code || '',
          emailAddress: client.email,
          gender: client.gender,
          dialCode: `+${client.country_code}`,
          phoneNumber: client.mobile,
          phone: client.phone || '',
          publisherName: client.publisher_name || '',
          publisherDescription: client.publisher_description || '',
          publisherEmail: client.publisher_email || '',
          publisherCode: client.publisher_code || '',
          publisherAddress: client.publisher_address || '',
          postBox: client.post_box || '',
          postalCode: client.postal_code || ''
        }}
        validateOnChange
        onSubmit={(value) => handleSubmit(value)}
        validationSchema={publisherRegisterSchemas}
      >
        {({
            values,
            handleChange,
            setFieldValue,
            errors,
            touched,
            setFieldTouched
          }) => (
          <>
            <div className={"publisher-register"}>
              <div className={"right-section"}>
                <TextInput
                  placeholder={t("First name")}
                  value={values.firstName}
                  onChange={handleChange("firstName")}
                  error={{
                    message: errors.firstName,
                    visibility: touched.firstName
                  }}
                  onBlur={() => setFieldTouched("firstName")}
                />
                <TextInput
                  placeholder={t("Last name")}
                  value={values.lastName}
                  onChange={handleChange("lastName")}
                  error={{
                    message: errors.lastName,
                    visibility: touched.lastName
                  }}
                  onBlur={() => setFieldTouched("lastName")}
                />
                <TextInput
                  placeholder={t("National ID")}
                  value={values.nationalID}
                  onChange={handleChange("nationalID")}
                  error={{
                    message: errors.nationalID,
                    visibility: touched.nationalID
                  }}
                  onBlur={() => setFieldTouched("nationalID")}
                />
                <div className={"gender-container"}>
                  <RadioButton
                    group={"gender"}
                    id={"male"}
                    title={t("Male")}
                    checked={values.gender === "male"}
                    updateValue={() => setFieldValue("gender", "male")}
                  />
                  <RadioButton
                    group={"gender"}
                    id={"female"}
                    title={t("Female")}
                    checked={values.gender === "female"}
                    updateValue={() => setFieldValue("gender", "female")}
                  />
                </div>
                <PhoneNumberInput
                  dialCode={values.dialCode}
                  onDialCodeChange={handleChange("dialCode")}
                  phoneNumber={values.phoneNumber}
                  onPhoneNumberChange={handleChange("phoneNumber")}
                  disabled
                />
                <TextInput
                  placeholder={t("Email address")}
                  value={values.emailAddress}
                  onChange={handleChange("emailAddress")}
                  error={{
                    message: errors.emailAddress,
                    visibility: touched.emailAddress
                  }}
                  onBlur={() => setFieldTouched("emailAddress")}
                  disabled
                />

                <TextInput
                  placeholder={t("Publisher Name")}
                  value={values.publisherName}
                  onChange={handleChange("publisherName")}
                  error={{
                    message: errors.publisherName,
                    visibility: touched.publisherName
                  }}
                  onBlur={() => setFieldTouched("publisherName")}
                />

                <textarea
                  value={values.publisherDescription}
                  style={{ height: 144 }}
                  placeholder={t("Description / Publisher Introduction")}
                  onChange={handleChange("publisherDescription")}
                  onBlur={() => setFieldTouched("publisherDescription")}
                ></textarea>
              </div>

              <div />

              <div className={"left-section"}>
                <p>{t("Upload file")}</p>
                <div className="uploader-zones">
                  <UploadZone
                    title={"آپلود لوگوی انتشارات"}
                    uploadOnSelect={false}
                    onSelect={handleLogoSelected}
                    onRemove={handleLogoRemoved}
                    logs={false}
                    preview={previewLogo}
                  />
                  <UploadZone
                    title={"آپلود مدارک"}
                    multiple
                    onUploadSuccess={handleUploadDocumentSuccess}
                    uploadOnSelect
                    uploadURL={API.publisher.documents}
                    onSelect={handleDocumentFileSelected}
                    uploadFolder="document"
                    onRemove={handleDocumentRemoved}
                    logs={false}
                    preview={selectedDocumentPreview}
                  />
                </div>

                <FileViewer
                  urls={previewDocuments}
                  title={"مدارک"}
                  onRemove={handleRemoveDocument}
                />

                <TextInput
                  placeholder={t("Phone with Country Code")}
                  value={values.phone}
                  onChange={handleChange("phone")}
                  error={{
                    message: errors.phone,
                    visibility: touched.phone
                  }}
                  onBlur={() => setFieldTouched("phone")}
                />
                <TextInput
                  placeholder={t("Postal Box")}
                  value={values.postBox}
                  onChange={handleChange("postBox")}
                  error={{
                    message: errors.postBox,
                    visibility: touched.postBox
                  }}
                  onBlur={() => setFieldTouched("postBox")}
                />
                <TextInput
                  placeholder={t("Postal Code")}
                  value={values.postalCode}
                  onChange={handleChange("postalCode")}
                  error={{
                    message: errors.postalCode,
                    visibility: touched.postalCode
                  }}
                  onBlur={() => setFieldTouched("postalCode")}
                />
                <TextInput
                  placeholder={t("Publisher Email")}
                  value={values.publisherEmail}
                  onChange={handleChange("publisherEmail")}
                  error={{
                    message: errors.publisherEmail,
                    visibility: touched.publisherEmail
                  }}
                  onBlur={() => setFieldTouched("publisherEmail")}
                />
                <TextInput
                  placeholder={t("Publisher Code")}
                  value={values.publisherCode}
                  onChange={handleChange("publisherCode")}
                  error={{
                    message: errors.publisherCode,
                    visibility: touched.publisherCode
                  }}
                  onBlur={() => setFieldTouched("publisherCode")}
                />
                <textarea
                  value={values.publisherAddress}
                  style={{ height: 144 }}
                  placeholder={t("Central Address")}
                  onChange={handleChange("publisherAddress")}
                  onBlur={() => setFieldTouched("publisherAddress")}
                ></textarea>
                <button
                  type={"button"}
                  className={"button primary submit-register"}
                  onClick={() => handleSubmit(values)}
                >
                  {loading ? <CircleLoading /> : t("Confirm")}
                </button>
              </div>
            </div>
            {showOtpModal && (
              <ModalWrapper
                className={"otp-modal"}
                toggle={() => {
                  console.log(interval);
                  clearInterval(interval);
                  setShowOtpModal(false);
                  setOtp("");
                }}
              >
                <div>
                  <p style={{ textAlign: "center" }}>
                    {otpType === "EMAIL"
                      ? values.emailAddress
                      : `${dialCode}${phoneNumber}`}
                  </p>
                </div>
                <p style={{ textAlign: "center" }}>
                  {otpType === "EMAIL"
                    ? t(
                      "Please enter the code sent via Email in the field below."
                    )
                    : t(
                      "Please enter the code sent via SMS in the field below."
                    )}
                </p>
                <OTPInput
                  value={otp}
                  onChange={(value) => {
                    setOtp(value);
                    if (value.length === 5) {
                      setShowOtpModal(false);
                      handleSubmit({
                        ...values,
                        otp: value
                      });
                    }
                  }}
                  autoFocus
                  OTPLength={5}
                  otpType="number"
                  className={"otp-input"}
                />
                {timer !== 0 && (
                  <div className={"center timer"}>
                    <p>{renderTimer()}</p>
                    {icons.progressRing}
                  </div>
                )}
                <p className={"center"}>{t("Remaining time")}</p>
                {timer === 0 && (
                  <button
                    type={"button"}
                    className={"button primary"}
                    onClick={() => resendOTP(values.emailAddress)}
                  >
                    {otpLoading ? <CircleLoading /> : t("Resend")}
                  </button>
                )}
              </ModalWrapper>
            )}
          </>
        )}
      </Formik>
    </>
  );
};

export default PublisherProfile;
