import { useRef, useState, createRef } from 'react';
import './css/AudioPlayer1.scss';
// Utils
import audioFile from '../../../../../utils/fake-data/az-zamin-ta-mah.mp3';
import icons from '../../../../../utils/icons';
import { useEffect } from 'react';

const AudioPlayer1 = ({
  url,
  cover,
  onTimeUpdate,
  audioBook,
  title,
  authors,
}) => {
  // const playerRef = useRef();
  // const [isPlaying, setIsPlaying] = useState(false);
  // const [volume, setVolume] = useState(50);
  // const [isMuted, setIsMuted] = useState(false);
  // const [time, setTime] = useState(0);
  // const [duration, setDuration] = useState(0);

  // function renderTimer() {
  //   if (duration === 0 && time === 0) {
  //     return '00:00/00:00';
  //   }
  //   if (playerRef.current) {
  //     // Current time
  //     let currentMinutes = Math.floor(playerRef.current.currentTime / 60);
  //     let currentSeconds = Math.floor(playerRef.current.currentTime % 60);
  //     if (currentMinutes < 10) {
  //       currentMinutes = '0' + currentMinutes;
  //     }
  //     if (currentSeconds < 10) {
  //       currentSeconds = '0' + currentSeconds;
  //     }
  //     const currentTime = currentMinutes + ':' + currentSeconds;
  //     // Total time
  //     let totalMinutes = Math.floor(playerRef.current.duration / 60);
  //     let totalSeconds = Math.floor(playerRef.current.duration % 60);
  //     if (totalMinutes < 10) {
  //       totalMinutes = '0' + totalMinutes;
  //     }
  //     if (totalSeconds < 10) {
  //       totalSeconds = '0' + totalSeconds;
  //     }
  //     const totalTime = totalMinutes + ':' + totalSeconds;
  //     // Timer
  //     return currentTime + '/' + totalTime;
  //   } else {
  //     return '00:00/00:00';
  //   }
  // }

  // function toggle() {
  //   if (isPlaying) {
  //     setIsPlaying(false);
  //     playerRef.current.pause();
  //   } else {
  //     setIsPlaying(true);
  //     playerRef.current.play();
  //   }
  // }

  // function handleTimeUpdate(e) {
  //   setDuration(Math.floor(e.target.duration));
  //   const totalTime = Math.floor(e.target.duration);
  //   const currentTime = Math.floor(e.target.currentTime);
  //   const percentage = (currentTime * 100) / totalTime;
  //   setTime(Math.floor(e.target.currentTime));
  //   if (onTimeUpdate) onTimeUpdate(currentTime);
  //   currentTime === totalTime && setIsPlaying(false);
  // }

  // function handleTimeChange(e) {
  //   const selectedTime = Math.floor(e.target.value);
  //   setTime(selectedTime);
  //   playerRef.current.currentTime = e.target.value;
  // }

  // function handleAudioChange(e) {
  //   playerRef.current.volume = e.target.value / 100;
  //   setVolume(e.target.value);
  // }

  // function handleMute() {
  //   if (isMuted) {
  //     playerRef.current.volume = volume / 100;
  //     setIsMuted(false);
  //   } else {
  //     playerRef.current.volume = 0;
  //     setIsMuted(true);
  //   }
  // }

  // const [isPlaying, setIsPlaying] = useState(false);
  // const [playbackSpeed, setPlaybackSpeed] = useState(1.0);
  // // const [volume, setVolume] = useState(0.5);
  // const [currentTime, setCurrentTime] = useState(0);
  // const [duration, setDuration] = useState(0);
  // const [progress, setProgress] = useState(0);

  // const audioRef = useRef();

  // const togglePlay = () => {
  //   if (isPlaying) {
  //     audioRef.current.pause();
  //   } else {
  //     audioRef.current.play();
  //   }
  //   setIsPlaying(!isPlaying);
  // };

  // const changeSpeed = (speed) => {
  //   setPlaybackSpeed(speed);
  //   audioRef.current.playbackRate = speed;
  // };

  // const skipForward = () => {
  //   audioRef.current.currentTime += 10; // Skip forward 10 seconds
  // };

  // const skipBackward = () => {
  //   audioRef.current.currentTime -= 10; // Skip backward 10 seconds
  // };

  // // const handleVolumeChange = (e) => {
  // //   const newVolume = parseFloat(e.target.value);
  // //   setVolume(newVolume);
  // //   audioRef.current.volume = newVolume;
  // // };

  // const handleTimeUpdate = () => {
  //   const currentTime = audioRef.current.currentTime;
  //   const duration = audioRef.current.duration;
  //   const progressPercentage = (currentTime / duration) * 100;
  //   setCurrentTime(currentTime);
  //   setDuration(duration);
  //   setProgress(progressPercentage);
  // };

  // const handleSeek = (e) => {
  //   const seekTime = (e.target.value / 100) * audioRef.current.duration;
  //   audioRef.current.currentTime = seekTime;
  // };

  // useEffect(() => {
  //   // Cleanup effect to clear interval when the component is unmounted
  //   return () => clearInterval(interval);
  // }, []);

  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isMuted, setIsMuted] = useState(false);
  const [playbackSpeed, setPlaybackSpeed] = useState(1);
  useEffect(() => {
    const audio = audioRef.current;

    if (!audio) return; // Add a check to handle initial render when audioRef.current is null

    const updateTime = () => setCurrentTime(audio.currentTime);
    const updateDuration = () => setDuration(audio.duration);

    audio.addEventListener('timeupdate', updateTime);
    audio.addEventListener('durationchange', updateDuration);

    return () => {
      audio.removeEventListener('timeupdate', updateTime);
      audio.removeEventListener('durationchange', updateDuration);
    };
  }, [audioRef.current]);

  useEffect(() => {
    // Update audio source when audioUrl changes
    audioRef.current.src = url;
    // Reset the player
    audioRef.current.load();
    setIsPlaying(false);
    setCurrentTime(0);
  }, [url]);

  const playPauseToggle = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeChange = (e) => {
    const newTime = e.target.value;
    audioRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  };

  const handleVolumeChange = (e) => {
    const newVolume = e.target.value;
    audioRef.current.volume = newVolume;
    setIsMuted(false);
  };

  const toggleMute = () => {
    const newMutedState = !isMuted;
    audioRef.current.muted = newMutedState;
    setIsMuted(newMutedState);
  };

  const skipTime = (amount) => {
    audioRef.current.currentTime += amount;
    setCurrentTime(audioRef.current.currentTime);
  };

  const changeSpeed = () => {
    const newSpeed = playbackSpeed === 1 ? 2 : 1;
    audioRef.current.playbackRate = newSpeed;
    setPlaybackSpeed(newSpeed);
  };

  const closeHandler = () => {
    audioBook(false);
  };

  return (
    <div className={'fixed-bottom row  player '}>
      {/* <div className='audio_detail'>
        <span>{icons.menu}</span>
        <div className='audio_detail_image'>
          <img src={cover} alt='' />
        </div>
        <div className='audio_detail_name'>
          <p className='title'>کوردبوون، ئاوێنەی ڕەنجی کوردایەتی</p>
          <p className='short_desc'>بەشی یەکەم</p>
        </div>
      </div>
      <div className='audio_play'>
        <div className='d-flex audio_play_first'>
          <span className='px-3'>{icons.twoX}</span>
          <span className='px-3'>{icons.mute}</span>
        </div>
        <div className='d-flex audio_play_second'>
          <span>۰۳:۰۳</span>
          <input type={'range'} />
          <span>۰۷:۳۴</span>
        </div>
        <div className='audio_play_third'>
          <span className='px-2'>{icons.increase}</span>
          <span className='px-2'>{icons.mainPlay}</span>
          <span className='px-2'>{icons.reverse}</span>
        </div>
      </div>
      <div className='close'>
        <span>{icons.closed}</span>
      </div> */}
      {/* <div>{icons.menu}</div>
      <div className='audio_image'>
        <img src={cover} alt='' />
      </div>

      <button onClick={() => changeSpeed(2)}>Speed 2x</button>

      <audio ref={audioRef} onTimeUpdate={handleTimeUpdate}>
        <source src={url} type='audio/mp3' />
        Your browser does not support the audio tag.
      </audio>
      <button onClick={skipBackward}>-10s</button>
      <button onClick={togglePlay}>{isPlaying ? 'Pause' : 'Play'}</button>
      <button onClick={skipForward}>+10s</button> */}
      <audio ref={audioRef}>
        <source src={url} type='audio/mp3' />
        Your browser does not support the audio tag.
      </audio>
      <div className='col-sm-3 col-md-3 col-11'>
        <div className='audio_detail'>
          <span>{icons.menu}</span>
          <div className='audio_detail_image'>
            <img src={cover} alt='' />
          </div>
          <div className='audio_detail_name'>
            <p className='title'>{title}</p>
            <p className='short_desc'>{authors}</p>
          </div>
        </div>
      </div>
      <div className='col-sm-8 col-md-8 col-0'>
        <div className='row audio_play text-center'>
          <div className='col-3 col-sm-2 col-md-1  order-sm-last order-md-first'>
            <div className='d-flex audio_play_first'>
              <span onClick={changeSpeed}>{icons.twoX}</span>
              <span className='pe-3' onClick={toggleMute}>
                {icons.mute}
              </span>
            </div>
          </div>
          <div className='col-9 col-sm-6 col-md-9 '>
            <div className='d-flex audio_play_second'>
              <span>{formatTime(currentTime)}</span>
              <input
                type={'range'}
                max={duration || 1}
                value={currentTime}
                onChange={handleTimeChange}
              />
              <span>{formatTime(duration)}</span>
            </div>
          </div>
          <div className='col-0 col-sm-3 col-md-2 '>
            <div className='audio_play_third'>
              <span
                className='px-2'
                onClick={() => {
                  skipTime(10);
                }}
              >
                {icons.increase}
              </span>
              <span className='px-2' onClick={playPauseToggle}>
                {isPlaying ? icons.pause1 : icons.mainPlay}
              </span>
              <span
                className='px-2'
                onClick={() => {
                  skipTime(-10);
                }}
              >
                {icons.reverse}
              </span>
            </div>
          </div>
        </div>
        {/* <div className='audio_play'>
          <div className='d-flex audio_play_first'>
            <span className='px-3'>{icons.twoX}</span>
            <span className='px-3'>{icons.mute}</span>
          </div>
          <div className='d-flex audio_play_second'>
            <span>۰۳:۰۳</span>
            <input type={'range'} />
            <span>۰۷:۳۴</span>
          </div>
          <div className='audio_play_third'>
            <span className='px-2'>{icons.increase}</span>
            <span className='px-2'>{icons.mainPlay}</span>
            <span className='px-2'>{icons.reverse}</span>
          </div>
        </div> */}
      </div>
      <div className='col-sm-1 col-md-1 col-1 order-first  order-md-last'>
        <div className='close'>
          <span onClick={closeHandler}>{icons.closed}</span>
        </div>
      </div>
    </div>
  );
};
const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
};
export default AudioPlayer1;
