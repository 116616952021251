import React, { Fragment } from 'react';
import './css/Table.scss';
import { useTranslation } from 'react-i18next';
import { getDateTime } from '../../../../../utils/helpers';

const Table = ({ records }) => {
  const { t } = useTranslation();
  const number = 25000;

  return (
    <div className={'sales-records-table'}>
      <table>
        <thead>
          <tr>
            <th>{t('Date / Day of payment')}</th>
            <th>{t('Order number')}</th>
            <th>{t('Price')}</th>
            <th>{t('Discount')}</th>
            <th>{t('Final Price')}</th>
            <th>{t('Profit')}</th>
            <th>{t('Sold product')}</th>
          </tr>
        </thead>

        <tbody>
          {records.map((record, index) => (
            <Fragment key={index}>
              <tr className={'spacer'}></tr>
              <tr
                key={index}
                className={!record.is_withdrawn ? 'yellow-row' : ''}
              >
                <td>{getDateTime(record.order.paid_at)}</td>
                <td>{record.order.number}</td>
                <td>{record.price}</td>
                <td>{record.discount}%</td>
                <td>{record.total_price}</td>
                <td>{record.total_price_publisher_profit}</td>
                <td>{record.product.title}</td>
              </tr>
            </Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
