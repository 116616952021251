import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import "./css/Gadgets.scss";
// Utils
import icons from "../../../../utils/icons";
import axios from "./../../../../utils/http";
import API from "./../../../../utils/api";
import {getRouter} from "../../../../utils/helpers";
import {useParams} from "react-router-dom";
import CircleLoading from "../../../common/others/CircleLoading";
import {toast} from "react-toastify";

const Gadgets = () => {
    const {t} = useTranslation();
    const {userType} = useParams();
    const [devices, setDevices] = useState([]);
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getDevices();
    }, [])

    const getDevices = async () => {
        try {
            const {data} = await axios.get(getRouter(API.dashboard.devices, {
                userType
            }));

            if (!data.success)
                return;

            setDevices(data.data.devices);

        } catch (e) {

        }
    }

    const handleDeleteDevice = async (device) => {
        if (loading)
            return;

        setLoading(true);
        setSelectedDevice(device);

        try {
            const {data} = await axios.delete(getRouter(API.dashboard.deleteDevice, {
                userType,
                deviceId: device.id
            }));

            if (!data.success) {
                toast(data.message, {type: 'error'})
                return;
            }

            toast(data.message, {type: 'success'});

            setDevices(devices.filter(d => d.id != device.id))

        } catch (e) {

        }
        setLoading(false);

    }

    return (
        <div className={"gadgets"}>
            <p>
                {t(
                    "The Reader app is usable across three different devices. If you want to use a new device, first remove one from the list below."
                )}
            </p>
            {/*<p>*/}
            {/*  {t(*/}
            {/*    "Note: Each user can delete the active device up to three times during one year (Starting from the registration date)."*/}
            {/*  )}*/}
            {/*</p>*/}
            {/*<p>*/}
            {/*  {t(*/}
            {/*    "Number of times left to remove the device in this year of membership: 4"*/}
            {/*  )}*/}
            {/*</p>*/}
            <p className={'active-devices-title'}>{t("Active devices")}</p>

            {devices.map((device) => (
                <div key={device.id}>
                    <p>{device.title}</p>

                    <button className={'btn-delete-device'} onClick={() => handleDeleteDevice(device)}>
                        {loading && device == selectedDevice
                            ? <CircleLoading color={'#1c3c53'}/>
                            : icons.delete}
                    </button>
                </div>
            ))}
        </div>
    );
};

export default Gadgets;
