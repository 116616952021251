import React, {useContext, useEffect, useRef, useState} from "react";
import "./AddReview.scss";
import {useTranslation} from "react-i18next";
// Common components
import TextInput from "../../common/others/TextInput";
// Sub components
import TabNavigator from "./sub-components/TabNavigator";
import ModalWrapper from "../../common/others/ModalWrapper";
import UploadZone from "../../common/others/UploadZone";
import ProductFormContext from "../../../utils/contexts/ProductForm";
import Uploader from "../../common/others/Uploader";
import axios from "../../../utils/http";
import Api from "../../../utils/api";
import {toast} from "react-toastify";
// Utils

const AddReviewModal = ({toggleDisplay}) => {
    const {t} = useTranslation();
    const {productData, setProductData} = useContext(ProductFormContext);

    const [sections, setSections] = useState(productData?.reviews);
    const [currentSection, setCurrentSection] = useState(0);

    const handleUploadReviewImage = async (files, base64) => {
        try {
            const fd = new FormData();
            fd.append("review_image", files[0]);
            const {data} = await axios.post(Api.publisher.products.reviews.uploadImage, fd, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });
            if (data.success) {
                const {id, path, url} = data.data;
                const sectionsClone = [...sections];
                if (sectionsClone[currentSection].images[0]) {
                    sectionsClone[currentSection].images[0].id = id;
                    sectionsClone[currentSection].images[0].file = files[0];
                    sectionsClone[currentSection].images[0].url = url;
                    sectionsClone[currentSection].images[0].path = path;
                    sectionsClone[currentSection].images[0].base64 = base64;
                } else {
                    sectionsClone[currentSection].images.push({
                        id: id,
                        file: files[0],
                        url: url,
                        path: path,
                        base64: base64
                    });
                }

                setSections(sectionsClone);
            }
        } catch (e) {
            const sectionsClone = [...sections];
            if (sectionsClone[currentSection].images[0]) {
                sectionsClone[currentSection].images[0].id = null;
                sectionsClone[currentSection].images[0].file = null;
                sectionsClone[currentSection].images[0].url = "";
                sectionsClone[currentSection].images[0].path = "";
                sectionsClone[currentSection].images[0].base64 = "";
            } else {
                sectionsClone[currentSection].images.push({
                    id: null,
                    file: null,
                    url: "",
                    path: "",
                    base64: ""
                });
            }

            setSections(sectionsClone);
        }
    };

    if (!sections?.length)
        return <ModalWrapper className={"add-review-modal review-modal-lg"} lg toggle={() => toggleDisplay()}>
            <p className={"add-review-title"}>{t("Add Review")}</p>
            <TabNavigator sections={sections} setSections={(sections) => {
                setSections(sections);
            }} currentSection={currentSection}
                          setCurrentSection={setCurrentSection}/>
            <div className="add-review-form" style={{borderRadius: 20}}>
                <p className={"reviews-not-founds"}>No Reviews Added</p>
            </div>
        </ModalWrapper>;

    return (
        <ModalWrapper className={"add-review-modal review-modal-lg"} lg toggle={() => toggleDisplay()}>
            <p className={"add-review-title"}>{t("Add Review")}</p>
            <TabNavigator sections={sections} setSections={(sections) => {
                setSections(sections);

            }} currentSection={currentSection}
                          setCurrentSection={setCurrentSection}/>

            <div className="add-review-form">
                <TextInput
                    placeholder={t("Section Title")}
                    value={sections[currentSection].title}
                    onChange={(e) => {
                        const sectionsClone = [...sections];
                        sectionsClone[currentSection].title = e.target.value;
                        setSections(sectionsClone);
                    }}
                />
                <textarea className={"review-text-input"} placeholder={t("Review Text")} onChange={(e) => {
                    const sectionsClone = [...sections];
                    sectionsClone[currentSection].text = e.target.value;
                    setSections(sectionsClone);
                }} value={sections[currentSection].text}></textarea>

                <div className="add-review-images">
                    <div className="review-image-info">
                        <textarea placeholder={t("Caption for Uploaded File")} onChange={(e) => {
                            const sectionsClone = [...sections];
                            if (sectionsClone[currentSection].images[0]) {
                                sectionsClone[currentSection].images[0].description = e.target.value;
                            } else {
                                sectionsClone[currentSection].images.push({
                                    description: e.target.value
                                });
                            }
                            setSections(sectionsClone);
                        }} value={sections[currentSection]?.images[0]?.description || ""}></textarea>
                        {/*<button className={"btn-confirm-review-image"}>{t("Submit")}</button>*/}
                    </div>
                    <div className="review-image-upload">
                        <Uploader title={t("Upload Image")}
                                  key={currentSection}
                                  preview={sections[currentSection]?.images[0]?.base64 || sections[currentSection]?.images[0]?.url}
                                  selectedFile={sections[currentSection]?.images[0]?.file}
                                  onUpload={handleUploadReviewImage}
                                  uploadOnChange
                                  onRemove={() => {
                                      const sectionsClone = [...sections];
                                      sectionsClone[currentSection].images[0].file = null;
                                      sectionsClone[currentSection].images[0].url = "";
                                      sectionsClone[currentSection].images[0].path = "";
                                      sectionsClone[currentSection].images[0].base64 = "";
                                      setSections(sectionsClone);
                                  }}
                        />
                    </div>
                </div>
                <div className="review-actions">
                    <button className={"btn-submit-review"} onClick={() => {
                        setProductData({
                            ...productData,
                            reviews: sections
                        })
                        toast.success(t('Review Added'))
                    }}>{t("Submit")}</button>
                    <button className={"btn-cancel-review"} onClick={() => toggleDisplay()}>{t("Cancel")}</button>
                </div>
            </div>
        </ModalWrapper>
    );
};

export default AddReviewModal;
