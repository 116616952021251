import React from "react";
import "./Step.scss";
import {useTranslation} from "react-i18next";
// Utils
import tempMedia from "../../../utils/tempMedia";
import Skeleton from "react-loading-skeleton";

export const StepSkeleton = () => {
    return (
        <div className={'guide-step-skeleton'}>
            <div style={{flexGrow: 1}}>
                <Skeleton width={'50%'}/>
                <Skeleton/>
                <Skeleton/>
                <Skeleton/>
            </div>

            <div className={'step-image-skeleton'}>
                <Skeleton height={250}/>
            </div>
        </div>
    );
}

const Step = ({title, content, image}) => {
    const {t} = useTranslation();

    return (
        <div className={"guide-step"}>
            <div>
                <p>{t(title)}</p>
                <p>{t(content)}</p>
            </div>

            <img alt={"Step"} className={'step-image'} src={image || tempMedia.others.guideStep}/>
        </div>
    );
};

export default Step;
