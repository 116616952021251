import "./css/PaymentVerify.scss";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import queryString from "query-string";
import API from "../../utils/api";
import axios from "../../utils/http";
import CircleLoading from "../../components/common/others/CircleLoading";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";


const PaymentVerify = (match) => {

    const navigate = useNavigate();
    const {t} = useTranslation();
    const search = useLocation().search;
    const [token, setToken] = useState("");
    const [status, setStatus] = useState("");
    const [loading, setLoading] = useState(true);
    const [result, setResult] = useState(false);
    const [message, setMessage] = useState("");
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        verifyPayment(queryString.parse(search));
    }, []);

    const verifyPayment = async (query) => {
        try {
            const {data} = await axios.get(API.public.verifyPayment, {
                params: {
                    token: query?.token || query?.Authority || "",
                    Status: query?.Status || ""
                }
            });

            setResult(data.success);
            setIsMobile(data.data.is_mobile);
            setMessage(data.message);
        } catch (e) {

        }
        setLoading(false);
    };


    return <div className={"payment-verify"}>
        {loading && <CircleLoading width={250} height={250} color={"#1c3c53"}/>}
        {!loading && <p className={`${result ? "payment-success" : "payment-failed"}`}>{message}</p>}
        {(!loading && isMobile) && <a href={"anubani://open.com"} className={'return-to-app'}>{t("Return to App")}</a>}
    </div>;
};

export default PaymentVerify;
