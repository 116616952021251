import React, { useContext } from 'react';
import './css/RightSection.scss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import images from '../../../../utils/images';
import footerConfigs from '../../../../utils/configs/footer';
import NavbarContext from '../../../../utils/contexts/Navbar';

const RightSection = ({ info }) => {
  const { t } = useTranslation();
  const { modalsDisplayStatus, toggleModals, authValues, setAuthValues } =
    useContext(NavbarContext);

  return (
    <div className={'footer-right-section'}>
      <img
        alt={'Anobani'}
        src={images.anobaniLogo}
        style={{ height: '72px', width: '260px' }}
      />

      <div>
        <div>
          <Link to='/about-us'>{t('About us')}</Link>
          <Link to='/contact-us'>{t('Contact us')}</Link>
          <Link to='/work-with-us'>{t('Work with us')}</Link>
          <Link to='/guide'>{t('Guide')}</Link>
          <Link to='/terms-of-use'>{t('Terms of use')}</Link>
          <button
            onClick={() => {
              setAuthValues({ ...authValues, userType: 'marketer' });
              toggleModals({ ...modalsDisplayStatus, auth: true });
            }}
          >
            {t('Login as Marketer')}
          </button>
          <br />
          <button className='footer_btn_danger mt-3 '>{t('Articles')}</button>
          <button className='footer_btn_primary mt-3 mx-3'>
            {t('Celebrities')}
          </button>

          {/* <div style={{ marginTop: 15 }} className='d-flex'>
            {footerConfigs.socialMedia.map((item, index) => (
              <div key={index}>
                <a href={item.link}>{item.icon}</a>
              </div>
            ))}
          </div> */}
        </div>

        <div>
          <p>{t('Recommended Books')}</p>
          {info.recommendedProducts.map((item) => (
            <Link key={item.id} to={`/products/${item?.slug}`}>
              {item?.title}
            </Link>
          ))}
        </div>

        <div>
          {/* <img
            alt={'Anobani'}
            src='/enamad.png'
            style={{ width: '125px', height: '136px' }}
          /> */}
          {/* <p>
            {t('Support')} <br /> {t('24/7')}
          </p> */}

          {/* <div style={{ marginTop: 15 }}>
            {footerConfigs.socialMedia.map((item, index) => (
              <div key={index}>
                <a href={item.link}>{item.icon}</a>
              </div>
            ))}
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default RightSection;
