import axios from 'axios';
import { toast } from 'react-toastify';
import { getLocale } from './helpers';
import { logOut } from '../redux/actions/clientActions';

// export const BASE_URL = 'http://192.168.1.7:8000/api';
// export const BASE_URL = 'http://anobaniapi.2.vistadev.ir/public/api';
// export const BASE_URL = 'http://anobaniapi-test.2.vistadev.ir/public/api';
// export const BASE_URL = 'https://anobaniapi-test.vistadev.ir/public/api';
export const BASE_URL = 'https://api.anubani.com/api';

const axiosConfig = {
  baseURL: BASE_URL,
  timeout: 0,
  headers: {
    'X-Localization': getLocale(),
  },
};
const instance = axios.create(axiosConfig);
instance.defaultAxios = axios.create(axiosConfig);
instance.translator = null;
instance.navicator = null;
instance.dispatch = null;
instance.meta = {
  skipDefaultCatch: false,
};

instance.interceptors.request.use(
  (req) => {
    // req.headers.common['X-Localization'] = getLocale();

    return req;
  },
  (err) => Promise.reject(err)
);

instance.interceptors.response.use(
  (res) => {
    const { data } = res;
    if (!data?.success && data?.message) {
      toast(data.message, { type: 'error' });
    }

    return res;
  },
  (err) => {
    if (typeof err.response?.status == 'undefined') {
      toast(instance.translator('Error Communicating with Server'), {
        type: 'error',
      });
      return Promise.reject(err);
    }

    const status = err.response.status;

    switch (status) {
      case 401:
        handleUnauthorized(err);
        break;
      case 400:
        handleDevices(err);
        break;
      case 403:
        handleForbidden(err);
        break;
      case 404:
        handleNotFound(err);
        break;
      case 422:
        handleUnprocessableEntity(err.response.data.errors);
        break;
      case 429:
        handleToManyRequest(err);
        break;
      case 500:
        handleServerError(err);
        break;
      default:
        handleTimeoutError();
        break;
    }

    return Promise.reject(err);
  }
);

instance.setAuthorizationHeader = (token) => {
  instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  instance.defaultAxios.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${token}`;
};

instance.setAuthorizationHeader(localStorage.getItem('token'));

const handleUnprocessableEntity = (messages) => {
  for (const field in messages) {
    toast(messages[field][0], { type: 'error' });
  }
};

const handleTimeoutError = () => {
  toast(instance.translator('Error Communicating with Server'), {
    type: 'error',
  });
};

const handleUnauthorized = () => {
  toast(instance.translator('Authentication Failed! Please Login Again'), {
    type: 'error',
  });
  instance.setAuthorizationHeader(null);
  localStorage.removeItem('token');
  instance.dispatch(logOut());
  // instance.navicator('/')
};

const handleNotFound = () => {
  // toast(instance.translator("Page or Resource Not Found"), {type: "error"});
  instance.navicator('/404-not-found', {
    replace: true,
  });
};

const handleForbidden = () => {
  //TODO: handle Forbidden
};
const handleDevices = () => {
  //TODO: handle Forbidden
};

const handleServerError = () => {
  //TODO: handle Server Error
  toast(
    instance.translator('Error Communicating with Server, Please Try Again'),
    { type: 'error' }
  );
};

const handleToManyRequest = () => {
  //TODO: handle TO Many Request
  toast(
    instance.translator(
      'Your Requests Have Exceeded the Limit. Please Wait a few Seconds and Try Again'
    ),
    { type: 'error' }
  );
  instance.navicator('/429-to-many-requests');
};

export default instance;
