import React, { useEffect, useState } from 'react';
import './Product.scss';
import FormRightSection from './sub-components/others/FormRightSection';
import FormLeftSection from './sub-components/others/FormLeftSection';
import ProductFormContext from '../../../../utils/contexts/ProductForm';
import axios from '../../../../utils/http';
import Api from '../../../../utils/api';
import CircleLoading from '../../../common/others/CircleLoading';
import { useParams } from 'react-router-dom';
import { getRouter } from '../../../../utils/helpers';
import moment from 'moment-jalaali';
import { isPlainObject } from 'lodash';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { logDOM } from '@testing-library/react';

const Product = () => {
  const { t } = useTranslation();
  const [productInfo, setProductInfo] = useState(null);
  const { slug } = useParams();
  const [loading, setLoading] = useState(false);
  const [productId, setProductId] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [locale, setLocale] = useState(false);
  const [previousLocale, setPreviousLocale] = useState(false);
  const [productData, setProductData] = useState({
    name: '',
    description: '',
    authors: [],
    translators: [],
    speakers: [],
    publishedDate: null,
    seasons: '',
    volume: '',
    purchaseType: 'free',
    currency: null,
    prices: {},
    discountStartedAt: null,
    discountEndedAt: null,
    isInSpecialDiscount: false,
    reviews: [],
    pageCount: '',
    cover: {
      url: '',
      file: null,
      path: '',
      base64: '',
    },
    demo: null,
    main: null,
    audioDemo: null,
    audioMain: null,
  });
  const [globalProductData, setGlobalProductData] = useState({
    productType: {
      key: 'ELECTRONIC_BOOK',
      value: t('Electronic Book'),
      has_audio_book: true,
      has_multiple_files: true,
    },
    hasAudioBook: false,
    category: null,
    subCategory: null,
    filterCategory: null,
    genre: null,
    collection: null,
    episode: null,
  });
  const [localesProductData, setLocalesProductData] = useState({});

  useEffect(() => {
    getProductInfo();
  }, []);

  useEffect(() => {
    if (previousLocale && locale && productInfo) {
      setLocalesProductData({
        ...localesProductData,
        [previousLocale.key]: { ...productData },
      });

      const currentLocaleProductData = localesProductData[locale.key]
        ? { ...localesProductData[locale.key] }
        : {
            name: '',
            description: '',
            authors: [],
            translators: [],
            speakers: [],
            publishedDate: null,
            seasons: '',
            volume: '',
            purchaseType: 'free',
            currency: productInfo?.currencies[0],
            prices: {},
            discountStartedAt: null,
            discountEndedAt: null,
            isInSpecialDiscount: false,
            reviews: [],
            cover: null,
            demo: null,
            audioDemo: null,
            main: null,
            audioMain: null,
            pageCount: '',
          };

      setProductData(currentLocaleProductData);

      setPreviousLocale(locale);
    }
  }, [locale]);

  // useEffect(() => {
  //     if (productId)
  //         return;
  //
  //     const newLocaleProductData = {};
  //     for (const lang in localesProductData) {
  //         const localeProduct = localesProductData[lang];
  //
  //         newLocaleProductData[lang] = {
  //             ...localeProduct,
  //             demo: localeProduct?.demo,
  //             main: localeProduct?.main,
  //             audioDemo: localeProduct?.audioDemo,
  //             audioMain: localeProduct?.audioMain,
  //         };
  //     }
  //     setLocalesProductData(newLocaleProductData);
  //
  //
  //     setProductData({
  //         ...productData,
  //         demo: productData?.demo,
  //         main: productData?.main,
  //         audioDemo: productData?.audioDemo,
  //         audioMain: productData?.audioMain,
  //     })
  //
  //
  // }, [globalProductData.productType])
  const getProductInfo = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(Api.publisher.products.info);
      const languages = data.data.languages?.map((language) => ({
        key: language.locale,
        value: language.title,
      }));
      const currencies = data.data.currencies?.map((currency) => ({
        key: currency.currency,
        value: currency.title,
      }));
      setProductInfo({
        ...data.data,
        languages,
        currencies,
      });
      setProductData({
        ...productData,
        currency: currencies[0],
      });
      setPreviousLocale(languages[0]);
      setLocale(languages[0]);

      if (slug) {
        await getProductData(languages, currencies);
      }
    } catch (e) {}
    setLoading(false);
  };

  const getProductData = async (languages, currencies) => {
    try {
      const { data } = await axios.get(
        getRouter(Api.publisher.products.product, {
          slug,
        })
      );
      if (data.success) {
        const product = data.data;
        setProductId(product.id);
        setGlobalProductData({
          productType: product.product_type,
          hasAudioBook: product.has_audio_book,
          category: product.category,
          subCategory: product.sub_category,
          filterCategory: product.filter_category,
          genre: product.genre,
          collection: product.collection,
        });

        const productLang = {};
        for (const lang in product.product) {
          const currentProductLang = product.product[lang];
          const currentPrices = {};
          for (const price of currentProductLang.prices) {
            currentPrices[price.currency] = {
              price: price.price,
              percent: price.discount_percent,
            };
          }

          productLang[lang] = {
            name: currentProductLang.title,
            isbn: currentProductLang.isbn,
            description: currentProductLang.description,
            seasons: currentProductLang.seasons,
            volume: currentProductLang.volume,
            pageCount: currentProductLang.pageCount,
            cover: currentProductLang.cover,
            purchaseType: currentProductLang.purchase_type,
            publishedDate: currentProductLang.published_at
              ? moment(currentProductLang.published_at)
              : null,
            isInSpecialDiscount: currentProductLang.has_special_discount,
            authors: currentProductLang.authors,
            translators: currentProductLang.translators,
            speakers: currentProductLang.speakers,
            demo:
              product.product_type.key === 'ELECTRONIC_BOOK' ||
              product.product_type.key === 'MAGAZINE'
                ? currentProductLang.demo.filter(
                    (demo) =>
                      demo.extension === 'epub' || demo.extension === 'pdf'
                  )?.[0]
                : currentProductLang.demo.filter(
                    (demo) => demo.extension === 'mp3'
                  )?.[0],
            main:
              product.product_type.key === 'ELECTRONIC_BOOK' ||
              product.product_type.key === 'MAGAZINE'
                ? currentProductLang.main.filter(
                    (main) =>
                      main.extension === 'epub' || main.extension === 'pdf'
                  )?.[0]
                : currentProductLang.main.filter(
                    (main) => main.extension === 'mp3'
                  )?.[0],
            audioDemo: product.has_audio_book
              ? currentProductLang.demo.filter(
                  (demo) => demo.extension === 'mp3'
                )?.[0]
              : null,
            audioMain: product.has_audio_book
              ? currentProductLang.main.filter(
                  (main) => main.extension === 'mp3'
                )?.[0]
              : null,
            currency:
              currentProductLang.purchase_type === 'paid'
                ? currencies[0]
                : null,
            prices: currentPrices,
            reviews: currentProductLang.reviews,
            discountStartedAt:
              currentProductLang.prices.length &&
              currentProductLang.prices[0].discount_started_at
                ? moment(currentProductLang.prices[0].discount_started_at)
                : null,
            discountEndedAt:
              currentProductLang.prices.length &&
              currentProductLang.prices[0].discount_ended_at
                ? moment(currentProductLang.prices[0].discount_ended_at)
                : null,
          };
        }
        const activeLocaleKey = Object.keys(productLang)[0];
        const activeLocale = languages.find(
          (lang) => lang.key === activeLocaleKey
        );
        setLocale(activeLocale);
        setPreviousLocale(activeLocale);
        setProductData(productLang[activeLocaleKey]);
        setLocalesProductData(productLang);
      }
    } catch (e) {}
  };

  const handleSubmit = () => {
    if (submitLoading) return;

    // const completeProductData = {
    //   ...localesProductData,
    //   [previousLocale.key]: { ...productData }
    // };

    let storeData = {
      product_type: globalProductData.productType?.key || null,
      has_audio_book: +globalProductData.hasAudioBook,
      category: globalProductData.category?.id || null,
      sub_category: globalProductData.subCategory?.id || null,
      filter_category: globalProductData.filterCategory?.id || null,
      genre: globalProductData.genre?.id || null,
      collection: globalProductData.collection?.id || null,
      episode: globalProductData?.episode || null,
    };

    // for (const lang in completeProductData) {
    const langProductData = { ...productData };
    const authorIds = langProductData?.authors?.map((author) => author.key);
    const translatorIds = langProductData?.translators?.map(
      (translator) => translator.key
    );
    const speakerIds = langProductData?.speakers?.map((speaker) => speaker.key);
    const prices = [];
    if (langProductData?.purchaseType?.toLowerCase() === 'paid') {
      for (const currency in langProductData?.prices) {
        prices.push({
          currency,
          price: langProductData?.prices[currency]?.price,
          discount_percent: langProductData?.prices[currency]?.percent || null,
          discount_started_at:
            langProductData?.purchaseType?.toLowerCase() === 'paid'
              ? langProductData?.discountStartedAt?.toISOString()
              : null,
          discount_ended_at:
            langProductData?.purchaseType?.toLowerCase() === 'paid'
              ? langProductData?.discountEndedAt?.toISOString()
              : null,
        });
      }
    }

    // console.log(langProductData, 'langProductData');
    // console.log(langProductData?.cover?.path);

    const demoFiles = [langProductData?.demo?.path];
    // const demoFiles = langProductData?.demo?.path;
    if (globalProductData.hasAudioBook && langProductData?.audioDemo?.path) {
      demoFiles.push(langProductData?.audioDemo?.path);
    }
    // console.log(demoFiles, 'demoFiles');

    const mainFiles = [langProductData?.main?.path];
    // const mainFiles = langProductData?.main?.path;
    if (globalProductData.hasAudioBook && langProductData?.audioMain?.path) {
      mainFiles.push(langProductData?.audioMain?.path);
    }

    // console.log(mainFiles, "mainFiles");
    // console.log(langProductData, "langProductData");
    // console.log(
    //   langProductData?.cover?.path[0],
    //   "langProductData?.cover?.path[0]"
    // );

    // console.log(langProductData?.cover);
    storeData = {
      ...storeData,
      language: locale.key,
      title: langProductData.name,
      collection: globalProductData.collection?.id || null,
      collaborators: [...authorIds, ...translatorIds, ...speakerIds],
      purchase_type: langProductData?.purchaseType,
      has_special_discount: +langProductData?.isInSpecialDiscount,
      pageCount: langProductData?.pageCount,
      prices,
      published_at: langProductData?.publishedDate?.toISOString(),
      description: langProductData?.description,
      seasons: langProductData?.seasons,
      volume: langProductData?.volume,
      reviews: langProductData?.reviews.map((review) => ({
        title: review.title,
        text: review.text,
        images: [
          {
            id: review?.images[0]?.id,
            description: review?.images[0]?.description,
          },
        ],
      })),
      cover: langProductData?.cover?.path[0],
      demo: demoFiles,
      main: mainFiles,
    };
    // console.log(storeData);
    // }
    handleSubmitToServer(storeData);
  };

  const handleSubmitToServer = async (requestData) => {
    setSubmitLoading(true);
    try {
      const { data } = await axios.post(
        Api.publisher.products.products + (productId ? `/${productId}` : ''),
        requestData
      );
      if (data.success) {
        if (productId) {
          toast(
            t(
              'Product Created Successfully. After the Reviews of the Admins It will be Published'
            )
          );
        } else {
          toast(
            t(
              'Product Updated Successfully. After the Reviews of the Admins It will be Published'
            )
          );
          setProductId(data.data.product?.id);
        }
      }
    } catch (e) {}
    setSubmitLoading(false);
  };

  if (loading)
    return <CircleLoading color={'#1c3c53'} width={200} height={200} />;

  return (
    <div className={'my-product'}>
      <ProductFormContext.Provider
        value={{
          info: productInfo,
          setInfo: setProductInfo,
          productData,
          setProductData,
          locale,
          setLocale,
          globalProductData,
          setGlobalProductData,
          handleSubmit,
          submitLoading,
          slug,
          productId,
        }}
      >
        <div>
          <FormRightSection />
          <FormLeftSection />
        </div>
      </ProductFormContext.Provider>
    </div>
  );
};

export default Product;
