import React, {useEffect, useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import "./../../../dashboard-related/common/others/css/Gadgets.scss"
import "./css/MaxConnection.scss";
// Common components
import ModalWrapper from "../../../common/others/ModalWrapper";
// Utils
import icons from "../../../../utils/icons";
import NavbarContext from "../../../../utils/contexts/Navbar";
import auth from "./Auth";
import axios from "../../../../utils/http";
import {getRouter} from "../../../../utils/helpers";
import API from "../../../../utils/api";
import {toast} from "react-toastify";
import {logIn} from "../../../../redux/actions/clientActions";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import CircleLoading from "../../../common/others/CircleLoading";

const MaxConnectionModal = () => {
    const {t} = useTranslation();
    const navigator = useNavigate();
    const {
        modalsDisplayStatus,
        toggleModals,
        maxConnections,
        setMaxConnections,
        authValues
    } = useContext(NavbarContext);
    const [loading, setLoading] = useState(false);
    const [selectedDevice, setSelectedDevice] = useState(false);
    const dispatch = useDispatch();

    function toggle() {
        setMaxConnections({
            devices: [],
            token: ""
        })
        toggleModals({...modalsDisplayStatus, maxConnection: false});
    }


    const deleteDeviceAndLogin = async (device) => {
        if (loading)
            return;

        setSelectedDevice(device);

        setLoading(true);

        try {
            const {data} = await axios.delete(getRouter(API.auth.maxConnection, {
                deviceId: device.id,
                token: maxConnections.token
            }));

            if (data.success) {
                const {user, token} = data.data;
                localStorage.setItem('token', token);
                axios.setAuthorizationHeader(token);
                dispatch(logIn(user));
                navigator(`/dashboard/${authValues.userType}/profile`)
                toggleModals({...modalsDisplayStatus, maxConnection: false});

                setMaxConnections({
                    devices: [],
                    token: ""
                })
            }
        } catch (e) {

        }
        setLoading(false)
    }
    return (
        <ModalWrapper className={"max-connection"} toggle={toggle}>

            <div className={"gadgets"}>
                <p style={{marginTop: 15}}>
                    {t(
                        "The Reader app is usable across three different devices. If you want to use a new device, first remove one from the list below."
                    )}
                </p>

                <p>{t("Active devices")}</p>

                {maxConnections.devices.map((device) => (
                    <div key={device.id} className={'max-connection-devices'}>
                        <p>{device.title}</p>

                        <button onClick={() => deleteDeviceAndLogin(device)}>
                            {loading && selectedDevice == device
                                ? <CircleLoading color={"#516975"}/>
                                : icons.delete}
                        </button>
                    </div>
                ))}
            </div>

        </ModalWrapper>
    );
};

export default MaxConnectionModal;
