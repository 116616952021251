import tempMedia from "../tempMedia";

const products = {
  booksAndJournals: [
    {
      title: "از زمین تا ماه",
      author: "ژول ورن",
      image: tempMedia.products.booksAndJournals.azZaminTaMah,
      summary:
        "با پایان جنگ‌های داخلی آمریکا، توپچی‌های باشگاه گان (باشگاه توپ) از کار بیکارمی‌شوند. روزی، رئیس باشگاه ایده‌ای مطرح می‌کند که مایۀ شگفتی همگان می‌شود. او پیشنهاد می‌دهد که با توپی غول‌آسا گلوله‌ای بزرگ را به ماه بفرستند و با قمر زمین ارتباط برقرار کنند. همزمان با انجام مقدمات عملیات، مردی فرانسوی گویی به‌یکباره از آسمان نازل می‌شود و با تلگرافی همه‌چیز را به هم می‌زند: گلوله‌ای استوانه‌مخروطی را جایگزین خمپارۀ کروی کنید.",
    },
    {
      title: "بال‌های آتشین",
      author: "توئی‌تی سادرلند",
      image: tempMedia.products.booksAndJournals.balHayeAtashin,
      summary:
        "بیست سال که از جنگ بگذرد، اژدهازادگان خواهند آمد... آن هنگام که زمین غرق در خون و اشک است، اژدهازادگان خواهند آمد... آبی‌ترین تخم دریابالان را بجوی. اژدهای شب به‌سویت خواهد آمد. بزرگ‌ترین تخم اژدها در بلندای کوهستان اژدهای آسمان را برایت به ارمغان خواهد آورد. برای یافتن اژدهای زمین، در خاک تخم اژدهایی به رنگ خون را بجوی. اژدهای شن به دور از چشم ملکه‌های رقیب در خفا، پنهان خواهد ماند.",
    },
    {
      title: "باغ مخفی",
      author: "فرانسیس هادسون برنت",
      image: tempMedia.products.booksAndJournals.baqeMakhfi,
      summary:
        "در را باز کنید و همراه مری وارد باغ شوید... مری لنکس، پس از آنکه پدر و مادرش را از دست می‌دهد، با کشتی به خلنگزارهای یورکشایر می‌آید. او در عمارت دلگیر عمویش زندگی می‌کند و حسابی تنهاست. اما یک روز در زمین‌های عمارت، باغ مخفی را کشف می‌کند؛ اما عمویش اجازه نمی‌دهد کسی وارد باغ شود. مری توی باغچه‌ای کلیدی قدیمی را پیدا می‌کند، و به‌طور جادویی راه در باغ مخفی را می‌یابد.",
    },
    {
      title: "هری پاتر و فرزند نفرین شده",
      author: "جان تیفانی",
      image: tempMedia.products.booksAndJournals.harryPotter,
      summary:
        "جناب آقای حجت گلابی هم از مترجمان کتاب هستند که به دلیل محدودیت در درج تعداد مترجم نام آنها در بالا ذکر نشده است ایستگاه شلوغ و مملو از جمعیت است. آدم‌های بسیاری در ایستگاه هستند که هر کدام می‌خواهند برای خود جایی بروند. در بین این فشار و شلوغی، دو قفس بزرگ بر روی دو چرخ‌دستی سنگین، تلق و تلوق می‌کنند. این چرخ‌دستی‌ها توسط دو پسر به نام‌های جیمز پاتر و آلبوس پاتر کشیده می‌شوند که مادر آنها، جینی، هم به دنبالشان در حال حرکت است. مردی سی و هفت ساله به نام هری، دختر خود، لی‌لی را بر روی شانه‌هایش حمل می‌کند.",
    },
    {
      title: "جعبه پرنده",
      author: "جاش مالرمن",
      image: tempMedia.products.booksAndJournals.jabeParande,
      summary:
        "داستان درمورد چیزی بیرون از خانه هاست که هیچکس نمی‌داند چیست.... فقط وقتی چشم انسان به آن بخورد به جنون مبتلا شده و می‌میرد، مردم شهر تمام پنجره های خانه خود را با پتو و آویزهای سیاه رنگ می‌پوشانند و با میخ به دیوار میکوبند، درهای خانه را قفل کرده تا چشمشان به آن چیز نیفتد. مالوری زنی باردار است که در این اتفاقات خواهر خود را از دست می‌دهد و از پدر و مادرش بی‌خبر است. چشمانش را با چشم بند می‌بندد و سوار اتومبیلش می‌شود تا به جایی امن در کنار رودخانه که آگهی آن را در روزنامه مشاهده کرده بود برود.",
    },
    {
      title: "جزء از کل",
      author: "استیو تولتز",
      image: tempMedia.products.booksAndJournals.jozAzKol,
      summary:
        "کتاب جز از کل بانام انگلیسی A fraction of the whole اثر «استیو تولتز» در سال 2008 منتشر شد. این کتاب یکی از موفق‌ترین آثار ادبیات استرالیا است که در مدت‌زمان کمی توانست به‌عنوان یکی از پرفروش‌ترین آثار نیویورک‌تایمز و آمازون شناخته شود. این کتاب روایت دو نسل از خانواده‌ی «دین» است که با کارهای عجیب‌وغریب و گاها جنایی و ترسناک در میان مردم کشور استرالیا شناخته شده‌اند.",
    },
    {
      title: "لبخند مهتاب",
      author: "ژولین آراندا",
      image: tempMedia.products.booksAndJournals.labkhandeMahtab,
      summary:
        "در پایان جنگ جهانی دوم، دیداری غیرمنتظره مسیر سرنوشت مردی را رقم میزند... در زمان اشغال نازی‌ها، پل ورتیون پانزده‌ساله، پسر حساس والدینی کشاورز و گندم‌کار، ترجیح می‌دهد به‌جای رنج کشیدن در کشت‌زارهای ساحلی بریتانیایی، به اقیانوس سفر کند و به اسرار زندگی بیندیشد. در روزی سرنوشت‌ساز، زندگی پل توسط افسر آلمانی مهربانی که چشمهایش همچون دریا آبی بود، نجات پیدا کرد. هنگامی‌که روستای پل آزاد شد، جمعیتی خشمگین برای انتقام از اشغالگران شورش کرد. آن افسر آلمانی هنگام مرگ از پل می‌خواهد که به او قول دهد دخترش را پیدا کند و به او بگوید که پدرش عاشقش بود.",
    },
    {
      title: "مائده‌های زمینی",
      author: "آندره ژید",
      image: tempMedia.products.booksAndJournals.maedeHayeZamini,
      summary:
        "ناتانائل، از عنوان خشنی که خوشم آمده است به این کتاب بدهم دچار اشتباه مشو. می‌توانسم آن را «منالک» بنامم، اما منالک نیز همچون تو، هرگز وجود نداشته است. تنها نام آدمی که بر این کتاب می‌شد گذاشت نام خود من بود، اما در آن صورت من چگونه یارای آن می‌داشتم که خود را نویسنده آن معرفی کنم. من خویشتن را بی‌هیچ آرایش و شرمی در این کتاب نمایانده‌ام و اگر گاهی در آن از سرزمین‌هایی سخن می‌گویم که هرگز ندیده‌ام و عطرهایی که هرگز نبوئیده‌ام و یا کارهایی که هرگز نکرده‌ام و یا از تو، ای ناتانائل که هنوزت ندیده‌ام هیچ از سر سالوس و ریا نیست و این چیزها خود، ای ناتانائل که این کتاب را خواهی خواند، از نامی که بر تو نهاده‌ام، چون نامی را که آینده به تو خواهد داد نمی‌دانستم، دروغ‌تر نیست. و آنگاه که کتاب مرا خواندی آن را بیفکن و بیرون رو. دلم می‌خواهد که این کتاب شوق خروج را در تو برانگیزد. خروج از هرجا، از شهر و خانواده‌ات، از اطاقت و از اندیشه‌ات کتاب مرا با خود به همراه مبر.",
    },
    {
      title: "من پیش از تو",
      author: "جوجو مویز",
      image: tempMedia.products.booksAndJournals.manPishAzTo,
      summary:
        "ین روزها به ندرت رمان عاشقانه‌ای خواهید یافت که درس زندگی دهد، یا اینکه قهرمانان کتاب در پایان با قلب مخاطب همراه شوند. اثر پرفروش من پیش از تو نوشته رمان‌نویس مشهور انگلیسی جوجو مویز از این دست کتاب‌ها است.",
    },
  ],

  audioBooks: [
    {
      title: "من پیش از تو",
      author: "جوجو مویز",
      image: tempMedia.products.audioBooks.manPishAzToAB,
      summary:
        "ین روزها به ندرت رمان عاشقانه‌ای خواهید یافت که درس زندگی دهد، یا اینکه قهرمانان کتاب در پایان با قلب مخاطب همراه شوند. اثر پرفروش من پیش از تو نوشته رمان‌نویس مشهور انگلیسی جوجو مویز از این دست کتاب‌ها است.",
    },
    {
      title: "مائده‌های زمینی",
      author: "آندره ژید",
      image: tempMedia.products.audioBooks.maedeHayeZaminiAB,
      summary:
        "ناتانائل، از عنوان خشنی که خوشم آمده است به این کتاب بدهم دچار اشتباه مشو. می‌توانسم آن را «منالک» بنامم، اما منالک نیز همچون تو، هرگز وجود نداشته است. تنها نام آدمی که بر این کتاب می‌شد گذاشت نام خود من بود، اما در آن صورت من چگونه یارای آن می‌داشتم که خود را نویسنده آن معرفی کنم. من خویشتن را بی‌هیچ آرایش و شرمی در این کتاب نمایانده‌ام و اگر گاهی در آن از سرزمین‌هایی سخن می‌گویم که هرگز ندیده‌ام و عطرهایی که هرگز نبوئیده‌ام و یا کارهایی که هرگز نکرده‌ام و یا از تو، ای ناتانائل که هنوزت ندیده‌ام هیچ از سر سالوس و ریا نیست و این چیزها خود، ای ناتانائل که این کتاب را خواهی خواند، از نامی که بر تو نهاده‌ام، چون نامی را که آینده به تو خواهد داد نمی‌دانستم، دروغ‌تر نیست. و آنگاه که کتاب مرا خواندی آن را بیفکن و بیرون رو. دلم می‌خواهد که این کتاب شوق خروج را در تو برانگیزد. خروج از هرجا، از شهر و خانواده‌ات، از اطاقت و از اندیشه‌ات کتاب مرا با خود به همراه مبر.",
    },
    {
      title: "لبخند مهتاب",
      author: "ژولین آراندا",
      image: tempMedia.products.audioBooks.labkhandeMahtabAB,
      summary:
        "در پایان جنگ جهانی دوم، دیداری غیرمنتظره مسیر سرنوشت مردی را رقم میزند... در زمان اشغال نازی‌ها، پل ورتیون پانزده‌ساله، پسر حساس والدینی کشاورز و گندم‌کار، ترجیح می‌دهد به‌جای رنج کشیدن در کشت‌زارهای ساحلی بریتانیایی، به اقیانوس سفر کند و به اسرار زندگی بیندیشد. در روزی سرنوشت‌ساز، زندگی پل توسط افسر آلمانی مهربانی که چشمهایش همچون دریا آبی بود، نجات پیدا کرد. هنگامی‌که روستای پل آزاد شد، جمعیتی خشمگین برای انتقام از اشغالگران شورش کرد. آن افسر آلمانی هنگام مرگ از پل می‌خواهد که به او قول دهد دخترش را پیدا کند و به او بگوید که پدرش عاشقش بود.",
    },
    {
      title: "جزء از کل",
      author: "استیو تولتز",
      image: tempMedia.products.audioBooks.jozAzKolAB,
      summary:
        "کتاب جز از کل بانام انگلیسی A fraction of the whole اثر «استیو تولتز» در سال 2008 منتشر شد. این کتاب یکی از موفق‌ترین آثار ادبیات استرالیا است که در مدت‌زمان کمی توانست به‌عنوان یکی از پرفروش‌ترین آثار نیویورک‌تایمز و آمازون شناخته شود. این کتاب روایت دو نسل از خانواده‌ی «دین» است که با کارهای عجیب‌وغریب و گاها جنایی و ترسناک در میان مردم کشور استرالیا شناخته شده‌اند.",
    },
    {
      title: "از زمین تا ماه",
      author: "ژول ورن",
      image: tempMedia.products.audioBooks.azZaminTaMahAB,
      summary:
        "با پایان جنگ‌های داخلی آمریکا، توپچی‌های باشگاه گان (باشگاه توپ) از کار بیکارمی‌شوند. روزی، رئیس باشگاه ایده‌ای مطرح می‌کند که مایۀ شگفتی همگان می‌شود. او پیشنهاد می‌دهد که با توپی غول‌آسا گلوله‌ای بزرگ را به ماه بفرستند و با قمر زمین ارتباط برقرار کنند. همزمان با انجام مقدمات عملیات، مردی فرانسوی گویی به‌یکباره از آسمان نازل می‌شود و با تلگرافی همه‌چیز را به هم می‌زند: گلوله‌ای استوانه‌مخروطی را جایگزین خمپارۀ کروی کنید.",
    },
    {
      title: "هری پاتر و فرزند نفرین شده",
      author: "جان تیفانی",
      image: tempMedia.products.audioBooks.harryPotterAB,
      summary:
        "جناب آقای حجت گلابی هم از مترجمان کتاب هستند که به دلیل محدودیت در درج تعداد مترجم نام آنها در بالا ذکر نشده است ایستگاه شلوغ و مملو از جمعیت است. آدم‌های بسیاری در ایستگاه هستند که هر کدام می‌خواهند برای خود جایی بروند. در بین این فشار و شلوغی، دو قفس بزرگ بر روی دو چرخ‌دستی سنگین، تلق و تلوق می‌کنند. این چرخ‌دستی‌ها توسط دو پسر به نام‌های جیمز پاتر و آلبوس پاتر کشیده می‌شوند که مادر آنها، جینی، هم به دنبالشان در حال حرکت است. مردی سی و هفت ساله به نام هری، دختر خود، لی‌لی را بر روی شانه‌هایش حمل می‌کند.",
    },
    {
      title: "بال‌های آتشین",
      author: "توئی‌تی سادرلند",
      image: tempMedia.products.audioBooks.balHayeAtashinAB,
      summary:
        "بیست سال که از جنگ بگذرد، اژدهازادگان خواهند آمد... آن هنگام که زمین غرق در خون و اشک است، اژدهازادگان خواهند آمد... آبی‌ترین تخم دریابالان را بجوی. اژدهای شب به‌سویت خواهد آمد. بزرگ‌ترین تخم اژدها در بلندای کوهستان اژدهای آسمان را برایت به ارمغان خواهد آورد. برای یافتن اژدهای زمین، در خاک تخم اژدهایی به رنگ خون را بجوی. اژدهای شن به دور از چشم ملکه‌های رقیب در خفا، پنهان خواهد ماند.",
    },
    {
      title: "جعبه پرنده",
      author: "جاش مالرمن",
      image: tempMedia.products.audioBooks.jabeParandeAB,
      summary:
        "داستان درمورد چیزی بیرون از خانه هاست که هیچکس نمی‌داند چیست.... فقط وقتی چشم انسان به آن بخورد به جنون مبتلا شده و می‌میرد، مردم شهر تمام پنجره های خانه خود را با پتو و آویزهای سیاه رنگ می‌پوشانند و با میخ به دیوار میکوبند، درهای خانه را قفل کرده تا چشمشان به آن چیز نیفتد. مالوری زنی باردار است که در این اتفاقات خواهر خود را از دست می‌دهد و از پدر و مادرش بی‌خبر است. چشمانش را با چشم بند می‌بندد و سوار اتومبیلش می‌شود تا به جایی امن در کنار رودخانه که آگهی آن را در روزنامه مشاهده کرده بود برود.",
    },
    {
      title: "باغ مخفی",
      author: "فرانسیس هادسون برنت",
      image: tempMedia.products.audioBooks.baqeMakhfiAB,
      summary:
        "در را باز کنید و همراه مری وارد باغ شوید... مری لنکس، پس از آنکه پدر و مادرش را از دست می‌دهد، با کشتی به خلنگزارهای یورکشایر می‌آید. او در عمارت دلگیر عمویش زندگی می‌کند و حسابی تنهاست. اما یک روز در زمین‌های عمارت، باغ مخفی را کشف می‌کند؛ اما عمویش اجازه نمی‌دهد کسی وارد باغ شود. مری توی باغچه‌ای کلیدی قدیمی را پیدا می‌کند، و به‌طور جادویی راه در باغ مخفی را می‌یابد.",
    },
  ],

  podcasts: [
    {
      title: "۷۵ روز گمشده در دریا",
      channel: "چنل بی",
      episode: 5,
      image: tempMedia.products.podcasts.haftadoPangRozGomshodeDarDarya,
    },
    {
      title: "آموک",
      channel: "چنل بی",
      episode: 50,
      image: tempMedia.products.podcasts.amok,
    },
    {
      title: "دانشمند و دریا",
      channel: "چنل بی",
      episode: 60,
      image: tempMedia.products.podcasts.daneshmandODarya,
    },
    {
      title: "دست",
      channel: "چنل بی",
      episode: 35,
      image: tempMedia.products.podcasts.dast,
    },
    {
      title: "دراز گودال ماریانا",
      channel: "چنل بی",
      episode: 12,
      image: tempMedia.products.podcasts.derazGoladeMariana,
    },
    {
      title: "انقراض بشر",
      channel: "چنل بی",
      episode: 9,
      image: tempMedia.products.podcasts.enqerazeBashar,
    },
    {
      title: "جان مکافی",
      channel: "چنل بی",
      episode: 36,
      image: tempMedia.products.podcasts.johnMcafee,
    },
    {
      title: "کارما و کرونا",
      channel: "چنل بی",
      episode: 13,
      image: tempMedia.products.podcasts.karmaOCorona,
    },
    {
      title: "کشتی اشباح",
      channel: "چنل بی",
      episode: 4,
      image: tempMedia.products.podcasts.kashtiAshbah,
    },
    {
      title: "من کشته خواهم شد",
      channel: "چنل بی",
      episode: 39,
      image: tempMedia.products.podcasts.manKoshteKhahamShod,
    },
    {
      title: "موج انرژی",
      channel: "چنل بی",
      episode: 1,
      image: tempMedia.products.podcasts.mojeEnergy,
    },
    {
      title: "میتوس",
      channel: "چنل بی",
      episode: 7,
      image: tempMedia.products.podcasts.mythos,
    },
    {
      title: "نامه‌ای از نروژ",
      channel: "چنل بی",
      episode: 20,
      image: tempMedia.products.podcasts.nameEAzNorvej,
    },
    {
      title: "اوشو",
      channel: "چنل بی",
      episode: 1,
      image: tempMedia.products.podcasts.osho,
    },
    {
      title: "پادکست نامه",
      channel: "چنل بی",
      episode: 16,
      image: tempMedia.products.podcasts.podcastName,
    },
  ],
};

export default products;
