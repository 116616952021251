import React, {useEffect, useState} from "react";
import "./css/MyPublishers.scss";
import {useTranslation} from "react-i18next";
// Components common in marketeer dashboard
import MyPublishersAndProfitHeader from "./MyPublishersAndProfitHeader";
import axios from "./../../../utils/http";
import Api from "../../../utils/api";
import moment from "moment-jalaali";
import {getDate} from "../../../utils/helpers";

const MyPublishers = () => {
    const {t} = useTranslation();
    const [publishers, setPublishers] = useState([]);
    const [sortBy, setSortBy] = useState('desc');

    useEffect(() => {
        getMyPublishers();
    }, [sortBy]);


    const getMyPublishers = async () => {
        try {
            const {data} = await axios.get(Api.dashboard.myPublishers, {
                params: {
                    sort_by: sortBy
                }
            })
            if (data.success) {
                setPublishers(data.data);
            }
        } catch (e) {
        }
    }

    return (
        <div className={"my-publishers"}>
            <MyPublishersAndProfitHeader title={t("My publishers")} onSortChange={(sort) => setSortBy(sort)}
                                         showprices={false}/>

            <div>
                <table>
                    <thead>
                    <tr>
                        <th>{t("Logo")}</th>
                        <th>{t("Full Name")}</th>
                        <th>{t("Publisher name")}</th>
                        <th>{t("Publisher Phone")}</th>
                        <th>{t("Publisher Email")}</th>
                        <th>{t("Registered Date")}</th>
                    </tr>
                    </thead>

                    <tbody>
                    {publishers.map((publisher) => (
                        <tr key={publisher.id}>
                            <td><img className={'my-publisher-logo'} src={publisher.publisher_logo_url} alt=""/></td>
                            <td>{publisher.first_name} {publisher.last_name}</td>
                            <td>{publisher.publisher_name}</td>
                            <td>{publisher.publisher_phone}</td>
                            <td>{publisher.publisher_email}</td>
                            <td>{getDate(publisher.created_at)}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default MyPublishers;
