import React, { useState } from "react";
import "./css/Discount.scss";
import { useTranslation } from "react-i18next";
import CircleLoading from "../../../common/others/CircleLoading";

const Discount = ({ onDiscountCodeSubmit, loading }) => {
  const { t } = useTranslation();
  const [discount, setDiscount] = useState();

  return (
    <div className={"discount"}>
      <div>
        <p>{t("Discount")}</p>
        <p>{t("If you have a discount code, enter it in this section")}</p>
      </div>

      <div>
        <input type={"text"} placeholder={t("Code")} onChange={(e) => setDiscount(e.target.value)} />
        <button type={"button"} className={"button primary"} onClick={() => onDiscountCodeSubmit(discount)}>
          {loading ? <CircleLoading /> : t("Submit")}
        </button>
      </div>
    </div>
  );
};

export default Discount;
