import { useRef, useState } from "react";
import "./Player.scss";
// Utils
import icons from "../../../../../../utils/icons";

const Player = ({ url }) => {
  const playerRef = useRef();
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(50);
  const [isMuted, setIsMuted] = useState(false);
  const [time, setTime] = useState(0);
  const [duration, setDuration] = useState(0);

  function renderTimer() {
    if (duration === 0 && time === 0) {
      return "00:00/00:00";
    }
    if (playerRef.current) {
      // Current time
      let currentMinutes = Math.floor(playerRef.current.currentTime / 60);
      let currentSeconds = Math.floor(playerRef.current.currentTime % 60);
      if (currentMinutes < 10) {
        currentMinutes = "0" + currentMinutes;
      }
      if (currentSeconds < 10) {
        currentSeconds = "0" + currentSeconds;
      }
      const currentTime = currentMinutes + ":" + currentSeconds;
      // Total time
      let totalMinutes = Math.floor(playerRef.current.duration / 60);
      let totalSeconds = Math.floor(playerRef.current.duration % 60);
      if (totalMinutes < 10) {
        totalMinutes = "0" + totalMinutes;
      }
      if (totalSeconds < 10) {
        totalSeconds = "0" + totalSeconds;
      }
      const totalTime = totalMinutes + ":" + totalSeconds;
      // Timer
      return currentTime + "/" + totalTime;
    } else {
      return "00:00/00:00";
    }
  }

  function toggle() {
    if (isPlaying) {
      setIsPlaying(false);
      playerRef.current.pause();
    } else {
      setIsPlaying(true);
      playerRef.current.play();
    }
  }

  function handleTimeUpdate(e) {
    setDuration(e.target.duration);
    const totalTime = e.target.duration;
    const currentTime = e.target.currentTime;
    const percentage = (e.target.currentTime * 100) / e.target.duration;
    document.getElementById("time-background").style.width =percentage<50 ? `${percentage}%` : `calc(${percentage}% - 5px)`;
    setTime(e.target.currentTime);
    currentTime === totalTime && setIsPlaying(false);
  }

  function handleTimeChange(e) {
    // const totalTime = Math.floor(playerRef.current.duration);
    // const selectedTime = Math.floor(e.target.value);
    setTime(e.target.value);
    playerRef.current.currentTime = e.target.value;
  }

  function handleMute() {
    if (isMuted) {
      playerRef.current.volume = volume / 100;
      setIsMuted(false);
    } else {
      playerRef.current.volume = 0;
      setIsMuted(true);
    }
  }

  const handleForward = () => {
    const forwardTime = time + 5 >= duration ? duration : time + 5;
    setTime(forwardTime);
    playerRef.current.currentTime = forwardTime;
  };

  const handleRewind = () => {
    const forwardTime = time - 5 <= 0 ? 0 : time - 5;
    setTime(forwardTime);
    playerRef.current.currentTime = forwardTime;
  };

  return (
    <div className={"player"}>
      <audio
        id={"audio-player"}
        ref={playerRef}
        onTimeUpdate={handleTimeUpdate}
      >
        <source src={url} type={"audio/mp3"} />
      </audio>

      <input type={"range"} value={time} onChange={handleTimeChange} step={0.1} min={0} max={duration} />
      <div id={"time-background"} className={"time-background"} />

      <div className={"controls"}>
        <div>
          <button type={"button"} onClick={handleForward}>{icons.next}</button>
          <button type={"button"} onClick={toggle}>
            {isPlaying ? icons.stop2 : icons.play2}
          </button>
          <button type={"button"} onClick={handleRewind}>{icons.previous}</button>
        </div>

        <div>
          <button type={"button"} onClick={handleMute}>
            {isMuted ? icons.audioStopped : icons.audioPlaying2}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Player;
