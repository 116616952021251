import React, { useEffect, useState } from 'react';
import './css/Guide.scss';
import { useTranslation } from 'react-i18next';
// Components specific to guide page
import Step, { StepSkeleton } from '../../components/home-related/guide/Step';
import Skeleton from 'react-loading-skeleton';
import axios from '../../utils/http';
import API from '../../utils/api';

const GuideSkeleton = () => (
  <>
    <Skeleton />
    <Skeleton />
    <Skeleton />
    <StepSkeleton />
    <StepSkeleton />
    <StepSkeleton />
  </>
);

const Guide = () => {
  const { t } = useTranslation();
  const [guides, setGuides] = useState(null);
  const [loading, setLoading] = useState(false);

  const getGuides = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(API.public.guides);
      if (data.success) {
        setGuides(data.data);
      }
    } catch (e) {}
    setLoading(false);
  };

  useEffect(() => {
    getGuides();
  }, []);

  if (loading) {
    return (
      <div className={'guide'}>
        <p className={'home-title m24'}>{t('Guide')}</p>
        <GuideSkeleton />
      </div>
    );
  }

  return (
    <div className={'guide'}>
      <p className={'home-title m24'}>{t('Guide')}</p>

      <p className={'content'}>{guides?.description}</p>

      {guides?.guids?.map((item, index) => (
        <Step
          title={item.title}
          content={item.text}
          image={item.image}
          key={index}
        />
      ))}
    </div>
  );
};

export default Guide;
