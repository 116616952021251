import React, { useEffect, useState } from "react";
import "./css/LibraryProducts.scss";
import { useParams } from "react-router-dom";
import axios from "./../../utils/http";
import API from "./../../utils/api";
import Product, { ProductSkeleton } from "../../components/common/others/Product";
import { getRouter } from "../../utils/helpers";
import CircleLoading from "../../components/common/others/CircleLoading";
import InfiniteScroll from "react-infinite-scroll-component";

const LibraryProducts = () => {
  const { type } = useParams();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    getLibraryProducts();
  }, []);

  const getLibraryProducts = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(nextPageUrl || getRouter(API.library.libraryCategory, {
        type
      }));

      if (data.success) {
        setProducts([...products, ...data.data.products.data]);
        setNextPageUrl(data.data.products.next_page_url);
        setTotal(data.data.products.total);
      }

    } catch (e) {

    }
    setLoading(false);
  };

  const removeFromLibrary = async (product) => {
    try {
      const { data } = await axios.delete(getRouter(API.library.removeFromLibrary, {
        libraryItemId: product.library_id
      }));

      if (data.success) {
        await getLibraryProducts();
      }

    } catch (e) {

    }
  };


  if (loading) return (<>
    <h1 className={"library-title"}>کتابخانه من</h1>
    <p className={"library-description"}>می توانید لیست تمام کتاب های خود را ببینید و ویرایش های لازم را انجام دهید</p>
    <div className={"library-products"}>
      <ProductSkeleton type={"electronic_book"} />
      <ProductSkeleton type={"electronic_book"} />
      <ProductSkeleton type={"electronic_book"} />
      <ProductSkeleton type={"electronic_book"} />
      <ProductSkeleton type={"electronic_book"} />
      <ProductSkeleton type={"electronic_book"} />
      <ProductSkeleton type={"electronic_book"} />
      <ProductSkeleton type={"electronic_book"} />
      <ProductSkeleton type={"electronic_book"} />
      <ProductSkeleton type={"electronic_book"} />
      <ProductSkeleton type={"electronic_book"} />
      <ProductSkeleton type={"electronic_book"} />
      <ProductSkeleton type={"electronic_book"} />
      <ProductSkeleton type={"electronic_book"} />
      <ProductSkeleton type={"electronic_book"} />
      <ProductSkeleton type={"electronic_book"} />
      <ProductSkeleton type={"electronic_book"} />
      <ProductSkeleton type={"electronic_book"} />
      <ProductSkeleton type={"electronic_book"} />
      <ProductSkeleton type={"electronic_book"} />
      <ProductSkeleton type={"electronic_book"} />
    </div>
  </>);
  return <>
    <h1 className={"library-title"}>کتابخانه من</h1>
    <p className={"library-description"}>می توانید لیست تمام کتاب های خود را ببینید و ویرایش های لازم را انجام دهید</p>
    <InfiniteScroll next={getLibraryProducts} hasMore={!!nextPageUrl} loader={<CircleLoading />} dataLength={total}>
      <div className={"library-products"}>

        {products.map(product => <Product type={product.order_type} data={product}
                                          onRemoveLibrary={(libraryItem) => removeFromLibrary(libraryItem)}
                                          onReadOnline={(libraryItem) => libraryItem} />)}
      </div>
    </InfiniteScroll>
  </>;
};

export default LibraryProducts;
