import i18n from "i18next";
import {initReactI18next} from "react-i18next";
// Translations
import {getLocale} from "../utils/helpers";
import faTranslations from "./faTranslations";
import kukTranslations from "./kukTranslation";
import kuTranslations from "./kuTranslation";

i18n.use(initReactI18next).init({
    resources: {
        kuk: {
            translation: kukTranslations,
        },
        fa: {
            translation: faTranslations,
        },
        ku: {
            translation: kuTranslations,
        },
    },
    lng: getLocale(),
    fallbackLng: "kuk",
});
