import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './css/Product.scss';
// Utils
import icons from '../../../utils/icons';
import Skeleton from 'react-loading-skeleton';
import { isPlainObject } from 'lodash';
import CircleLoading from './CircleLoading';
import { useTranslation } from 'react-i18next';
// import useDeviceDetection from '../../../hooks/useDeviceDetection';
import Price from '../../product/sub-components/product-info/sub-components/Price';

export const ProductSkeleton = ({ type }) => {
  const className = `product ${type}`;
  return (
    <div className={className}>
      <Skeleton className={'skeleton-img'} circle={type === 'audio_book'} />
      {/*<Skeleton width={"80%"}/>*/}
      {/*<Skeleton/>*/}
    </div>
  );
};
const Product = ({
  data,
  type,
  forShoppingCart,
  remove,
  price,
  removeLoading,
  onRemoveLibrary,
  onReadOnline,
  onRemoveBookmark,
  onEdit,
  onDelete,
}) => {
  const { t, i18n } = useTranslation();
  const [clickCount, setClickCount] = useState(0);
  const [smallDevice, setSmallDevice] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [smallTablet, setSmallTablet] = useState(false);
  const navigate = useNavigate();
  // const device = useDeviceDetection();
  // console.log(device);
  // const className = `product ${type} ${forShoppingCart && "for-shopping-cart"}`;
  //   const className = `product electronic_book ${
  //     forShoppingCart && 'for-shopping-cart'
  //   }`;
  const className = `product  ${
    type == 'audio_book' ? 'electronic_book' : type
  } ${forShoppingCart && 'for-shopping-cart'}`;
  const to = `/products/${data.slug}`;
  const summary =
    data?.description?.length > 250
      ? data?.description.substr(0, 250) + '...'
      : data?.description;
  const author =
    data.author && isPlainObject(data.author) ? data.author.name : data.author;
  const speaker =
    data.speaker && isPlainObject(data.speaker)
      ? data.speaker.name
      : data.speaker;
  const translator =
    data.translator && isPlainObject(data.translator)
      ? data.translator.name
      : data.translator;
  let contributors = [];
  if (author) contributors.push(author);
  if (speaker) contributors.push(speaker);
  if (translator) contributors.push(translator);
  contributors = contributors.join('، ');

  const formattedContributors =
    contributors?.length > 20
      ? contributors.substr(0, 20) + '...'
      : contributors;

  const handleClick = (e) => {
    e.preventDefault();

    if (window.innerWidth > 1344) {
      navigate(to);
    } else {
      // Increment the click count
      setClickCount(clickCount + 1);

      // Check if it's the first click (hover effect)
      if (clickCount === 0) {
        // Add any hover effect styles or animations here
        if (window.innerWidth <= 478) {
          // (Mobiles)
          setSmallDevice(true);
        } else if (window.innerWidth <= 544) {
          // (Mobiles)
          setMobile(true);
        } else if (window.innerWidth <= 704) {
          // (Smaller tablets)
          setSmallTablet(true);
        } else if (window.innerWidth <= 944) {
          //(Tablets)
          setMobile(true);
        } else if (window.innerWidth <= 1200) {
          //(Landscape Tablets)
          setMobile(true);
        }
      }

      // Check if it's the second click (redirect)
      if (clickCount === 1) {
        // Redirect to the detail page
        // Replace '/detail-page' with your actual route
        // window.location.href = to;
        navigate(to);
      }
    }
  };

  return (
    <div className={className}>
      <Link
        // to={to}
        to='#'
        onClick={handleClick}
      >
        <img alt={'Product'} src={data.cover} />
        {/*<p>*/}
        {/*    {type === "electronic_book" || type === "magazine" ? summary : type === "audio_book" || type === "podcast" ? `گوینده: ${speaker || author || translator}` : ""}*/}
        {/*</p>*/}
        {/*<p>{data.title}</p>*/}
        {/*<p>{formattedContributors}</p>*/}
        {(type === 'electronic_book' ||
          type === 'magazine' ||
          type === 'audio_book') &&
        window.innerWidth > 1344 ? (
          <div className='product_menu_box'>
            <div className='product_menu_list'>
              <Price />
            </div>
          </div>
        ) : (
          <></>
        )}

        {(type === 'electronic_book' ||
          type === 'magazine' ||
          type === 'audio_book') &&
        smallDevice === true ? (
          <div className='product_menu_box_small'>
            <div className='product_menu_list_small'>
              <Price />
            </div>
          </div>
        ) : (
          <></>
        )}

        {(type === 'electronic_book' ||
          type === 'magazine' ||
          type === 'audio_book') &&
        mobile === true ? (
          <div className='product_menu_box_mobile'>
            <div className='product_menu_list_mobile'>
              <Price />
            </div>
          </div>
        ) : (
          <></>
        )}

        {(type === 'electronic_book' ||
          type === 'magazine' ||
          type === 'audio_book') &&
        smallTablet === true ? (
          <div className='product_menu_box_smallTablet'>
            <div className='product_menu_list_smallTablet'>
              <Price />
            </div>
          </div>
        ) : (
          <></>
        )}
      </Link>
      {/*{!forShoppingCart && !onRemoveLibrary && !onRemoveBookmark && !onDelete && !onEdit ? <div className={'product-arrow-icon'} style={i18n.language === 'kuk' ? {left:'initial',right:16,transform:'rotate(-90deg)'} : null}>{icons.arrowhead}</div> : null}*/}

      {forShoppingCart && (
        <>
          <p className={'price'}>
            {(+price.total_price)?.toLocaleString()} {t(price.currency)}
          </p>
          <button
            className={'button ternary delete'}
            type={'button'}
            onClick={() => remove()}
          >
            {removeLoading ? (
              <CircleLoading width={30} height={30} color={'#1c3c53'} />
            ) : (
              t('Remove From Cart')
            )}
          </button>
        </>
      )}
      {onEdit ? (
        <>
          <button
            className={'button ternary delete read'}
            type={'button'}
            onClick={() => onEdit(data)}
          >
            {t('Edit')}
          </button>
        </>
      ) : null}
      {onDelete ? (
        <>
          <button
            className={'button ternary delete'}
            type={'button'}
            onClick={() => onDelete(data)}
          >
            {removeLoading ? (
              <CircleLoading width={30} height={30} color={'#1c3c53'} />
            ) : (
              t('Delete')
            )}
          </button>
        </>
      ) : null}
      {onReadOnline ? (
        <>
          <button
            className={'button ternary delete read'}
            type={'button'}
            onClick={() => onReadOnline(data)}
          >
            {type === 'electronic_book' || type === 'magazine'
              ? t('Read Online')
              : t('Listen Online')}
          </button>
        </>
      ) : null}
      {onRemoveLibrary ? (
        <>
          <button
            className={'button ternary delete'}
            type={'button'}
            onClick={() => onRemoveLibrary(data)}
          >
            {removeLoading ? (
              <CircleLoading width={30} height={30} color={'#1c3c53'} />
            ) : (
              t('Remove From Library')
            )}
          </button>
        </>
      ) : null}
      {onRemoveBookmark ? (
        <>
          <button
            className={'button ternary delete'}
            type={'button'}
            onClick={() => onRemoveBookmark(data)}
          >
            {removeLoading ? (
              <CircleLoading width={30} height={30} color={'#1c3c53'} />
            ) : (
              t('Remove From Favorites')
            )}
          </button>
        </>
      ) : null}
    </div>
  );
};

export default Product;
