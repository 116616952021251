import Dropzone from 'react-dropzone';
import './css/Uploader.scss';
import { useEffect, useState } from 'react';
import icons from '../../../utils/icons';
import CircleLoading from './CircleLoading';

const Uploader = ({
  title,
  onChange,
  onRemove,
  onUpload,
  multiple = false,
  preview = null,
  selectedFile,
  removable = false,
  uploadOnChange = false,
  accept,
}) => {
  const [files, setFiles] = useState([]);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [filePreview, setFilePreview] = useState(preview);
  const [previewTitle, setPreviewTitle] = useState(selectedFile?.name || title);

  //   console.log(title);
  // console.log(preview);
  useEffect(() => {
    if (
      !selectedFile ||
      !selectedFile?.name ||
      typeof selectedFile === 'string'
    ) {
      setPreviewTitle(title);
      return;
    }

    setPreviewTitle(selectedFile?.name);
  }, [selectedFile, title]);

  useEffect(() => {
    setFilePreview(preview);
  }, [preview]);

  const handleOnDrop = (acceptedFiles) => {
    if (uploadLoading || !acceptedFiles.length) return;

    if (!acceptedFiles[0].type.match(/image\/.+/)) {
      setPreviewTitle(acceptedFiles[0]?.name);
      if (onChange) {
        onChange(acceptedFiles, null);
      }
      if (uploadOnChange) handleUpload(acceptedFiles, null);
    } else {
      const fr = new FileReader();
      fr.addEventListener(
        'load',
        function () {
          setFilePreview(fr.result);
          if (onChange) {
            onChange(acceptedFiles, fr.result);
          }
          if (uploadOnChange) handleUpload(acceptedFiles, fr.result);
        },
        false
      );
      fr.readAsDataURL(acceptedFiles[0]);
    }

    if (multiple) setFiles([...files, ...acceptedFiles]);
    else setFiles(acceptedFiles);
  };

  const handleRemove = (file) => {
    setFiles(files.filter((f) => f !== file));
    if (onRemove) onRemove(file);
  };

  const handleUpload = async (files, base64) => {
    setUploadLoading(true);
    await onUpload(files, base64);
    setUploadLoading(false);
  };

  return (
    <Dropzone onDrop={handleOnDrop} multiple={multiple} accept={accept}>
      {({ getRootProps, getInputProps }) => (
        <section className={'uploader'}>
          <div {...getRootProps()} className={'uploader-inner'}>
            <input {...getInputProps()} />
            <div className={'uploader-info'}>
              {preview || filePreview ? (
                <img
                  src={filePreview || preview}
                  alt=''
                  className='uploader-preview'
                />
              ) : null}
              {!preview && !filePreview && previewTitle ? (
                <p>{previewTitle}</p>
              ) : null}
            </div>
          </div>
          {uploadLoading && (
            <div className={'uploader-loading'}>
              <CircleLoading color={'#1c3c53'} width={120} height={120} />
            </div>
          )}
          {!!((selectedFile && !multiple) || removable) ? (
            <button
              className={'btn-remove-outer-file'}
              onClick={() => {
                handleRemove(files[0]);
                setFilePreview(null);
                setPreviewTitle(title);
              }}
            >
              {icons.delete}
            </button>
          ) : null}
        </section>
      )}
    </Dropzone>
  );
};

export default Uploader;
