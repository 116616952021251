import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter as Router} from "react-router-dom";
import {ToastContainer, Zoom} from "react-toastify";
import {getLocale} from "./utils/helpers";
import {SkeletonTheme} from "react-loading-skeleton";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// The app component
import App from "./App";
// Redux
import {Provider} from "react-redux";
import store from "./redux/redux";
// Utils
import "./i18n/i18n";

// Global stylesheets
import "./global-stylesheets/animations.scss";
import "./global-stylesheets/colors.scss";
import "./global-stylesheets/element-types.scss";
import "./global-stylesheets/fonts.scss";
import "./global-stylesheets/global-styles.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";
import 'react-tooltip/dist/react-tooltip.css';

ReactDOM.render(
    <Router>
        <Provider store={store}>
            <SkeletonTheme height={20}>
                <App/>
            </SkeletonTheme>
            <ToastContainer
                position={"top-center"}
                theme={"light"}
                autoClose={3000}
                hideProgressBar
                closeOnClick
                draggable
                pauseOnHover={false}
                rtl={getLocale() !== "kuk"}
                toastStyle={{fontFamily: "IRANSans, sans-serif"}}
                className={'toast-container'}
                limit={3}
                transition={Zoom}
            />
        </Provider>
    </Router>,
    document.getElementById("root")
);

serviceWorkerRegistration.register();
