import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import "./css/EmailLogin.scss";
// Common components
import ModalWrapper from "../../../common/others/ModalWrapper";
import TextInput from "../../../common/others/TextInput";
// Form validation
import {Formik} from "formik";
import {emailLoginSchemas} from "../../../../utils/formValidationSchemas";
// Utils
import icons from "../../../../utils/icons";
import NavbarContext from "../../../../utils/contexts/Navbar";
import axios from "../../../../utils/http";
import {getRouter} from "../../../../utils/helpers";
import API from "../../../../utils/api";
import {toast} from "react-toastify";
import {logIn} from "../../../../redux/actions/clientActions";
import {useDispatch} from "react-redux";
import CircleLoading from "../../../common/others/CircleLoading";

const ForgetPasswordConfirm = (props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {modalsDisplayStatus, toggleModals, setAuthValues, authValues} = useContext(NavbarContext);
    const [loading, setLoading] = useState(false);

    function toggle() {
        toggleModals({...modalsDisplayStatus, forgetPasswordConfirm: false});
        setAuthValues({
            ...authValues, resetEmailAddress: '', resetToken: ''
        })
    }

    async function submit(values) {
        if (loading)
            return;

        setLoading(true);

        try {

            const {data} = await axios.put(getRouter(API.auth.forgetPassword.change, {
                userType: authValues.userType
            }), {
                password: values.password,
                password_confirmation: values.passwordConfirmation,
                token: authValues.resetToken,
                email: authValues.resetEmailAddress
            });

            if (data.success) {
                toggleModals({...modalsDisplayStatus, forgetPasswordConfirm: false, auth: true});
            }
        } catch (e) {

        }

        setLoading(false);
    }

    return (
        <ModalWrapper className={"email-login-modal"} toggle={toggle}>
            {icons.user}
            <p className={"center modal-title"}>{t("Forget Password")}</p>

            <Formik
                initialValues={{
                    password: "",
                    passwordConfirmation: ""
                }}
                onSubmit={(values) => submit(values)}
            >
                {({
                      errors,
                      values,
                      handleChange,
                      touched,
                      setFieldTouched,
                      handleSubmit,
                  }) => (
                    <>
                        <TextInput
                            placeholder={t("Password")}
                            value={values.password}
                            onChange={handleChange("password")}
                            error={{
                                message: errors.password,
                                visible: touched.password,
                            }}
                            isPassword
                        />
                        <TextInput
                            placeholder={t("Password Confirmation")}
                            value={values.passwordConfirmation}
                            onChange={handleChange("passwordConfirmation")}
                            error={{
                                message: errors.passwordConfirmation,
                                visible: touched.passwordConfirmation,
                            }}
                            isPassword
                        />
                        <button onClick={() => handleSubmit()} type={"button"} className={"button primary"}>
                            {loading
                                ? <CircleLoading/>
                                : t("Confirm")}
                        </button>
                    </>
                )}
            </Formik>
        </ModalWrapper>
    );
};

export default ForgetPasswordConfirm;
