import "./Episode.scss";
// Utils
import icons from "../../../../../../utils/icons";

const Episode = ({ data, onClick }) => {
  return (
    <button type={"button"} className={"episode"} onClick={onClick}>
      {icons.sound}

      <div>
        <p>{data.title}</p>
        <p>قسمت: {data.episode}</p>
      </div>
    </button>
  );
};

export default Episode;
