import React, { useEffect, useState } from "react";
import "./TextIndicator.scss";
// Utils
import icons from "../../../../../utils/icons";

const TextIndicator = (props) => {
  const [showCopyToastMessage, setShowCopyToastMessage] = useState(false);
  let timeout = null;
  function toggleCopyToastMessage() {
    navigator.clipboard.writeText(props.content);
    setShowCopyToastMessage(true);
    timeout = setTimeout(() => {
      setShowCopyToastMessage(false);
    }, 2000);
  }
  useEffect(() => {
    return () => clearTimeout(timeout);
  }, [timeout]);

  return (
    <div
      className={`text-indicator ${props.copiable && "copiable"}`}
      onClick={() => props.copiable && toggleCopyToastMessage()}
    >
      <p>{props.title}</p>

      <p>{props.content}</p>

      {props.copiable && icons.copy}

      <p className={showCopyToastMessage ? "visible" : undefined}>کپی شد!</p>
    </div>
  );
};

export default TextIndicator;
