import "./css/Badge.scss";
import icons from "../../../utils/icons";


const Badge = ({ title, onRemoveClick }) => {
  return <div className={"badge-container"}>
    <p>{title}</p>
    {onRemoveClick ? <button onClick={onRemoveClick}>{icons.close}</button> : null}
  </div>;
};

export default Badge;
