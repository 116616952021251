import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import "./css/LogOut.scss";
// Common components
import ModalWrapper from "../../../common/others/ModalWrapper";
// Utils
import icons from "../../../../utils/icons";
import NavbarContext from "../../../../utils/contexts/Navbar";
import {logOut} from "../../../../redux/actions/clientActions";
import axios from './../../../../utils/http';
import API from "../../../../utils/api";
import {useNavigate} from "react-router-dom";
import CircleLoading from "../../../common/others/CircleLoading";

const LogOutModal = (props) => {
    const {t} = useTranslation();
    const {modalsDisplayStatus, toggleModals} = useContext(NavbarContext);
    const dispatch = useDispatch();
    const navigator = useNavigate();
    const [loading, setLoading] = useState(false);

    function toggle() {
        if (loading)
            return;
        toggleModals({...modalsDisplayStatus, logOut: false});
    }

    const handleLogout = async () => {
        if (loading)
            return;
        setLoading(true);
        try {
            const {data} = await axios.delete(API.auth.logout);

            if (data.success) {
                dispatch(logOut());
                localStorage.removeItem('token');
                axios.setAuthorizationHeader(null);
                navigator('/');
                toggle();
            }
        } catch (e) {

        }
        setLoading(false);
    }

    return (<ModalWrapper className={"log-out-modal"} toggle={toggle}>
        {icons.logOut}
        <p className={"center modal-title"}>{t("Log out")}</p>
        <p className={"modal-tip"}>{t("Do you want to leave?")}</p>
        <div>
            <button
                type={"button"}
                className={"button ternary"}
                onClick={handleLogout}
            >
                {loading
                    ? <CircleLoading color={'#1c3c53'}/>
                    : t("Yes")}

            </button>
            <button
                type={"button"}
                className={"button primary"}
                onClick={toggle}
            >
                {t("No")}
            </button>
        </div>
    </ModalWrapper>);
};

export default LogOutModal;
