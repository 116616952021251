import React, {useEffect, useState} from "react";
import {useClickOutside} from "@mantine/hooks";
import "./css/AmountSelector.scss";
// Utils
import giftCardAmounts from "../../../utils/fake-data/giftCardAmounts";
import i18n from "i18next";

const AmountSelector = ({value, updateValue, values, readOnly = false, className}) => {
    const [showDropdown, setShowDropdown] = useState(null);
    const dropdownRef = useClickOutside(
        () => showDropdown && setShowDropdown(false)
    );
    const dropdownClassName =
        showDropdown === true
            ? "active"
            : showDropdown === false
                ? "inactive"
                : undefined;

    return (
        <div
            className={`amount-selector ${className || ''}`}
            onClick={() => setShowDropdown(!showDropdown)}
            ref={dropdownRef}
        >

            <div className={dropdownClassName} style={{direction:'rtl'}}>
                {values.map((v, index) => (
                    <p
                        key={index}
                        className={value?.key === v.key ? "selected" : undefined}
                        onClick={() => readOnly ? null : updateValue(v)}
                        style={{cursor: readOnly ? 'default' : 'pointer', direction:i18n.language === 'kuk' ? 'ltr' : 'rtl'}}
                    >
                        {v.value}
                    </p>
                ))}
            </div>
        </div>
    );
};

export default AmountSelector;
