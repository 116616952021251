import "./Episodes.scss";
// Sub components
import Episode from "./sub-components/Episode";
// Utils
import tempMedia from "../../../../../utils/tempMedia";
import { useTranslation } from "react-i18next";
import CircleLoading from "../../../../common/others/CircleLoading";

const Episodes = ({ setCurrentEpisode, episodes, currentEpisode, hasMore, onLoadMore, loadMoreLoading }) => {
  const { t } = useTranslation();
  return (
    <div className={"episodes"}>
      <div>
        {currentEpisode && <>
          <img alt={"Podcast"} src={currentEpisode.cover} />
          <div>
            <p>{currentEpisode.title}</p>
            <p>{currentEpisode?.speakers?.map(speaker => speaker.name)?.join(", ")}</p>
            <p>{currentEpisode.published_at}</p>
          </div>
        </>}

      </div>

      <div className={"no-scrollbar"}>
        {episodes.map((item) => (
          <Episode
            key={item.id}
            data={item}
            onClick={() => setCurrentEpisode(item)}
          />
        ))}
        {hasMore && <div className={"load-more-episodes"}>
          <button className={"button primary"} onClick={() => {
            if (onLoadMore)
              onLoadMore();
          }}>{loadMoreLoading ? <CircleLoading width={20} /> : t("Load More")}</button>
        </div>}
      </div>
    </div>
  );
};

export default Episodes;
