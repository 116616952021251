import React, { useContext, useState } from 'react';
import './css/Comments.scss';
import { useTranslation } from 'react-i18next';
// Common components
// import NewsAndComment from '../../../common/others/NewsAndComment';
// Modals
import AddCommentModal from '../../../modals/AddComment';
// Utils
// import comments from '../../../../utils/fake-data/comments';
import ProductInfoContext from '../../../../utils/contexts/Product';
// import icons from '../../../../utils/icons';
import Rating from './Rating';
import { toast } from 'react-toastify';
import { getRouter } from '../../../../utils/helpers';
import Api from '../../../../utils/api';
import { useSelector } from 'react-redux';
import moment from 'moment';
import axios from '../../../../utils/http';
const Comments = () => {
  const { t } = useTranslation();
  // const [showAddCommentModal, setShowAddCommentModal] = useState(false);
  const { product } = useContext(ProductInfoContext);
  const [userRating, setUserRating] = useState(0);

  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(false);
  const client = useSelector((state) => state.client);

  const handleRatingChange = (rating) => {
    setUserRating(rating);
  };

  const addComment = async () => {
    if (!client) {
      toast(t('For This Comment, First you Need To Login'), {
        type: 'error',
      });
      return;
    }
    if (loading) return;

    setLoading(true);

    try {
      const { data } = await axios.post(
        getRouter(Api.comments.addComment, {
          productId: product?.id,
        }),
        {
          user_id: client.id,
          comment,
          rating: userRating,
        }
      );

      if (data.success) {
        toast(
          t(
            'Your Comment has Been Submitted and Will Show it After Confirmation'
          ),
          { type: 'success' }
        );
      }
    } catch (e) {}
    setLoading(false);
  };
  return (
    <div className={'comments'}>
      {/* <div>
        <p>{t('Comments about audio book')}</p>
        <button
          type={'button'}
          className={'button primary'}
          onClick={() => setShowAddCommentModal(true)}
        >
          {t('Add comment')}
        </button>
      </div>

      {comments?.map((comment) => (
        <NewsAndComment
          key={comment.id}
          comment={comment}
          isComment
          replies={comment.replies}
        />
      ))}

      {showAddCommentModal && (
        <AddCommentModal
          productId={product?.id}
          toggleDisplay={setShowAddCommentModal}
        />
      )} */}

      {client ? (
        <div className='cardA'>
          <p className='comment-title'>{t('comment title')}</p>
          <p className='comment-subtitle'>{t('Comment Subtitle')}</p>

          <div className=' d-flex'>
            {/* <div className='comment-img'>
              <div className='comment'>
                <img src='/avtar.png' alt='manager name' />
              </div>
            </div> */}
            <div className='comment-message'>
              <h5 className='mt-5 comment-name'>
                {client.first_name} {client.last_name}
              </h5>
              <p className='date'>{moment().format('YYYY/MM/DD')}</p>
              <textarea
                rows='7'
                className='my-3 '
                placeholder={t('Comment PlaceHolder')}
                onChange={(e) => setComment(e.target.value)}
              ></textarea>
              <div className='d-flex justify-content-between'>
                <div>
                  <p>{t('Comment Note')}</p>
                </div>
                <div style={{ width: '150px' }}>
                  <div className='ratting d-flex'>
                    <Rating onRatingChange={handleRatingChange} />
                  </div>
                  <p className='mt-2'>{`${userRating} ئەستێرە لە 5 ئەستێرە`}</p>
                </div>
              </div>
              <div className='comment-btn'>
                <button type='button' onClick={() => addComment()}>
                  {t('Confirm')}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <button className='add-comment-btn' onClick={() => addComment()}>
            {t('Add comment')}
          </button>
        </div>
      )}
    </div>
  );
};

export default Comments;
