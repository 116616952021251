import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import OTPInput from "otp-input-react";
import { toast } from "react-toastify";
import "./PublisherRegister.scss";
// Components
import TextInput from "../../components/common/others/TextInput";
import RadioButton from "../../components/common/others/RadioButton";
import PhoneNumberInput from "../../components/common/phone-number-input/PhoneNumberInput";
import CircleLoading from "../../components/common/others/CircleLoading";
import ModalWrapper from "../../components/common/others/ModalWrapper";
// Form validation
import { Formik } from "formik";
import { publisherRegisterSchemas } from "../../utils/formValidationSchemas";
// Utils
import icons from "../../utils/icons";
import axios from "../../utils/http";
import { getRouter } from "../../utils/helpers";
import API from "../../utils/api";
import UploadZone from "../../components/common/others/UploadZone";
import { useNavigate } from "react-router-dom";

const PublisherRegister = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [otpType, setOtpType] = useState("EMAIL");
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(120);
  const [otpLoading, setOtpLoading] = useState(false);
  const [logo, setLogo] = useState("");
  const [documents, setDocuments] = useState([]);
  const [interval, setIntervalKey] = useState(null);
  const navigate = useNavigate();

  function renderTimer() {
    const minutes = Math.floor(timer / 60);
    const minutesToShow = minutes < 10 ? "0" + minutes : minutes;
    const seconds = timer % 60;
    const secondsToShow = seconds < 10 ? "0" + seconds : seconds;
    return minutesToShow + ":" + secondsToShow;
  }

  const [dialCode, setDialCode] = useState("+98");
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    console.log(interval);
  }, [interval]);

  const resendOTP = async (emailAddress) => {
    if (otpLoading || loading) return;
    setOtpLoading(true);
    if (!showOtpModal)
      setLoading(true);
    const putData = {};
    if (otpType === "EMAIL") {
      putData.email = emailAddress;
    } else {
      putData.mobile = phoneNumber;
      putData.country_code = dialCode;
    }
    try {
      const { data } = await axios.post(
        getRouter(API.auth.otp, {
          userType: "publisher"
        }),
        putData
      );

      if (data.success) {
        if (!showOtpModal)
          setShowOtpModal(true);
        toast(data.data?.code, { type: "success" });
        setTimer(120);
        let myTimer = 120;
        const myInterval = setInterval(() => {
          if (myTimer > 0) {
            myTimer--;
            setTimer(myTimer);
          } else {
            clearInterval(myInterval);
          }
        }, 1000);
        setIntervalKey(myInterval);

      }
    } catch (e) {
    }
    setLoading(false);
    setOtpLoading(false);
  };

  async function handleSubmit(values) {
    if (loading) return;
    setLoading(true);
    try {
      const { data } = await axios.post(
        getRouter(API.auth.register, { userType: "publisher" }),
        {
          first_name: values.firstName,
          last_name: values.lastName,
          national_code: values.nationalID,
          gender: values.gender,
          country_code: dialCode.replace("+", ""),
          mobile: phoneNumber,
          email: values.emailAddress,
          otp: values.otp,
          referral: values.referral,
          documents,
          phone: values.phone,
          publisher_name: values.publisherName,
          publisher_description: values.publisherDescription,
          publisher_email: values.publisherEmail,
          publisher_code: values.publisherCode,
          publisher_address: values.publisherAddress,
          post_box: values.postBox,
          postal_code: values.postalCode,
          logo
        }
      );
      if (data.success) {
        toast(t("Your Registration as Publisher has been Successfully Completer! You can now Login."), { type: "success" });
        toast(t("Your Username and Password has been sent to your Email."), { type: "success" });
        navigate("/", { replace: true });
      }
    } catch (e) {
    }
    setLoading(false);
  }

  const handleUploadLogoSuccess = (response) => {
    setLogo(response.data.path);
  };

  const handleLogoRemoved = () => {
    setLogo("");
  };

  const handleUploadDocumentSuccess = (response) => {
    setDocuments([...documents, response.data.path]);
  };

  const handleDocumentRemoved = (file, index) => {
    setDocuments(documents.filter((d, i) => i !== index));
  };

  return (
    <>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          nationalID: "",
          birthday: "",
          emailAddress: "",
          gender: "male",
          dialCode: "",
          phoneNumber: "",
          phone: "",
          publisherName: "",
          publisherDescription: "",
          publisherEmail: "",
          publisherCode: "",
          publisherAddress: "",
          postBox: "",
          postalCode: "",
          referral: ""
        }}
        validateOnChange
        onSubmit={(value) => handleSubmit(value)}
        validationSchema={publisherRegisterSchemas}
      >
        {({
            values,
            handleChange,
            setFieldValue,
            errors,
            touched,
            setFieldTouched
          }) => (
          <>
            <div className={"publisher-register"}>
              <div className={"right-section"}>
                <TextInput
                  placeholder={t("First name")}
                  value={values.firstName}
                  onChange={handleChange("firstName")}
                  error={{
                    message: errors.firstName,
                    visibility: touched.firstName
                  }}
                  onBlur={() => setFieldTouched("firstName")}
                />
                <TextInput
                  placeholder={t("Last name")}
                  value={values.lastName}
                  onChange={handleChange("lastName")}
                  error={{
                    message: errors.lastName,
                    visibility: touched.lastName
                  }}
                  onBlur={() => setFieldTouched("lastName")}
                />
                <TextInput
                  placeholder={t("National ID")}
                  value={values.nationalID}
                  onChange={handleChange("nationalID")}
                  error={{
                    message: errors.nationalID,
                    visibility: touched.nationalID
                  }}
                  onBlur={() => setFieldTouched("nationalID")}
                />
                <div className={"gender-container"}>
                  <RadioButton
                    group={"gender"}
                    id={"male"}
                    title={t("Male")}
                    checked={values.gender === "male"}
                    updateValue={() => setFieldValue("gender", "male")}
                  />
                  <RadioButton
                    group={"gender"}
                    id={"female"}
                    title={t("Female")}
                    checked={values.gender === "female"}
                    updateValue={() => setFieldValue("gender", "female")}
                  />
                </div>
                <PhoneNumberInput
                  dialCode={dialCode}
                  onDialCodeChange={setDialCode}
                  phoneNumber={phoneNumber}
                  onPhoneNumberChange={(e) => setPhoneNumber(e.target.value)}
                />
                <TextInput
                  placeholder={t("Email address")}
                  value={values.emailAddress}
                  onChange={handleChange("emailAddress")}
                  error={{
                    message: errors.emailAddress,
                    visibility: touched.emailAddress
                  }}
                  onBlur={() => setFieldTouched("emailAddress")}
                />

                <TextInput
                  placeholder={t("Referral Code")}
                  value={values.referral}
                  onChange={handleChange("referral")}
                  error={{
                    message: errors.referral,
                    visibility: touched.referral
                  }}
                  onBlur={() => setFieldTouched("referral")}
                />

                <TextInput
                  placeholder={t("Publisher Name")}
                  value={values.publisherName}
                  onChange={handleChange("publisherName")}
                  error={{
                    message: errors.publisherName,
                    visibility: touched.publisherName
                  }}
                  onBlur={() => setFieldTouched("publisherName")}
                />

                <textarea value={values.publisherDescription}
                          style={{ height: 144 }}
                          placeholder={t("Description / Publisher Introduction")}
                          onChange={handleChange("publisherDescription")}
                          onBlur={() => setFieldTouched("publisherDescription")}>
                </textarea>
              </div>

              <div />

              <div className={"left-section"}>
                <p>{t("Upload file")}</p>
                <div className="uploader-zones">
                  <UploadZone title={"آپلود لوگوی انتشارات"} onUploadSuccess={handleUploadLogoSuccess} uploadOnSelect
                              uploadURL={API.publisher.logo} uploadFolder="logo" onRemove={handleLogoRemoved} />
                  <UploadZone title={"آپلود مدارک"} multiple onUploadSuccess={handleUploadDocumentSuccess}
                              uploadOnSelect
                              uploadURL={API.publisher.documents} uploadFolder="document"
                              onRemove={handleDocumentRemoved} />
                </div>


                <TextInput
                  placeholder={t("Phone with Country Code")}
                  value={values.phone}
                  onChange={handleChange("phone")}
                  error={{
                    message: errors.phone,
                    visibility: touched.phone
                  }}
                  onBlur={() => setFieldTouched("phone")}
                />
                <TextInput
                  placeholder={t("Postal Box")}
                  value={values.postBox}
                  onChange={handleChange("postBox")}
                  error={{
                    message: errors.postBox,
                    visibility: touched.postBox
                  }}
                  onBlur={() => setFieldTouched("postBox")}
                />
                <TextInput
                  placeholder={t("Postal Code")}
                  value={values.postalCode}
                  onChange={handleChange("postalCode")}
                  error={{
                    message: errors.postalCode,
                    visibility: touched.postalCode
                  }}
                  onBlur={() => setFieldTouched("postalCode")}
                />
                <TextInput
                  placeholder={t("Publisher Email")}
                  value={values.publisherEmail}
                  onChange={handleChange("publisherEmail")}
                  error={{
                    message: errors.publisherEmail,
                    visibility: touched.publisherEmail
                  }}
                  onBlur={() => setFieldTouched("publisherEmail")}
                />
                <TextInput
                  placeholder={t("Publisher Code")}
                  value={values.publisherCode}
                  onChange={handleChange("publisherCode")}
                  error={{
                    message: errors.publisherCode,
                    visibility: touched.publisherCode
                  }}
                  onBlur={() => setFieldTouched("publisherCode")}
                />
                <textarea value={values.publisherAddress}
                          style={{ height: 144 }}
                          placeholder={t("Central Address")}
                          onChange={handleChange("publisherAddress")}
                          onBlur={() => setFieldTouched("publisherAddress")}>
                </textarea>
                <button
                  onClick={() => resendOTP(values.emailAddress)}
                  type={"button"}
                  className={"button primary submit-register"}
                >
                  {loading ? <CircleLoading /> : t("Confirm")}
                </button>
              </div>
            </div>
            {showOtpModal && (
              <ModalWrapper
                className={"otp-modal"}
                toggle={() => {
                  console.log(interval);
                  clearInterval(interval);
                  setShowOtpModal(false);
                  setOtp("");
                }}
              >
                <div>
                  <p style={{ textAlign: "center" }}>
                    {otpType === "EMAIL"
                      ? values.emailAddress
                      : `${dialCode}${phoneNumber}`}
                  </p>
                </div>
                <p style={{ textAlign: "center" }}>
                  {otpType === "EMAIL"
                    ? t(
                      "Please enter the code sent via Email in the field below."
                    )
                    : t(
                      "Please enter the code sent via SMS in the field below."
                    )}
                </p>
                <OTPInput
                  value={otp}
                  onChange={(value) => {
                    setOtp(value);
                    if (value.length === 5) {
                      setShowOtpModal(false);
                      handleSubmit({
                        ...values,
                        otp: value
                      });
                    }
                  }}
                  autoFocus
                  OTPLength={5}
                  otpType="number"
                  className={"otp-input"}
                />
                {timer !== 0 && (
                  <div className={"center timer"}>
                    <p>{renderTimer()}</p>
                    {icons.progressRing}
                  </div>
                )}
                <p className={"center"}>{t("Remaining time")}</p>
                {timer === 0 && (
                  <button
                    type={"button"}
                    className={"button primary"}
                    onClick={() => resendOTP(values.emailAddress)}
                  >
                    {otpLoading ? <CircleLoading /> : t("Resend")}
                  </button>
                )}
              </ModalWrapper>
            )}
          </>
        )}
      </Formik>
    </>
  );
};

export default PublisherRegister;
