import React, { useEffect, useState } from "react";
import "./css/AlarmsAndNewPaper.scss";
import { useTranslation } from "react-i18next";
import Card from "../../components/common/others/Card";
import axios from "./../../utils/http";
import API from "./../../utils/api";
import InfiniteScroll from "react-infinite-scroll-component";
import CircleLoading from "../../components/common/others/CircleLoading";
import Notification from "../../components/common/others/Notification";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Notifications = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const client = useSelector(state => state.client);
  const [notifications, setNotifications] = useState([]);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [total, setTotal] = useState(0);

  const getNotifications = async () => {
    try {
      const { data } = await axios.get(nextPageUrl || API.dashboard.notifications);
      setNotifications([...notifications, ...data.data.data]);
      setNextPageUrl(data.data.next_page_url);
      setTotal(data.data.total);
    } catch (e) {

    }
  };

  useEffect(() => {
    if (!client) {
      toast(t("Authentication Failed! Please Login Again"), { type: "error" });
      navigate("/");
      return;
    }
    getNotifications();
  }, []);


  return (
    <Card className={"alarms-and-newspaper"}>
      <p>{t("Notifications")}</p>
      {notifications.length ?
        <InfiniteScroll next={getNotifications} hasMore={!!nextPageUrl} loader={<CircleLoading />} dataLength={total}>
          {notifications.map((notification, index) => (
            <Notification notification={notification} key={index} />
          ))}
        </InfiniteScroll>
        : <p className={"empty-notification"}>{t("No Notifications Found")}</p>
      }
    </Card>
  );
};

export default Notifications;
