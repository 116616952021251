// Books and journals
import azZaminTaMah from "../assets/temp-media/products/books-and-journals/az-zamin-ta-mah.jpg";
import balHayeAtashin from "../assets/temp-media/products/books-and-journals/bal-haye-atashin.jpg";
import baqeMakhfi from "../assets/temp-media/products/books-and-journals/baqe-makhfi.png";
import harryPotter from "../assets/temp-media/products/books-and-journals/harry-potter.jpg";
import jabeParande from "../assets/temp-media/products/books-and-journals/jabe-parande.jpg";
import jozAzKol from "../assets/temp-media/products/books-and-journals/joz-az-kol.jpg";
import labkhandeMahtab from "../assets/temp-media/products/books-and-journals/labkhande-mahtab.jpg";
import maedeHayeZamini from "../assets/temp-media/products/books-and-journals/maede-haye-zamini.jpg";
import manPishAzTo from "../assets/temp-media/products/books-and-journals/man-pish-az-to.jpg";
// Audio books
import azZaminTaMahAB from "../assets/temp-media/products/audio-books/az-zamin-ta-mah.jpg";
import balHayeAtashinAB from "../assets/temp-media/products/audio-books/bal-haye-atashin.jpg";
import baqeMakhfiAB from "../assets/temp-media/products/audio-books/baqe-makhfi.png";
import harryPotterAB from "../assets/temp-media/products/audio-books/harry-potter.jpg";
import jabeParandeAB from "../assets/temp-media/products/audio-books/jabe-parande.jpg";
import jozAzKolAB from "../assets/temp-media/products/audio-books/joz-az-kol.jpg";
import labkhandeMahtabAB from "../assets/temp-media/products/audio-books/labkhande-mahtab.jpg";
import maedeHayeZaminiAB from "../assets/temp-media/products/audio-books/maede-haye-zamini.jpg";
import manPishAzToAB from "../assets/temp-media/products/audio-books/man-pish-az-to.jpg";
// Podcasts
import haftadoPangRozGomshodeDarDarya from "../assets/temp-media/products/podcasts/75-roz-gomshode-dar-darya.jpg";
import amok from "../assets/temp-media/products/podcasts/amok.jpg";
import daneshmandODarya from "../assets/temp-media/products/podcasts/daneshmand-o-darya.jpg";
import dast from "../assets/temp-media/products/podcasts/dast.jpg";
import derazGoladeMariana from "../assets/temp-media/products/podcasts/deraz-golade-mariana.jpg";
import enqerazeBashar from "../assets/temp-media/products/podcasts/enqeraze-bashar.jpg";
import johnMcafee from "../assets/temp-media/products/podcasts/john-mcafee.jpg";
import karmaOCorona from "../assets/temp-media/products/podcasts/karma-o-corona.jpg";
import kashtiAshbah from "../assets/temp-media/products/podcasts/kashti-ashbah.jpg";
import manKoshteKhahamShod from "../assets/temp-media/products/podcasts/man-koshte-khaham-shod.jpg";
import mojeEnergy from "../assets/temp-media/products/podcasts/moje-energy.jpg";
import mythos from "../assets/temp-media/products/podcasts/mythos.jpg";
import nameEAzNorvej from "../assets/temp-media/products/podcasts/name-e-az-norvezh.jpg";
import osho from "../assets/temp-media/products/podcasts/osho.jpg";
import podcastName from "../assets/temp-media/products/podcasts/podcast-name.jpg";
// Others
import captcha from "../assets/temp-media/others/captcha.png";
import guideStep from "../assets/temp-media/others/guide-step.png";
import namadeEtemadeElectronic from "../assets/temp-media/others/namade-etemade-electronic.png";
import neshaneMelliSabt from "../assets/temp-media/others/neshane-melli-sabt.png";
import noFriendsButMountain from "../assets/temp-media/others/no-friends-but-mountain.png";
import siteSuggestionOne from "../assets/temp-media/others/site-suggestion-1.png";
import siteSuggestionOneLTR from "../assets/temp-media/others/site-suggestion-1-ltr.png";
import siteSuggestionTwo from "../assets/temp-media/others/site-suggestion-2.png";
import w from "../assets/temp-media/others/w.png";
import wRead from "../assets/temp-media/others/w-read.png";

const tempMedia = {
  products: {
    booksAndJournals: {
      azZaminTaMah,
      balHayeAtashin,
      baqeMakhfi,
      harryPotter,
      jabeParande,
      jozAzKol,
      labkhandeMahtab,
      maedeHayeZamini,
      manPishAzTo,
    },

    audioBooks: {
      azZaminTaMahAB,
      balHayeAtashinAB,
      baqeMakhfiAB,
      harryPotterAB,
      jabeParandeAB,
      jozAzKolAB,
      labkhandeMahtabAB,
      maedeHayeZaminiAB,
      manPishAzToAB,
    },

    podcasts: {
      haftadoPangRozGomshodeDarDarya,
      amok,
      daneshmandODarya,
      dast,
      derazGoladeMariana,
      enqerazeBashar,
      johnMcafee,
      karmaOCorona,
      kashtiAshbah,
      manKoshteKhahamShod,
      mojeEnergy,
      mythos,
      nameEAzNorvej,
      osho,
      podcastName,
    },
  },

  others: {
    captcha,
    guideStep,
    namadeEtemadeElectronic,
    neshaneMelliSabt,
    noFriendsButMountain,
    siteSuggestionOne,
    siteSuggestionOneLTR,
    siteSuggestionTwo,
    w,
    wRead,
  },
};

export default tempMedia;
