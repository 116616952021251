import React from "react";
import { useTranslation } from "react-i18next";
import "./css/SlideOne.scss";
// Utils
import icons from "../../../utils/icons";
import images from "../../../utils/images";

const SlideOne = ({ value }) => {
  const { t } = useTranslation();

  return (
    <div className={"gift-card-slide slide-one"}>
      <div>
        <img alt={"Anobani"} src={images.anobaniLogo} />
        <p>
          {value?.toLocaleString()}
        </p>
      </div>

      <div>
        {icons.giftBoxOrange}
        {icons.giftBoxTurquoise}
        {icons.giftBoxYellow}
      </div>

      <div>
        <p>{t("Gift card")}</p>
        <p>{t("Anobani e-book")}</p>
      </div>
    </div>
  );
};

export default SlideOne;
