import React, {useState} from "react";
import "./css/SearchBox.scss";
// Utils
import icons from "../../../../utils/icons";

const SearchBox = ({search, onSearchChange, onSearchSubmit}) => {
    const [showFullBox, setShowFullBox] = useState(false);

    return (
        <form onSubmit={(e) => {
            e.preventDefault();
            onSearchSubmit();
        }} className={`filters-search-box ${showFullBox && "visible"}`}>
            <div onClick={() => setShowFullBox(!showFullBox)}>{icons.search}</div>
            <input type={"text"} value={search} onChange={e => onSearchChange(e.target.value)}/>
        </form>
    );
};

export default SearchBox;
