import { useContext, useEffect, useState } from 'react';
import './css/PurchaseAndPreview.scss';
// Sub components
import AudioPlayer from './AudioPlayer';
// Modals
import PodcastModal from '../../../../modals/podcast/Podcast';
// Utils
import ProductInfoContext from '../../../../../utils/contexts/Product';
import { useLocation, useNavigate } from 'react-router-dom';
import { getRouter } from '../../../../../utils/helpers';
import Api from '../../../../../utils/api';
import { toast } from 'react-toastify';
import axios from '../../../../../utils/http';
import CircleLoading from '../../../../common/others/CircleLoading';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { setClient } from '../../../../../redux/actions/clientActions';

const PurchaseAndPreview = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [showPodcastModal, setShowPodcastModal] = useState(false);
  const { product, bookType } = useContext(ProductInfoContext);
  const [cart, setCart] = useState(product?.in_cart);
  const [cartLoading, setCartLoading] = useState(false);
  const [podcastEpisodes, setPodcastEpisodes] = useState([]);
  const [hasMoreEpisodes, setHasMoreEpisodes] = useState(false);
  const [hasMoreEpisodesLoading, setHasMoreEpisodesLoading] = useState(false);
  const [currentEpisodesPage, setCurrentEpisodesPage] = useState(1);
  const [currentTime, setCurrentTime] = useState();
  const client = useSelector((state) => state.client);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addRemoveToCart = async (type) => {
    if (!client) {
      toast(t('For This Purchase, First you Need To Login'), {
        type: 'error',
      });
      return;
    }
    if (cartLoading) return;
    setCartLoading(true);
    try {
      const { data } = await axios.post(
        getRouter(Api.cart.cartAction, {
          productId: product?.id,
        }),
        {
          type,
          language: product?.current_language,
        }
      );

      if (data.success) {
        toast(data.message, { type: 'success' });
        const isInCart = Object.values(cart).find((c) => c);
        if (isInCart) {
          dispatch(
            setClient({
              ...client,
              cart_items_count: +client.cart_items_count - 1,
            })
          );
        } else {
          dispatch(
            setClient({
              ...client,
              cart_items_count: +client.cart_items_count + 1,
            })
          );
        }
        setCart({ ...cart, [type]: !cart[type] });
      }
    } catch (e) {}
    setCartLoading(false);
  };

  const goToReaderPage = (type) => {
    if (!client) {
      toast(t('For This Purchase, First you Need To Login'), {
        type: 'error',
      });
      return;
    }

    return navigate('/reader', {
      state: {
        title: product?.title,
        slug: product?.slug,
        locale: product?.current_language,
        productType: product?.type,
        purchaseType: type,
        url:
          type === 'demo'
            ? product?.demo?.epub?.url || product?.demo?.pdf?.url
            : product?.main?.epub?.url || product?.main?.pdf?.url,
        lastPage: product?.last_page,
      },
    });
  };

  const handleTimeUpdate = async (time) => {
    if (!client || client?.role != 'user') return;

    if (time == currentTime) return;

    setCurrentTime(time);

    if (time % 5 !== 0) return;

    try {
      await axios.post(
        getRouter(Api.dashboard.readBook, {
          product: product?.slug,
        }),
        {
          product_type: product?.type,
          product_lang: product?.current_language,
          time: 5,
        }
      );
    } catch (e) {}
  };

  function renderActions() {
    switch (product?.type) {
      case 'electronic_book':
      case 'audio_book':
        if (bookType === 'electronic_book') {
          return (
            <>
              {product?.is_purchased?.electronic_book ||
              product?.purchase_type === 'free' ? (
                <button
                  onClick={() => goToReaderPage('main')}
                  type={'button'}
                  className={'button-light'}
                >
                  {t('Read Book')}
                </button>
              ) : (
                <button
                  type={'button'}
                  onClick={() => addRemoveToCart('electronic_book')}
                  // className={'button primary'}
                >
                  {cartLoading ? (
                    <CircleLoading />
                  ) : cart?.electronic_book ? (
                    t('Remove from Cart')
                  ) : (
                    t('Buy and Read')
                  )}
                </button>
              )}
              {/* {!product?.is_purchased?.electronic_book &&
                product?.purchase_type === 'paid' &&
                product?.demo?.epub && (
                  <button
                    onClick={() => goToReaderPage('demo')}
                    type={'button'}
                    // className={'button secondary'}
                  >
                    {t('Demo')}
                  </button>
                )} */}
            </>
          );
        }
        if (bookType === 'audio_book') {
          return (
            <>
              {!product?.is_purchased?.audio_book &&
              product?.purchase_type === 'paid' ? (
                <button
                  type={'button'}
                  onClick={() => addRemoveToCart('audio_book')}
                  // className={'button primary'}
                >
                  {cartLoading ? (
                    <CircleLoading />
                  ) : cart?.audio_book ? (
                    t('Remove from Cart')
                  ) : (
                    t('Buy Audio Book')
                  )}
                </button>
              ) : null}
              {/* {(product?.is_purchased?.audio_book &&
                product?.purchase_type === 'paid') ||
              product?.purchase_type === 'free' ? (
                <>
                  {product?.main?.mp3?.map((el) => (
                    <div key={el?.url}>
                      <p>{el?.original_file_name}</p>
                      <AudioPlayer
                        url={el?.url}
                        onTimeUpdate={handleTimeUpdate}
                      />
                    </div>
                  ))}
                </>
              ) : (
                <AudioPlayer url={product?.demo?.mp3?.url} />
              )} */}
            </>
          );
        }
        break;

      case 'magazine':
        return (
          <>
            {!product?.is_purchased?.magazine &&
            product?.purchase_type === 'paid' ? (
              <button
                type={'button'}
                onClick={() => addRemoveToCart('magazine')}
                // className={'button primary'}
              >
                {cartLoading ? (
                  <CircleLoading />
                ) : cart?.magazine ? (
                  t('Remove from Cart')
                ) : (
                  t('Buy Magazine')
                )}
              </button>
            ) : (
              <button
                onClick={() => goToReaderPage('main')}
                type={'button'}
                // className={'button primary'}
              >
                {t('Read Magazine')}
              </button>
            )}
            {!product?.is_purchased?.magazine &&
            product?.purchase_type === 'paid' &&
            product?.demo?.epub ? (
              <button
                onClick={() => goToReaderPage('demo')}
                type={'button'}
                // className={'button secondary'}
              >
                {t('Demo')}
              </button>
            ) : null}
          </>
        );

      case 'podcast':
        return (
          <>
            {!product?.is_purchased?.podcast &&
            product?.purchase_type === 'paid' ? (
              <button
                type={'button'}
                // className={'button primary'}
                onClick={() => {
                  // setShowPodcastModal(true)
                  addRemoveToCart('podcast');
                }}
              >
                {cartLoading ? (
                  <CircleLoading />
                ) : cart?.podcast ? (
                  t('Remove from Cart')
                ) : (
                  t('Buy Podcast')
                )}
              </button>
            ) : null}
            {podcastEpisodes?.length ? (
              <button
                type={'button'}
                // className={'button primary'}
                onClick={() => setShowPodcastModal(true)}
                style={{ width: '100%' }}
              >
                {t('Other Podcasts of Collection')}
              </button>
            ) : null}
            {/* {(product?.is_purchased?.podcast &&
              product?.purchase_type === 'paid') ||
            product?.purchase_type === 'free' ? (
              // <AudioPlayer
              //   url={product?.main?.mp3?.url}
              //   onTimeUpdate={handleTimeUpdate}
              // />
              <>
                {product?.main?.mp3?.map((el) => (
                  <div key={el?.url}>
                    <p>{el?.original_file_name}</p>
                    <AudioPlayer
                      url={el?.url}
                      onTimeUpdate={handleTimeUpdate}
                    />
                  </div>
                ))}
              </>
            ) : (
              <AudioPlayer url={product?.demo?.mp3?.url} />
            )} */}
          </>
        );

      default:
        break;
    }
  }

  useEffect(() => {
    if (product?.type === 'podcast') {
      getPodcastEpisodes();
    }
  }, []);

  const getPodcastEpisodes = async (hasMore = false) => {
    if (hasMoreEpisodesLoading) return;
    setHasMoreEpisodesLoading(true);
    try {
      const { data } = await axios.get(
        getRouter(Api.products.podcastsEpisodes, {
          productId: product?.id,
        }),
        {
          params: {
            page: hasMore ? currentEpisodesPage + 1 : 1,
          },
        }
      );
      const { podcast } = data.data;
      setPodcastEpisodes([...podcastEpisodes, ...podcast.data]);
      setCurrentEpisodesPage(podcast.current_page);
      setHasMoreEpisodes(!!podcast.next_page_url);
    } catch (e) {}
    setHasMoreEpisodesLoading(false);
  };

  return (
    <div
      className={`purchase-and-preview ${
        product?.secondary_type ? bookType : product?.type
      }`}
    >
      {renderActions()}

      {showPodcastModal &&
      product?.type === 'podcast' &&
      podcastEpisodes.length ? (
        <PodcastModal
          toggle={setShowPodcastModal}
          episodes={podcastEpisodes}
          hasMore={hasMoreEpisodes}
          onLoadMore={() => getPodcastEpisodes(hasMoreEpisodes)}
          loadMoreLoading={hasMoreEpisodesLoading}
        />
      ) : null}
    </div>
  );
};

export default PurchaseAndPreview;
