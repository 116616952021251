const kukTranslations = {
  // Numbers
  '24/7': 'Heft rojên hefteyê (9.00 heta 18.00)',

  // A
  Articles: 'Gotarên',
  'About us': 'çûna nava',
  'Link Copied!': 'Girêdan hate kopî kirin!',
  'Active devices': 'Amûrên çalak',
  Activity: 'Çalakî',
  'Add card': 'karta zêde bike',
  'Add comment': 'Şîroveyek tomar bikin',
  'Your Comment has Been Submitted and Will Show it After Confirmation':
    'Şîroveya we hatiye tomarkirin û piştî pejirandinê dê were xuyang kirin',
  'Add invite code': 'Koda vexwendinê zêde bikin',
  'Add new product': 'Hilbera nû zêde bikin',
  'Edit Product': 'guherandina berhemê',
  'Select Product Type': 'Cureyê hilberê hilbijêre',
  Collection: 'berhevokê',
  'Select Category': 'Kategoriyek hilbijêrin',
  'Select Sub Category': 'Tîpa şaxê diyar bikin',
  'Select Filter Category': 'Parzûnê bi komkirinê hilbijêrin',
  'Select Genre': 'Cureyek hilbijêrin',
  'Add new section': 'Beşek nû lê zêde bike',
  'Add new wallet': 'Pûçek nû zêde bikin',
  'Add review': 'Nirxandinê zêde bikin',
  'Add your comment': 'Şîroveya xwe tomar bikin',
  Notifications: 'Notifications',
  'All the time': 'herdem',
  'Kurdi Kermanji': 'Kurdî Kirmancî',
  'Kurdi Soranî': 'کوردی سۆرانی',
  Amount: 'biha',
  'Anobani suggestions': 'Pêşniyara Anubani',
  'Anobani e-book': 'Anubani e-pirtûka',
  'Anobani gift card': 'Qerta krediyê diyariya Anubani',
  Apply: 'Bikaranîn',
  'Audio book': 'Pirtûka deng',
  'Has Audio book': 'Pirtûka Audio heye',
  'Add to Special Discounts':
    'Divê hilbera min ji bo dakêşanên li ser malperê di kategoriya hilberên taybetî de were danîn',
  'Audio books in this collection': 'Pirtûkên dengî yên vê berhevokê',
  'Other Products in this collection': 'Berhemên din di vê berhevokê de',
  Podcasts: 'Podcasts',
  'Add Review': 'Çavdêriyê lê zêde bike',
  'Section Title': 'Sernavê beşê',
  'Review Text': 'Nivîsara Çavdêriyê',
  'Caption for Uploaded File': 'Danasîna pelê barkirî',
  'Audio books': 'pirtûkên dengî',
  'Add New Products': 'Berhemên Nû lê zêde bikin',
  Author: 'nivîskar',
  Authors: 'Nivîskar',
  'No Notifications Found': 'Ti hişyarî nehat dîtin',
  'Select Currency': 'Pereyê xwestî hilbijêrin',

  // B
  'Best sellers of the week': 'Firotana herî baş a hefteyê',
  Birthday: 'Roja bûyînê',
  Blog: 'Blog',
  'Buy audio book': 'Pirtûkên dengî bikirin',
  'Buy e-book': 'Kirîna e-pirtûk',
  'Buy e-books': 'E-pirtûkan bikirin',
  'Buy journal': 'Kirîna kovaran',
  'Buy podcast': 'Kirîna podcasts',
  'Gateway Payment': 'Payment Înternetê',
  'Pay with Paypal': 'Payment bi rêya PayPal',
  'Pay with Anobani Wallet': 'Tezmînata bi berîka Anubani',
  'Pay with all Shetabit Cards':
    '(بە هەموو کارتەکانی ئەندامی شتاب پارە دەدرێت)',
  'Operation Completed Successfully': 'Pêvajo bi dawî bû',
  'You Wallet Just Charged': 'Qalîteya we tê barkirin',
  'Operation Failed': 'Operasyon bi ser neket',
  'Add Review for Product': 'Raya xwe ya hilberê tomar bikin',
  // C
  Cancel: 'Bişûndekirinî',
  Captions: 'Terîf',
  'Card number': 'hejmara karta',
  'Central office address': 'navnîşana ofîsa navendî',
  'Change password': 'Şîfreyê biguherînin',
  'Choose the payment method': 'Dîroka dravdanê hilbijêrin',
  Code: 'Navê dizî',
  Comments: 'comments',
  Commission: 'Simsarî',
  'Common questions': 'Pirsên hevpar',
  Confirm: 'tesdîq',
  'Confirm and send': 'Qeyd bikin û bişînin',
  'Confirm your phone number': 'Jimara têlefona xwe verast bikin',
  'Confirm your Email': 'Email xwe piştrast bikin',
  'Verify Email': 'E-nameyê piştrast bikin',
  Connection: 'Peywendî',
  'Contact us': 'paqij bûn',
  'Contents of works': 'Naveroka xebatan',
  'Current password': 'Şîfreya heyî',
  'Charge Wallet': 'Berûpelê ji nû ve şarj bikin',
  'File Upload': 'barkirina pelê',
  'Your Request has been Submitted and After Confirmation, Your Wallet Will be Charged!':
    'Daxwaza we hate tomar kirin û piştî pejirandinê, berîka we dê were xerc kirin!',
  'Return to App': 'Vegere bernameyê',
  'Load More': 'Zêdetir nîşan bide',

  // D
  'Date / Day of payment': 'Roja Tezmînatê ',
  Day: 'Roja',
  'View: Day': 'Dîtin: roz',
  'View: Week': 'Dîtin: heftane',
  'View: Month': 'Dîtin: Heyv',
  'Delete from cart': 'Jêbirina ji peyva kirînê',
  'Device name': 'navê amûrê',
  'Electronic Book': 'pirtûka elektronîk',
  'Audio Book': 'pirtûka dengî',
  Discount: 'Kêmkirinî',
  Dollars: 'Dolar',
  'The Latest': 'nûtirîn',
  'The Oldest': 'Ya herî kevn',
  'The Purchases': 'best firoşkarên',
  'The Populars': 'Favorites',
  Speaker: 'Wizer',
  Speakers: 'Wizeran',
  'Published Date': 'Dîroka weşanê',
  'Dollar wallet': 'Cûreyek Dolar',
  'Do you want to be informed about the latest books, offers and new versions of book reader software by receiving emails?':
    'Ma hûn dixwazin bi e-nameyê ji pirtûk, pêşniyar û guhertoyên herî dawî werin agahdar kirin؟',
  'Do you want registration, changes, user comments, etc. to be emailed to you?':
    'Ma hûn dixwazin qeydkirin, guhertin, şîroveyên bikarhêner û hwd ji we re bi e-nameyê bêne şandin؟',
  'Do you want to leave?': 'Ma hûn dixwazin ji malperê derkevin?',
  Category: 'Komkirin',
  Genre: 'genre',
  'Product Type': 'awa',
  Publishers: 'Weşanxaneyên',
  Collaborator: 'Nivîskar, wergêr û axaftvan',
  'Sort By': 'li gorî xwe rêzkirin',

  // E
  'E-Book': 'Dîjîtal Pirtûkên',
  'Wallet:': 'berîka:',
  Edit: 'Gûherrandinî',
  'Email address': 'Emailê',
  'Referral Code': 'Referral Code',
  'Your Referral Code': 'Koda Referral ya we',
  Username: 'Navê bikarhêner',
  'Empty cart': 'Selika kirînê vala ye',
  Everywhere: 'Herder',
  EXP: 'Roja derbasî',
  'Error Communicating with Server': 'Di danûstandina bi serverê re çewtî',
  'Error Communicating with Server, Please Try Again':
    'Di danûstandina bi serverê re çewtî, ji kerema xwe dîsa biceribîne',

  // F
  Female: 'Mê',
  'Final payment': 'Tezmînata dawî',
  'First name': 'nav',
  'Forgot password': 'Şîfreya xwe ji bîr dike',
  Free: 'Belaş',
  'Upload Cover (jpeg, jpg, png)': 'Bergê barkirin (jpeg, jpg, png)',
  'Upload Sample (epub, pdf)': 'Pelê nimûneyê barkirin (epub, pdf)',
  'Upload Sample (mp3)': 'Pelê nimûneyê barkirin (mp3)',
  'Upload Sample (Selected)': 'Pelê nimûneyê barkirin (hilbijartin)',
  'Upload Main File (epub, pdf)': 'Pelê orîjînal barkirin (epub, pdf)',
  'Upload Main File (mp3)': 'Pelê orîjînal barkirin (mp3)',
  'Upload Main File (Selected)': 'Pelê orîjînal barkirin (hilbijartin)',
  'Profile Updated Successfully!': 'Profîl an destkeftî hate guherandin!',

  // G
  Gadgets: 'Gadgets',
  'Gift card': 'karta diyariyê',
  Go: 'Berdewam bike',
  Guide: 'Birêvebir',

  // H
  Home: 'Rûpelê Sereke',

  // I
  Iban: 'شماره شبا',
  "I don't know": 'nizanim',
  'If you have a discount code, enter it in this section':
    'Ger kodek te ya dakêşanê hebe, wê li vir binivîse',
  'I have accepted the rules regarding the usage of Anobani.':
    'Ez Mercên Bikaranînê yên Anubani qebûl dikim.',
  'In case of publishing publications or requesting cooperation, send us your information so that it can be confirmed after reviewing and completing the registration process for you and your team.':
    'Heke hûn dixwazin bi Anubani re hevkariyê bikin, agahdariya xwe ji me re bişînin. Hûn û ekîba we dê piştî kontrolkirin û temamkirina pêvajoya qeydkirinê werin agahdar kirin..',
  'In case you want to increase your stock, transfer the intended amount to the account below, and send us the receipt. Your stock will increase within 24 hours.':
    'Heke hûn dixwazin balansa hesabê xwe zêde bikin, mîqdara xwestinê razînin jimara jêrîn û meqbûzê bişînin. Balansa we dê di 24 demjimêrên pêş de zêde bibe.',
  'Increase amount': 'Mîqdarê zêde bikin',
  'Increase inventory': 'Envanterê zêde bikin',
  'Increase stock': 'Balansa walletê zêde bikin',
  'Install Anobani application': 'Serlêdana Anubani saz bikin',
  'Internet payment': 'Tezmînata Înternetê',
  'Introduce me to a new book': 'Ji min re pirtûkek nû bide nasîn',
  'Invite code': 'Koda vexwendinê',
  Issue: 'Pirsegirêk',
  'Issue tracking': 'Koda Şopandinê',

  // J
  Journal: 'Rojname',

  // L
  Language: 'Ziman',
  Latest: 'ya herî nû',
  'Last name': 'paşnav',
  'Birth Date': 'Roja bûyînê',
  'Latest podcasts': 'Podcastên herî dawî',
  "Let's study": 'Em bixwînin',
  Literature: 'edebiyat',
  'Login / Signup': 'têketinê / Fêhrist',
  'Log in': 'têketinê',
  Register: 'Fêhrist',
  'Log out': 'derketin',

  // M
  Magazine: 'Kovar',
  Male: 'Mêr',
  Marketer: 'Marketer',
  'Marketeer profile': 'Profîla bazarê',
  'Marketeer register guide file': 'Pelê rêberê qeydkirinê Marketer',
  Marketing: 'Marketing',
  'Marketing login': 'Têkeve Beşa Marketing',
  'Membership fee': 'Heqê endametiyê',
  'Message with card': 'Peyam bi kartê',
  Month: 'Meh',
  'My account': 'Hesabê min',
  'My audio books': 'Pirtûka dengî min ',
  'My books': 'pirtûkên min',
  'My E-book': 'E-pirtûka min',
  'My e-books': 'E-pirtûka min',
  'My favorite books': 'Pirtûkên min ên bijare',
  'My journals': 'Kovarên min',
  'My library': 'pirtûkxaneya min',
  'My product': 'berhema min',
  'My publishers': 'Weşanxaneyên min',

  // N
  Name: 'Nav',
  'National ID': 'Nasnameya Neteweyî',
  'New journals': 'Kovarên Nû',
  'New Books': 'Pirtûkên herî dawî',
  'E-Books': 'E-Pirtûk',
  Magazines: 'Kovara',
  'New password': 'Şîfreya nû',
  No: 'Na',
  'Note: Each user can delete the active device up to three times during one year (Starting from the registration date).':
    'Têbînî: Her bikarhêner dikare di nav salekê de sê caran amûrê çalak jêbibe.',
  'Number of times left to remove the device in this year of membership: 4':
    'Hejmara carên ku ji bo rakirina cîhazê di sala endametiya heyî de mane: 4',

  // O
  Off: 'Discount',
  Oldest: 'Yê herî kevn',
  'Order number': 'Hejmara fermanê',
  'Other books by this author': 'Pirtûkên din ên vî nivîskarî',
  'Other books by this publisher': 'Pirtûkên din ên vê çapê',

  // P
  Cash: 'Pere',
  Password: 'Şîfre',
  'Password Confirmation': 'Şîfre Dubare',
  Payable: 'pêdivî',
  'Payment date': 'Dîroka dravdanê',
  'Payment time': 'Wextê dravdanê',
  'Payment using Anobani wallet': 'Derdana bi berîka Anubani',
  'Personal profile': 'Profîla Kesane',
  'Phone number': 'Hejmara mobîl',
  'Please enter the code sent via SMS in the field below.':
    'Ji kerema xwe koda SMS di qutiya têketina jêrîn de binivîse.',
  'Please enter the code sent via Email in the field below.':
    'Ji kerema xwe koda ku ji e-nameyê re hatî şandin di qutiya têketina jêrîn de binivîse.',
  'Please select the country code, and input your phone number.':
    'Ji kerema xwe koda welat hilbijêrin, û jimara xweya mobîl binivîse.',
  'Please upload the photos of your identity and the relevant publishing credentials.':
    'Ji kerema xwe wêneyên belgeyên nasnameya xwe û weşanên têkildar binivîsin..',
  'Please wait a moment': 'Ji kerema xwe demek bisekinin',
  Podcast: 'Podcast',
  'Postal code': 'Qutiya PO',
  Price: 'Biha',
  'Final Price': 'Bihayê Dawî',
  'Remove From Cart': 'Ji selikê rake',
  'Remove From Library': 'Ji Pirtûkxaneyê Rake',
  'Remove From Favorites': 'Ji Vebijêrkan Rake',
  'Read Online': 'Bi serhêl bixwînin',
  'Listen Online': 'Listen Online',
  'Your Request Submitted. You are now Redirecting to Payment Gateway!':
    'Daxwaza te hat qeyd kirin. Tu niha ber bi portalê dravdanê ve hatî veguhestin!',
  'Print length': 'Hejmara rûpelan',
  'Problem statement': 'Daxuyaniya pirsgirêkê',
  Profit: 'Qazbûn',
  'Sale Records': 'Sale Records',
  'Profit of the month': 'Qezenc mehekê',
  'Withdrawable Profit': 'Qazenca Vekêşan',
  'Profit of the year': 'Qezenc salek',
  'Public domain': 'qada giştî',
  'Publisher name': 'Navê weşanger',
  'Full Name': 'Navê û paşnav',
  'Publisher Phone': 'Hejmara telefona weşanger',
  Logo: 'Logo',
  'Registered Date': 'Dîroka qeydkirinê',
  Publisher: 'Weşanxane',
  'Publisher profile': 'Profîla Weşanger',
  'Publishers login': 'Têketina weşanxaneyan',
  'Publishers register file': 'Pelê qeydkirina weşanxaneyan',
  "Publisher's email address": 'Navnîşana e-nameya weşanger',
  "Publisher's code": 'Koda weşanger',
  "Publisher's details": 'Daxuyan / danasîna weşanan',
  "Publisher's name": 'Navê weşanger',
  Publishing: 'Weşanxane',
  Episode: 'Episode',
  'Select Color': 'Reng Hilbijêre',
  'Select Font Size': 'Mezinahiya Curenivîsê Hilbijêre',
  'List and Settings': 'Indeks û mîhengan',
  'See all Formats And Versions': 'Hemî format û guhertoyan bibînin',
  'Electronic Version': 'Guhertoya Elektronîkî',
  'Purchase records': 'Qeydên kirînê',
  'Purchased books': 'Pirtûkan kirîn',
  'Page or Resource Not Found': 'Rûpel an agahdariya xwestî nehat dîtin',
  'Read Book': 'Pirtûkek dixwînin',
  'Read Magazine': 'Kovarekê dixwîne',
  'Buy E-Book': 'E-pirtûkek bikirin',
  'Buy Audio Book': 'Pirtûkên dengî bikirin',
  'Buy Magazine': 'Kovarek bikirin',
  'Buy Podcast': 'Podcastan bikirin',
  'Remove from Cart': 'Ji selikê rakin',
  Demo: 'Mînak',
  'Other Podcasts of Collection': 'Podcastên Din ên Berhevkirinê',

  // R
  'Rate of study': 'Rêjeya xwendinê',
  'Receive email': 'E-nameyê bistînin',
  'Receiver email address': 'Navnîşana e-nameya wergirê diyariyê',
  'Receiver name': 'Navê wergir',
  'Register reviews for the product': 'Ji bo hilberê nirxandinan tomar bikin',
  'Registered users': 'Bikarhênerên qeydkirî',
  'Register your purchase now': 'Niha kirîna xwe tomar bikin',
  'Release date': 'Dîroka berdanê',
  'Not Registered': 'Ne Qeydkirî',
  'Remaining time': 'Dema mayî',
  'Repeat new password': 'Şîfreya nû dubare bike',
  'Reply to': 'Bersiva',
  'Report card': 'Karta raporê',
  'Request book': 'Pirtûka Daxwazî',
  'Request the book you want so we can add it to the store if possible.':
    'Pirtûka ku hûn dixwazin bixwazin, da ku heke gengaz be em dikarin wê li firotgehê zêde bikin.',
  Resend: 'Ji nû ve bişîne',
  'Review text': 'Nivîs binirxîne',
  'Book Name': 'Navê Pirtûkê',
  'Author Name': 'Navê Nivîskar',
  'Publisher Name': 'Navê Weşanger',
  'Description / Publisher Introduction': 'Pêşgotin / Pêşgotina Weşanan',
  Submit: 'Qeyd kirin',
  'Collection Name': 'Navê Berhevkirinê',
  'Upload Image': 'Wêneyê barkirin',
  'Phone with Country Code': 'Jimara têlefonê bi koda welat',
  'Postal Box': 'Postal Box',
  'Postal Code': 'Koda Poste',
  'Publisher Email': 'E-nameya weşanger',
  'Publisher Code': 'Koda Weşanxaneyê',
  'Central Address': 'Navnîşana Ofîsa Navendî',
  'Your Request has been Submitted': 'Daxwaza we hat şandin',
  'Your Registration as Publisher has been Successfully Completer! You can now Login.':
    'Qeydkirina we wekî weşanger serketî bû! Naha hûn dikarin têkevin hesabê xwe.',
  'Your Registration as Marketer has been Successfully Completer! You can now Login.':
    'Qeydkirina we wekî bazarker serketî ye! Naha hûn dikarin têkevin hesabê xwe.',
  'Your Username and Password has been sent to your Email.':
    'Navê bikarhêner û şîfre ji e-nameyê re hatine şandin.',
  // S
  'Sales records': 'Qeydên firotanê',
  'Sample book': 'Nimûne Versiyon',
  Search: 'Lêgerîn',
  Season: 'Demsal',
  Section: 'beş',
  'Section title': 'Sernavê beşê',
  'See activity': 'Çalakiyê Binêre',
  'See all': 'Hemû bibînin',
  Delete: 'Jêbirin',
  'Select wallet': 'Baletê hilbijêre',
  Send: 'nermijîn',
  Settings: 'Settings',
  'Show less': 'Kêm nîşan bide',
  'Show more': 'Zêdetir bibîne',
  'Site profit percentage': 'Ji sedî qezenca malperê',
  'Sign in using e-mail': 'Bi e-nameyê têkevin',
  'Forget Password': 'Raxistina şîfreyê',
  'Sign in using e-mail address': 'Bi e-nameyê têkevin',
  'Sign in using phone number': 'Têkeve bi karanîna hejmara xweya mobîl',
  'Sign in using Google account': 'Bi karanîna hesabê xweya Google-ê têkevin',
  Size: 'mezin',
  Published: 'Weşandin',
  'Sold product': 'Berhema firotî',
  'Sort by': 'ferman ji aliyê',
  Currency: 'Dirav',
  Stock: 'Kredî',
  'Stock increase rate': 'Rêjeya zêdebûna stokê',
  'Study for everyone': 'Ji bo her kesî bixwînin',
  'Study time report': 'Rapora dema xwendinê',
  'Suggestion or criticism': 'Pêşniyar an rexne',
  Support: 'Piştgirî',
  'Recommended Books': 'Pirtûkên Pêşniyar',

  // T
  Tax: 'Bacê',
  'Terms of use': 'Şertên bikaranînê',
  'The best gift for the best': 'Diyariya çêtirîn ji bo çêtirîn',
  'The Reader app is usable across three different devices. If you want to use a new device, first remove one from the list below.':
    'Nermalava Reader dikare li ser sê amûrên cûda were bikar anîn. Heke hûn dixwazin amûrek nû lê zêde bikin, pêşî yek ji cîhazan ji navnîşa jêrîn derxînin.',
  'This week': 'Vê hefte',
  'This month': 'Ev meh',
  Title: 'Sernav',
  'Title / Name on card': 'Nav',
  Today: 'Îro',
  Toman: 'Toman',
  'Toman wallet': 'Baletê Tûmen',
  Total: 'Tevahî',
  'Total profit': 'Qaseya giştî',
  Translator: 'Wergêr',
  Translators: 'Werger',

  // U
  'Upload file': 'Pelê barkirin',
  'Upload file (PNG, JPG, PDF, ePUB, MP3)':
    'Pelê barkirin(PNG, JPG, PDF, ePUB, MP3)',
  'Upload file (PNG, JPG)': 'Pelê barkirin (PNG, JPG)',
  'Upload photo': 'Wêneyê barkirin',
  'Uploaded file details': 'Hûrgiliyên pelê yên barkirî',
  User: 'Bikarhêner',

  // V
  View: 'Çavdêrî',
  'View all': 'Hemû Binêre',
  'View more': 'Zêdetir Binêre',
  Volume: 'Lihêv',

  // Y
  Yes: 'Erê',
  'You can specify the time of sending the gift card email.':
    'Hûn dikarin diyar bikin kengê e-nameya qerta diyariyê dê were şandin',
  'Your cost': 'Berdêla te',
  'Discount Percent': 'Ji sedî Discount',
  'Start Date': 'Dîroka Destpêkê',
  'End Date': 'Dîroka Dawî',
  'Your credit': 'Krediya te',
  'Your Requests Have Exceeded the Limit. Please Wait a few Seconds and Try Again':
    'Hejmara daxwazên te ji sînor derbas bû. Ji kerema xwe çend saniyan bisekine û dîsa biceribîne',

  // W
  Wallet: 'tûrikê diravan',
  Week: 'hefte',
  'We never send files': 'Em bi tu awayî dosyayan naşînin',
  'What else did the buyers of this book purchase?':
    'Kirînerên vê pirtûkê wekî din çi bikirin?',
  'Work with us': 'bi me re hevkariyê bikin',
  Welcome: 'bi xêr hatî',

  // Temp
  'Lorem ipsum xs':
    'Lorem Ipsum metnek xapînok e ku bi sadebûnek ne têgihîştî ji hêla pîşesaziya çapkirinê ve hatî hilberandin',
  'Lorem ipsum s':
    'Lorem Epsom metnek xapînok e ku ji pîşesaziya çapkirinê bi sadebûnek nayê famkirin hatî hilberandin û ji hêla sêwiranerên grafîkî ve tê bikar anîn.',
  'Lorem ipsum m':
    'Lorme Epsom metnek sexte ye ku ji pîşesaziya çapkirinê û bi karanîna sêwiranerên grafîkî ve sadebûnek nayê fêm kirin e. Çapxane û nivîsar, lê her weha rojname û kovar di stûn û rêzan de jî li gorî hewcedariyê û ji bo şert û mercên heyî yên teknolojiyê û sepanên cihêreng bi mebestê başkirina amûrên pratîkî.',
  'Lorem ipsum l':
    'Lorme Epsom metnek sexte ye ku ji pîşesaziya çapkirinê û bi karanîna sêwiranerên grafîkî ve sadebûnek nayê fêm kirin e. Çapxane û nivîsar, lê her weha rojname û kovar di stûn û rêzan de jî li gorî hewcedariyê û ji bo şert û mercên heyî yên teknolojiyê û sepanên cihêreng bi mebestê ji bo pêşdebirina amûrên pratîkî, di ji sedî şêst û sê paşeroj, niha û paşerojê de gelek pirtûk hene, ku ji bo peydakirina zanyariyên zêdetir ji sêwiranerên kompîturê re, nemaze sêwiranerên afirîner û çanda pêşeng, pêdivî bi gelek zanîna civakê û pisporan heye. bi zimanê farisî..',
  'Lorem ipsum xxl':
    'Lorme Epsom metnek sexte ye ku ji pîşesaziya çapkirinê û bi karanîna sêwiranerên grafîkî ve sadebûnek nayê fêm kirin e. Çapxane û nivîsar, lê her weha rojname û kovar di stûn û rêzan de jî li gorî hewcedariyê û ji bo şert û mercên heyî yên teknolojiyê û sepanên cihêreng bi mebestê ji bo baştirkirina amûrên pratîkî, ji sedî şêst û sê yên paşeroj, niha û paşerojê de gelek pirtûk hene, ji bo afirandina zanyariyên zêdetir ji sêwiranerên kompîturê re, nemaze sêwiranerên afirîner û çanda pêşeng, pêdivî bi zanîna civakê û pisporan heye. di zimanê farisî de, mirov dikare hêvî bike ku hemû zehmetî di peydakirina çareserî û şert û mercên zehmet ên nivîsandinê de bi dawî bibin û dema pêwîst, tevî tîpkirina destkeftên sereke û bersivdana pirsên berdewam ên cîhana sêwiranê ya heyî, be. bingehîn tê bikaranîn..',
  'The Secret Garden': 'Baxçeyê veşartî',
  'January 14th, 1990': '1400/11/05',
  Time: '16:24',
  "Left section's description":
    'Bi kirîna xweya yekem a ji Anubani re, hûn dikarin% 60 dakêşiyek taybetî bistînin',
  'Francis Hudson Brant': 'فرانسیس هادسون برنت',
  Value: 'mîqdara ji',
  Address:
    'آدرس: تهران، خیابان گاندی جنوبی، کوچه گاندی اول، پلاک ۲۲، طبقه اول - ۰۹۱۲۹۴۸۷۴۶۲',
  'We are with you for telephone support every day of the week (9 to 6 P.M) with the number: 02831286':
    'Ji bo piştgiriya telefonê, em her rojên hefteyê (ji saet 09:00 heta 18:00) li 09128472648 bi we re ne.',
  'For telephone support, in all day week (from ':
    'Ji bo piştgiriya têlefonê, di hemî rojên hefteyê de (ji ',
  Call: 'bi no',
  'We are with you': 'Em bi we re ne.',
  'Company Address:': 'Navnîşan:',
  'Select Problem': 'hilbijartina pirsgirêkê',
  'Error in app usage or payment': 'Çewtiya bikaranîna nermalavê an dravdanê',
  'Issue details':
    'Heke hûn piştî dravdanê û vegerandina pirtûkê nikaribin pirtûkê di beşa Pirtûkên Min de bibînin, biçin beşa Pirtûkxaneyê û li îkona hevdemkirinê ya li serê ekranê bixin. Piştî vê yekê, pirtûka navborî dê li pirtûkxaneya we were zêde kirin..',
  'Payment issue': 'Zehmetiya dayîna',
  'Buying issue': 'Zehmetiya kirînê',
  'Book download issue': 'Pirsgirêka daxistina pirtûkê',
  'Book not added issue': 'Pirtûka kirî li pirtûkxaneyê nehat zêdekirin',
  'Stock not added issue': 'Krediya kirî li berîka nayê zêdekirin',
  'Invite code usage issue': 'pêşniyar an rexne',
  'Others issue': 'Bûyerên din',
  'Home page': 'Rûpela Malê',
  'Internet payment tip': '(Derdana bi hemû kartên endamên Şatabê)',
  'Jack Toren': 'Jack Thorne',
  '1 week ago': 'hefteyek berê',
  'Ofoqe Taban': 'Horizonê dibiriqîne',
  'Take a large library with you': 'Pirtûkxaneyek mezin bi xwe re bînin',
  'Everywhere, we always': 'Li her derê, em her dem',
  'Introduce the best harrow to you': 'Em ya herî baş bi we didin nasîn',
  'Travel with us to another world': 'Bi me re bigerin cîhanek din',
  'The Eight Mass Market Paperback': 'Şumiz heşt bazara girseyî',
  'Open Access journal': 'Rojnameya Gihîştina Vekirî',
  'In Your Dreams with Chris Gethard, presented by Casper':
    'در رویاهای شما با کریس گتهارد، ارائه شده توسط کاسپر',
  'By Catherine Neville (Author), speaker by Katherine Neville':
    'توسط کاترین نویل (نویسنده)، صداگذاری توسط کاترین نویل',
  'Number 10': 'شماره ۱۰',
  'With Chris Gethard and Gary Richardson': 'با کریس گتهارد و گری ریچاردسون',
  'See all formats and editions': 'Hemû format û guhertoyan bibînin',
  'Electronic version': 'Guhertoya elektronîkî',
  'E-book': 'pirtûka elektronîk',
  'September 20th, 2000': '۱ مهر ۱۳۷۹',
  'Ballantine Books': 'افق سرخ',
  Persian: 'فارسی',
  '624 pages': '۶۲۴ صفحه',
  'Review of the audio book No Friend Except for the Mountain by Behrouz Buchani':
    'نقد و بررسی کتاب صوتی هیچ دوستی جز کوه، اثر بهروز بوچانی',
  Review: 'Axaftin',
  'About Audio No friends except Mountain':
    'درباره کتاب صوتی هیچ دوستی به جز کوه',
  'Comments about audio book': 'Şîroveyên li ser pirtûka dengî',
  'In Your Dreams': 'Roja deynan',
  'Erfan Kakaei': 'عرفان کاکائی',
  '1hr, 14min, 42s': '۱ ساعت و ۱۴ دقیقه و ۴۲ ثانیه',
  'October 16th, 2020': '۱۳ مهر ۱۴۰۰',
  '0 seconds': '۰ ثانیه',
  'Authentication Failed! Please Login Again':
    'Nasname têk çû! Ji kerema xwe dîsa têkevinê',
  'Best Selling Books': 'Pirtûkên herî zêde têne firotin',
  Journals: 'kovaran',
  Favorite: 'Herî populer ya hefteyê',
  Status: 'Rewş',
  Paid: 'Pere kirin',
  'Not Paid': 'bêpere',
  Failed: 'Bi ser neket',
  Payment: 'Li benda Tezmînatê',
  Checking: 'Kontrolkirin',
  resendCode: 'Kodê ji nû ve bişînin',
  error: 'Jêbirin bi ser neket',
  notExist: 'nav da nîne',
  downloadedBefor: 'berê hatiye daxistin',
  defaultTheme: 'Mijara desitpêk',
  bookSettings: 'rêxistina pertûkê',
  fontSize: 'qebareya nivîsandin',
  lineSpace: 'navbera rêzê',
  fa: 'Farsî',
  en: 'Îngilîzî',
  ar: 'Kurdê Soranî',
  ku: 'Kurdê Kurmancî',
  dinar: 'Dinar',
  author: 'nivîskar',
  translator: 'Wergêr',
  speaker: 'bêjer',
  selectDate: 'dem hilbijêre',
  selectDateTime: 'Dem û demjimêr hilbijêrin',
  week: 'heftê',
  page: 'Rûpel',
  favoriteVoice: 'Pertûka Dengî ya herî navdar',
  favoriteElectronic: 'Pertûka elektronîkî ya herî navdar',

  'Login as Marketer': 'Têkeve Beşa Kirrûbirrê',
  'No Contents Found': 'Ti navnîşek nehat dîtin',
  'No Highlight Found': 'Nîşanek balkêş nehat dîtin',
  'No Notes Found': 'Têbînî nehat dîtin',
  'No Items Found': 'Tiştek nehat dîtin',
  'Enter at least 3 Characters': 'Bi kêmanî 3 Karakterên binivîse',
  'Select Font': 'Hilbijartina tîpan',
  'Add Highlight': 'Zehf zêde bikin',
  'Add Note': 'Nîşeyan zêde bikin',
  Share: 'par',
  'Text Must be Less Than 600 Character!': 'Divê nivîs ji 600 tîpan kêmtir be!',
  'Text Copied!': 'Nivîs hat kopîkirin!',

  'Product Created Successfully. After the Reviews of the Admins It will be Published':
    'Berhem bi serkeftî hate afirandin. Ew ê piştî nirxandina rêveberan were weşandin',
  'Product Updated Successfully. After the Reviews of the Admins It will be Published':
    'Berhem bi serkeftî hate guherandin. Ew ê piştî nirxandina rêveberan were weşandin',

  'Gift Card': 'karta diyariyê',
  'For Using Website, Please Accept the Rules':
    'Ji bo bikaranîna malperê, ji kerema xwe qaîdeyên qebûl bikin!',
  'For This Purchase, First you Need To Login.':
    'Ji bo vê kirînê, divê hûn pêşî têkevin.',
  'Shopping Cart': 'Ereboka destan',
  'Celebrity voices': 'Dengbêjên navdar',
  'Celebrity voices detail':
    'Dengê wan kesan bibihîzin ku di dîroka huner û edebiyata kurdî de beşeke girîng in.',
  'Farhad Pirbal': 'Ferhad Pîrbal',
  'Yunus Rezaei': 'Yunus Rezaei',
  'Nasser Razazi': 'Nasser Razazi',
  'Shirko Bey': 'Şîrko Bey',
  'Hassan Darzi': 'Hesen Derzî',
  'Teacher Aso': 'Mamoste Aso',
  'Manifestation of creativity': 'Nîşandana afirîneriyê',
  'Hello everyone': 'Hemû deng',
  Celebrities: 'Celebrities',
  'Seven days a week (9am to 6pm)': 'Heft rojên hefteyê (9ê sibê heta 18:00)',
  'footer-description':
    'Hemû mafên vê platformê di destê Enstîtuya Tak de ne û mafên rewşenbîrî û madî yên berheman ji weşangerên wê re parastî ne.',
  'Discount Detail':
    'Bi kirîna xweya yekem a ji Anubani re, ji sedî 60% dakêşiyek taybetî bistînin.',
  'comment title': 'Nêrîna we li ser pênc astên serokatiyê çi ye?',
  'Comment Subtitle':
    'Wekî xwendevanek vê pirtûkê, hûn dikarin kesên din agahdar bikin ka ew çawa dixebite.',
  'Comment Note': 'Dengdana xwe li vir jî bistîne.',
  'Comment List Title': 'Şîroveyên li ser pênc astên serokatiyê',
  'Comment List SubTitle': 'Li vir 21 kesên ku li ser vê berhemê axivîne hene.',
  'Comment PlaceHolder': 'Li vir şîroveya xwe binivîsin.',
  'List of sections': 'Lîsteya beşan',
  'Number of Pages': 'Hejmara rûpelan',
  'See how many pages': 'Binêrin çend rûpel',
  'Listening to examples': 'Guhdarîkirina mînakan',
  Duration: 'demajok',
  'Buy and Read': 'Kirîn û xwendin',
};

export default kukTranslations;
