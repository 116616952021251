import React, { useContext, useState } from 'react';
import './css/CommentsList.scss';
import { useTranslation } from 'react-i18next';
// Common components
// import NewsAndComment from '../../../common/others/NewsAndComment';
// Modals
// import AddCommentModal from '../../../modals/AddComment';
// Utils
// import comments from '../../../../utils/fake-data/comments';
// import ProductInfoContext from '../../../../utils/contexts/Product';
import icons from '../../../../utils/icons';
import moment from 'moment';

const CommentsList = ({ comments }) => {
  const { t } = useTranslation();

  // const { product } = useContext(ProductInfoContext);

  return (
    <div className={'commentsList'}>
      <div className='cardA  mt-3'>
        <p className='comment-title'>{t('Comment List Title')}</p>
        <p className='comment-subtitle'>{t('Comment List SubTitle')}</p>

        {comments?.map((comment, i) => (
          <div className='d-flex commentsListCard' key={i}>
            {/* <div className='comment-img'>
              <div className='comment'>
                <img src={`/${comment.image}`} alt='user' />
              </div>
            </div> */}
            <div className='comment-message'>
              <div className='d-flex justify-content-between'>
                <div>
                  <h5 className='mt-5 comment-name'>{comment.userName}</h5>
                  <p className='date'>
                    {moment(comment.created_at).format('YYYY/MM/DD')}
                  </p>
                </div>
                <div className='d-flex'>
                  <div className='mt-5 p-2'>{icons.ratted}</div>
                  <p className='mt-5 px-2 rate'>{comment.rating}</p>
                </div>
              </div>
              <p className='my-3 comment-detail'>{comment.comment}</p>

              {/* <div className='comment-like-comments'>
                <div className='d-flex'>
                  <p>{comment.replay}</p>
                  <div className='mx-1 '>{icons.comment}</div>
                </div>
                <div className='d-flex'>
                  <p>{comment.like}</p>
                  <div className='mx-1 '>{icons.heart}</div>
                </div>
              </div> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CommentsList;
