import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import './Product.scss';
// Sub components
import ProductInfo from './sub-components/product-info/ProductInfo';
import Review from './sub-components/others/Review';
import Comments from './sub-components/others/Comments';
import Suggestions from './sub-components/others/Suggestions';
// Utils
import ProductContext from '../../utils/contexts/Product';
import axios from '../../utils/http';
import API from '../../utils/api';
import { getDate, getRouter } from '../../utils/helpers';
import { useSelector } from 'react-redux';
import CircleLoading from '../common/others/CircleLoading';
import { useTranslation } from 'react-i18next';
import loading from '../modals/Loading';
import PurchaseAndPreview from './sub-components/product-info/sub-components/PurchaseAndPreview';
import CommentsList from './sub-components/others/CommentsList';
import icons from '../../utils/icons';
import AudioPlayer1 from './sub-components/product-info/sub-components/AudioPlayer1';
import { useNavigate } from 'react-router-dom';

import Banners from '../../utils/fake-data/banner';
import SiteSuggestionsSlider from '../home-related/home/site-siggestions-slider/SiteSuggestionsSlider';
import Price from './sub-components/others/Price';
const Product = () => {
  const { t } = useTranslation();
  const { slug } = useParams();
  const [publisher, setPublisher] = useState();
  const [otherProducts, setOtherProducts] = useState([]);
  const [collectionProducts, setCollectionProducts] = useState([]);
  const [publisherProducts, setPublisherProducts] = useState([]);
  const [product, setProduct] = useState();
  const [comments, setComments] = useState([]);
  const [bookType, setBookType] = useState('');
  // const [commentCurrentPage, setCommentCurrentPage] = useState(1);
  // const [hasMoreComment, setHasMoreComment] = useState(false);
  // const [moreLoading, setMoreLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [prices, setPrices] = useState([]);
  const [hasAudioBook, setHasAudioBook] = useState(false);

  const [openAudioBook, setOpenAudioBook] = useState(false);
  const [audioUrl, setAudioUrl] = useState('');
  const [isActive, setIsActive] = useState([]);
  // const [isActive, setIsNum] = useState(false);
  const navigate = useNavigate();
  const client = useSelector((state) => state.client);

  const [homeData, setHomeData] = useState({
    magazine_banners: [],
  });

  const getHomeData = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(API.public.home);

      if (data.success) {
        setHomeData(data.data);
      }
    } catch (e) {}
    setLoading(false);
  };

  useEffect(() => {
    getHomeData();
  }, []);
  // const [isBtnClick, setIsBtnClick] = useState(fa);
  const authors = product?.authors.map((author) => author?.name)?.join(', ');
  // console.log(product);
  useEffect(() => {
    getProduct();
    // getComments();
  }, [slug]);

  const getProduct = async (lang = null) => {
    setLoading(true);
    try {
      const params = {};
      if (lang) params.lang = lang;
      const { data } = await axios.get(
        getRouter(API.products.product, { slug }),
        {
          params,
        }
      );

      if (data.success) {
        setPublisher(data.data.publisher);
        setProduct(data.data.product);
        setPrices(
          data.data.product?.prices.map((price) => ({
            key: price?.id,
            value: `${price?.price} ${t(price.currency_name)}`,
          })) || []
        );
        setOtherProducts(data.data.most_purchased_products);
        setCollectionProducts(data.data.collection_products);
        setPublisherProducts(data.data.publisher_products);
        setComments(data.data.product_comments);
        // setMostPurchasedProducts(data.data.most_purchased_products);
        // setNewProducts(data.data.new_products);
        const prod = data.data.product;
        if (prod?.type === 'electronic_book' && prod?.has_epub) {
          setBookType('electronic_book');
        } else if (
          (prod?.type === 'audio_book' ||
            prod?.secondary_type === 'audio_book') &&
          prod?.has_audio
        ) {
          setBookType('audio_book');
        } else {
          setBookType(prod?.type);
        }
        setHasAudioBook(prod?.secondary_type === 'audio_book');
      }
    } catch (e) {}

    setLoading(false);
  };

  // const getComments = async () => {
  //   if (moreLoading) setMoreLoading(true);

  //   setMoreLoading(true);
  //   console.log(product, 'productId');
  //   try {
  //     const { data } = await axios.get(
  //       getRouter(API.products.comments, { slug }),
  //       {
  //         params: {
  //           page: hasMoreComment ? commentCurrentPage + 1 : commentCurrentPage,
  //         },
  //       }
  //     );

  //     if (data.success) {
  //       setComments([...comments, ...data.data.data]);
  //       setCommentCurrentPage(data.data.current_page);
  //       setHasMoreComment(!!data.data.next_page_url);
  //     }
  //   } catch (e) {}
  //   setMoreLoading(false);
  // };

  const handleChangeLanguage = (lang) => {
    getProduct(lang);
  };

  function convertDurationToTime(durationSeconds) {
    const hours = Math.floor(durationSeconds / 3600);
    const minutes = Math.floor((durationSeconds % 3600) / 60);
    const seconds = Math.floor(durationSeconds % 60);

    const formattedTime = `${String(hours).padStart(2, '0')}:${String(
      minutes
    ).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

    return formattedTime;
  }

  const audioHandler = (url) => {
    if (!client) {
      toast(t('For This Purchase, First you Need To Login'), {
        type: 'error',
      });
      return;
    }
    setOpenAudioBook(true);

    setAudioUrl(url);
  };

  const closeHandler = (data) => {
    setOpenAudioBook(data);
  };

  const demoHandler = (data) => {
    setOpenAudioBook(data);

    if (product?.type === 'audio_book') {
      setAudioUrl(product?.demo.mp3.url);
    }
    if (product?.type === 'electronic_book') {
      goToReaderPage('demo');
    }
  };

  const goToReaderPage = (type) => {
    console.log('product', product);
    console.log('type', type);
    return navigate('/reader', {
      state: {
        title: product?.title,
        slug: product?.slug,
        locale: product?.current_language,
        productType: product?.type,
        purchaseType: type,
        url:
          type === 'demo'
            ? product?.demo?.epub?.url || product?.demo?.pdf?.url
            : product?.main?.epub?.url || product?.main?.pdf?.url,
        lastPage: product?.last_page,
      },
    });
  };

  const handleExpandRow = (personId) => {
    if (!client) {
      return;
    }
    let newExpandedRows = [...isActive];
    const isRowExpanded = newExpandedRows.includes(personId);
    if (isRowExpanded) {
      // If already expanded, collapse the row
      const index = newExpandedRows.indexOf(personId);
      newExpandedRows.splice(index, 1);
    } else {
      // If not expanded, expand the row
      newExpandedRows = [personId];
    }
    setIsActive(newExpandedRows);
  };

  const audioUrlHandler = (audioUrl) => {
    setAudioUrl(audioUrl);
  };
  const openAudioBookHandler = (openAudioBook) => {
    setOpenAudioBook(openAudioBook);
  };

  if (loading)
    return (
      <div className={'product-loading-container'}>
        <CircleLoading width={250} height={250} color={'#1c3c53'} />
      </div>
    );

  return (
    <div>
      <ProductContext.Provider
        value={{
          product,
          publisher,
          prices,
          bookType,
          toggleBookType: setBookType,
          hasAudioBook,
          onLanguageChange: handleChangeLanguage,
        }}
      >
        <div className='row mx-0'>
          <div className='col-12  col-lg-9'>
            <ProductInfo product={product} demoDataBtn={demoHandler} />
            <Price
              product={product}
              bookType={bookType}
              openAudioBook={openAudioBookHandler}
              audioUrl={audioUrlHandler}
            />
            <Review title={product?.title} reviews={product?.review} />
            <Comments
            // comments={comments}
            // onLoadMore={() => getComments()}
            />
            {/* {hasMoreComment && (
              <div className='load-more-container'>
                <button onClick={() => getComments()}>
                  {moreLoading ? <CircleLoading /> : t('Load More')}
                </button>
              </div>
            )} */}
            <CommentsList
              comments={comments}
              // onLoadMore={() => getComments()}
            />
          </div>
          <div className='col-12  col-lg-3 fixed-col '>
            <div className='cardB mb-3 d-none d-lg-block'>
              <div className='cardB-header d-flex align-items-center'>
                {product?.type === 'electronic_book' && (
                  <>
                    {icons.eBook}
                    <p>{t('E-book')}</p>
                  </>
                )}
                {product?.type === 'audio_book' && (
                  <p>
                    {icons.headPhone} &nbsp;
                    {t('Audio book')}
                  </p>
                )}
                {product?.type === 'podcast' && (
                  <p>
                    {icons.podcast} &nbsp;
                    {t('Podcast')}
                  </p>
                )}
              </div>

              <div className='cardB-body'>
                <div className='d-flex justify-content-between'>
                  <span>
                    {t('Size')} :
                    {bookType === 'electronic_book' ||
                    bookType === 'magazine' ? (
                      <strong>{product?.epub_size}</strong>
                    ) : (
                      <strong> {product?.mp3_size}</strong>
                    )}
                  </span>
                  <span>
                    {t('Published')}:
                    <strong> {getDate(product?.created_at)}</strong>
                  </span>
                </div>

                <div className='d-flex justify-content-between mb-4'>
                  <span>
                    {t('Language')}:<strong>{product?.language_title}</strong>
                  </span>

                  {(product?.type === 'audio_book' ||
                    product?.type === 'podcast') && (
                    <span>
                      {t('Duration')}:
                      <strong>
                        {convertDurationToTime(product?.mp3_duration)}
                      </strong>
                    </span>
                  )}
                  {product?.type === 'electronic_book' && (
                    <span>
                      {t('Number of Pages')}:<strong>{product?.pages}</strong>
                    </span>
                  )}
                </div>

                {product?.purchase_type === 'paid' ? (
                  <div className='d-flex justify-content-between'>
                    <span>
                      <strong style={{ fontSize: '20px' }}>
                        {product.prices[2].price}
                      </strong>
                      دولار
                    </span>
                    <span>
                      <strong style={{ fontSize: '20px' }}>
                        {product.prices[1].price}
                      </strong>
                      دینار
                    </span>
                    <span>
                      <strong style={{ fontSize: '20px' }}>
                        {product.prices[0].price}
                      </strong>
                      تمەن
                    </span>
                  </div>
                ) : (
                  <div>
                    <span>
                      <strong style={{ fontSize: '20px' }}>{t('Free')}</strong>
                    </span>
                  </div>
                )}
                <PurchaseAndPreview product={product} />
              </div>
            </div>

            <div className='cardB d-none d-lg-block'>
              <div className='cardB-body'>
                <div className='row'>
                  <div className='col-md-3'>
                    <img src='/60.png' alt='60' />
                  </div>
                  <div className='col-md-8'>
                    <p className=' justify-content-end'>
                      {t('Discount Detail')}
                    </p>
                    <div className='d-flex justify-content-between'>
                      <span>کۆدی داشکاندن: </span>
                      <span>
                        <strong>ANFB60</strong>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {(product?.type === 'audio_book' ||
              product?.type === 'podcast') && (
              <div className='cardB mt-3 d-none d-lg-block'>
                {product?.type === 'audio_book' && (
                  <div className='cardB-audio-header '>
                    <p>
                      {icons.list} {t('List of sections')}
                    </p>
                  </div>
                )}
                <div className='cardB-body'>
                  <div>
                    {(product?.is_purchased?.audio_book &&
                      product?.purchase_type === 'paid') ||
                    product?.purchase_type === 'free' ? (
                      <>
                        {product?.main?.mp3?.map((el, i) => {
                          const isExpandedPurchaseCredit = isActive.includes(
                            el?.url
                          );
                          return (
                            <div
                              className='d-flex justify-content-between py-2'
                              key={i}
                            >
                              <div>
                                <div key={el?.url} className='list-section'>
                                  <span
                                    onClick={(e) => {
                                      audioHandler(el?.url);
                                      handleExpandRow(el?.url);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {isExpandedPurchaseCredit
                                      ? icons.pause
                                      : icons.smallPlay}
                                  </span>
                                  &nbsp;
                                  <span
                                    onClick={(e) => {
                                      audioHandler(el?.url);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {el?.original_file_name}
                                  </span>
                                </div>
                              </div>
                              <div>{convertDurationToTime(el?.duration)}</div>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {openAudioBook && (
          <AudioPlayer1
            url={audioUrl}
            cover={product?.cover}
            audioBook={closeHandler}
            title={product?.title}
            authors={authors}
          />
        )}
        <Suggestions
          otherProducts={otherProducts}
          collectionProducts={collectionProducts}
          publisherProducts={publisherProducts}
        />
        {Banners?.length ? (
          <SiteSuggestionsSlider banners={homeData.magazine_banners} />
        ) : null}
      </ProductContext.Provider>
    </div>
  );
};

export default Product;
