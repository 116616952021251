import React, {useEffect, useState} from "react";
import "./StudyTimeReport.scss";
import Chart from "react-apexcharts";
// Sub components
import Header from "./sub-components/Header";
import PeriodSelector from "./sub-components/PeriodSelector";
import StudyIndicator from "./sub-components/StudyIndicator";
import API from "../../../../utils/api";
import axios from "../../../../utils/http";
import {useTranslation} from "react-i18next";

const StudyTimeReport = () => {
    const {t} = useTranslation();
    const [options, setOptions] = useState({
        chart: {
            id: "apexchart-example",
        }, plotOptions: {
            bar: {
                borderRadius: 15, columnWidth: 10,
            },
        },
    });
    const [dailySeries, setDailySeries] = useState([{
        name: "", data: [],
    },]);
    const [weekSeries, setWeekSeries] = useState([{
        name: "", data: [],
    },]);
    const [monthSeries, setMonthSeries] = useState([{
        name: "", data: [],
    },]);
    const [currentSeries, setCurrentSeries] = useState(dailySeries);
    const [period, setPeriod] = useState({
        key: 'DAY', dropdownTitle: t("View: Day"), title: t("Day")
    });
    const [reportTime, setReportTime] = useState({});
    const [studyReport, setStudyReport] = useState({});
    const [today, setToday] = useState("");
    const [lastBook, setLastBook] = useState(null);

    const periods = [{
        key: 'DAY', dropdownTitle: t("View: Day"), title: t("Day")
    }, {
        key: 'WEEK', dropdownTitle: t("View: Week"), title: t("Week")
    }, {
        key: 'MONTH', dropdownTitle: t("View: Month"), title: t("Month")
    }]

    useEffect(() => {
        getReports();
    }, [])

    useEffect(() => {
        switch (period.key) {
            case "DAY":
                setCurrentSeries(dailySeries);
                break;
            case "WEEK":
                setCurrentSeries(weekSeries);
                break;
            case "MONTH":
                setCurrentSeries(monthSeries);
                break;
            default:
                setCurrentSeries(dailySeries);
        }
    }, [period])

    const getReports = async () => {
        try {
            const {data} = await axios.get(API.dashboard.readingReports);
            if (!data.success)
                return;

            setReportTime({
                dayTime: data.data.today_time_format,
                weekTime: data.data.week_time_format,
                monthTime: data.data.month_time_format
            });

            setStudyReport({
                book: data.data.e_book_time_format,
                audio: data.data.a_book_time_format
            });

            setToday(data.data.today);
            setLastBook(data.data.last_book)

            setDailySeries([{
                name: t('Day'), data: data.data.daily_report.map(daily => ({
                    x: daily.title,
                    y: daily.value,
                    fillColor: "#67b7dc"
                }))
            }]);

            setWeekSeries([{
                name: t('Week'), data: data.data.weekly_report.map(daily => ({
                    x: daily.title,
                    y: daily.value,
                    fillColor: "#67b7dc"
                }))
            }]);

            setMonthSeries([{
                name: t('Month'), data: data.data.monthly_report.map(daily => ({
                    x: daily.title,
                    y: daily.value,
                    fillColor: "#67b7dc"
                }))
            }]);

            setCurrentSeries([{
                name: t('Day'), data: data.data.daily_report.map(daily => ({
                    x: daily.title,
                    y: daily.value,
                    fillColor: "#67b7dc"
                }))
            }]);

        } catch (e) {

        }
    }

    return (<div className={"study-time-report"}>
        <Header dayTime={reportTime.dayTime} weekTime={reportTime.weekTime} monthTime={reportTime.monthTime}/>
        <PeriodSelector period={period} periods={periods} onChange={(p) => setPeriod(p)}/>

        <div>
            <StudyIndicator book={studyReport.book} audio={studyReport.audio} today={today} lastBook={lastBook}/>

            <div>
                <Chart
                    options={options}
                    series={currentSeries}
                    type="bar"
                    width={"100%"}
                    height={391}
                />
            </div>
        </div>
    </div>);
};

export default StudyTimeReport;
