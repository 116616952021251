import Card from "../../components/common/others/Card";
import icons from "../../utils/icons";
import {useTranslation} from "react-i18next";
import  './css/NotFound.scss';

const NotFound = () => {
    const {t} = useTranslation();
    return <div className={'not-found'}>
        <Card className={'not-found-container'}>
            {icons.notFound}
            <p>{t("Page or Resource Not Found")}</p>
        </Card>
    </div>
}

export default NotFound;