import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Collapse } from "@mantine/core";
import "./CollapsibleMenu.scss";
// Sub components
import CollapsibleItem from "./sub-components/CollapsibleItem";

const CollapsibleMenu = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={"collapsible-menu"}>
      <p
        className={isOpen ? "open" : undefined}
        onClick={() => setIsOpen(!isOpen)}
      >
        {data.value}
      </p>

      <Collapse in={isOpen}>
        {data.subOptions.map((subOption) => (
          <CollapsibleItem key={subOption} data={subOption} />
        ))}
      </Collapse>
    </div>
  );
};

export default CollapsibleMenu;
