import React from 'react';
// import { useTranslation } from 'react-i18next';
import './Social.scss';

const BookRequestForm = () => {
  // const { t } = useTranslation();
  // const [loading, setLoading] = useState(false);

  return (
    <div className='social-container'>
      <div className='row my-4 social'>
        <div className='col-md-6'>
          <p className='social-title'>دانلود اپلیکیشن</p>
          <br />
          <p className='social-description'>
            می توانید با هر تلفن هوشمند و یا تبلبتی، اپلیکشین آنوبانی را روی
            گوشی خود نصب نمایید.
          </p>
        </div>
        <div className='col-md-6'>
          <div className='social_img'>
            <img className='mr-3' src='/AppGallery.png' alt='' />
            <img src='/google_play.png' alt='' />
            <img src='/MobileAppStoreBadge.png' alt='' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookRequestForm;
