import React from 'react';
import './css/LeftSection.scss';
import { useTranslation } from 'react-i18next';
// Utils
import { useNavigate } from 'react-router-dom';

const LeftSection = () => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const navigator = useNavigate();

  return (
    <div className={'footer-left-section'}>
      <div>
        <div>
          <img
            alt={'Anobani'}
            src='/enamad.png'
            style={{ width: '125px', height: '136px' }}
          />
        </div>
        <div>
          <p
            className={i18n.language === 'kuk' ? 'ltr' : ''}
            style={{ fontSize: 28 }}
          >
            {t('Off')}
          </p>
          <p>{t('Buy e-books')}</p>
        </div>

        <div />

        <div>
          <div>
            <p>{t("Left section's description")}</p>
            <p onClick={() => navigator('/products')}>
              {t('Register your purchase now')}
            </p>
          </div>

          <p onClick={() => navigator('/products')}>{t('Go')}</p>
        </div>
      </div>

      <div>
        {/* <a
          referrerPolicy='origin'
          style={{ width: 120, height: 140 }}
          target='_blank'
          href='https://trustseal.enamad.ir/?id=323539&amp;Code=y5sd3sNnyCcNvkAURPox'
        >
          <img
            referrerPolicy='origin'
            src='https://Trustseal.eNamad.ir/logo.aspx?id=323539&amp;Code=y5sd3sNnyCcNvkAURPox'
            alt=''
            style={{ cursor: 'pointer', width: 120, height: 140 }}
            id='y5sd3sNnyCcNvkAURPox'
          />
        </a> */}
        <img
          src='/footerAdd.png'
          alt=''
          style={{
            cursor: 'pointer',
            marginTop: 30,
            width: 328,
            height: 58,
          }}
        />
      </div>
    </div>
  );
};

export default LeftSection;
