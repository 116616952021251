import React, { useEffect, useState } from "react";
import "./css/Profit.scss";
import { useTranslation } from "react-i18next";
// Components common in marketeer dashboard
import MyPublishersAndProfitHeader from "./MyPublishersAndProfitHeader";
import axios from "../../../utils/http";
import Api from "../../../utils/api";
import { getDateTime } from "../../../utils/helpers";

const Profit = () => {
  const { t } = useTranslation();
  const [profits, setProfits] = useState([]);
  const [totalProfit, setTotalProfit] = useState(0);
  const [withdrawableProfit, setWithdrawableProfit] = useState(0);
  const [currencies, setCurrencies] = useState([]);
  const [sortBy, setSortBy] = useState("desc");
  const [currency, setCurrency] = useState(null);

  useEffect(() => {
    getProfits();
  }, [sortBy, currency]);

  const getProfits = async () => {
    try {
      const { data } = await axios.get(Api.dashboard.profit, {
        params: {
          currency: currency?.currency,
          sort_by: sortBy
        }
      });
      if (data.success) {
        setProfits(data.data.profits);
        setTotalProfit(data.data.total_profit);
        setWithdrawableProfit(data.data.withdrawable_profit);
        if (!currencies.length)
          setCurrencies(data.data.currencies);
      }
    } catch (e) {

    }
  };

  return (
    <div className={"profit"}>
      <MyPublishersAndProfitHeader title={t("Profit")} totalProfit={totalProfit}
                                   withdrawableProfit={withdrawableProfit}
                                   onSortChange={(sort) => setSortBy(sort)}
                                   currencies={currencies}
                                   onCurrencyChange={(c) => setCurrency(c)} />

      <div>
        <table>
          <thead>
          <tr>
            <th>{t("Payment date")}</th>
            <th>{t("Publisher name")}</th>
            <th>{t("Price")}</th>
            <th>{t("Commission")}</th>
            <th>{t("Order number")}</th>
            <th>{t("Activity")}</th>
          </tr>
          </thead>

          <tbody>
          {profits.map((profit, index) => (
            <>
              <tr className={"spacer"}></tr>
              <tr key={index} className={!profit.is_withdrawn ? "yellow-row" : ""}>
                <td>{getDateTime(profit.order.paid_at)}</td>
                <td>{profit.publisher_name}</td>
                <td>{profit.total_price}</td>
                <td>{profit.profit}</td>
                <td>{profit.order.number}</td>
                <td>{profit.product.title}</td>
              </tr>
            </>
          ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Profit;
