import React, { useContext, useEffect, useState } from 'react';
import './css/Cover.scss';
// Utils
import ProductInfoContext from '../../../../../utils/contexts/Product';
import tempMedia from '../../../../../utils/tempMedia';

const Cover = ({ cover, language, languages, type, onLanguageChange }) => {
  const [lang, setLang] = useState(language?.toUpperCase());
  const { product } = useContext(ProductInfoContext);

  useEffect(() => {
    setLang(language?.toUpperCase());
  }, [language]);
  return (
    <div className={`cover ${product?.type === 'podcast' && 'podcast'}`}>
      <img alt={'Cover'} src={cover} />

      {/* <div>
            <p>نسخه</p>
            {languages?.map((l) => <button
                type={"button"}
                className={l.toUpperCase() === lang ? "active" : ""}
                onClick={() => {
                    if (l.toUpperCase() !== lang) {
                        onLanguageChange(l.toLowerCase());
                        setLang(l.toUpperCase());
                    }
                }}
            >
                {l.toUpperCase()}
            </button>)}
        </div> */}
    </div>
  );
};

export default Cover;
