import React, { useEffect, useState } from "react";
import "./css/DatePickerInput.scss";
// Utils
import icons from "../../../utils/icons";
import { Calendar } from "react-datepicker2";
import ModalWrapper from "./ModalWrapper";
import { getLocale } from "../../../utils/helpers";
import { useTranslation } from "react-i18next";

const DatePickerInput = ({ value, placeholder, onChange, clearable = false, withTime = false }) => {
  const { i18n } = useTranslation();
  const [showDateModal, setShowDateModal] = useState(false);
  const [formattedValue, setFormattedValue] = useState("");
  useEffect(() => {
    formatValue();
  }, [value]);

  const formatValue = () => {
    if (!value) {
      setFormattedValue(null);
      return;
    }
    if (getLocale() === "fa") {
      setFormattedValue(value?.format(`jYYYY/jMM/jDD${withTime ? " HH:mm" : ""}`));
      return;
    }
    setFormattedValue(value?.format(`YYYY/MM/DD${withTime ? " HH:mm" : ""}`));
  };
  return (
    <>
      <div className={`date-picker-input ${i18n.language === "kuk" ? "ltr" : ""}`}
           onClick={() => setShowDateModal(true)}>
        {placeholder && <label
          className={`date-picker-label ${formattedValue ? "date-picker-label-float" : ""}`}>{placeholder}</label>}
        <p>{formattedValue || placeholder}</p>
        <div className={"date-icon-container"}>
          {clearable && value ? null : icons.calendar}
          {clearable && value ? <div style={{ height: 24 }} onClick={(e) => {
            e.stopPropagation();
            onChange(null);
          }
          }>
            {icons.cross}
          </div> : null}
        </div>

      </div>
      {showDateModal &&
        <ModalWrapper xs containerClass={"date-picker-modal-container"} toggle={() => setShowDateModal(false)}>
          <Calendar timePicker={withTime}
                    value={value}
                    onChange={onChange}
                    isGregorian={getLocale() !== "fa"}
          />
        </ModalWrapper>}
    </>

  );
};

export default DatePickerInput;
