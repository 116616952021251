import React, { useContext, useEffect, useState } from 'react';
import './css/FormLeftSection.scss';
import { useTranslation } from 'react-i18next';
// Common components
import RadioButton from '../../../../../common/others/RadioButton';
import TextInput from '../../../../../common/others/TextInput';
// Modals
import AddReviewModal from '../../../../../modals/add-review/AddReview';
import ProductFormContext from '../../../../../../utils/contexts/ProductForm';
import UploadZone from '../../../../../common/others/UploadZone';
import DropdownMenu from '../../../../../common/others/DropdownMenu';
import AmountSelector from '../../../../../home-related/gift-card/AmountSelector';
import Checkbox from '../../../../../common/others/Checkbox';
import DatePickerInput from '../../../../../common/others/DatePickerInput';
import icons from '../../../../../../utils/icons';
import CollectionsModal from '../../../../../modals/collections-modal/CollectionsModal';
import Uploader from '../../../../../common/others/Uploader';
import axios from '../../../../../../utils/http';
import Api from '../../../../../../utils/api';
import CircleLoading from '../../../../../common/others/CircleLoading';
// import CollectionsModal from "../../../../../modals/collections-modal/CollectionsModal";

const FormLeftSection = () => {
  const { t } = useTranslation();
  const {
    info,
    setInfo,
    productData,
    locale,
    setProductData,
    handleSubmit,
    globalProductData,
    setGlobalProductData,
    submitLoading,
  } = useContext(ProductFormContext);
  const [showAddReviewModal, setShowAddReviewModal] = useState(false);
  const [showCollectionsModal, setShowCollectionsModal] = useState(false);

  const handleCreatedCollection = (collection) => {
    setInfo({
      ...info,
      collections: [...info?.collections, collection],
    });
  };

  // console.log(productData, 'productData');

  const handleUploadProductFile = async (files, base64, fileType) => {
    // console.log(files, 'files');

    try {
      const fd = new FormData();
      fd.append('product_type', globalProductData?.productType?.key);
      fd.append(
        'product_file_type',
        fileType.toLowerCase() === 'cover'
          ? 'cover'
          : fileType.toLowerCase().includes('demo')
          ? 'demo'
          : 'main'
      );

      for (let i = 0; i < files.length; i++) {
        fd.append(`product_file[${i}]`, files[i]);
      }
      // fd.append("product_file", files);
      const { data } = await axios.post(Api.publisher.products.uploadFile, fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // console.log(data, "response from file upload");
      if (data.success) {
        const { path, url } = data.data[0];
      
        const paths = data.data.map((obj) => obj.path);
   

        // console.log(paths, "PATHS");

        // console.log(path, url);
        // setProductData({
        //   ...productData,
        //   [fileType]: {
        //     file: files[0],
        //     mFile: files,
        //     path,
        //     url,
        //     base64,
        //   },
        // });

        setProductData((prevProductData) => {
          // console.log(prevProductData, "preData");
          // console.log(prevProductData[fileType]?.path, "path");
          const prevMFile = prevProductData[fileType]?.mFile || []; // Check if mFile exists in prevProductData

          const uniqueFiles = [
            ...new Set([
              ...prevMFile,
              ...(prevProductData[fileType]?.file
                ? [prevProductData[fileType]?.file]
                : []),
              ...files,
            ]),
          ];

          return {
            ...prevProductData,
            [fileType]: {
              ...prevProductData[fileType],
              file: files[0],
              mFile: uniqueFiles,
              path: prevProductData[fileType]?.path ? [...paths] : paths,
              url,
              base64,
            },
          };
        });
      }
    } catch (e) {
      console.log(e);
      setProductData({
        ...productData,
        [fileType]: {
          file: null,
          mFile: null,
          path: [],
          url: '',
          base64: '',
        },
      });
    }
  };

  return (
    <div className={'form-left-section'}>
      <div></div>

      <div className={'collection-container'}>
        <DropdownMenu
          icon={icons.add}
          onIconClick={() => setShowCollectionsModal(true)}
          value={
            globalProductData.collection
              ? globalProductData.collection.title
              : t('Collection')
          }
        >
          {info?.collections?.map((collection) => (
            <div
              key={collection.id}
              onClick={() => {
                setGlobalProductData({
                  ...globalProductData,
                  collection,
                });
              }}
            >
              {collection.title}
            </div>
          ))}
        </DropdownMenu>
      </div>

      <div className='uploader-zone-container'>
        <Uploader
          title={t('Upload Cover (jpeg, jpg, png)')}
          accept={{ 'image/*': ['.jpeg', '.jpg', '.png'] }}
          key={locale.key + '1'}
          preview={productData?.cover?.base64 || productData?.cover?.url}
          onUpload={async (files, base64) =>
            await handleUploadProductFile(files, base64, 'cover')
          }
          uploadOnChange
          selectedFile={productData?.cover?.file}
          onRemove={() => {
            setProductData({
              ...productData,
              cover: null,
            });
          }}
        />

        <div className={'file-uploader'}>
          <div>
            <Uploader
              title={
                productData?.demo?.url
                  ? t('Upload Sample (Selected)')
                  : globalProductData?.productType?.key === 'ELECTRONIC_BOOK' ||
                    globalProductData?.productType?.key === 'MAGAZINE'
                  ? t('Upload Sample (epub, pdf)')
                  : t('Upload Sample (mp3)')
              }
              key={locale.key + '2'}
              accept={
                globalProductData?.productType?.key === 'ELECTRONIC_BOOK' ||
                globalProductData?.productType?.key === 'MAGAZINE'
                  ? {
                      'application/epub+zip': [],
                      'application/pdf': [],
                    }
                  : { 'audio/mpeg': [] }
              }
              onUpload={async (files, base64) =>
                await handleUploadProductFile(files, base64, 'demo')
              }
              uploadOnChange
              selectedFile={productData?.demo?.file || productData?.demo?.url}
              onRemove={() => {
                setProductData({
                  ...productData,
                  demo: null,
                });
              }}
            />
            <Uploader
              title={
                productData?.main?.url
                  ? t('Upload Main File (Selected)')
                  : globalProductData?.productType?.key === 'ELECTRONIC_BOOK' ||
                    globalProductData?.productType?.key === 'MAGAZINE'
                  ? t('Upload Main File (epub, pdf)')
                  : t('Upload Main File (mp3)')
              }
              multiple={
                globalProductData?.productType?.key == 'PODCAST' ||
                globalProductData?.productType?.key == 'AUDIO_BOOK'
                  ? true
                  : false
              }
              key={locale.key + '3'}
              accept={
                globalProductData?.productType?.key === 'ELECTRONIC_BOOK' ||
                globalProductData?.productType?.key === 'MAGAZINE'
                  ? {
                      'application/epub+zip': [],
                      'application/pdf': [],
                    }
                  : { 'audio/mpeg': [] }
              }
              onUpload={async (files, base64) =>
                await handleUploadProductFile(files, base64, 'main')
              }
              uploadOnChange
              selectedFile={
                {
                  name: productData?.main?.mFile
                    ?.map((file) => file.name)
                    .join(', '),
                } || productData?.main?.url
              }
              // selectedFile={productData?.main?.file || productData?.main?.url}
              onRemove={() => {
                setProductData({
                  ...productData,
                  main: null,
                });
              }}
            />
          </div>
          {globalProductData?.hasAudioBook ? (
            <div>
              <Uploader
                title={
                  productData?.audioDemo?.url
                    ? t('Upload Sample (Selected)')
                    : t('Upload Sample (mp3)')
                }
                accept={{ 'audio/mpeg': [] }}
                key={locale.key + '4'}
                onUpload={async (files, base64) =>
                  await handleUploadProductFile(files, base64, 'audioDemo')
                }
                uploadOnChange
                selectedFile={
                  productData?.audioDemo?.file || productData?.audioDemo?.url
                }
                onRemove={() => {
                  setProductData({
                    ...productData,
                    audioDemo: null,
                  });
                }}
              />
              <Uploader
                title={
                  productData?.audioMain?.url
                    ? t('Upload Main File (Selected)')
                    : t('Upload Main File (mp3)')
                }
                key={locale.key + '5'}
                accept={{ 'audio/mpeg': [] }}
                onUpload={async (files, base64) =>
                  await handleUploadProductFile(files, base64, 'audioMain')
                }
                uploadOnChange
                selectedFile={
                  productData?.audioMain?.file || productData?.audioMain?.url
                }
                onRemove={() => {
                  setProductData({
                    ...productData,
                    audioMain: null,
                  });
                }}
              />
            </div>
          ) : null}
        </div>
      </div>

      {globalProductData?.productType?.key === 'ELECTRONIC_BOOK' ||
      globalProductData?.productType?.key === 'MAGAZINE' ? (
        <div className={'page-count-info'}>
          <TextInput
            placeholder={t('Pages Count')}
            value={productData?.pageCount || ''}
            onChange={(e) =>
              setProductData({
                ...productData,
                pageCount: e.target.value,
              })
            }
            isNumber
          />
        </div>
      ) : null}

      <div className={'price-type'}>
        <RadioButton
          group={'purchase_type'}
          id={'free'}
          title={t('Free')}
          checked={productData?.purchaseType === 'free'}
          updateValue={() =>
            setProductData({
              ...productData,
              purchaseType: 'free',
            })
          }
        />
        <RadioButton
          group={'purchase_type'}
          id={'paid'}
          title={t('Cash')}
          checked={productData?.purchaseType === 'paid'}
          updateValue={() =>
            setProductData({
              ...productData,
              purchaseType: 'paid',
            })
          }
        />
      </div>

      {productData?.purchaseType === 'paid' ? (
        <>
          {info?.currencies?.length ? (
            <AmountSelector
              className={'price-currency-selector'}
              value={productData?.currency}
              updateValue={(currency) =>
                setProductData({
                  ...productData,
                  currency,
                })
              }
              values={info?.currencies}
            />
          ) : null}

          <TextInput
            placeholder={t('Price')}
            value={productData?.prices[productData?.currency?.key]?.price || ''}
            onChange={(e) =>
              setProductData({
                ...productData,
                prices: {
                  ...productData?.prices,
                  [productData?.currency?.key]: {
                    ...(productData?.prices[productData?.currency?.key] || {}),
                    price: e.target.value,
                  },
                },
              })
            }
            isNumber
          />

          <p className={'price-details'}>
            {t('Your cost')}:{' '}
            {productData.prices[productData?.currency?.key]?.price || 0}{' '}
            {t(productData?.currency?.key)}
          </p>
          <p className={'price-details'}>
            {t('Site profit percentage')}: {100 - info.profit}%
          </p>
          {/*<p>{t("Tax")}:</p>*/}
          <p className={'price-details'}>
            {t('Total')}:{' '}
            {+(productData.prices[productData?.currency?.key]?.price || 0) -
              +(!isNaN(productData.prices[productData?.currency?.key]?.price)
                ? +(
                    productData.prices[productData?.currency?.key]?.price *
                    ((100 - info.profit) / 100)
                  )
                : 0)}{' '}
            {t(productData?.currency?.key)}
          </p>
          <Checkbox
            id={'isInSpecialDiscount'}
            checked={!!productData?.isInSpecialDiscount}
            onChange={(checked) => {
              setProductData({
                ...productData,
                isInSpecialDiscount: checked,
              });
            }}
            title={t('Add to Special Discounts')}
          />
          <TextInput
            className={'discount-text-input'}
            placeholder={t('Discount Percent')}
            value={
              productData?.prices[productData?.currency?.key]?.percent || ''
            }
            onChange={(e) =>
              setProductData({
                ...productData,
                prices: {
                  ...productData?.prices,
                  [productData?.currency?.key]: {
                    ...(productData?.prices[productData?.currency?.key] || {}),
                    percent: e.target.value,
                  },
                },
              })
            }
          />
          <div style={{ margin: '16px 0' }}>
            <DatePickerInput
              placeholder={t('Start Date')}
              value={productData?.discountStartedAt}
              onChange={(date) =>
                setProductData({
                  ...productData,
                  discountStartedAt: date,
                })
              }
              clearable
            />
          </div>
          <DatePickerInput
            placeholder={t('End Date')}
            value={productData?.discountEndedAt}
            onChange={(date) =>
              setProductData({
                ...productData,
                discountEndedAt: date,
              })
            }
            clearable
          />
        </>
      ) : null}

      <button
        type={'button'}
        className={'btn-add-reviews-modal'}
        onClick={() => {
          setShowAddReviewModal(true);
        }}
      >
        {t('Add Review for Product')}
      </button>

      <div className={'product-actions'}>
        <button type={'button'} className={'btn-cancel-product'}>
          {t('Cancel')}
        </button>
        <button
          type={'button'}
          className={'btn-submit-product'}
          onClick={handleSubmit}
        >
          {submitLoading ? <CircleLoading /> : t('Submit')}
        </button>
      </div>

      {showAddReviewModal && (
        <AddReviewModal toggleDisplay={setShowAddReviewModal} />
      )}

      {showCollectionsModal && (
        <CollectionsModal
          locale={locale}
          toggleDisplay={setShowCollectionsModal}
          onCreated={handleCreatedCollection}
        />
      )}
    </div>
  );
};

export default FormLeftSection;
