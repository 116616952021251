import React from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
// Components common between home sections
import SliderButtons from '../common/SliderButtons';
import SiteSuggestion, {
  SiteSuggestionSkeleton,
} from './sub-components/SiteSuggestion';
// Utils

export const SiteSuggestionsSliderSkeleton = () => {
  const { i18n } = useTranslation();

  return (
    <Swiper
      className={`product-and-site-suggestions-slider`}
      dir={i18n.language === 'fa' ? 'rtl' : 'ltr'}
      loop={true}
      spaceBetween={16}
      slidesPerView={'auto'}
    >
      <SwiperSlide>
        <SiteSuggestionSkeleton />
      </SwiperSlide>
      <SwiperSlide>
        <SiteSuggestionSkeleton />
      </SwiperSlide>
      <SwiperSlide>
        <SiteSuggestionSkeleton />
      </SwiperSlide>
      <SwiperSlide>
        <SiteSuggestionSkeleton />
      </SwiperSlide>

      <SliderButtons />
    </Swiper>
  );
};

const SiteSuggestionsSlider = ({ banners }) => {
  const { i18n } = useTranslation();

  return (
    <Swiper
      className={`product-and-site-suggestions-slider pb-25 `}
      dir={i18n.language === 'fa' ? 'rtl' : 'ltr'}
      // loop={true}
      // spaceBetween={16}
      // slidesPerView={'auto'}
      slidesPerView={1}
      spaceBetween={16}
      breakpoints={{
        640: {
          spaceBetween: 16,
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 'auto',
          spaceBetween: 16,
        },
        1024: {
          slidesPerView: 'auto',
          spaceBetween: 16,
        },
      }}
    >
      {banners.map((banner, index) => (
        <SwiperSlide key={index}>
          <SiteSuggestion banner={banner} />
        </SwiperSlide>
      ))}

      <SliderButtons />
    </Swiper>
  );
};

export default SiteSuggestionsSlider;
