import React from "react";
import "./css/Checkbox.scss";

const Checkbox = ({id, title, checked = false, onChange, error = null}) => {
    return (
        <div>
            <label className={"checkbox"} htmlFor={`checkbox-${id}`}>
                <input checked={checked} onChange={(e) => onChange ? onChange(!checked) : null} type={"checkbox"}
                       id={`checkbox-${id}`}/>

                <div>
                    <div/>
                </div>

                <p>{title}</p>
            </label>
            {error && <p style={{color: '#ff0000',fontSize:13}}>{error}</p>}
        </div>
    );
};

export default Checkbox;
