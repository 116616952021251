import React from "react";
import "./Tab.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Tab = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div
      className={`dashboard-tab-navigator-tab ${
        location.pathname === props.link && "active"
      }`}
      onClick={() => {
        props.changeCurrentTab(t(props.title));
        navigate(props.link);
      }}
    >
      {props.icon}
      <p>{t(props.title)}</p>
    </div>
  );
};

export default Tab;
