import React, { useEffect, useState } from "react";
import "./css/Products.scss";
import Filters from "../../components/categories-related/filters/Filters";
import API from "../../utils/api";
import axios from "../../utils/http";
import Product, { ProductSkeleton } from "../../components/common/others/Product";

const BookIntroductions = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    setLoading(true);

    try {
      const { data } = await axios.get(API.products.bookIntroductions);

      setProducts(data.data.products);
    } catch (e) {

    }

    setLoading(false);
  };

  if (loading) return (<div className={"categories"}>
    <div style={{paddingTop:30}}>
      <ProductSkeleton type={"electronic_book"} />
      <ProductSkeleton type={"electronic_book"} />
      <ProductSkeleton type={"electronic_book"} />
      <ProductSkeleton type={"electronic_book"} />
      <ProductSkeleton type={"electronic_book"} />
      <ProductSkeleton type={"electronic_book"} />
      <ProductSkeleton type={"electronic_book"} />
      <ProductSkeleton type={"electronic_book"} />
      <ProductSkeleton type={"electronic_book"} />
      <ProductSkeleton type={"electronic_book"} />
      <ProductSkeleton type={"electronic_book"} />
      <ProductSkeleton type={"electronic_book"} />
      <ProductSkeleton type={"electronic_book"} />
      <ProductSkeleton type={"electronic_book"} />
      <ProductSkeleton type={"electronic_book"} />
      <ProductSkeleton type={"electronic_book"} />
      <ProductSkeleton type={"electronic_book"} />
      <ProductSkeleton type={"electronic_book"} />
      <ProductSkeleton type={"electronic_book"} />
      <ProductSkeleton type={"electronic_book"} />
      <ProductSkeleton type={"electronic_book"} />
    </div>
  </div>);

  return (
    <div className={"categories"}>
      <div style={{paddingTop:30}}>
        {products.map(product => <Product key={product.id} type={product.type} data={product} />)}
      </div>
    </div>);
};

export default BookIntroductions;
