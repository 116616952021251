import "./css/FileViewer.scss";
import icons from "../../../utils/icons";

const SingleFileViewer = ({ url, onRemove, removeable = true }) => {
  return <div className={"file-viewer-container"}>
    <img src={typeof url === "string" ? url : url.url} alt="" className={"file-viewer-view"} />
    {removeable ? <button className={"btn-remove-view"} onClick={() => {
      if (onRemove)
        onRemove(url);
    }
    }>{icons.delete}</button> : null}
  </div>;
};

const FileViewer = ({ urls, onRemove, removable = true, title }) => {

  if (Array.isArray(urls))
    return <>
      {title && <p>{title}</p>}
      <div className="file-viewers-container">
        {urls.map((url, index) => <SingleFileViewer key={index}
                                                    url={url}
                                                    onRemove={onRemove}
                                                    removeable={removable} />)}
      </div>
    </>;
  else

    return <>
      {title && <p>{title}</p>}
      <SingleFileViewer url={urls} onRemove={onRemove} removeable={removable} />
    </>;
};

export default FileViewer;
