const productSliderBreakpointsConfig = {
  1: {
    spaceBetween: 8,
  },
  480: {
    spaceBetween: 16,
  },
};

export default productSliderBreakpointsConfig;
