import Dropzone from "react-dropzone";
import "./css/UploadZone.scss";
import {useEffect, useState} from "react";
import icons from "../../../utils/icons";
import axios from "./../../../utils/http";
import Api from "../../../utils/api";
import CircleLoading from "./CircleLoading";

const UploadZone = ({
                        title,
                        onSelect,
                        onUploadSuccess,
                        onUploadFailed,
                        onRemove,
                        multiple = false,
                        uploadOnSelect = true,
                        uploadURL = Api.publisher.documents,
                        uploadFolder = "documents",
                        logs = true,
                        preview = null,
                        removable = true,
                        selectedFile
                    }) => {
    const [files, setFiles] = useState([]);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [filePreview, setFilePreview] = useState(null);

    useEffect(() => {
        if (selectedFile) {
            const fr = new FileReader();
            fr.addEventListener('load', function () {
                console.log(fr.result);
                setFilePreview(fr.result);
            }, false);
            fr.readAsDataURL(selectedFile);
        }
    }, [selectedFile])

    const handleOnDrop = (acceptedFiles) => {
        if (uploadLoading) return;

        const fr = new FileReader();
        fr.addEventListener('load', function () {
            setFilePreview(fr.result);
        }, false);
        fr.readAsDataURL(acceptedFiles[0]);


        if (multiple) setFiles([...files, ...acceptedFiles]);
        else setFiles(acceptedFiles);

        if (onSelect) onSelect(acceptedFiles, uploadFile);

        if (uploadOnSelect) {
            uploadFile(acceptedFiles[0]);
        }
    };

    const handleRemove = (file, index) => {
        setFiles(files.filter((f) => f !== file));
        if (onRemove) onRemove(file, multiple ? index : null);
    };

    const uploadFile = async (file) => {
        setUploadLoading(true);
        try {
            const fd = new FormData();
            fd.append(uploadFolder, file);

            const {data} = await axios.post(uploadURL, fd, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            if (onUploadSuccess) onUploadSuccess(data);
        } catch (e) {
            if (onUploadFailed) onUploadFailed();
        }
        setUploadLoading(false);
    };

    return (
        <Dropzone onDrop={handleOnDrop} multiple={multiple}>
            {({getRootProps, getInputProps}) => (
                <section className={"upload-zone"}>
                    <div {...getRootProps()} className={"upload-zone-inner"}>
                        <input {...getInputProps()} />
                        <div className={"upload-zone-info"}>
                            {preview || filePreview ? (
                                <img src={preview || filePreview} alt="" className="upload-zone-preview"/>
                            ) : null}
                            {!preview && !filePreview ? <p>{title}</p> : null}
                        </div>
                    </div>
                    {files.length > 0 && logs ? (
                        <div className={"uploader-zone-files"}>
                            {files.map((file, index) => (
                                <div key={index} className={"uploader-zone-file"}>
                                    <span>{file.name}</span>
                                    <button
                                        className={"btn-remove-file"}
                                        onClick={() => handleRemove(file, index)}
                                    >
                                        {icons.delete}
                                    </button>
                                </div>
                            ))}
                        </div>
                    ) : null}
                    {uploadLoading && (
                        <div className={"upload-zone-loading"}>
                            <CircleLoading color={"#1c3c53"} width={120} height={120}/>
                        </div>
                    )}
                    {!logs && (files.length || selectedFile) && !multiple && removable ? <button
                        className={"btn-remove-outer-file"}
                        onClick={() => {
                            handleRemove(files[0], null)
                            setFilePreview(null)
                        }}
                    >
                        {icons.delete}
                    </button> : null}
                </section>
            )}
        </Dropzone>
    );
};

export default UploadZone;
