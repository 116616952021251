import React from 'react';
import { Fragment } from 'react';
import PurchaseAndPreview from '../product-info/sub-components/PurchaseAndPreview';
import icons from '../../../../utils/icons';
import { getDate } from '../../../../utils/helpers';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useState } from 'react';

function convertDurationToTime(durationSeconds) {
  const hours = Math.floor(durationSeconds / 3600);
  const minutes = Math.floor((durationSeconds % 3600) / 60);
  const seconds = Math.floor(durationSeconds % 60);

  const formattedTime = `${String(hours).padStart(2, '0')}:${String(
    minutes
  ).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

  return formattedTime;
}
const Price = ({ product, bookType, openAudioBook, audioUrl }) => {
  const [isActive, setIsActive] = useState([]);
  const client = useSelector((state) => state.client);
  const audioHandler = (url) => {
    if (!client) {
      toast(t('For This Purchase, First you Need To Login'), {
        type: 'error',
      });
      return;
    }
    openAudioBook(true);

    audioUrl(url);
  };

  const handleExpandRow = (personId) => {
    if (!client) {
      return;
    }
    let newExpandedRows = [...isActive];
    const isRowExpanded = newExpandedRows.includes(personId);
    if (isRowExpanded) {
      // If already expanded, collapse the row
      const index = newExpandedRows.indexOf(personId);
      newExpandedRows.splice(index, 1);
    } else {
      // If not expanded, expand the row
      newExpandedRows = [personId];
    }
    setIsActive(newExpandedRows);
  };
  return (
    <Fragment>
      <div className='cardB my-3 d-block d-lg-none'>
        <div className='cardB-header d-flex align-items-center'>
          {product?.type === 'electronic_book' && (
            <>
              {icons.eBook}
              <p>{t('E-book')}</p>
            </>
          )}
          {product?.type === 'audio_book' && (
            <p>
              {icons.headPhone} &nbsp;
              {t('Audio book')}
            </p>
          )}
          {product?.type === 'podcast' && (
            <p>
              {icons.podcast} &nbsp;
              {t('Podcast')}
            </p>
          )}
        </div>

        <div className='cardB-body'>
          <div className='d-flex justify-content-between'>
            <span>
              {t('Size')} :
              {bookType === 'electronic_book' || bookType === 'magazine' ? (
                <strong>{product?.epub_size}</strong>
              ) : (
                <strong> {product?.mp3_size}</strong>
              )}
            </span>
            <span>
              {t('Published')}:<strong> {getDate(product?.created_at)}</strong>
            </span>
          </div>

          <div className='d-flex justify-content-between mb-4'>
            <span>
              {t('Language')}:<strong>{product?.language_title}</strong>
            </span>

            {(product?.type === 'audio_book' ||
              product?.type === 'podcast') && (
              <span>
                {t('Duration')}:
                <strong>{convertDurationToTime(product?.mp3_duration)}</strong>
              </span>
            )}
            {product?.type === 'electronic_book' && (
              <span>
                {t('Number of Pages')}:<strong>{product?.pages}</strong>
              </span>
            )}
          </div>

          {product?.purchase_type === 'paid' ? (
            <div className='d-flex justify-content-between'>
              <span>
                <strong style={{ fontSize: '20px' }}>
                  {product.prices[2].price}
                </strong>
                دولار
              </span>
              <span>
                <strong style={{ fontSize: '20px' }}>
                  {product.prices[1].price}
                </strong>
                دینار
              </span>
              <span>
                <strong style={{ fontSize: '20px' }}>
                  {product.prices[0].price}
                </strong>
                تمەن
              </span>
            </div>
          ) : (
            <div>
              <span>
                <strong style={{ fontSize: '20px' }}>{t('Free')}</strong>
              </span>
            </div>
          )}
          <PurchaseAndPreview product={product} />
        </div>
      </div>
      <div className='cardB my-3 d-block d-lg-none'>
        <div className='cardB-body'>
          <div className='row'>
            <div className='col-md-3'>
              <img src='/60.png' alt='60' />
            </div>
            <div className='col-md-8'>
              <p className=' justify-content-end'>{t('Discount Detail')}</p>
              <div className='d-flex justify-content-between'>
                <span>کۆدی داشکاندن: </span>
                <span>
                  <strong>ANFB60</strong>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {(product?.type === 'audio_book' || product?.type === 'podcast') && (
        <div className='cardB mt-3 d-block d-lg-none'>
          {product?.type === 'audio_book' && (
            <div className='cardB-audio-header '>
              <p>
                {icons.list} {t('List of sections')}
              </p>
            </div>
          )}
          <div className='cardB-body'>
            <div>
              {(product?.is_purchased?.audio_book &&
                product?.purchase_type === 'paid') ||
              product?.purchase_type === 'free' ? (
                <>
                  {product?.main?.mp3?.map((el, i) => {
                    const isExpandedPurchaseCredit = isActive.includes(el?.url);
                    return (
                      <div
                        className='d-flex justify-content-between py-2'
                        key={i}
                      >
                        <div>
                          <div key={el?.url} className='list-section'>
                            <span
                              onClick={(e) => {
                                audioHandler(el?.url);
                                handleExpandRow(el?.url);
                              }}
                              style={{ cursor: 'pointer' }}
                            >
                              {isExpandedPurchaseCredit
                                ? icons.pause
                                : icons.smallPlay}
                            </span>
                            &nbsp;
                            <span
                              onClick={(e) => {
                                audioHandler(el?.url);
                              }}
                              style={{ cursor: 'pointer' }}
                            >
                              {el?.original_file_name}
                            </span>
                          </div>
                        </div>
                        <div>{convertDurationToTime(el?.duration)}</div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Price;
