import React, {useEffect, useState} from "react";
import "./css/TermsOfUse.scss";
import {useTranslation} from "react-i18next";
import axios from './../../utils/http';
import API from './../../utils/api';
import Skeleton from "react-loading-skeleton";

const TermsOfUseSkeleton = () => <>
    <Skeleton width={'50%'} height={35}/>
    <br/>
    <Skeleton width={'50%'}/>
    <Skeleton/>
    <Skeleton/>
    <Skeleton/>
    <br/>
    <Skeleton width={'50%'}/>
    <Skeleton/>
    <Skeleton/>
    <Skeleton/>
    <br/>
    <Skeleton width={'50%'}/>
    <Skeleton/>
    <Skeleton/>
    <Skeleton/>
    <br/>
    <Skeleton width={'50%'}/>
    <Skeleton/>
    <Skeleton/>
    <Skeleton/>
</>;

const TermsOfUse = () => {
    const {t} = useTranslation();
    const [termOfUses, setTermOfUses] = useState(null);
    const [loading, setLoading] = useState(false);

    const getTermOfUses = async () => {
        setLoading(true);
        try {
            const {data} = await axios.get(API.public.termOfUses);
            if (data.success) {
                setTermOfUses(data.data)
            }
        } catch (e) {

        }
        setLoading(false);
    }

    useEffect(() => {
        getTermOfUses();
    }, [])


    return (
        <div className={"terms-of-use"}>
            <p className={"home-title m24"}>{t("Terms of use")}</p>

            {loading
                ? <TermsOfUseSkeleton/>
                : <>
                    <p>{termOfUses?.welcome_message}</p>
                    {termOfUses?.term_of_uses?.map((item, index) => <>
                        <p className={"title"}>{item.title}</p>
                        <pre className={"content"}>{item.text}</pre>
                    </>)}
                </>}
        </div>
    );
};

export default TermsOfUse;
