import React, { useEffect, useState } from "react";
import "./PurchaseRecords.scss";
import { useTranslation } from "react-i18next";
import axios from "../../../utils/http";
import API from "../../../utils/api";
import { replace, startCase, upperFirst } from "lodash";
import { Link } from "react-router-dom";

const PurchaseRecords = () => {
  const { t } = useTranslation();

  const [purchases, setPurchases] = useState([]);

  useEffect(() => {
    getPurchases();
  }, []);

  const getPurchases = async () => {
    try {
      const { data } = await axios.get(API.dashboard.purchasesHistory);

      if (data.success) {
        setPurchases(data.data.purchases_history);
      }
    } catch (e) {

    }
  };

  return (
    <div className={"purchase-records"}>
      <table>
        <thead>
        <tr>
          <th>{t("Payment date")}</th>
          <th>{t("Payment time")}</th>
          <th>{t("Amount")}</th>
          <th>{t("Status")}</th>
          <th>{t("Issue tracking")}</th>
          <th>{t("Purchased books")}</th>
        </tr>
        </thead>

        <tbody>
        {purchases.map((purchase) => (
          <tr key={purchase.id}>
            <td>{purchase.paid_at_date}</td>
            <td>{purchase.paid_at_time}</td>
            <td>
              {purchase.total_price_format}
            </td>
            <td>{t(startCase(replace(purchase.status, "_", " ")))}</td>
            <td>{purchase.order_number}</td>
            <td>
              <Link
                to={`/dashboard/user/purchase-records/${purchase.order_number}`}>{t("View all")}</Link>
            </td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
};

export default PurchaseRecords;
