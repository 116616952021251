import moment from 'moment-jalaali';

export const getLocale = () => {
  return localStorage.getItem('locale') || 'ku';
};

export const setLocale = (locale) => {
  localStorage.setItem('locale', locale);
  window.location.reload();
};

export const getRouter = (route, options) => {
  const matches = [...route.matchAll(/{([a-zA-Z0-9]+)}/g)];

  for (const match of matches) {
    if (!options[match[1]])
      throw new Error(`option '${match[1]}' not provided`);
    route = route.replace(match[0], options[match[1]]);
  }

  return route;
};

export const getAuthenticatedToken = () => {
  return localStorage.getItem('token');
};

export const getDate = (timestamp) => {
  if (getLocale() === 'fa') return moment(timestamp).format('jYYYY/jMM/jDD');

  return moment(timestamp).format('YYYY/MM/DD');
};

export const getDateTime = (timestamp) => {
  if (getLocale() === 'fa')
    return moment(timestamp).format('jYYYY/jMM/jDD - HH:mm');

  return moment(timestamp).format('YYYY/MM/DD HH:mm');
};

export const getTime = (timestamp) => {
  return moment(timestamp).format('HH:mm');
};
