import { ReactComponent as AlarmsAndNewspaper } from '../assets/icons/alarms-and-newspaper.svg';
import { ReactComponent as Arrowhead } from '../assets/icons/arrowhead.svg';
import { ReactComponent as AudioBook } from '../assets/icons/audio-book.svg';
import { ReactComponent as AudioPlaying } from '../assets/icons/audio-playing.svg';
import { ReactComponent as AudioPlaying2 } from '../assets/icons/audio-playing-2.svg';
import { ReactComponent as AudioStopped } from '../assets/icons/audio-stopped.svg';
import { ReactComponent as Calendar } from '../assets/icons/calendar.svg';
import { ReactComponent as Clock } from '../assets/icons/clock.svg';
import { ReactComponent as Copy } from '../assets/icons/copy.svg';
import { ReactComponent as Cross } from '../assets/icons/cross.svg';
import { ReactComponent as Delete } from '../assets/icons/delete.svg';
import { ReactComponent as Dislike } from '../assets/icons/dislike.svg';
import { ReactComponent as EBook } from '../assets/icons/e-book.svg';
import { ReactComponent as Edit } from '../assets/icons/edit.svg';
import { ReactComponent as Epub } from '../assets/icons/epub.svg';
import { ReactComponent as GiftBoxes } from '../assets/icons/gift-boxes.svg';
import { ReactComponent as GiftBoxOrange } from '../assets/icons/gift-box-orange.svg';
import { ReactComponent as GiftBoxTurquoise } from '../assets/icons/gift-box-turquoise.svg';
import { ReactComponent as GiftBoxYellow } from '../assets/icons/gift-box-yellow.svg';
import { ReactComponent as GiftCard } from '../assets/icons/gift-card.svg';
import { ReactComponent as Google } from '../assets/icons/google.svg';
import { ReactComponent as GooglePlay } from '../assets/icons/google-play.svg';
import { ReactComponent as Heart } from '../assets/icons/heart.svg';
import { ReactComponent as Home } from '../assets/icons/home.svg';
import { ReactComponent as InputVisible } from '../assets/icons/input-visible.svg';
import { ReactComponent as InputInvisible } from '../assets/icons/input-invisible.svg';
import { ReactComponent as Instagram } from '../assets/icons/instagram.svg';
import { ReactComponent as Journal } from '../assets/icons/journal.svg';
import { ReactComponent as Jpg } from '../assets/icons/jpg.svg';
import { ReactComponent as Like } from '../assets/icons/like.svg';
import { ReactComponent as Loading } from '../assets/icons/loading.svg';
import { ReactComponent as LogOut } from '../assets/icons/logOut.svg';
import { ReactComponent as Mp3 } from '../assets/icons/mp3.svg';
import { ReactComponent as Next } from '../assets/icons/next.svg';
import { ReactComponent as Previous } from '../assets/icons/previous.svg';
import { ReactComponent as Pdf } from '../assets/icons/pdf.svg';
import { ReactComponent as Play } from '../assets/icons/play.svg';
import { ReactComponent as Play2 } from '../assets/icons/play2.svg';
import { ReactComponent as Plus } from '../assets/icons/plus.svg';
import { ReactComponent as Png } from '../assets/icons/png.svg';
import { ReactComponent as Podcast } from '../assets/icons/podcast.svg';
import { ReactComponent as ProgressRing } from '../assets/icons/progress-ring.svg';
import { ReactComponent as Reply } from '../assets/icons/reply.svg';
import { ReactComponent as Search } from '../assets/icons/search.svg';
import { ReactComponent as Share } from '../assets/icons/share.svg';
import { ReactComponent as ShoppingCart } from '../assets/icons/shopping-cart.svg';
import { ReactComponent as SlideMenu } from '../assets/icons/slide-menu.svg';
import { ReactComponent as Sound } from '../assets/icons/sound.svg';
import { ReactComponent as Stop } from '../assets/icons/stop.svg';
import { ReactComponent as Stop2 } from '../assets/icons/stop2.svg';
import { ReactComponent as Telegram } from '../assets/icons/telegram.svg';
import { ReactComponent as Telephone } from '../assets/icons/telephone.svg';
import { ReactComponent as Tick } from '../assets/icons/tick.svg';
import { ReactComponent as Twitter } from '../assets/icons/twitter.svg';
import { ReactComponent as User } from '../assets/icons/user.svg';
import { ReactComponent as Wallet } from '../assets/icons/wallet.svg';
import { ReactComponent as WhatsApp } from '../assets/icons/whatsapp.svg';
import { ReactComponent as Add } from '../assets/icons/add.svg';
import { ReactComponent as Close } from '../assets/icons/close.svg';
import { ReactComponent as EnterFullScreen } from '../assets/icons/enter-full-screen.svg';
import { ReactComponent as ExitFullScreen } from '../assets/icons/exit-full-screen.svg';
import { ReactComponent as Hamburger } from '../assets/icons/hamburger.svg';
import { ReactComponent as Minus } from '../assets/icons/minus.svg';
import { ReactComponent as Exit } from '../assets/icons/exit.svg';
import { ReactComponent as NotFound } from '../assets/icons/not_found.svg';
import { ReactComponent as Note } from '../assets/icons/notes.svg';
import { ReactComponent as Content } from '../assets/icons/contents.svg';
import { ReactComponent as Highlight } from '../assets/icons/highlight.svg';
import { ReactComponent as Comment } from '../assets/icons/comment.svg';
import { ReactComponent as Ratted } from '../assets/icons/ratted.svg';
import { ReactComponent as UnRatted } from '../assets/icons/un-ratted.svg';
import { ReactComponent as Eye } from '../assets/icons/eye.svg';
import { ReactComponent as Star } from '../assets/icons/star.svg';
import { ReactComponent as List } from '../assets/icons/list.svg';
import { ReactComponent as HeadPhone } from '../assets/icons/head-phone.svg';
import { ReactComponent as Pause } from '../assets/icons/pause.svg';
import { ReactComponent as Pause1 } from '../assets/icons/pause1.svg';
import { ReactComponent as Menu } from '../assets/icons/menu.svg';
import { ReactComponent as TwoX } from '../assets/icons/2x.svg';
import { ReactComponent as Mute } from '../assets/icons/mute.svg';
import { ReactComponent as Reverse } from '../assets/icons/reverse.svg';
import { ReactComponent as Increase } from '../assets/icons/increase.svg';
import { ReactComponent as MainPlay } from '../assets/icons/main-play.svg';
import { ReactComponent as Closed } from '../assets/icons/colse1.svg';
import { ReactComponent as SmallPause } from '../assets/icons/small-pause.svg';
import { ReactComponent as SmallPlay } from '../assets/icons/smallPlay.svg';

const icons = {
  alarmsAndNewspaper: <AlarmsAndNewspaper />,
  arrowhead: <Arrowhead />,
  audioBook: <AudioBook />,
  audioPlaying: <AudioPlaying />,
  audioPlaying2: <AudioPlaying2 />,
  audioStopped: <AudioStopped />,
  calendar: <Calendar />,
  clock: <Clock />,
  copy: <Copy />,
  cross: <Cross />,
  delete: <Delete />,
  dislike: <Dislike />,
  eBook: <EBook />,
  edit: <Edit />,
  ePub: <Epub />,
  giftBoxes: <GiftBoxes />,
  giftBoxOrange: <GiftBoxOrange />,
  giftBoxTurquoise: <GiftBoxTurquoise />,
  giftBoxYellow: <GiftBoxYellow />,
  giftCard: <GiftCard />,
  google: <Google />,
  googlePlay: <GooglePlay />,
  heart: <Heart />,
  home: <Home />,
  inputVisible: <InputVisible />,
  inputInvisible: <InputInvisible />,
  instagram: <Instagram />,
  journal: <Journal />,
  jpg: <Jpg />,
  like: <Like />,
  loading: <Loading />,
  logOut: <LogOut />,
  mp3: <Mp3 />,
  next: <Next />,
  previous: <Previous />,
  pdf: <Pdf />,
  play: <Play />,
  play2: <Play2 />,
  plus: <Plus />,
  minus: <Minus />,
  png: <Png />,
  podcast: <Podcast />,
  progressRing: <ProgressRing />,
  reply: <Reply />,
  search: <Search />,
  share: <Share />,
  shoppingCart: <ShoppingCart />,
  slideMenu: <SlideMenu />,
  sound: <Sound />,
  stop: <Stop />,
  stop2: <Stop2 />,
  telegram: <Telegram />,
  telephone: <Telephone />,
  tick: <Tick />,
  twitter: <Twitter />,
  user: <User />,
  whatsApp: <WhatsApp />,
  wallet: <Wallet />,
  add: <Add />,
  close: <Close />,
  enterFullScreen: <EnterFullScreen />,
  exitFullScreen: <ExitFullScreen />,
  hamburger: <Hamburger />,
  exit: <Exit />,
  notFound: <NotFound />,
  note: <Note />,
  content: <Content />,
  highlight: <Highlight />,
  comment: <Comment />,
  ratted: <Ratted />,
  unRatted: <UnRatted />,
  eye: <Eye />,
  star: <Star />,
  list: <List />,
  headPhone: <HeadPhone />,
  pause: <Pause />,
  pause1: <Pause1 />,
  menu: <Menu />,
  twoX: <TwoX />,
  mute: <Mute />,
  increase: <Increase />,
  reverse: <Reverse />,
  mainPlay: <MainPlay />,
  closed: <Closed />,
  smallPause: <SmallPause />,
  smallPlay: <SmallPlay />,
};

export default icons;
