const workWithUsInputsConfigs = {
  rightSection: [
    {
      placeholder: "First name",
      fieldName: "firstName",
    },
    {
      placeholder: "Last name",
      fieldName: "lastName",
    },
    {
      placeholder: "National ID",
      fieldName: "nationalID",
    },
  ],

  leftSection: {
    partOne: [
      {
        placeholder: "Publisher's name",
        fieldName: "publishersName",
      },
      {
        placeholder: "Invite code",
        fieldName: "inviteCode",
      },
      {
        placeholder: "Central office address",
        fieldName: "centralOfficeAddress",
      },
    ],
    partTwo: [
      {
        placeholder: "Postal code",
        fieldName: "postalCode",
      },
      {
        placeholder: "Publisher's email address",
        fieldName: "publishersEmailAddress",
      },
      {
        placeholder: "Publisher's code",
        fieldName: "publishersCode",
      },
    ],
  },
};

export default workWithUsInputsConfigs;
