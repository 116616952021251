import React, {useEffect, useState} from "react";
import "./MyProductsType.scss";
import {useNavigate, useParams} from "react-router-dom";
import {getRouter} from "../../../../utils/helpers";
import Api from "../../../../utils/api";
import axios from "../../../../utils/http";
import Product, {ProductSkeleton} from "../../../common/others/Product";
import CircleLoading from "../../../common/others/CircleLoading";
import {useTranslation} from "react-i18next";

const MyProductsType = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {type} = useParams();
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const [nextPageUrl, setNextPageUrl] = useState(null);
    const [total, setTotal] = useState(0);
    const [hasMore, setHasMore] = useState(false);
    const [moreLoading, setMoreLoading] = useState(false);

    useEffect(() => {
        getProductsType();
    }, []);

    const handleDeleteProduct = async (product) => {
        try {
            const {data} = await axios.delete(getRouter(Api.publisher.products.remove, {
                productId: product.id
            }));

            if (data.success) {

                await getProductsType(true);
            }

        } catch (e) {

        }
    };

    const getProductsType = async (reset = false) => {
        if (moreLoading)
            return;
        if (reset)
            setLoading(true);
        setMoreLoading(true);

        try {
            const {data} = await axios.get((!reset ? nextPageUrl : null) || getRouter(Api.publisher.products.myProductsCategory, {
                type
            }));

            if (data.success) {

                setProducts(!reset ? [...products, ...data.data.products.data] : data.data.products.data);
                setNextPageUrl(data.data.products.next_page_url);
                setTotal(data.data.products.total);
                setHasMore(!!data.data.products.next_page_url);
            }

        } catch (e) {

        }
        setLoading(false);
        setMoreLoading(false);
    };

    if (loading) return (<>
        <div className={"library-products"}>
            <ProductSkeleton type={"electronic_book"}/>
            <ProductSkeleton type={"electronic_book"}/>
            <ProductSkeleton type={"electronic_book"}/>
            <ProductSkeleton type={"electronic_book"}/>
            <ProductSkeleton type={"electronic_book"}/>
            <ProductSkeleton type={"electronic_book"}/>
            <ProductSkeleton type={"electronic_book"}/>
            <ProductSkeleton type={"electronic_book"}/>
            <ProductSkeleton type={"electronic_book"}/>
            <ProductSkeleton type={"electronic_book"}/>
            <ProductSkeleton type={"electronic_book"}/>
            <ProductSkeleton type={"electronic_book"}/>
            <ProductSkeleton type={"electronic_book"}/>
            <ProductSkeleton type={"electronic_book"}/>
            <ProductSkeleton type={"electronic_book"}/>
            <ProductSkeleton type={"electronic_book"}/>
            <ProductSkeleton type={"electronic_book"}/>
            <ProductSkeleton type={"electronic_book"}/>
            <ProductSkeleton type={"electronic_book"}/>
            <ProductSkeleton type={"electronic_book"}/>
            <ProductSkeleton type={"electronic_book"}/>
        </div>
    </>);
    return <>
        <div className={"my-products-list"}>

            {products.map(product => <Product type={product.type} data={product}
                                              onEdit={(product) => navigate(`/dashboard/publisher/products/${product.slug}/edit`)}
                                              onDelete={(product) => handleDeleteProduct(product)}/>)}
        </div>
        {hasMore && <div className="load-more-container">
            <button onClick={() => getProductsType()}>{moreLoading ? <CircleLoading/> : t("Load More")}</button>
        </div>}
    </>;
};

export default MyProductsType;
