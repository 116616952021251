import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import "./css/EmailLogin.scss";
// Common components
import ModalWrapper from "../../../common/others/ModalWrapper";
import TextInput from "../../../common/others/TextInput";
// Form validation
import {Formik} from "formik";
import {emailLoginSchemas} from "../../../../utils/formValidationSchemas";
// Utils
import icons from "../../../../utils/icons";
import NavbarContext from "../../../../utils/contexts/Navbar";
import axios from "../../../../utils/http";
import {getRouter} from "../../../../utils/helpers";
import API from "../../../../utils/api";
import {toast} from "react-toastify";
import {logIn} from "../../../../redux/actions/clientActions";
import {useDispatch} from "react-redux";
import CircleLoading from "../../../common/others/CircleLoading";

const EmailLoginModal = (props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {modalsDisplayStatus, toggleModals, setMaxConnections, authValues, setAuthValues} = useContext(NavbarContext);
    const [loading, setLoading] = useState(false);

    function toggle() {
        toggleModals({...modalsDisplayStatus, emailLogin: false});
        setAuthValues({...authValues, emailAddress: '', password: ''})
    }

    async function submit(values) {
        if (loading)
            return;

        setLoading(true);

        setAuthValues({...authValues, emailAddress: values.emailAddress, password: values.password});

        try {
            const {data} = await axios.post(API.auth.userLogin, {
                email: values.emailAddress,
                password: values.password
            });

            if (data.success) {
                const {user, token, code} = data.data;
                if (code) {
                    toast(code, {type: 'success'})
                    toggleModals({...modalsDisplayStatus, emailLogin: false, emailRegisterOtp: true});
                } else {
                    localStorage.setItem('token', token);
                    axios.setAuthorizationHeader(token);
                    dispatch(logIn(user));
                    toggleModals({...modalsDisplayStatus, emailLogin: false});
                    setAuthValues({...authValues, emailAddress: '', password: ''})
                }
            }
        } catch (e) {
            if (e.response?.status === 400 && e.response?.data?.data) {
                const data = e.response?.data?.data;
                setMaxConnections(data);
                toggleModals({...modalsDisplayStatus, emailLogin: false, maxConnection: true});
            }
        }

        setLoading(false);
    }

    return (
        <ModalWrapper className={"email-login-modal"} toggle={toggle}>
            {icons.user}
            <p className={"center modal-title"}>{t("Sign in using e-mail")}</p>

            <Formik
                initialValues={{emailAddress: authValues.emailAddress, password: authValues.password}}
                onSubmit={(values) => submit(values)}
            >
                {({
                      errors,
                      values,
                      handleChange,
                      touched,
                      setFieldTouched,
                      handleSubmit,
                  }) => (
                    <>
                        <TextInput
                            placeholder={t("Email address")}
                            value={values.emailAddress}
                            onChange={handleChange("emailAddress")}
                            onBlur={() => setFieldTouched("emailAddress")}
                            error={{
                                message: errors.emailAddress,
                                visible: touched.emailAddress,
                            }}
                            ltr
                        />
                        <TextInput
                            placeholder={t("Password")}
                            value={values.password}
                            onChange={handleChange("password")}
                            error={{
                                message: errors.password,
                                visible: touched.password,
                            }}
                            isPassword
                        />
                        <p onClick={() => toggleModals({
                            ...modalsDisplayStatus,
                            emailLogin: false,
                            forgetPasswordRequest: true
                        })}>{t("Forgot password")}</p>
                        <button onClick={() => handleSubmit()} type={"button"} className={"button primary"}>
                            {loading
                                ? <CircleLoading/>
                                : t("Log in")}
                        </button>
                        <button type={'button'}
                                onClick={() => toggleModals({...modalsDisplayStatus, auth: true, emailLogin: false})}
                                className={"button center"} style={{
                            background: 'none',
                            border: 'none',
                            color: '#516975',
                            fontSize: '14px',
                            fontWeight: 500
                        }}>{t("Sign in using phone number")}</button>
                    </>
                )}
            </Formik>
        </ModalWrapper>
    );
};

export default EmailLoginModal;
