import "./PlayerAndDetails.scss";
// Sub components
import Player from "./sub-components/Player";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
// Utils

const PlayerAndDetails = ({ currentEpisode }) => {
  const { t } = useTranslation();
  return (
    <div className={"player-and-details no-scrollbar"}>
      <img alt={"Podcast"} src={currentEpisode?.cover} />

      {currentEpisode?.is_purchased || currentEpisode?.purchase_type === "free" ? <>
          <Player url={currentEpisode?.main} />
          <Link class={"button primary btn-buy-podcast"}
                to={`/products/${currentEpisode?.slug}`}>{t("Details of Podcast")}</Link>
        </> :
        <Link class={"button primary btn-buy-podcast"}
              to={`/products/${currentEpisode?.slug}`}>{t("Buy Podcast")}</Link>}

      <p>{currentEpisode.title}</p>
      <p>{t("Episode")}: {currentEpisode.episode}</p>

      <p>{currentEpisode.description}</p>
    </div>
  );
};

export default PlayerAndDetails;
