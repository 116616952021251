import React from "react";
import {useTranslation} from "react-i18next";
import "./css/Header.scss";
// Utils
import icons from "../../../../../utils/icons";
import {useSelector} from "react-redux";

const Header = ({dayTime, weekTime, monthTime}) => {
    const {t} = useTranslation();
    const client = useSelector(state => state.client)

    return (
        <div className={"study-time-report-header"}>
            <div>
                <p>{t("Report card")}</p>
                <div>
                    <p>{client.first_name + " " + client.last_name}</p>
                    {icons.user}
                </div>
            </div>

            <div>
                <p>{t("Today")}</p>
                <p>{dayTime}</p>
            </div>

            <div>
                <p>{t("This week")}</p>
                <p>{weekTime}</p>
            </div>

            <div>
                <p>{t("This month")}</p>
                <p>{monthTime}</p>
            </div>
        </div>
    );
};

export default Header;
