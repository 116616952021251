import { useState } from "react";
import { useClickOutside } from "@mantine/hooks";
import "./Podcast.scss";
// Sub components
import Episodes from "./sub-components/episodes/Episodes";
import PlayerAndDetails from "./sub-components/player-and-details/PlayerAndDetails";
// Utils
import icons from "../../../utils/icons";

const PodcastModal = ({ toggle, episodes, hasMore, onLoadMore,loadMoreLoading }) => {
  const podcastModalRef = useClickOutside(() => toggle(false));
  const [currentEpisode, setCurrentEpisode] = useState(episodes[0]);

  return (
    <div className={"podcast-modal"}>
      <div ref={podcastModalRef}>
        <button type={"button"} onClick={() => toggle(false)}>
          {icons.cross}
        </button>
        <Episodes setCurrentEpisode={setCurrentEpisode} episodes={episodes} currentEpisode={currentEpisode}
                  hasMore={hasMore}
                  onLoadMore={onLoadMore} loadMoreLoading={loadMoreLoading}/>
        <PlayerAndDetails currentEpisode={currentEpisode} />
      </div>
    </div>
  );
};

export default PodcastModal;
