import React, {useState} from "react";
import {useClickOutside} from "@mantine/hooks";
import "./css/FilterDropdown.scss";
// Common components
import Checkbox from "../../../common/others/Checkbox";
// Utils
import icons from "../../../../utils/icons";

const FilterDropdown = ({
                            title,
                            value,
                            updateValue,
                            options,
                            singleMode,
                            style = {}
                        }) => {
    const [showDropdown, setShowDropdown] = useState(null);
    const dropdownRef = useClickOutside(
        () => showDropdown && setShowDropdown(false)
    );
    const className = `filters-dropdown ${
        showDropdown === true
            ? "active"
            : showDropdown === false
                ? "inactive"
                : undefined
    }`;
    return (
        <div className={className} ref={dropdownRef} style={style}>
            <div onClick={() => setShowDropdown(!showDropdown)}>
                <p>{singleMode && value ? (options?.find(item => item?.key == value)?.value || title) : title}</p>
                {icons.arrowhead}
            </div>

            <div className={"no-scrollbar"}>
                {singleMode
                    ? options.map((item) => (
                        <p
                            key={item.key}
                            onClick={() => {
                                updateValue(item?.key);
                                setShowDropdown(false);
                            }}
                        >
                            {item.value}
                        </p>
                    ))
                    : options.map((item) => (
                        <Checkbox key={item.key} checked={!!value?.find(v => v == item?.key)} id={item?.key}
                                  title={item?.value} onChange={() => {
                            const exists = !!value?.find(v => v == item?.key);
                            if (exists) {
                                updateValue(value.filter(v => v != item?.key))
                            } else {
                                updateValue([...value, item?.key])
                            }
                        }}/>
                    ))}
            </div>
        </div>
    );
};

export default FilterDropdown;
