import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import "./Wallet.scss";
// Common components
import TextInput from "../../../common/others/TextInput";
// Modals
// Utils
import BlueWallet from "./../../../../assets/images/blue-wallet.svg";
import RedWallet from "./../../../../assets/images/red-wallet.svg";
import GrayWallet from "./../../../../assets/images/gray-wallet.svg";
import {ReactComponent as WalletIcon} from "./../../../../assets/icons/wallet-white.svg";
import {ReactComponent as PlusIcon} from "./../../../../assets/icons/plus.svg";
import axios from "../../../../utils/http";
import API from "../../../../utils/api";
import {getRouter} from "../../../../utils/helpers";
import CircleLoading from "../../../common/others/CircleLoading";
import {toast} from "react-toastify";
import ModalWrapper from "../../../common/others/ModalWrapper";
import {useSelector} from "react-redux";
import icons from "../../../../utils/icons";
import CreditCard from "./sub-components/CreditCard";

const Wallet = () => {
    const {t} = useTranslation();
    const {userType} = useParams();
    const [amount, setAmount] = useState("");
    const [creditCardNumber, setCreditCardNumber] = useState("");
    const [creditCardOwner, setCreditCardOwner] = useState("");
    const [wallets, setWallets] = useState([]);
    const [creditCards, setCreditCards] = useState([]);
    const [selectedCreditCard, setSelectedCreditCard] = useState(null);
    const [selectedWallet, setSelectedWallet] = useState(null);
    const [loading, setLoading] = useState(false);
    const [receiptModal, setReceiptModal] = useState(false);
    const [receiptFile, setReceiptFile] = useState(null);
    const [creditCardModal, setCreditCardModal] = useState(null);
    const backgrounds = [BlueWallet, RedWallet, GrayWallet];
    const client = useSelector(state => state.client);


    useEffect(() => {
        getWallets();
        if (client.role !== 'user')
            getCreditCards();
    }, []);

    const getWallets = async () => {
        try {
            const {data} = await axios.get(getRouter(API.dashboard.wallets, {
                userType: userType.toLowerCase()
            }));

            if (data.success) {
                setWallets(data.data.wallets);
            }
        } catch (e) {

        }
    };

    const getCreditCards = async () => {
        try {
            const {data} = await axios.get(getRouter(API.dashboard.creditCards, {
                userType: userType.toLowerCase()
            }));

            if (data.success) {
                setCreditCards(data.data);
            }
        } catch (e) {

        }
    };

    const handleSelectWalletCharge = (wallet) => {
        setSelectedWallet(wallet);
        if (wallet.receipt) {
            setReceiptModal(true);
        }
    };

    const handleWalletCharge = async () => {
        if (loading)
            return;
        setLoading(true);
        try {
            const {data} = await axios.post(getRouter(API.dashboard.chargeWallet, {
                userType: userType.toLocaleString(),
                walletId: selectedWallet.id
            }), {
                amount,
                receipt: receiptFile
            }, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            });

            if (data.success) {
                if (data?.data?.link) {
                    toast(t("Your Request Submitted. You are now Redirecting to Payment Gateway!"), {type: "success"});
                    window.location.href = data.data.link;
                } else {
                    toast(t("Your Request has been Submitted and After Confirmation, Your Wallet Will be Charged!"), {type: "success"});
                }
                setAmount("");
                setSelectedWallet(null);
                setReceiptModal(false);
                setReceiptFile(null);
            }
        } catch (e) {

        }

        setLoading(false);
    };

    const handleStoreEditCreditCard = async () => {
        if (loading)
            return;
        setLoading(true);
        try {
            let res = null;
            if (selectedCreditCard) {
                res = await axios.put(getRouter(API.dashboard.creditCardsAction, {
                        userType: userType.toLowerCase(),
                        creditCard: selectedCreditCard.id
                    }),
                    {
                        currency: creditCardModal,
                        number: creditCardNumber,
                        owner: creditCardOwner
                    }
                );
            } else {
                res = await axios.post(getRouter(API.dashboard.creditCards, {
                        userType: userType.toLowerCase()
                    }),
                    {
                        currency: creditCardModal,
                        number: creditCardNumber,
                        owner: creditCardOwner
                    }
                );
            }

            const {data} = res;

            if (data.success) {
                if (selectedCreditCard) {
                    setCreditCards(creditCards.map(cc => cc.id == selectedCreditCard.id ? {
                        ...cc,
                        card_number: creditCardNumber,
                        card_owner: creditCardOwner
                    } : cc));
                } else {
                    setCreditCards([...creditCards, data.data]);
                }
                setCreditCardNumber("");
                setCreditCardOwner("");
                setSelectedCreditCard(null);
            }
        } catch (e) {

        }

        setLoading(false);
    };

    const handleDeleteCreditCard = async (creditCard) => {
        try {
            const {data} = await axios.delete(getRouter(API.dashboard.creditCardsAction, {
                userType: userType.toLowerCase(),
                creditCard: creditCard.id
            }));

            if (data.success) {
                setCreditCardNumber("");
                setCreditCardOwner("");
                setCreditCards(creditCards.filter(cc => cc.id != creditCard.id));
            }
        } catch (e) {

        }
    };


    return (
        <>
            <div className={"wallets"}>
                {wallets.map((wallet, index) => <div key={wallet.id} className={"wallet"}>
                    <div className="wallet-inner">
                        {selectedWallet === wallet && !selectedWallet.receipt ?
                            <>
                                <div className="wallet-info">
                                    <h2>
                                        <WalletIcon/>
                                        {t("Charge Wallet")}: {wallet.title}
                                    </h2>
                                    {client.role === "user" ?
                                        <button className={"btn-charge-wallet selected"} onClick={() => {
                                            setSelectedWallet(null);
                                            setAmount("");
                                        }
                                        }>
                                            <PlusIcon/>
                                        </button> : null}
                                </div>
                                <div className={"wallet-charge"}>
                                    <TextInput
                                        placeholder={t("Stock increase rate")}
                                        value={amount}
                                        onChange={(e) => setAmount(e.target.value)}
                                        number
                                    />
                                    <button className={"btn-wallet-charge"} onClick={handleWalletCharge}>
                                        {loading ? <CircleLoading/> : t("Charge Wallet")}
                                    </button>
                                </div>
                                <img src={backgrounds[index]} alt=""/>
                            </>
                            : <>
                                <div className="wallet-info">
                                    <h2>
                                        <WalletIcon/>
                                        {t("Wallet")}: {wallet.title}
                                    </h2>
                                    {client.role === "user" ?
                                        <button className={"btn-charge-wallet"}
                                                onClick={() => handleSelectWalletCharge(wallet)}>
                                            <PlusIcon/>
                                        </button> : null}

                                    {client.role === "publisher" || client.role === "marketer" ?
                                        <button className={"btn-charge-wallet"}
                                                onClick={() => setCreditCardModal(wallet.currency)}>
                                            <WalletIcon/>
                                        </button> : null}
                                </div>
                                <h3 className={"wallet-price"} style={{marginRight: "auto"}}>{wallet.amount_format}</h3>
                                <img src={backgrounds[index]}/>
                            </>
                        }
                    </div>
                </div>)}
            </div>
            {(receiptModal && selectedWallet) &&
                <ModalWrapper className={"auth-modal"} toggle={() => setReceiptModal(false)}>
                    <p className={"center modal-title"}>{t("Increase stock")}: {selectedWallet?.title}</p>
                    <p className={"modal-tip"}>
                        {t("In case you want to increase your stock, transfer the intended amount to the account below, and send us the receipt. Your stock will increase within 24 hours.")}
                    </p>
                    <p className={"modal-tip"}>
                        {selectedWallet.credit_card?.card_owner}
                    </p>
                    <p className={"modal-tip"}>
                        {selectedWallet.credit_card?.card_number}
                    </p>

                    <div>
                        <TextInput
                            placeholder={t("Stock increase rate")}
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            number
                        />
                        <h3 className={"upload-file-title"}>{t("File Upload")}</h3>
                        <div className={"image-preview-container"}>
                            {receiptFile ? <img src={URL.createObjectURL(receiptFile)} alt=""/> : null}
                        </div>

                        <div className={"receipt-file-container"}>
                            <label>
                                <input type="file" accept={"image/jpeg,image/jpg,image/png"}
                                       onChange={e => setReceiptFile(e.target.files[0])}/>
                                {t("Upload file (PNG, JPG)")}
                            </label>
                        </div>
                        <div>
                            <button onClick={handleWalletCharge} className={"btn-submit-receipt"}>{loading ?
                                <CircleLoading/> : t("Confirm and send")}</button>
                        </div>
                    </div>

                </ModalWrapper>}

            {creditCardModal &&
                <ModalWrapper className={"auth-modal"}
                              toggle={() => {
                                  setCreditCardNumber("");
                                  setCreditCardOwner("");
                                  setCreditCardModal(null);
                              }}>
                    <p className={"center modal-title"}>{t("Credit Cards")}: {t(creditCardModal)}</p>

                    <div style={{display: "flex", flexDirection: "column", gap: 16}}>
                        <TextInput
                            placeholder={t("Credit Card Number")}
                            value={creditCardNumber}
                            onChange={(e) => setCreditCardNumber(e.target.value)}
                            number
                        />
                        <TextInput
                            placeholder={t("Credit Card Owner")}
                            value={creditCardOwner}
                            onChange={(e) => setCreditCardOwner(e.target.value)}
                            number
                        />
                        <div style={{display: "flex", gap: 16}}>
                            <button onClick={handleStoreEditCreditCard} className={"btn-submit-receipt"}>{loading ?
                                <CircleLoading/> : t(selectedCreditCard ? "Edit" : "Submit")}</button>
                            {selectedCreditCard ?
                                <button onClick={() => {
                                    setCreditCardNumber("");
                                    setCreditCardOwner("");
                                    setSelectedCreditCard(null);
                                }} className={"btn-submit-receipt"}>{loading ?
                                    <CircleLoading/> : t("Cancel")}</button> : null}
                        </div>
                    </div>
                    {creditCards.length ? <div style={{display: "flex", flexDirection: "column", gap: 16}}>
                        {creditCards.filter(creditCard => creditCard.currency == creditCardModal).map((creditCard, index) =>
                            <CreditCard
                                key={index}
                                creditCard={creditCard}
                                onEdit={(cc) => {
                                    setCreditCardNumber(cc.card_number);
                                    setCreditCardOwner(cc.card_owner);
                                    setSelectedCreditCard(cc);
                                }}
                                onDelete={(cc) => handleDeleteCreditCard(cc)}
                            />)}
                    </div> : null}

                </ModalWrapper>}
        </>

    );


    // return (
    //   <div className={"wallet"}>
    //     <div>
    //       <div className={"right-section"}>
    //         <div onClick={() => setShowAddNewCardModal(true)}>{icons.plus}</div>
    //
    //         <div>
    //           {icons.wallet}
    //           <p>{t("Toman wallet")}</p>
    //           <p>
    //             {number.toLocaleString()} {t("Toman")}
    //           </p>
    //         </div>
    //
    //         <Card />
    //
    //         <TextInput
    //           placeholder={t("Increase amount")}
    //           value={tomanIncreaseAmount}
    //           updateValue={(e) => setTomanIncreaseAmount(e.target.value)}
    //           number
    //         />
    //
    //         <button
    //           type={"button"}
    //           className={"button filled"}
    //           onClick={() => setShowIncreaseStockModal(true)}
    //         >
    //           {t("Increase inventory")}
    //         </button>
    //       </div>
    //
    //       {userType === "user" && <IncreaseStock status={"visible"} />}
    //     </div>
    //
    //     <div className={"left-section"}>
    //       <div onClick={() => setShowAddNewCardModal(true)}>{icons.plus}</div>
    //
    //       <div>
    //         {icons.wallet}
    //         <p>{t("Dollar wallet")}</p>
    //         <p>
    //           {number.toLocaleString()} {t("Dollars")}
    //         </p>
    //       </div>
    //
    //       <Card default />
    //       <Card />
    //
    //       <TextInput
    //         placeholder={t("Increase amount")}
    //         value={dollarIncreaseAmount}
    //         updateValue={(e) => setDollarIncreaseAmount(e.target.value)}
    //         number
    //       />
    //
    //       <button
    //         type={"button"}
    //         className={"button filled"}
    //         onClick={() => setShowIncreaseStockModal(true)}
    //       >
    //         {t("Increase inventory")}
    //       </button>
    //     </div>
    //
    //     {userType === "user" && <IncreaseStock status={"invisible"} />}
    //     {showAddNewCardModal && (
    //       <AddNewCardModal toggleDisplay={setShowAddNewCardModal} />
    //     )}
    //     {showIncreaseStockModal && (
    //       <IncreaseStockModal toggleDisplay={setShowIncreaseStockModal} />
    //     )}
    //   </div>
    // );
};

export default Wallet;
