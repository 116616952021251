const Banners = [
  {
    id: 1,
    product: null,
    banner_url: '/banner3.png',
    mobile_banner_url: '/banner3.png',
  },
  {
    id: 2,
    product: null,
    banner_url: '/banner2.png',
    mobile_banner_url: '/banner2.png',
  },
  {
    id: 3,
    product: null,
    banner_url: '/banner1.png',
    mobile_banner_url: '/banner1.png',
  },
];
export default Banners;
