import React from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
// Components common between home sections
import SliderButtons from './common/SliderButtons';
import Product, { ProductSkeleton } from '../../common/others/Product';
// Utils
import products from '../../../utils/fake-data/products';
import productSliderBreakpointsConfig from '../../../utils/configs/productSliderBreakpoints';

export const ProductSliderSkeleton = ({ type }) => {
  const { i18n } = useTranslation();

  return (
    <Swiper
      className={`product-and-site-suggestions-slider`}
      dir={i18n.language !== 'kuk' ? 'rtl' : 'ltr'}
      loop={true}
      modules={[Pagination]}
      pagination={{ clickable: true }}
      slidesPerView={'auto'}
      breakpoints={productSliderBreakpointsConfig}
    >
      <SwiperSlide>
        <ProductSkeleton type='electronic_book' />
      </SwiperSlide>
      <SwiperSlide>
        <ProductSkeleton type='electronic_book' />
      </SwiperSlide>
      <SwiperSlide>
        <ProductSkeleton type='electronic_book' />
      </SwiperSlide>
      <SwiperSlide>
        <ProductSkeleton type='electronic_book' />
      </SwiperSlide>
      <SwiperSlide>
        <ProductSkeleton type='electronic_book' />
      </SwiperSlide>
      <SwiperSlide>
        <ProductSkeleton type='electronic_book' />
      </SwiperSlide>

      <SliderButtons />
    </Swiper>
  );
};

const ProductSlider = ({
  products,
  type,
  onRemoveLibrary,
  onReadOnline,
  ...props
}) => {
  const { i18n } = useTranslation();

  return (
    <Swiper
      className={`product-and-site-suggestions-slider electronic_book  `}
      dir={i18n.language !== 'kuk' ? 'rtl' : 'ltr'}
      modules={[Pagination]}
      pagination={{ clickable: true }}
      slidesPerView={'auto'}
      breakpoints={productSliderBreakpointsConfig}
    >
      {products.map((product, index) => (
        <SwiperSlide style={{ alignSelf: 'flex-end' }} key={index}>
          <Product
            type={type || product.type}
            data={product}
            onRemoveLibrary={onRemoveLibrary}
            onReadOnline={onReadOnline}
            {...props}
          />
        </SwiperSlide>
      ))}

      <SliderButtons />
    </Swiper>
  );
};

export default ProductSlider;
