import React from "react";
import "./css/Suggestions.scss";
import { useTranslation } from "react-i18next";
// Common components
import Product from "../../../common/others/Product";
// Utils
import products from "../../../../utils/fake-data/products";
import ProductSlider from "../../../home-related/home/ProductSlider";

const Suggestions = ({ otherProducts, collectionProducts, publisherProducts }) => {
  const { t } = useTranslation();

  return (
    <div className={"suggestions"}>
      {otherProducts.length ? <>
        <p>{t("What else did the buyers of this book purchase?")}</p>
        <ProductSlider products={otherProducts} />
      </> : null}

      {
        collectionProducts.length ? <>
          <p>{t("Other Products in this collection")}</p>
          <ProductSlider products={collectionProducts} />
        </> : null
      }

      {
        publisherProducts.length ? <>
          <p>{t("Other books by this publisher")}</p>
          <ProductSlider products={publisherProducts} />
        </> : null
      }
    </div>
  );
};

export default Suggestions;
