import React from "react";
import "./css/RadioButton.scss";

const RadioButton = (props) => {
  return (
    <label className={"radio-button"} htmlFor={`radio-button-${props.id}`}>
      <input
        type={"radio"}
        name={props.group}
        id={`radio-button-${props.id}`}
        checked={props.checked}
        onChange={props.updateValue}
      />
      <div>
        <div />
      </div>
      <p>{props.title}</p>
    </label>
  );
};

export default RadioButton;
