import React, { useEffect, useState } from "react";
import "./css/Settings.scss";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
// Common components
import RadioButton from "../../../common/others/RadioButton";
import TextInput from "../../../common/others/TextInput";
import axios from "./../../../../utils/http";
import { getRouter } from "../../../../utils/helpers";
import Api from "../../../../utils/api";
import { camelCase, snakeCase } from "lodash";
import { toast } from "react-toastify";
import loading from "../../../modals/Loading";
import CircleLoading from "../../../common/others/CircleLoading";

const Settings = () => {
  const { userType } = useParams();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatNewPassword] = useState("");
  const [settings, setSettings] = useState({});
  const [receiveEmail, setReceiveEmail] = useState(true);
  const [receiveRegistration, setReceiveRegistration] = useState(true);
  const [receiveChanges, setReceiveChanges] = useState(true);
  const [receiveProductEdit, setReceiveProductEdit] = useState(true);
  const [receiveUserComments, setReceiveUserComments] = useState(true);

  const getSettings = async () => {
    try {
      const { data } = await axios.get(
        getRouter(Api.dashboard.settings, {
          userType: userType.toLowerCase(),
        })
      );

      if (!data.success) return;

      const settings = {};

      for (const setting in data.data) {
        settings[camelCase(setting)] = data.data[setting];
      }
      setSettings(settings);
    } catch (e) {}
  };

  useEffect(() => {
    getSettings();
  }, []);

  const handleUpdateSettings = async (key, value) => {
    console.log(key, value);
    setSettings({
      ...settings,
      [key]: value,
    });
    try {
      const { data } = await axios.put(
        getRouter(Api.dashboard.settings, {
          userType: userType.toLowerCase(),
        }),
        {
          [snakeCase(key)]: +value,
        }
      );

      if (!data.success) {
        setSettings({
          ...settings,
          [key]: !value,
        });
      } else {
        toast(data.message, { type: "success" });
      }
    } catch (e) {}
  };

  const handleResetPassword = async () => {
    if (loading) return;

    setLoading(true);
    try {
      const { data } = await axios.put(
        getRouter(Api.dashboard.resetPassword, {
          userType: userType.toLowerCase(),
        }),
        {
          current_password: currentPassword,
          password: newPassword,
          password_confirmation: repeatNewPassword,
        }
      );

      if (!data.success) return;

      setCurrentPassword("");
      setNewPassword("");
      setRepeatNewPassword("");

      toast(data.message, { type: "success" });
    } catch (e) {}
    setLoading(false);
  };

  return (
    <div className={"settings"}>
      <div>
        <p>{t("Change password")}</p>
        <TextInput
          placeholder={t("Current password")}
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          isPassword
        />
        <TextInput
          placeholder={t("New password")}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          isPassword
        />
        <TextInput
          placeholder={t("Repeat new password")}
          value={repeatNewPassword}
          onChange={(e) => setRepeatNewPassword(e.target.value)}
          isPassword
        />
        <button
          onClick={handleResetPassword}
          type={"button"}
          className={"button primary"}
        >
          {loading ? <CircleLoading /> : t("Confirm")}
        </button>
      </div>

      <div>
        <p>{t("Receive email")}</p>
        <p>
          {userType === "user"
            ? t(
                "Do you want to be informed about the latest books, offers and new versions of book reader software by receiving emails?"
              )
            : t(
                "Do you want registration, changes, user comments, etc. to be emailed to you?"
              )}
        </p>

        {userType === "user" || userType === "marketer" ? (
          <div>
            <RadioButton
              group={"receive-email"}
              id={"no"}
              title={t("Yes")}
              checked={!!settings?.receiveEmail}
              updateValue={() => handleUpdateSettings("receiveEmail", true)}
            />
            <RadioButton
              group={"receive-email"}
              id={"yes"}
              title={t("No")}
              checked={!!!settings?.receiveEmail}
              updateValue={() => handleUpdateSettings("receiveEmail", false)}
            />
          </div>
        ) : (
          <>
            <div>
              <p>{t("Registration")}</p>
              <RadioButton
                group={"receive-registration"}
                id={"no"}
                title={t("Yes")}
                checked={!!settings?.receiveSubmitEmail}
                updateValue={() =>
                  handleUpdateSettings("receiveSubmitEmail", true)
                }
              />
              <RadioButton
                group={"receive-registration"}
                id={"yes"}
                title={t("No")}
                checked={!!!settings?.receiveSubmitEmail}
                updateValue={() =>
                  handleUpdateSettings("receiveSubmitEmail", false)
                }
              />
            </div>
            <div>
              <p>{t("Changes")}</p>
              <RadioButton
                group={"receive-changes"}
                id={"no2"}
                title={t("Yes")}
                checked={!!settings?.receiveChangeEmail}
                updateValue={() =>
                  handleUpdateSettings("receiveChangeEmail", true)
                }
              />
              <RadioButton
                group={"receive-changes"}
                id={"yes2"}
                title={t("No")}
                checked={!!!settings?.receiveChangeEmail}
                updateValue={() =>
                  handleUpdateSettings("receiveChangeEmail", false)
                }
              />
            </div>
            <div>
              <p>{t("Edit products")}</p>
              <RadioButton
                group={"receive-edit-products"}
                id={"no3"}
                title={t("Yes")}
                checked={!!settings?.receiveChangeProductEmail}
                updateValue={() =>
                  handleUpdateSettings("receiveChangeProductEmail", true)
                }
              />
              <RadioButton
                group={"receive-edit-products"}
                id={"yes3"}
                title={t("No")}
                checked={!!!settings?.receiveChangeProductEmail}
                updateValue={() =>
                  handleUpdateSettings("receiveChangeProductEmail", false)
                }
              />
            </div>
            <div>
              <p>{t("User comments")}</p>
              <RadioButton
                group={"receive-user-comments"}
                id={"no4"}
                title={t("Yes")}
                checked={!!settings?.receiveUserCommentEmail}
                updateValue={() =>
                  handleUpdateSettings("receiveUserCommentEmail", true)
                }
              />
              <RadioButton
                group={"receive-user-comments"}
                id={"yes4"}
                title={t("No")}
                checked={!!!settings?.receiveUserCommentEmail}
                updateValue={() =>
                  handleUpdateSettings("receiveUserCommentEmail", false)
                }
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Settings;
