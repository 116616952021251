import * as Yup from "yup";

// Authentication
// // Auth modal
export const authSchemas = Yup.object().shape({
  dialCode: Yup.number().required("* Required"),
  phoneNumber: Yup.number().required("* Required"),
  terms: Yup.boolean(),
  inviteCode: Yup.string().nullable()
    .min(10, "The invite code must be 10 characters")
    .max(10, "The invite code must be 10 characters")
});
// // Email login modal
export const emailLoginSchemas = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("* Required"),
  password: Yup.string().required("* Required")
});

// Book request form
export const bookRequestFormSchemas = Yup.object().shape({
  name: Yup.string().required("* Required"),
  author: Yup.string().required("* Required"),
  publisher: Yup.string().required("* Required")
});

// Contact us
export const contactUsSchemas = Yup.object().shape({
  title: Yup.string().required("* Required"),
  dialCode: Yup.string().required("* Required"),
  phoneNumber: Yup.string().required("* Required"),
  emailAddress: Yup.string().email().required("* Required")
});

// Work with us
export const workWithUsSchemas = Yup.object().shape({
  firstName: Yup.string().required("* Required"),
  lastName: Yup.string().required("* Required"),
  nationalID: Yup.string().required("* Required"),
  dialCode: Yup.string().required("* Required"),
  phoneNumber: Yup.string().required("* Required"),
  emailAddress: Yup.string().email().required("* Required"),
  birthday: Yup.string().required("* Required")
});

// Marketer register
export const marketerRegisterSchemas = Yup.object().shape({
  firstName: Yup.string().required("* Required"),
  lastName: Yup.string().required("* Required"),
  nationalID: Yup.string().required("* Required"),
  dialCode: Yup.string().required("* Required"),
  phoneNumber: Yup.string().required("* Required"),
  emailAddress: Yup.string().email().required("* Required"),
});

export const publisherRegisterSchemas = Yup.object().shape({
  firstName: Yup.string().required("* Required"),
  lastName: Yup.string().required("* Required"),
  nationalID: Yup.string().required("* Required"),
  dialCode: Yup.string().required("* Required"),
  phoneNumber: Yup.string().required("* Required"),
  emailAddress: Yup.string().email().required("* Required"),
  birthday: Yup.string().required("* Required"),
  phone: Yup.string().required("* Required"),
  publisherName: Yup.string().required("* Required"),
  publisherDescription: Yup.string().required("* Required"),
  publisherEmail: Yup.string().email().required("* Required"),
  publisherCode: Yup.string().required("* Required"),
  publisherAddress: Yup.string().required("* Required"),
  postBox: Yup.string().required("* Required"),
  postalCode: Yup.string().required("* Required"),
  logo: Yup.string().required("* Required"),
  referral: Yup.string().nullable()
    .min(10, "The invite code must be 10 characters")
    .max(10, "The invite code must be 10 characters")
});
