import React, { useEffect, useState } from "react";
import "./css/Library.scss";
import "./css/LibraryProducts.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import axios from "./../../utils/http";
import API from "./../../utils/api";
import { getRouter } from "../../utils/helpers";
import Product, { ProductSkeleton } from "../../components/common/others/Product";

const Bookmark = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState("my-favorite-books");
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getBookmarks();
  }, []);

  const getBookmarks = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(API.bookmarks.bookmarks);

      if (data.success) {
        setProducts(data.data.bookmarks);
      }

    } catch (e) {

    }
    setLoading(false);
  };
  const removeFromBookmark = async (product) => {
    try {
      const { data } = await axios.post(getRouter(API.bookmarks.removeFromBookmark, {
        productId: product.id
      }));

      if (data.success) {
        await getBookmarks();
      }

    } catch (e) {

    }
  };

  if (loading) return (<>
    <h1 className={"library-title"}>کتابخانه من</h1>
    <p className={"library-description"}>می توانید لیست تمام کتاب های خود را ببینید و ویرایش های لازم را انجام دهید</p>
    <div className={"library"}>
      <div className={"tab-selector-container"}>
        <p
          className={currentTab === "my-books" ? "active" : undefined}
          onClick={() => navigate("/library")}
        >
          {t("My books")}
        </p>
        <p
          className={currentTab === "my-favorite-books" ? "active" : undefined}
        >
          {t("My favorite books")}
        </p>
      </div>
      <div className={"library-products"}>
        <ProductSkeleton type={"electronic_book"} />
        <ProductSkeleton type={"electronic_book"} />
        <ProductSkeleton type={"electronic_book"} />
        <ProductSkeleton type={"electronic_book"} />
        <ProductSkeleton type={"electronic_book"} />
        <ProductSkeleton type={"electronic_book"} />
        <ProductSkeleton type={"electronic_book"} />
        <ProductSkeleton type={"electronic_book"} />
        <ProductSkeleton type={"electronic_book"} />
        <ProductSkeleton type={"electronic_book"} />
        <ProductSkeleton type={"electronic_book"} />
        <ProductSkeleton type={"electronic_book"} />
        <ProductSkeleton type={"electronic_book"} />
        <ProductSkeleton type={"electronic_book"} />
        <ProductSkeleton type={"electronic_book"} />
        <ProductSkeleton type={"electronic_book"} />
        <ProductSkeleton type={"electronic_book"} />
        <ProductSkeleton type={"electronic_book"} />
        <ProductSkeleton type={"electronic_book"} />
        <ProductSkeleton type={"electronic_book"} />
        <ProductSkeleton type={"electronic_book"} />
      </div>
    </div>
  </>);

  return (<>
    <h1 className={"library-title"}>کتابخانه من</h1>
    <p className={"library-description"}>می توانید لیست تمام کتاب های خود را ببینید و ویرایش های لازم را انجام دهید</p>
    <div className={"library"}>
      <div className={"tab-selector-container"}>
        <p
          className={currentTab === "my-books" ? "active" : ""}
          onClick={() => navigate("/library")}
        >
          {t("My books")}
        </p>
        <p
          className={currentTab === "my-favorite-books" ? "active" : ""}
        >
          {t("My favorite books")}
        </p>
      </div>

      {/*<InfiniteScroll next={removeFromBookmark} hasMore={!!nextPageUrl} loader={<CircleLoading />} dataLength={total}>*/}
      <div className={"library-products"}>

        {products.map(product => <Product type={product.type} data={product}
                                          onRemoveBookmark={(p) => removeFromBookmark(p)} />)}
      </div>
      {/*</InfiniteScroll>*/}
    </div>
  </>);
};

export default Bookmark;
