import React, { useEffect, useState } from "react";
import "./css/ContactUs.scss";
import { useTranslation } from "react-i18next";
// Common components
import Card from "../../components/common/others/Card";
import TextInput from "../../components/common/others/TextInput";
import PhoneNumberInput from "../../components/common/phone-number-input/PhoneNumberInput";
// Sub components
import CollapsibleMenu from "../../components/home-related/contact-us/CollapsibleMenu";
// Form validation
import { Formik } from "formik";
import { contactUsSchemas } from "../../utils/formValidationSchemas";
// Utils
import contactUsDropdownsConfig from "../../utils/configs/contactUsDropdowns";
import tempMedia from "../../utils/tempMedia";
import axios from "../../utils/http";
import Api from "../../utils/api";
import RadioButton from "../../components/common/others/RadioButton";
import DropdownMenu from "../../components/common/others/DropdownMenu";
import CircleLoading from "../../components/common/others/CircleLoading";
import { toast } from "react-toastify";
import { value } from "lodash/seq";

const ContactUs = () => {
  const { t } = useTranslation();
  const [os, setOs] = useState("");
  const [description, setDescription] = useState("");
  const [contactInfo, setContactInfo] = useState();
  const [selectedProblem, setSelectedProblem] = useState();
  const [problemFile, setProblemFile] = useState();
  const [loading, setLoading] = useState(false);
  const [faqs, setFaqs] = useState([]);

  const problems = [t("Buying issue"), t("Payment issue")];

  useEffect(() => {
    getContactUsInfo();
  }, []);

  const getContactUsInfo = async () => {
    try {
      const { data } = await axios.get(Api.public.contactUs);

      if (!data.success)
        return;

      setContactInfo(data.data.settings);
      const faqGroups = data.data.faq_groups.map(faqGroup => {
        return {
          key: faqGroup.id,
          value: faqGroup.title,
          subOptions: faqGroup.faqs.map(faq => ({
            key: faq.id,
            value: faq.title,
            innerValue: faq.content
          }))
        };
      });
      setFaqs(faqGroups);
    } catch (e) {

    }
  };

  async function submit(values) {
    if (loading)
      return;
    setLoading(true);
    try {
      const { data } = await axios.post(Api.public.contactUs, {
        os: os,
        title: values.title,
        problem: selectedProblem,
        description: description,
        country_code: values.dialCode.replace("+", ""),
        mobile: values.phoneNumber,
        email: values.emailAddress,
        file: problemFile
      }, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      if (data.success) {
        toast(data.message, { type: "success" });
      }
    } catch (e) {

    }
    setLoading(false);
  }

  return (
    <Card className={"contact-us"}>
      <p className={"home-title m16"}>{t("Contact us")}</p>
      <p>{t("Company Address:")} {contactInfo?.address} - <span
        style={{ display: "inline-block", direction: "ltr" }}>{contactInfo?.mobile}</span></p>
      <p>
        {t("For telephone support, in all day week (from ")} {contactInfo?.work_hour}) {t("Call")} <span style={{display:'inline-block', direction:'ltr'}}>{contactInfo?.phone}</span> {t("We are with you")}
      </p>

      <div>
        <div>
          <Formik
            initialValues={{
              title: "",
              dialCode: "+98",
              phoneNumber: "",
              emailAddress: ""
            }}
            validationSchema={contactUsSchemas}
            onSubmit={(values) => submit(values)}
          >
            {({
                errors,
                values,
                handleChange,
                setFieldValue,
                touched,
                setFieldTouched,
                handleSubmit
              }) => (
              <>
                <div style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  maxWidth: 436
                }}>
                  <RadioButton id={1} checked={os === "Reader"} updateValue={() => setOs("Reader")} group={"os"}
                               title={"Reader"} />
                  <RadioButton id={2} checked={os === "Windows"} updateValue={() => setOs("Windows")} group={"os"}
                               title={"Windows"} />
                  <RadioButton id={3} checked={os === "Android"} updateValue={() => setOs("Android")} group={"os"}
                               title={"Android"} />
                  <RadioButton id={4} checked={os === "IOS"} updateValue={() => setOs("IOS")} group={"os"}
                               title={"IOS"} />
                </div>
                <TextInput
                  placeholder={t("Title")}
                  value={values.title}
                  onChange={handleChange("title")}
                  onBlur={() => setFieldTouched("title")}
                  error={{
                    message: errors.title,
                    visibility: touched.title
                  }}
                />
                <DropdownMenu value={selectedProblem ? selectedProblem : t("Select Problem")}>
                  {problems.map(problem => <div className={"problem"} key={problem}
                                                onClick={() => setSelectedProblem(problem)}>
                    {problem}
                  </div>)}
                </DropdownMenu>
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder={t("Problem statement")}
                />
                <PhoneNumberInput
                  dialCode={values.dialCode}
                  onDialCodeChange={(dialCode) =>
                    setFieldValue("dialCode", dialCode)
                  }
                  phoneNumber={values.phoneNumber}
                  onPhoneNumberChange={handleChange("phoneNumber")}
                />
                <TextInput
                  placeholder={t("Email address")}
                  value={values.emailAddress}
                  onChange={handleChange("emailAddress")}
                  onBlur={() => setFieldTouched("emailAddress")}
                  error={{
                    message: errors.emailAddress,
                    visibility: touched.emailAddress
                  }}
                  ltr
                />
                <input type={"file"} onChange={e => setProblemFile(e.target.files[0])} />
                <div>
                  <button
                    type={"button"}
                    className={"button primary"}
                    onClick={() => handleSubmit()}
                  >
                    {loading ? <CircleLoading /> : t("Send")}
                  </button>
                  <button type={"button"} className={"button ternary"}>
                    {t("Cancel")}
                  </button>
                </div>
              </>
            )}
          </Formik>
        </div>

        <div>
          {faqs.map((item, index) => (
            <CollapsibleMenu key={index} data={item} />
          ))}
        </div>
      </div>
    </Card>
  );
};

export default ContactUs;
