import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useClickOutside } from "@mantine/hooks";
import "./TabNavigator.scss";
// Sub components
import Tab from "./sub-components/Tab";
// Utils
import dashboardTabNavigatorConfigs from "../../../utils/configs/dashboardTabNavigator";
import icons from "../../../utils/icons";

const TabNavigator = () => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const { userType } = useParams();
  const [currentTab, setCurrentTab] = useState(t("Personal profile"));
  const [showDropdown, setShowDropdown] = useState(null);
  const dropdownRef = useClickOutside(
    () => showDropdown && setShowDropdown(false)
  );
  const dropdownClassname =
    showDropdown === true
      ? "active"
      : showDropdown === false
      ? "inactive"
      : undefined;

  return (
    <div
      className={`dashboard-tab-navigator ${i18n.language === "kuk" && "ltr"}`}
      onClick={() => setShowDropdown(!showDropdown)}
      ref={dropdownRef}
    >
      <div>
        <p>{currentTab}</p>
        {icons.arrowhead}
      </div>

      <div className={dropdownClassname}>
        {userType === "user"
          ? dashboardTabNavigatorConfigs.user.map((item, index) => (
              <Tab
                key={index}
                icon={item.icon}
                title={item.title}
                link={item.link}
                changeCurrentTab={setCurrentTab}
                index={index}
              />
            ))
          : userType === "publisher"
          ? dashboardTabNavigatorConfigs.publisher.map((item, index) => (
              <Tab
                key={item.title}
                icon={item.icon}
                title={item.title}
                link={item.link}
                changeCurrentTab={setCurrentTab}
                index={index}
              />
            ))
          : dashboardTabNavigatorConfigs.marketer.map((item, index) => (
              <Tab
                key={item.title}
                icon={item.icon}
                title={item.title}
                link={item.link}
                changeCurrentTab={setCurrentTab}
                index={index}
              />
            ))}
      </div>
    </div>
  );
};

export default TabNavigator;
