export default {
  auth: {
    otp: '/{userType}/auth/otp',
    userLogin: '/user/auth',
    userGoogleLogin: '/user/auth/google/callback',
    marketerPublisherLogin: '/{userType}/auth/login',
    register: '/{userType}/auth/register',
    forgetPassword: {
      request: '/{userType}/auth/forget-password/request',
      verify: '/{userType}/auth/forget-password/verify',
      change: '/{userType}/auth/forget-password/change',
    },
    profile: '/{userType}/dashboard/profile',
    logout: '/auth/logout',
    maxConnection: '/devices/{deviceId}/token/{token}',
    authenticatedInfo: '/auth/authenticated-info',
  },
  dashboard: {
    settings: '/{userType}/dashboard/settings',
    resetPassword: '/{userType}/dashboard/reset-password',
    devices: '/{userType}/dashboard/devices',
    deleteDevice: '/{userType}/dashboard/devices/{deviceId}',
    purchasesHistory: '/user/dashboard/purchases-history',
    purchaseHistory: '/user/dashboard/purchases-history/{orderNumber}',
    readingReports: '/user/dashboard/reading-reports',
    notifications: '/user/dashboard/notifications',
    wallets: '/{userType}/dashboard/wallets',
    creditCards: '/{userType}/dashboard/credit-cards',
    creditCardsAction: '/{userType}/dashboard/credit-cards/{creditCard}',
    chargeWallet: '/{userType}/dashboard/wallets/{walletId}/charge',
    myPublishers: '/marketer/dashboard/my-publishers',
    profit: '/marketer/dashboard/profit',
    saleRecords: '/publisher/dashboard/sale-records',
    readBook: '/user/reading-reports/{product}',
    lastReadLocationBook: '/user/reader/{product}',
  },
  publisher: {
    documents: '/documents',
    removeDocuments: '/publisher/documents/{id}',
    logo: '/logo',
    products: {
      info: '/publisher/dashboard/products/info',
      uploadFile: '/publisher/products/files',
      reviews: {
        uploadImage: '/publisher/products/reviews/image',
      },
      products: '/publisher/products',
      myProducts: '/publisher/dashboard/products',
      myProductsCategory: '/publisher/dashboard/products/{type}',
      remove: '/publisher/products/{productId}',
      product: '/publisher/products/{slug}',
    },
    addCollaborator: '/publisher/collaborators/{type}',
    collections: {
      collections: '/publisher/collections',
    },
  },
  marketer: {
    removeDocuments: '/marketer/documents/{id}',
  },
  comments: {
    addComment: '/user/comments/{productId}',
    replyComment: '/user/comments/{productId}/{commentId}/reply',
    vote: '/user/comments/{commentId}/vote',
  },
  gift: {
    prices: '/gifts/prices',
    store: '/user/gifts',
    accept: '/user/gifts/{code}/accept',
  },
  cart: {
    cart: '/user/cart',
    cartAction: '/user/cart/{productId}',
    submitOrder: '/user/order',
  },
  library: {
    library: '/user/dashboard/library',
    libraryCategory: '/user/dashboard/library/{type}',
    removeFromLibrary: '/user/dashboard/library/{libraryItemId}',
  },
  bookmarks: {
    bookmarks: '/user/bookmarks',
    removeFromBookmark: '/user/bookmarks/{productId}',
  },
  products: {
    filters: '/filter-info',
    products: '/products',
    bookIntroductions: '/user/books-introduction',
    product: '/products/{slug}',
    comments: '/products/{slug}/comments',
    podcastsEpisodes: '/products/{productId}/collection/podcast',
  },
  public: {
    home: '',
    aboutUs: '/about-us',
    termOfUses: '/term-of-uses',
    guides: '/guides',
    requestBook: '/book-request',
    contactUs: '/contact-us',
    verifyPayment: '/payment/verify',
    categories: '/categories',
    collaborators: '/collaborators/{type}',
    siteSettings: '/site-info',
  },
};
