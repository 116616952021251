import {useState} from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Collapse} from "@mantine/core";
import "./Dropdown.scss";

const Dropdown = ({data}) => {
    const {t} = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={`slide-menu-dropdown ${isOpen && "open"}`}>
            <div onClick={() => setIsOpen(!isOpen)}>
                <div>
                    {data.icon ? <img src={data.icon} style={{width: 24}} alt=""/> : null}
                    <p>{t(data.title)}</p>
                </div>

                <svg
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M12.992 15.4769L13.0037 15.4672L13.0144 15.4566L16.7584 11.7446L16.7584 11.7446L16.7595 11.7435C17.0241 11.4788 17.0241 11.0652 16.7595 10.8005C16.4948 10.5359 16.0812 10.5359 15.8165 10.8005L12.48 14.137L9.15705 10.8141C9.0282 10.6599 8.84252 10.602 8.68398 10.602C8.51563 10.602 8.33589 10.6651 8.20049 10.8005L8.44798 11.048L8.20049 10.8005C8.06656 10.9345 8.00197 11.1214 8.00197 11.272C8.00197 11.4226 8.06656 11.6096 8.20049 11.7435L8.44798 11.496L8.20049 11.7435L11.9125 15.4555C12.2202 15.7633 12.6835 15.734 12.992 15.4769Z"
                        fill="#009DAE"
                        stroke="#009DAE"
                        strokeWidth="0.7"
                    />
                    <path
                        d="M2.28439 19.0289L2.3064 19.0654L2.3365 19.0955C2.55243 19.3114 2.97067 19.4363 3.27947 19.1275C3.48609 18.9209 3.60933 18.529 3.34906 18.2251C2.31281 16.3879 1.94999 14.9787 1.94999 12.52C1.94998 6.7293 6.68928 1.99 12.48 1.99C18.2707 1.99 23.01 6.7293 23.01 12.52C23.01 18.3107 18.2707 23.05 12.48 23.05C11.478 23.05 9.08756 23.0448 7.19095 22.1003C6.92633 21.8808 6.54551 21.8955 6.2965 22.1445C6.03181 22.4092 6.03181 22.8228 6.2965 23.0875L6.32609 23.1171L6.36183 23.1389C7.39035 23.7658 8.68014 24.0743 9.81563 24.2293C10.9572 24.3851 11.9767 24.39 12.48 24.39C19.0413 24.39 24.35 19.0813 24.35 12.52C24.35 5.9587 19.0413 0.650001 12.48 0.650001C5.91868 0.650002 0.609984 5.9587 0.609985 12.52C0.609986 15.2648 1.14395 17.1406 2.28439 19.0289Z"
                        fill="#009DAE"
                        stroke="#009DAE"
                        strokeWidth="0.7"
                    />
                </svg>
            </div>

            <Collapse in={isOpen}>
                {data.subMenu.map((subOption, index) => (
                    <Link
                        key={index}
                        className={"slide-menu-dropdown-link"}
                        to={{
                            pathname: `/products/categories/${subOption.slug}`,
                        }}
                    >
                        {subOption.title}
                    </Link>
                ))}
            </Collapse>
        </div>
    );
};

export default Dropdown;
