import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./DialCodesDropdown.scss";
// Utils
import countryDialCodes from "../../../../utils/country-dial-codes";
import icons from "../../../../utils/icons";

const DialCodesDropdown = ({
  dialCode,
  onDialCodeChange,
  showDropdown,
  setShowDropdown,
}) => {
  const { t } = useTranslation();
  const dropdownRef = useRef();
  const dropdownClassName = `dial-codes-dropdown-select-box ${
    showDropdown === true
      ? "active"
      : showDropdown === false
      ? "inactive"
      : undefined
  }`;
  const [searchValue, setSearchValue] = useState("");
  useEffect(() => {
    dropdownRef.current.scrollTo(0, 0);
    setSearchValue("");
  }, [showDropdown]);

  function handleDialCodeChange(code) {
    onDialCodeChange(code);
    setShowDropdown(false);
  }

  function renderCodes() {
    if (searchValue === "") {
      return countryDialCodes.map((item, index) => (
        <p key={index} onClick={() => handleDialCodeChange(item.dialCode)}>
          {item.dialCode + " " + item.name}
        </p>
      ));
    } else {
      return countryDialCodes.map(
        (item, index) =>
          item.name.toLowerCase().startsWith(searchValue.toLowerCase()) && (
            <p key={index} onClick={() => handleDialCodeChange(item.dialCode)}>
              {item.dialCode + " " + item.name}
            </p>
          )
      );
    }
  }

  return (
    <>
      <div
        className={"dial-codes-dropdown-value-section"}
        onClick={() => setShowDropdown(!showDropdown)}
      >
        {icons.arrowhead}
        <p>{dialCode}</p>
      </div>

      <div className={dropdownClassName} ref={dropdownRef}>
        <input
          type={"text"}
          placeholder={t("Search")}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        {renderCodes()}
      </div>
    </>
  );
};

export default DialCodesDropdown;
