import React, { useEffect, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./css/Loading.scss";
// Common components
import ModalWrapper from "../common/others/ModalWrapper";
// Utils
import icons from "../../utils/icons";
import NavbarContext from "../../utils/contexts/Navbar";

const LoadingModal = (props) => {
  const { t } = useTranslation();
  const { modalsDisplayStatus, toggleModals } = useContext(NavbarContext);
  function toggle() {
    toggleModals({ ...modalsDisplayStatus, loading: false });
  }
  // useEffect(() => {
  //     const timeOut = setTimeout(() => toggle(), 2000);
  //     return () => clearTimeout(timeOut);
  // }, []);

  return (
    <ModalWrapper className={"loading-modal"}>
      {icons.loading}

      <p>{t("Please wait a moment")}</p>
    </ModalWrapper>
  );
};

export default LoadingModal;
