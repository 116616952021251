import React, {useEffect, useState} from "react";
import "./css/AboutUs.scss";
import {useTranslation} from "react-i18next";
import axios from '../../utils/http';
import API from './../../utils/api';
import Skeleton from "react-loading-skeleton";

const AboutUsSkeleton = () => <>
    <Skeleton width={'50%'}/>
    <Skeleton/>
    <Skeleton/>
    <Skeleton/>
    <br/>
    <Skeleton width={'50%'}/>
    <Skeleton/>
    <Skeleton/>
    <Skeleton/>
    <br/>
    <Skeleton width={'50%'}/>
    <Skeleton/>
    <Skeleton/>
    <Skeleton/>
    <br/>
    <Skeleton width={'50%'}/>
    <Skeleton/>
    <Skeleton/>
    <Skeleton/>
    <br/>
    <Skeleton width={'50%'}/>
    <Skeleton/>
    <Skeleton/>
    <Skeleton/>
</>

const AboutUs = () => {
    const {t} = useTranslation();
    const [aboutUs, setAboutUs] = useState({
        about_us: [], description: ''
    });
    const [loading, setLoading] = useState(false);

    const getAboutUsInfo = async () => {
        setLoading(true);
        try {
            const {data} = await axios.get(API.public.aboutUs);

            if (data.success) {
                setAboutUs(data.data);
            }
        } catch (e) {

        }
        setLoading(false)
    };

    useEffect(() => {
        getAboutUsInfo();
    }, [])

    return (<div className={"about-us"}>
        <p className={"home-title m24"}>{t("About us")}</p>
        <p className={"content"}>{aboutUs.description || <Skeleton/>}</p>

        {loading ? <AboutUsSkeleton/> : aboutUs.about_us.map((item) => <>
            <p className={"header"}>{item.title}</p>
            <p className={"content"}>{item.text}</p>
        </>)}
    </div>);
};

export default AboutUs;
