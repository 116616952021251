export const logIn = (data) => {
  return (dispatch) => {
    dispatch({
      type: "LOGIN",
      payload: data
    });
  };
};

export const logOut = () => {
  return (dispatch) => {
    dispatch({
      type: "LOGOUT"
    });
  };
};

export const setClient = (data) => {
  return (dispatch) => {
    dispatch({
      type: "SET_DATA",
      payload: data
    });
  };
};
