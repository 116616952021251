import React, { useEffect, useRef, useState } from 'react';
import { useClickOutside } from '@mantine/hooks';
import './css/DropdownMenu.scss';
// Utils
import icons from '../../../utils/icons';

const DropdownMenu = ({
  children,
  value,
  icon = icons.arrowhead,
  onIconClick,
}) => {
  const [showDropdown, setShowDropdown] = useState(null);
  const dropdownContainerRef = useClickOutside(
    () => showDropdown && setShowDropdown(false)
  );
  const dropdownMenuRef = useRef();
  useEffect(() => {
    const scrollTopTop = () => {
      dropdownMenuRef.current?.scrollTo(0, 0);
    };
    document.addEventListener('click', scrollTopTop);
    return () => document.removeEventListener('click', scrollTopTop);
  }, [showDropdown]);
  const dropdownClassName = `no-scrollbar ${
    showDropdown === true
      ? 'active'
      : showDropdown === false
      ? 'inactive'
      : undefined
  }`;

  return (
    <div
      className={'dropdown-menu-reader'}
      onClick={() => setShowDropdown(!showDropdown)}
      ref={dropdownContainerRef}
    >
      <div className={'dropdown-item-reader'}>
        <p>{value}</p>
        <div
          className={'dropdown-icon-reader'}
          onClick={(e) => {
            if (onIconClick) {
              e.stopPropagation();
              onIconClick();
            }
          }}
        >
          {icon}
        </div>
      </div>

      <div
        className={`dropdown-items-reader ${dropdownClassName}`}
        ref={dropdownMenuRef}
      >
        {children}
      </div>
    </div>
  );
};

export default DropdownMenu;
