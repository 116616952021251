import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './Filters.scss';
// Sub components
import FilterDropdown from './sub-components/FilterDropdown';
import SearchBox from './sub-components/SearchBox';
// Utils
import { useTranslation } from 'react-i18next';

const Filters = ({
  filters,
  onFilterChange,
  selectedFilter,
  search,
  onSearchChange,
  onSearchSubmit,
}) => {
  const [filter, setFilter] = useState(selectedFilter);
  const { t } = useTranslation();

  useEffect(() => {
    setFilter(selectedFilter);
  }, [selectedFilter]);

  return (
    <div className={'filters'}>
      <SearchBox
        search={search}
        onSearchSubmit={onSearchSubmit}
        onSearchChange={(value) => onSearchChange(value)}
      />
      <FilterDropdown
        title={t('Category')}
        value={filter?.category}
        updateValue={(value) => onFilterChange('category', value)}
        options={filters?.categories || []}
        singleMode
        style={{ width: 180 }}
      />

      <FilterDropdown
        title={t('Genre')}
        value={filter?.genre}
        updateValue={(value) => onFilterChange('genre', value)}
        options={filters?.genres || []}
        singleMode
        style={{ width: 120 }}
      />

      <FilterDropdown
        title={t('Product Type')}
        value={
          Array.isArray(filter?.product_type)
            ? filter?.product_type
            : [filter?.product_type]
        }
        updateValue={(value) => onFilterChange('product_type', value)}
        options={filters?.product_types || []}
        style={{ width: 190 }}
      />

      <FilterDropdown
        title={t('Publishers')}
        value={filter?.publisher}
        updateValue={(value) => onFilterChange('publisher', value)}
        options={filters?.publishers || []}
        style={{ width: 190 }}
      />
      <FilterDropdown
        title={t('Sort By')}
        value={filter?.sort_by}
        updateValue={(value) => onFilterChange('sort_by', value)}
        options={filters?.sort_by || []}
        singleMode
        style={{ width: 150 }}
      />
      <FilterDropdown
        title={t('Collaborator')}
        value={filter?.collaborator}
        updateValue={(value) => onFilterChange('collaborator', value)}
        options={filters?.collaborators || []}
        style={{ width: 250 }}
      />
    </div>
  );
};

export default Filters;
