import React, {useState} from "react";
import "./TabNavigator.scss";
import {useTranslation} from "react-i18next";
import icons from "../../../../utils/icons";

const TabNavigator = ({sections, currentSection, setCurrentSection, setSections}) => {
    const {t} = useTranslation();

    return (
        <div className={"add-review-modal-tab-navigator"}>
            <div>
                {sections.map((item, index) => (
                    <p
                        key={index}
                        className={currentSection === index ? "active" : ''}
                        onClick={() => setCurrentSection(index)}
                    >
                        {t("Section") + ` ${index + 1}`}
                    </p>
                ))}
            </div>

            <div>
                <button
                    type={"button"}
                    className={"btn-add-review-section"}
                    onClick={() => {
                        const newSections = [...sections, {
                            title: '',
                            text: '',
                            images: [
                                {
                                    id: null,
                                    file: null,
                                    path: "",
                                    url: "",
                                    base64: "",
                                    description: ""
                                }
                            ]
                        }];
                        setSections(newSections);
                        setCurrentSection(newSections.length - 1);
                    }}
                >
                    {t("Add new section")}
                </button>
                {sections.length ? <button
                    className={'btn-remove-review-section'}
                    onClick={() => {
                        const newSections = sections.filter((item, index) => index !== currentSection);
                        setSections([...newSections])
                        setCurrentSection(!newSections.length ? 0 : newSections.length - 1)
                    }}
                >
                    {icons.delete}
                </button> : null}
            </div>
        </div>
    );
};

export default TabNavigator;
