import React, { useState } from "react";
import "./PaymentMethod.scss";
import { useTranslation } from "react-i18next";
// Sub components
import RadioButton from "./sub-components/RadioButton";

const PaymentMethod = ({ price, discount, totalPrice,currency, selectedWallet, onPaymentMethodChanged, paymentMethod }) => {
  const { i18n, t } = useTranslation();

  return (
    <div className={"payment-method"}>
      <div>
        <div>
          <p>{t("Price")}</p>
          <p>
            {(+price)?.toLocaleString()} {t(currency)}
          </p>
        </div>
        <div>
          <p>{t("Discount")}</p>
          <p>
            {(+discount)?.toLocaleString()} {t(currency)}
          </p>
        </div>
        <div>
          <p>{t("Payable")}</p>
          <p>
            {(+totalPrice)?.toLocaleString()} {t(currency)}
          </p>
        </div>
      </div>

      <div>
        <p>{t("Choose the payment method")}</p>

        <div>
          <RadioButton
            group={"payment-method"}
            id={"anobani-wallet"}
            checked={paymentMethod}
            toggleValue={() => onPaymentMethodChanged(true)}
            subTitle={`${(+selectedWallet?.amount)?.toLocaleString()} ${t(currency)}`}
          />

          {!selectedWallet?.receipt ? <RadioButton
            group={"payment-method"}
            id={selectedWallet?.currency === "USD" ? "paypal-gateway" : "internet-wallet"}
            checked={!paymentMethod}
            toggleValue={() => onPaymentMethodChanged(false)}
          /> : null}
        </div>
      </div>
    </div>
  );
};

export default PaymentMethod;
