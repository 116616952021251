import {useTranslation} from "react-i18next";
import "./TabNavigator.scss";

const TabNavigator = ({tab, changeTab}) => {
    const {t} = useTranslation();
    const tabs = ["User", "Publisher", "Marketer"];
    // const tabs = ["User"];

    return (
        <div className={"auth-form-tab-navigator"}>
            {tabs.map((item) => (
                <button
                    key={item}
                    type={"button"}
                    className={item.toLowerCase() === tab ? "active" : ''}
                    onClick={() => changeTab(item.toLowerCase())}
                >
                    {t(item)}
                </button>
            ))}
        </div>
    );
};

export default TabNavigator;
