import React from "react";
import { useTranslation } from "react-i18next";
import "./css/SlideTwo.scss";
// Utils
import icons from "../../../utils/icons";
import images from "../../../utils/images";

const SlideTwo = ({ value }) => {
  const { i18n, t } = useTranslation();

  return (
    <div className={"gift-card-slide slide-two"}>
      <div>
        <img alt={"Anobani"} src={images.anobaniLogo} />
        <p>
          {t("Study for everyone")}
          <br />
          {t("Everywhere")}
          <br />
          {t("All the time")}
        </p>

        <p>
          {t("Gift card")}
          <br />
          {t("Anobani e-book")}
        </p>
      </div>

      <div>
        <p>
          {value}
        </p>
        {icons.giftBoxes}
      </div>
    </div>
  );
};

export default SlideTwo;
