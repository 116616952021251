import "./css/CreditCard.scss";
import icons from "../../../../../utils/icons";

const CreditCard = ({ creditCard, onEdit, onDelete }) => {
  return <div className={`credit-card ${creditCard.default ? "credit-card-default" : ""}`}>
    <div className={"credit-card-actions"}>
      <button onClick={() => onDelete?.(creditCard)}>{icons.delete}</button>
      <button onClick={() => onEdit?.(creditCard)}>{icons.edit}</button>
    </div>
    <p className={"credit-card-number"}>{creditCard.card_number}</p>
    <p className={"credit-card-owner"}>{creditCard.card_owner}</p>
  </div>;
};

export default CreditCard;
