import React from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
// Components common between home sections
import SliderButtons from './common/SliderButtons';
import Product, { ProductSkeleton } from '../../common/others/Product';
// Utils
import testimonials from '../../../utils/fake-data/testimonial';
import productSliderBreakpointsConfig from '../../../utils/configs/productSliderBreakpoints';
import { Link } from 'react-router-dom';

export const ProductSliderSkeleton = ({ type }) => {
  const { i18n } = useTranslation();

  return (
    <Swiper
      className={`product-and-site-suggestions-slider`}
      dir={i18n.language !== 'kuk' ? 'rtl' : 'ltr'}
      loop={true}
      modules={[Pagination]}
      pagination={{ clickable: true }}
      slidesPerView={'auto'}
      breakpoints={productSliderBreakpointsConfig}
    >
      <SwiperSlide>
        <ProductSkeleton type='electronic_book' />
      </SwiperSlide>
      <SwiperSlide>
        <ProductSkeleton type='electronic_book' />
      </SwiperSlide>
      <SwiperSlide>
        <ProductSkeleton type='electronic_book' />
      </SwiperSlide>
      <SwiperSlide>
        <ProductSkeleton type='electronic_book' />
      </SwiperSlide>
      <SwiperSlide>
        <ProductSkeleton type='electronic_book' />
      </SwiperSlide>
      <SwiperSlide>
        <ProductSkeleton type='electronic_book' />
      </SwiperSlide>

      <SliderButtons />
    </Swiper>
  );
};

const TestimonialSlider = ({ celebrities }) => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  return (
    <Swiper
      className={`product-and-site-suggestions-slider electronic_book`}
      dir={i18n.language !== 'kuk' ? 'rtl' : 'ltr'}
      modules={[Pagination]}
      pagination={{ clickable: true }}
      slidesPerView={'auto'}
      breakpoints={productSliderBreakpointsConfig}
    >
      {celebrities?.map((celebrity, index) => (
        <SwiperSlide style={{ alignSelf: 'flex-end' }} key={index}>
          {celebrity.link ? (
            <Link to={celebrity.link ? celebrity.link : ''}>
              <div className='manager'>
                <img src={celebrity.slide_url} alt='manager name' />
                <div className='manager_name'>{t(`${celebrity.title}`)}</div>
              </div>
            </Link>
          ) : (
            <>
              {celebrity.product ? (
                <Link
                  to={
                    celebrity.product.slug
                      ? `/products/${celebrity.product.slug}`
                      : ''
                  }
                >
                  <div className='manager'>
                    <img src={celebrity.slide_url} alt='manager name' />
                    <div className='manager_name'>
                      {t(`${celebrity.title}`)}
                    </div>
                  </div>
                </Link>
              ) : (
                <Link to=''>
                  <div className='manager'>
                    <img src={celebrity.slide_url} alt='manager name' />
                    <div className='manager_name'>
                      {t(`${celebrity.title}`)}
                    </div>
                  </div>
                </Link>
              )}
            </>
          )}
        </SwiperSlide>
      ))}

      <SliderButtons />
    </Swiper>
  );
};

export default TestimonialSlider;
