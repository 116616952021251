import { useContext } from 'react';
import './ProductInfo.scss';
// Common components
import Card from '../../../common/others/Card';
// Sub components
import Cover from './sub-components/Cover';
import Details from './sub-components/Details';
// import PurchaseAndPreview from './sub-components/PurchaseAndPreview';
// import AudioPlayer from './sub-components/AudioPlayer';
import Footer from './sub-components/Footer';
// Utils
// import ProductContext from '../../../../utils/contexts/Product';
// import icons from '../../../../utils/icons';
import ProductInfoContext from '../../../../utils/contexts/Product';
import { useState } from 'react';
// import axios from '../../../../utils/http';
// import Api from '../../../../utils/api';
// import { getRouter } from '../../../../utils/helpers';
// import { useSelector } from 'react-redux';
// import { toast } from 'react-toastify';
// import { useTranslation } from 'react-i18next';
// import i18n from 'i18next';

const ProductInfo = ({ demoDataBtn }) => {
  const { product, onLanguageChange } = useContext(ProductInfoContext);

  //   const { t } = useTranslation();
  //   const [bookmarkCount, setBookmarkCount] = useState(0);
  //   const [isBookmarked, setIsBookmarked] = useState(false);
  //   const client = useSelector((state) => state.client);

  //   useEffect(() => {
  //     setBookmarkCount(product?.bookmarks_count || 0);
  //     setIsBookmarked(!!product?.is_bookmarked);
  //   }, [product]);

  //   const bookmarkProduct = async () => {
  //     try {
  //       const { data } = await axios.post(
  //         getRouter(Api.bookmarks.removeFromBookmark, {
  //           productId: product.id,
  //         })
  //       );

  //       if (data.success) {
  //         if (isBookmarked) {
  //           setBookmarkCount(bookmarkCount > 0 ? bookmarkCount - 1 : 0);
  //         } else {
  //           setBookmarkCount(bookmarkCount + 1);
  //         }
  //         setIsBookmarked(!isBookmarked);
  //       }
  //     } catch (e) {}
  //   };

  //   const copyShareLink = async () => {
  //     if (!navigator?.clipboard) return;
  //     try {
  //       await navigator.clipboard.writeText(product?.share_url);
  //       toast(t('Link Copied!'), { type: 'success' });
  //     } catch (e) {}
  //   };

  //   const shareLink = () => {
  //     const data = {
  //       title: product?.title,
  //       url: product?.share_url,
  //     };

  //     if (navigator?.canShare && navigator?.canShare(data)) {
  //       navigator.share(data);
  //     }
  //   };

  return (
    <div className='row'>
      <div className='col-md-4'>
        <Cover
          type={product?.type}
          cover={product?.cover}
          language={product?.current_language}
          languages={product?.languages}
          onLanguageChange={onLanguageChange}
        />
      </div>
      <div className='col-md-8'>
        <Card
          className={`product-info ${
            product?.type === 'podcast' ? 'podcast' : ''
          }`}
        >
          {/* <div
            className={
              i18n.language === 'kuk' ? 'product-like-share-right' : ''
            }
          >
            <div
              onClick={() => {
                copyShareLink();
                shareLink();
              }}
            >
              {icons.share}
            </div>
            <div
              style={{ direction: 'ltr' }}
              className={isBookmarked ? 'bookmarked' : ''}
              onClick={() =>
                client && client.role === 'user'
                  ? bookmarkProduct(product)
                  : null
              }
            >
              {icons.heart}
              <span style={{ margin: '0 5px' }}>{bookmarkCount}</span>
            </div>
          </div> */}
          <div></div>

          <div>
            <div>
              <Details product={product} demoData={demoDataBtn} />
              {/* <PurchaseAndPreview product={product} /> */}
            </div>
          </div>

          {/* <Footer product={product} /> */}
        </Card>
      </div>
    </div>
  );
};

export default ProductInfo;
