import React from "react";
import {useTranslation} from "react-i18next";
import "./css/StudyIndicator.scss";
// Utils
import icons from "../../../../../utils/icons";
import {Link} from "react-router-dom";

const StudyIndicator = ({book, audio, today, lastBook = null}) => {
    const {t} = useTranslation();

    return (
        <div className={"study-indicator"}>
            <p>{t("Rate of study")}</p>
            <p>{today}</p>

            <div>
                <div>
                    {icons.eBook}
                    <p>{book}</p>
                </div>
                <div>
                    {icons.audioBook}
                    <p>{audio}</p>
                </div>
            </div>

            {lastBook && <Link to={`/products/${lastBook.slug}`} className={"button ternary"}>
                {t("Let's study")}
            </Link>}
            <Link to={'/book-introductions'} className={"button primary"}>
                {t("Introduce me to a new book")}
            </Link>
        </div>
    );
};

export default StudyIndicator;
