import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import OTPInput from "otp-input-react";
import {toast} from "react-toastify";
import "./MarketerRegister.scss";
// Components
import TextInput from "../../components/common/others/TextInput";
import RadioButton from "../../components/common/others/RadioButton";
import PhoneNumberInput from "../../components/common/phone-number-input/PhoneNumberInput";
import CircleLoading from "../../components/common/others/CircleLoading";
import ModalWrapper from "../../components/common/others/ModalWrapper";
// Form validation
import {Formik} from "formik";
import {marketerRegisterSchemas} from "../../utils/formValidationSchemas";
// Utils
import icons from "../../utils/icons";
import axios from "../../utils/http";
import {getRouter} from "../../utils/helpers";
import API from "../../utils/api";
import UploadZone from "../../components/common/others/UploadZone";
import {useNavigate} from "react-router-dom";

const MarketerRegister = () => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [showOtpModal, setShowOtpModal] = useState(false);
    const [otpType, setOtpType] = useState("EMAIL");
    const [otp, setOtp] = useState("");
    const [timer, setTimer] = useState(120);
    const [otpLoading, setOtpLoading] = useState(false);
    const [logo, setLogo] = useState("");
    const [documents, setDocuments] = useState([]);
    const [interval, setIntervalKey] = useState(null);
    const navigate = useNavigate();

    function renderTimer() {
        const minutes = Math.floor(timer / 60);
        const minutesToShow = minutes < 10 ? "0" + minutes : minutes;
        const seconds = timer % 60;
        const secondsToShow = seconds < 10 ? "0" + seconds : seconds;
        return minutesToShow + ":" + secondsToShow;
    }

    const [dialCode, setDialCode] = useState("+98");
    const [phoneNumber, setPhoneNumber] = useState("");

    useEffect(() => {
        console.log(interval);
    }, [interval]);

    const resendOTP = async (emailAddress) => {

        if (otpLoading || loading) return;
        clearInterval(interval);

        setOtpLoading(true);
        if (!showOtpModal) setLoading(true);
        const putData = {};
        if (otpType === "EMAIL") {
            putData.email = emailAddress;
        } else {
            putData.mobile = phoneNumber;
            putData.country_code = dialCode;
        }
        try {
            const {data} = await axios.post(
                getRouter(API.auth.otp, {
                    userType: "marketer",
                }),
                putData
            );

            if (data.success) {
                if (!showOtpModal) setShowOtpModal(true);
                toast(data.data?.code, {type: "success"});
                setTimer(120);
                let myTimer = 120;
                const myInterval = setInterval(() => {
                    if (myTimer > 0) {
                        myTimer--;
                        setTimer(myTimer);
                    } else {
                        clearInterval(myInterval);
                    }
                }, 1000);
                setIntervalKey(myInterval);
            }
        } catch (e) {
        }
        setLoading(false);
        setOtpLoading(false);
    };

    async function handleSubmit(values) {
        if (loading) return;
        setLoading(true);
        try {
            const {data} = await axios.post(
                getRouter(API.auth.register, {userType: "marketer"}),
                {
                    first_name: values.firstName,
                    last_name: values.lastName,
                    national_code: values.nationalID,
                    gender: values.gender,
                    country_code: dialCode.replace("+", ""),
                    mobile: phoneNumber,
                    email: values.emailAddress,
                    otp: values.otp,
                    documents,
                }
            );
            if (data.success) {
                toast(
                    t(
                        "Your Registration as Marketer has been Successfully Completer! You can now Login."
                    ),
                    {type: "success"}
                );
                toast(t("Your Username and Password has been sent to your Email."), {
                    type: "success",
                });
                navigate("/", {replace: true});
            }
        } catch (e) {
        }
        setLoading(false);
    }

    const handleUploadDocumentSuccess = (response) => {
        setDocuments([...documents, response.data.path]);
    };

    const handleDocumentRemoved = (file, index) => {
        setDocuments(documents.filter((d, i) => i !== index));
    };

    return (
        <>
            <Formik
                initialValues={{
                    firstName: "",
                    lastName: "",
                    nationalID: "",
                    emailAddress: "",
                    gender: "male",
                    dialCode: "",
                    phoneNumber: "",
                }}
                validateOnChange
                onSubmit={(value) => handleSubmit(value)}
                validationSchema={marketerRegisterSchemas}
            >
                {({
                      values,
                      handleChange,
                      setFieldValue,
                      errors,
                      touched,
                      setFieldTouched,
                  }) => (
                    <>
                        <div className={"marketer-register"}>
                            <div className={"right-section"}>
                                <TextInput
                                    placeholder={t("First name")}
                                    value={values.firstName}
                                    onChange={handleChange("firstName")}
                                    error={{
                                        message: errors.firstName,
                                        visibility: touched.firstName,
                                    }}
                                    onBlur={() => setFieldTouched("firstName")}
                                />
                                <TextInput
                                    placeholder={t("Last name")}
                                    value={values.lastName}
                                    onChange={handleChange("lastName")}
                                    error={{
                                        message: errors.lastName,
                                        visibility: touched.lastName,
                                    }}
                                    onBlur={() => setFieldTouched("lastName")}
                                />
                                <TextInput
                                    placeholder={t("National ID")}
                                    value={values.nationalID}
                                    onChange={handleChange("nationalID")}
                                    error={{
                                        message: errors.nationalID,
                                        visibility: touched.nationalID,
                                    }}
                                    onBlur={() => setFieldTouched("nationalID")}
                                />
                                <div className={"gender-container"}>
                                    <RadioButton
                                        group={"gender"}
                                        id={"male"}
                                        title={t("Male")}
                                        checked={values.gender === "male"}
                                        updateValue={() => setFieldValue("gender", "male")}
                                    />
                                    <RadioButton
                                        group={"gender"}
                                        id={"female"}
                                        title={t("Female")}
                                        checked={values.gender === "female"}
                                        updateValue={() => setFieldValue("gender", "female")}
                                    />
                                </div>
                                <PhoneNumberInput
                                    dialCode={dialCode}
                                    onDialCodeChange={setDialCode}
                                    phoneNumber={phoneNumber}
                                    onPhoneNumberChange={(e) => setPhoneNumber(e.target.value)}
                                />
                                <button
                                    onClick={() => resendOTP(values.emailAddress)}
                                    type={"button"}
                                    className={"button primary submit-register"}
                                >
                                    {loading ? <CircleLoading/> : t("Confirm")}
                                </button>
                            </div>

                            <div/>

                            <div className={"left-section"}>
                                <TextInput
                                    placeholder={t("Email address")}
                                    value={values.emailAddress}
                                    onChange={handleChange("emailAddress")}
                                    error={{
                                        message: errors.emailAddress,
                                        visibility: touched.emailAddress,
                                    }}
                                    onBlur={() => setFieldTouched("emailAddress")}
                                />
                                <p>{t("Upload file")}</p>
                                <div className="uploader-zones">
                                    <UploadZone
                                        title={"آپلود مدارک"}
                                        multiple
                                        onUploadSuccess={handleUploadDocumentSuccess}
                                        uploadOnSelect
                                        uploadURL={API.publisher.documents}
                                        uploadFolder="document"
                                        onRemove={handleDocumentRemoved}
                                    />
                                </div>
                            </div>
                        </div>
                        {showOtpModal && (
                            <ModalWrapper
                                className={"otp-modal"}
                                toggle={() => {
                                    clearInterval(interval);
                                    setShowOtpModal(false);
                                    setOtp("");
                                }}
                            >
                                <div>
                                    <p style={{textAlign: "center"}}>
                                        {otpType === "EMAIL"
                                            ? values.emailAddress
                                            : `${dialCode}${phoneNumber}`}
                                    </p>
                                </div>
                                <p style={{textAlign: "center"}}>
                                    {otpType === "EMAIL"
                                        ? t(
                                            "Please enter the code sent via Email in the field below."
                                        )
                                        : t(
                                            "Please enter the code sent via SMS in the field below."
                                        )}
                                </p>
                                <OTPInput
                                    value={otp}
                                    onChange={(value) => {
                                        setOtp(value);
                                        if (value.length === 5) {
                                            setShowOtpModal(false);
                                            setOtp("");
                                            handleSubmit({
                                                ...values,
                                                otp: value,
                                            });
                                        }
                                    }}
                                    autoFocus
                                    OTPLength={5}
                                    otpType="number"
                                    className={"otp-input"}
                                />
                                {timer !== 0 && (
                                    <div className={"center timer"}>
                                        <p>{renderTimer()}</p>
                                        {icons.progressRing}
                                    </div>
                                )}
                                <p className={"center"}>{t("Remaining time")}</p>
                                {timer === 0 && (
                                    <button
                                        type={"button"}
                                        className={"button primary"}
                                        onClick={() => resendOTP(values.emailAddress)}
                                    >
                                        {otpLoading ? <CircleLoading/> : t("Resend")}
                                    </button>
                                )}
                            </ModalWrapper>
                        )}
                    </>
                )}
            </Formik>
        </>
    );
};

export default MarketerRegister;
