const kuTranslations = {
  // Numbers
  '24/7': 'حەوت ڕۆژ لە هەفتەیەکدا (٩ی بەیانی تا ٦ی ئێوارە)',

  // A
  Articles: 'بابەتەکان',
  'About us': 'دەربارەی ئێمە',
  'Link Copied!': 'لینكەكە كۆپی كرا!',
  'Active devices': 'ئامێرە چالاکەکان',
  Activity: 'چالاکی',
  'Add card': 'زیادکردنی کارت',
  'Add comment': 'کۆمێنتێک تۆمار بکە',
  'Your Comment has Been Submitted and Will Show it After Confirmation':
    'کۆمێنتەکەت تۆمار کراوە و دوای پشتڕاستکردنەوە پیشان دەدرێت',
  'Add invite code': 'کۆدی بانگهێشتنامە زیاد بکە',
  'Add new product': 'بەرهەمی نوێ زیاد بکە',
  'Edit Product': 'دەستکاریکردنی بەرهەم',
  'Select Product Type': 'جۆری بەرهەمەکە هەڵبژێرە',
  Collection: 'کۆمەڵە',
  'Select Category': 'پۆلێک هەڵبژێرە',
  'Select Sub Category': 'جۆرایه‌تی-لق دیاری بكه‌',
  'Select Filter Category': 'فلتری بە پۆل کردن هەڵبژێرە',
  'Select Genre': 'ژانرێک هەڵبژێرە',
  'Add new section': 'بەشی نوێ زیاد بکە',
  'Add new wallet': 'جزدانێکی نوێ زیاد بکە',
  'Add review': 'پێداچوونەوە زیاد بکە',
  'Add your comment': 'کۆمێنتەکەت تۆمار بکە',
  Notifications: 'ئاگادارکردنەوەکان',
  'All the time': 'گشت کاتێک',
  'Kurdi Kermanji': 'Kurdî Kirmancî',
  'Kurdi Soranî': 'کوردی سۆرانی',
  Amount: 'نرخ',
  'Anobani suggestions': 'پێشنیاری ئانوبانی',
  'Anobani e-book': 'کتێبی ئەلیکترۆنی ئانوبانی',
  'Anobani gift card': 'کارتی بانکیی دیاری ئانوبانی',
  Apply: 'جێبەجێکردن',
  'Audio book': 'کتێبی دەنگی',
  'Has Audio book': 'کتێبی دەنگی هەیە',
  'Add to Special Discounts':
    'پێویستە بەرهەمەکەم لە پۆلی بەرهەمە تایبەتەکاندا دابنرێت بۆ داشکاندن لە سایتەکەدا',
  'Audio books in this collection': 'کتێبە دەنگییەکانی ئەم کۆمەڵەیە',
  'Other Products in this collection': 'بەرهەمەکانی تری ئەم کۆمەڵەیە',
  Podcasts: 'پۆدکاستەکان',
  'Add Review': 'پێداچوونەوە زیاد بکە',
  'Section Title': 'ناونیشانی بەش',
  'Review Text': 'پێداچوونەوە بە دەق بکە',
  'Caption for Uploaded File': 'وەسفکردنی پەڕگەی بارکراو',
  'Audio books': 'کتێبی دەنگی',
  'Add New Products': 'زیادکردنی بەرهەمی نوێ',
  Author: 'نووسەر',
  Authors: 'نووسەران',
  'No Notifications Found': 'هیچ ئاگادارکردنەوەیەک نەدۆزرایەوە',
  'Select Currency': 'دراوی دڵخواز هەڵبژێرە',

  // B
  'Best sellers of the week': 'زۆرترین فرۆشیاری هەفتە',
  Birthday: 'رۆژى لەدایکبوون',
  Blog: 'بلۆگ',
  'Buy audio book': 'کڕینی کتێبی دەنگی ',
  'Buy e-book': 'کڕینی کتێبێکی ئەلیکترۆنی ',
  'Buy e-books': 'کتێبە ئەلیکترۆنیکیەکان بکڕن',
  'Buy journal': 'کڕینی گۆڤار',
  'Buy podcast': 'کڕینی پۆدکاست',
  'Gateway Payment': 'پارەدانی ئۆنلاین',
  'Pay with Paypal': 'پارەدان لە ڕێگەی PayPal',
  'Pay with Anobani Wallet': 'پارەدان بە جزدانی ئانوبانی',
  'Pay with all Shetabit Cards':
    '(بە هەموو کارتەکانی ئەندامی شتاب پارە دەدرێت )',
  'Operation Completed Successfully': 'پرۆسەکە بە ئەنجام گەیەندرا',
  'You Wallet Just Charged': 'جزدانەکەت شەحن کراوەتەوە',

  'Operation Failed': 'ئۆپەراسیۆنەکە شکستی هێنا',
  'Add Review for Product': 'بۆچوونی خۆت بۆ بەرهەمەکە تۆمار بکە',
  // C
  Cancel: 'هەڵوەشاندنەوە',
  Captions: 'وەسف',
  'Card number': 'ژمارەی کارت',
  'Central office address': 'ناونیشانی ئۆفیسی ناوەندی',
  'Change password': 'گۆڕینی ژمارەی نهێنی',
  'Choose the payment method': 'شێوازی پارەدان هەڵبژێرە',
  Code: 'کۆد',
  Comments: 'کۆمێنتەکان',
  Commission: 'کۆمسیۆن',
  'Common questions': 'پرسیارە باوەکان',
  Confirm: 'پشتڕاستکردنەوە',
  'Confirm and send': ' تۆمار بکە و بنێرە',
  'Confirm your phone number': 'ژمارەی تەلەفۆنەکەت پشتڕاست بکەرەوە',
  'Confirm your Email': 'ئیمەیڵەکەت پشتڕاست بکەرەوە',
  'Verify Email': 'ئیمەیڵ پشتڕاست بکەرەوە',
  Connection: 'پەیوەندی',
  'Contact us': 'پەیوەندیمان پێوەبکە',
  'Contents of works': 'ناوەڕۆکی بەرهەمەکان',
  'Current password': 'پەسوردی ئێستا',
  'Charge Wallet': 'جزدانەکە شەحن‌ بکەرەوە',
  'File Upload': 'بارکردنی فایلەکان',
  'Your Request has been Submitted and After Confirmation, Your Wallet Will be Charged!':
    'داواکارییەکەت تۆمارکراوە و دوای پشتڕاستکردنەوە، جزدانەکەت پارەی لێ وەردەگیرێت!',
  'Return to App': 'گەڕانەوە بۆ بەرنامەکە',
  'Load More': 'زیاتر نیشان دە',

  // D
  'Date / Day of payment': 'ڕۆژ پارەدان/',
  Day: 'روژ',
  'View: Day': 'بینین: روژ',
  'View: Week': 'بینین: حەفتە',
  'View: Month': 'بینین: مانگ',
  'Delete from cart': 'لە سەبەتە دەربهێنە',
  'Device name': 'ناوی ئامێر',
  'Electronic Book': 'کتێبی ئەلیکترۆنی',
  'Audio Book': 'کتێبی دەنگی',
  Discount: 'داشکان',
  Dollars: 'دۆلار',
  'The Latest': 'نوێترین',
  'The Oldest': 'کۆنترین',
  'The Purchases': 'باشترین فرۆشیاره‌كان',
  'The Populars': 'دڵخوازەکان',
  Speaker: 'وێژەر',
  Speakers: 'وێژەران',
  'Published Date': 'ڕۆژی بڵاوکردنەوە',
  'Dollar wallet': 'جزدانی دۆلار',
  'Do you want to be informed about the latest books, offers and new versions of book reader software by receiving emails?':
    'ئایا دەتەوێت لە ڕێگەی ئیمەیڵەوە ئاگادار بکرێتەوە لە نوێترین کتێب و ئۆفەر و وەشانی نوێی؟',
  'Do you want registration, changes, user comments, etc. to be emailed to you?':
    'دەتەوێت تۆمارکردن و گۆڕانکاری و سەرنجی بەکارهێنەران و هتد بە ئیمەیڵ بۆت بنێردرێت؟؟',
  'Do you want to leave?': 'دەتەوێت سایتەکە بەجێبهێڵیت؟',
  Category: 'گروپکردن',
  Genre: 'ژانر',
  'Product Type': 'جۆر',
  Publishers: 'وەشانخانەکان',
  Collaborator: 'نووسەر و وەرگێڕ و وتاردەر',
  'Sort By': 'ڕیزکردن بەپێی',

  // E
  'E-Book': 'کتێبی ئەلیکترۆنی',
  'Wallet:': 'جزدان:',
  Edit: 'دەستکاری کردن',
  'Email address': 'ئیمەیل',
  'Referral Code': 'کۆدی ڕەوانەکردن',
  'Your Referral Code': 'کۆدی ڕەوانەکردنەکەت',
  Username: 'ناوی بەکارهێنەر',
  'Empty cart': 'سەبەتەی کڕینەکە بەتاڵە',
  Everywhere: 'لە هەر شوێنێک',
  EXP: 'کاتی بەسەرچوون',
  'Error Communicating with Server': 'هەڵە لە پەیوەندیکردن لەگەڵ سێرڤەرەکەدا',
  'Error Communicating with Server, Please Try Again':
    'هەڵە لە پەیوەندیکردن لەگەڵ سێرڤەرەکەدا ڕوویدا، تکایە دووبارە هەوڵبدەرەوە',

  // F
  Female: 'ژن',
  'Final payment': 'کۆتا پارەدان',
  'First name': 'ناو',
  'Forgot password': 'له‌بیرچوونی وشه‌ی نهێنی',
  Free: 'بەلاش',
  'Upload Cover (jpeg, jpg, png)': 'بەرگەکە بار بکە (jpeg, jpg, png)',
  'Upload Sample (epub, pdf)': 'فایلێکی نمونە بار بکە (epub, pdf)',
  'Upload Sample (mp3)': 'فایلێکی نمونە بار بکە (mp3)',
  'Upload Sample (Selected)': 'فایلێکی نمونە بار بکە (هەڵبژێردراوە)',
  'Upload Main File (epub, pdf)': 'فایلە ئەسڵییەکە بار بکە (epub, pdf)',
  'Upload Main File (mp3)': 'فایلە ئەسڵییەکە بار بکە (mp3)',
  'Upload Main File (Selected)': 'فایلە ئەسڵییەکە بار بکە (هەڵبژێردراوە)',
  'Profile Updated Successfully!': 'پڕۆفایل بە سەرکەوتویی دەستکاری کرا!',

  // G
  Gadgets: 'ئامێرەکان',
  'Gift card': 'كارتی دیاری',
  Go: 'برۆ',
  Guide: 'ڕێ نیشاندەر',

  // H
  Home: 'پەیجی سەرەکی',

  // I
  Iban: 'ژمارەی شبا',
  "I don't know": 'نازانم',
  'If you have a discount code, enter it in this section':
    'ئەگەر کۆدی داشکاندنت هەیە لێرە بینووسە',
  'I have accepted the rules regarding the usage of Anobani.':
    'من مەرجەکانی بەکارهێنانی ئەنوبانی قبوڵ دەکەم.',
  'In case of publishing publications or requesting cooperation, send us your information so that it can be confirmed after reviewing and completing the registration process for you and your team.':
    'ئەگەر دەتانەويت لە گەڵ ئانوبانی هاوکاری بکەن، زانیارییەکانتان بنێرن بۆمان. دوای پشکنین و تەواوکردنی پرۆسەی تۆمارکردن ئێوە و تیمەکەتان ئاگاداردەکرينەوە..',
  'In case you want to increase your stock, transfer the intended amount to the account below, and send us the receipt. Your stock will increase within 24 hours.':
    'ئەگەر دەتەوێت باڵانسی ئەکاونتەکەت زیاد بکەیت، بڕی دڵخوازی خۆت بخەرە سەر ئەو ژمارەیەی خوارەوە و پسوڵەکە بنێرە. لە ٢٤ کاتژمێری داهاتوودا باڵانسەکەت زیاد دەکات.',
  'Increase amount': 'بڕی زیادکردن ',
  'Increase inventory': 'زیادکردنی کۆگا',
  'Increase stock': 'هاوسەنگی جزدان زیاد بکە',
  'Install Anobani application': 'بەرنامەی ئانوبانی دابەزێنە',
  'Internet payment': 'پارەدان بە ئینتەرنێت',
  'Introduce me to a new book': 'کتێبێکی نوێم پێ بناسێنە',
  'Invite code': 'کۆدی بانگێشتکردن',
  Issue: 'کێشە',
  'Issue tracking': 'کۆدی بەدواداچوون',

  // J
  Journal: 'رۆژنامە',

  // L
  Language: 'زمان',
  Latest: 'نوێترین',
  'Last name': 'ناوی کۆتایی',
  'Birth Date': 'کاتى لەدایکبوون',
  'Latest podcasts': 'نوێترین پۆدکاستەکان',
  "Let's study": 'وەرن با بخوێنین',
  Literature: 'ئەدەب',
  'Login / Signup': 'تۆمارکردن /چونه‌ ژووره‌وه‌',
  'Log in': 'چونه‌ ژووره‌وه',
  Register: 'تۆمار کردن',
  'Log out': 'چونه‌ دەرەوە',

  // M
  Magazine: 'گۆڤار',
  Male: 'پیاو',
  Marketer: 'مارکێتەر',
  'Marketeer profile': 'پرۆفایلی مارکێتەر',
  'Marketeer register guide file': 'فایلێکی ڕێنمایی تۆمارکردن مارکێتەر',
  Marketing: 'مارکتینگ',
  'Marketing login': 'چوونەژوورەوەی بەشی مارکتینگ',
  'Membership fee': 'کرێی ئەندامبوون',
  'Message with card': 'پەیام بە کارتەکەوە',
  Month: 'مانگ',
  'My account': 'ئەکانتی من',
  'My audio books': 'کتێبی دەنگی من',
  'My books': 'کتێبەکانم',
  'My E-book': 'کتێبی ئەلیکترۆنی من',
  'My e-books': 'کتێبی ئەلیکترۆنی من',
  'My favorite books': 'کتێبە خۆشەویستەکانم',
  'My journals': 'گۆڤارەکانی من',
  'My library': 'کتێبخانەکەم',
  'My product': 'بەرهەمەکەم',
  'My publishers': 'بڵاوکەرەوەکانم',

  // N
  Name: 'ناو',
  'National ID': 'کۆدی نیشتمانی',
  'New journals': 'گۆڤاری نوێ',
  'New Books': 'نوێترین کتێبەکان',
  'E-Books': 'کتێبە ئەلیکترۆنیکیەکان ',
  Magazines: 'گۆڤارەکان',
  'New password': 'وشەی نهێنی نوێ',
  No: 'نەخێر',
  'Note: Each user can delete the active device up to three times during one year (Starting from the registration date).':
    'تێبینی: هەر بەکارهێنەرێک دەتوانێت لە ماوەی ساڵێکدا تا سێ جار ئامێرە چالاکەکە بسڕێتەوە.',
  'Number of times left to remove the device in this year of membership: 4':
    'ژمارەی جارەکانی لابردنی ئامێرەکە لەم ساڵەی ئەندامێتیدا:   ٤',

  // O
  Off: 'داشکاندن',
  Oldest: 'کۆنترین',
  'Order number': 'ژمارەی داواکاری',
  'Other books by this author': 'کتێبەکانی تری ئەم نووسەرە',
  'Other books by this publisher': 'کتێبەکانی تری ئەم وەشانخانە',

  // P
  Cash: 'پارەی نەخت',
  Password: 'وشەی نهێنی',
  'Password Confirmation': 'دوبارەکردنەوەی وشەی نهێنی',
  Payable: 'دەدرێت',
  'Payment date': 'کاتی پارەدان',
  'Payment time': 'کاتی پارەدان',
  'Payment using Anobani wallet': 'پارەدان بە جزدانی ئانۆبانی',
  'Personal profile': 'پڕۆفایلی کەسی',
  'Phone number': 'ژمارەی مۆبایل',
  'Please enter the code sent via SMS in the field below.':
    'تکایە لە بۆکسی نووسینی خوارەوە کۆدی کورتەنامەکە بنووسە.',
  'Please enter the code sent via Email in the field below.':
    'تکایە ئەو کۆدەی کە بۆ ئیمەیڵەکە نێردراوە لە بۆکسی چوونەژوورەوەی خوارەوە داخڵ بکە.',
  'Please select the country code, and input your phone number.':
    'تکایە کۆدی وڵاتەکە هەڵبژێرە، و ژمارەی مۆبایلەکەت بنووسە.',
  'Please upload the photos of your identity and the relevant publishing credentials.':
    'تکایە وێنەی بەڵگەنامەکانی ناسنامە و بڵاوکراوە پەیوەندیدارەکانتان دابنێن.',
  'Please wait a moment': 'تکایە ساتێک چاوەڕێ بکە',
  Podcast: 'پۆدکاست',
  'Postal code': 'بۆکسی پۆست',
  Price: 'نرخ',
  'Final Price': 'نرخی کۆتایی',
  'Remove From Cart': 'لە سەبەتە دەربهێنە',
  'Remove From Library': 'لابردنی لە کتێبخانە',
  'Remove From Favorites': 'لابردنی لە دڵخوازەکان',
  'Read Online': 'خوێندنەوەی ئۆنلاین',
  'Listen Online': 'گوێ بگرە بە شێوەی ئۆنلاین',
  'Your Request Submitted. You are now Redirecting to Payment Gateway!':
    'داواکاریەکەت تۆمارکراوە. ئێستا ئاڕاستە دەکرێیتەوە بۆ پۆرتاڵی پارەدان!',
  'Print length': 'ژمارەی لاپەڕەکان',
  'Problem statement': 'بەیاننامەی کێشە',
  Profit: 'قازانج',
  'Sale Records': 'تۆمارەکانی فرۆشتن',
  'Profit of the month': 'قازانجی مانگ',
  'Withdrawable Profit': 'قازانجی کشانەوە',
  'Profit of the year': 'قازانجی ساڵ',
  'Public domain': 'دۆمەینی گشتی',
  'Publisher name': 'ناوی بڵاوکەرەوە',
  'Full Name': 'ناوی یەکەم و کۆتایی',
  'Publisher Phone': 'تەلەفۆنی بڵاوکەرەوە',
  Logo: 'لۆگۆ',
  'Registered Date': 'بەرواری تۆمارکردن',
  Publisher: 'بڵاوکەرەوەکان',
  'Publisher profile': 'پڕۆفایلی بڵاوکەرەوە',
  'Publishers login': 'چوونەژوورەوەی بڵاوکەرەوەکان',
  'Publishers register file': 'پەڕگەی تۆمارکردنی بڵاوکەرەوەکان',
  "Publisher's email address": 'ناونیشانی ئیمەیڵی بڵاوکەرەوە',
  "Publisher's code": 'کۆدی بڵاوکەرەوە',
  "Publisher's details": 'وەسف / پێشەکی بڵاوکراوەکان',
  "Publisher's name": 'ناوی بڵاوکەرەوە',
  Publishing: 'بڵاوکردنەوە',
  Episode: 'ئەڵقە',
  'Select Color': 'ڕەنگ هەڵبژێرە',
  'Select Font Size': 'قەبارەی فۆنت هەڵبژێرە',
  'List and Settings': 'لیست و ڕێکخستنەکان',
  'See all Formats And Versions': 'هەموو فۆرمات و وەشانیەکان ببینە',
  'Electronic Version': 'وەشان ئەلیکترۆنی',
  'Purchase records': 'تۆمارەکانی کڕین',
  'Purchased books': 'کتێبی کڕدراو',
  'Page or Resource Not Found': 'لاپەڕە یان سەرچاوە نەدۆزرایەوە',
  'Read Book': 'کتێب بخوێنەرەوە',
  'Read Magazine': 'گۆڤار بخوێنەرەوە',
  'Buy E-Book': 'کتێبی ئەلیکترۆنی بکڕە',
  'Buy Audio Book': 'کڕینی کتێبی دەنگی',
  'Buy Magazine': 'گۆڤار بکڕە',
  'Buy Podcast': 'پۆدکاست بکڕە',
  'Remove from Cart': 'لە سەبەتە دەربهێنە',
  Demo: 'نموونە',
  'Other Podcasts of Collection': 'پۆدکاستەکانی تری ئەم بەشە',

  // R
  'Rate of study': 'ڕێژەی خوێندن',
  'Receive email': 'وەرگرتنی ئیمەیڵ',
  'Receiver email address': 'ناونیشانی ئیمەیڵی وەرگری دیاری',
  'Receiver name': 'ناوی وەرگر',
  'Register reviews for the product': 'تۆمارکردنی بۆچوون بۆ بەرهەمەکە',
  'Registered users': 'بەکارهێنەری تۆمارکراو',
  'Register your purchase now': 'ئێستا کڕینەکەت تۆمار بکە',
  'Release date': 'کاتی بڵاوکردنەوە',
  'Not Registered': 'تۆمار نەکراوە',
  'Remaining time': 'کاتی ماوەتەوە',
  'Repeat new password': 'وشەی نهێنی نوێ دووبارە بکەرەوە',
  'Reply to': 'وەڵامدانەوە به',
  'Report card': 'کارتی ڕاپۆرت',
  'Request book': 'داواکاری کتێب ',
  'Request the book you want so we can add it to the store if possible.':
    'داوای ئەو کتێبە بکە کە دەتەوێت، بۆ ئەوەی زیادی بکەین بۆ فرۆشگاکە.',
  Resend: 'دووبارە بنێرەوە',
  'Review text': 'دەقی بۆچوون',
  'Book Name': 'ناوی کتێب',
  'Author Name': 'ناوی نووسەر',
  'Publisher Name': 'ناوی بڵاوکەرەوە',
  'Description / Publisher Introduction': 'وەسف / پێشەکی بڵاوکراوەکان',
  Submit: 'تۆمارکردن',
  'Collection Name': 'ناوی کۆکردنەوە',
  'Upload Image': 'وێنە باربکە',
  'Phone with Country Code': 'ژمارەی تەلەفۆن بە کۆدی وڵات',
  'Postal Box': 'کۆدی پۆست',
  'Postal Code': 'کۆدی پۆست',
  'Publisher Email': 'ئیمەیڵی بڵاوکەرەوە',
  'Publisher Code': 'کۆدی بڵاوکەرەوە',
  'Central Address': 'ناونیشانی ئۆفیسی ناوەندی',
  'Your Request has been Submitted': 'داواکاریەکەت تۆمار کرا',
  'Your Registration as Publisher has been Successfully Completer! You can now Login.':
    'تۆمارکردنت وەک بڵاوکەرەوە سەرکەوتوو بووە! ئێستا دەتوانیت بچیتە ژوورەوە بۆ ئەکاونتەکەت.',
  'Your Registration as Marketer has been Successfully Completer! You can now Login.':
    'تۆمارکردنت وەک مارکترسەرکەوتووە! ئێستا دەتوانیت بچیتە ناو ئەکاونتەکەتەوە.',
  'Your Username and Password has been sent to your Email.':
    'ناوی بەکارهێنەر و وشەی نهێنی بۆ ئیمەیڵ نێردراوە.',
  // S
  'Sales records': 'تۆماری فرۆشتن',
  'Sample book': 'نموونەی بەرهەم',
  Search: 'گەڕان',
  Season: 'وەرز',
  Section: 'بەش',
  'Section title': 'ناونیشانی بەش',
  'See activity': 'چالاکی ببینە',
  'See all': 'هەموو ببینە',
  Delete: 'سڕینەوە',
  'Select wallet': 'جزدان هەڵبژێرە',
  Send: 'بنێرە',
  Settings: 'ڕێکخستنەکان',
  'Show less': 'کەمتر پیشان بدە',
  'Show more': 'زیاتر ببینە',
  'Site profit percentage': 'سەدا قازانجی ماڵپەڕ',
  'Sign in using e-mail': 'بە ئیمەیڵ بچۆرە ژوورەوە',
  'Forget Password': 'گەڕانەوەی وشەی نهێنی',
  'Sign in using e-mail address':
    'بە بەکارهێنانی ناونیشانی ئیمەیڵ بچۆرە ژوورەوە',
  'Sign in using phone number': 'بە بەکارهێنانی ژمارەی تەلەفۆن چوونەژوورەوە',
  'Sign in using Google account': 'بە بەکارهێنانی ئەژمێری گووگڵ چوونەژوورەوە',
  Size: 'قەبارە',
  Published: 'بڵاوکراوەتەوە',
  'Sold product': 'بەرهەمی فرۆشراو',
  'Sort by': 'ڕیزبەندی بەپێی',
  Currency: 'دراو',
  Stock: 'کرێدت',
  'Stock increase rate': 'رێژەی زیادبوونی پشکەکان',
  'Study for everyone': 'خوێندن بۆ هەمووان',
  'Study time report': 'گڕاپۆرتی کاتی خوێندن',
  'Suggestion or criticism': 'پێشنیار یان ڕەخنە',
  Support: 'پشتیوانی',
  'Recommended Books': 'کتێبی پێشنیار کراو',

  // T
  Tax: 'باج',
  'Terms of use': 'مەرجەکانی بەکارهێنان',
  'The best gift for the best': 'باشترین دیاری بۆ باشترینەکان',
  'The Reader app is usable across three different devices. If you want to use a new device, first remove one from the list below.':
    'دەتوانرێت ئەپی خوێنەر لەسەر سێ ئامێری جیاواز بەکاربهێنرێت. ئەگەر دەتەوێت ئامێرێکی نوێ زیاد بکەیت، سەرەتا یەکێک لە ئامێرەکان لە لیستەکەی خوارەوە دەربهێنە.',
  'This week': 'ئەم هەفتەیە',
  'This month': 'ئەم مانگە',
  Title: 'ناونیشان',
  'Title / Name on card': 'ناو',
  Today: 'ئەمڕۆ',
  Toman: 'تۆمان',
  'Toman wallet': 'جزدانی تۆمان',
  Total: 'کۆی گشتی',
  'Total profit': 'کۆی گشتی قازانج',
  Translator: 'وەرگێڕ',
  Translators: 'وەرگێڕان',

  // U
  'Upload file': 'بارکردنی پەڕگە',
  'Upload file (PNG, JPG, PDF, ePUB, MP3)':
    'بارکردنی فایل (PNG, JPG, PDF, ePUB, MP3)',
  'Upload file (PNG, JPG)': 'بارکردنی فایل (PNG, JPG)',
  'Upload photo': 'وێنە باربکە',
  'Uploaded file details': 'وردەکارییەکانی فایلی بارکراو',
  User: 'بەکارهێنەر',

  // V
  View: 'چاودێری',
  'View all': 'بینینی هەموو',

  'View more': 'زیاتر ببینە',
  Volume: 'بەرگ',
  // Y
  Yes: 'بەڵێ',
  'You can specify the time of sending the gift card email.':
    'دەتوانیت دیاری بکەیت کەی ئیمەیڵی کارتی دیاری دەنێردرێت',
  'Your cost': 'تێچووی تۆ',
  'Discount Percent': 'لەسەدا داشکاندن',
  'Start Date': 'کاتی دەستپێکردن',
  'End Date': 'کاتی کۆتایی',
  'Your credit': 'کریدتەکەت',
  'Your Requests Have Exceeded the Limit. Please Wait a few Seconds and Try Again':
    'ژمارەی داواکارییەکانتان سنوورەکەی تێپەڕاندووە. تکایە چەند چرکەیەک چاوەڕێ بکە و دووبارە هەوڵبدەرەوە',

  // W
  Wallet: 'جزدان',
  Week: 'هەفتە',
  'We never send files': 'هەرگیز فایل نانێرین',
  'What else did the buyers of this book purchase?':
    'کڕیارانی ئەم کتێبە چی تریان کڕیوە؟',
  'Work with us': 'ببن بە هاوکارمان',
  Welcome: 'بەخێر بێیت',

  // Temp
  'Lorem ipsum xs':
    'دەقی ساختە بە سادەیییەکی تێنەگەیشتوو لە پیشەسازی چاپەوە بەرهەم هێنراوە',
  'Lorem ipsum s':
    'لۆرم ئیپسۆم دەقێکی ساختەیە کە بە سادەیییەکی تێنەگەیشتوو لە پیشەسازی چاپکردنەوە و بە بەکارهێنانی دیزاینەری گرافیک بەرهەم هێنراوە.',
  'Lorem ipsum m':
    'لۆرمێ ئیپسۆم دەقێکی ساختەیە بە بەرهەمهێنانی سادەیی تێنەگەیشتوو لە پیشەسازی چاپ و بە بەکارهێنانی گرافیک دیزاینەر. چاپکەر و دەق، بەڵام هەروەها ڕۆژنامە و گۆڤار بە ستوون و ڕیز بەپێی پێویست و بۆ بارودۆخی ئێستای تەکنەلۆژیا و ئەپلیکەیشنی جۆراوجۆر بە ئامانج. لە باشترکردنی ئامرازە پراکتیکییەکان. be.',
  'Lorem ipsum l':
    'لۆرمێ ئیپسۆم دەقێکی ساختەیە بە بەرهەمهێنانی سادەیی تێنەگەیشتوو لە پیشەسازی چاپ و بە بەکارهێنانی گرافیک دیزاینەر. چاپکەر و دەق، بەڵام هەروەها ڕۆژنامە و گۆڤار بە ستوون و ڕیز بەپێی پێویست و بۆ بارودۆخی ئێستای تەکنەلۆژیا و ئەپلیکەیشنی جۆراوجۆر بە ئامانج. زۆرێک لە کتێبەکان لە سەدا شەست و سێی ڕابردوو و ئێستا و داهاتوو پێویستیان بە زانیارییەکی زۆر لە کۆمەڵگا و شارەزایان هەیە بۆ ئەوەی زانیاری زیاتر بۆ دیزاینەرانی کۆمپیوتەر و بە تایبەت دیزاینەرانی داهێنەر و کولتوری پێشەنگ لە زمانی فارسیدا دروست بکەن .',
  'Lorem ipsum xxl':
    'لۆرمێ ئیپسۆم دەقێکی ساختەیە بە بەرهەمهێنانی سادەیی تێنەگەیشتوو لە پیشەسازی چاپ و بە بەکارهێنانی گرافیک دیزاینەر. چاپکەر و دەق، بەڵام هەروەها ڕۆژنامە و گۆڤار بە ستوون و ڕیز بەپێی پێویست و بۆ بارودۆخی ئێستای تەکنەلۆژیا و ئەپلیکەیشنی جۆراوجۆر بە ئامانج. لە سەدا شەست و سێی ڕابردوو و ئێستا و داهاتوودا زۆر کتێب هەن کە پێویستیان بە زانیارییەکی زۆرە لە کۆمەڵگا و پسپۆڕان بۆ ئەوەی زانیاری زیاتر بۆ دیزاینەرانی کۆمپیوتەر دروست بکەن، بە تایبەت دیزاینەرانی داهێنەر و کولتوری پێشەنگ لە... زمانی فارسی، بە نەرمەکاڵا.دەتوانین هیوامان ئەوە بێت کە هەموو سەختی لە دابینکردنی چارەسەر و مەرجە سەختەکانی تایپکردن کۆتایی پێبێت و ئەو کاتەی کە پێویستە، لەوانەش تایپکردنی دەستکەوتە سەرەکییەکان و وەڵامدانەوەی پرسیارە بەردەوامەکانی جیهانی هەنووکەیی دیزاین، لە بنەڕەتدا بێت بەکارهاتوو.',
  'The Secret Garden': 'باخچەی نهێنی',
  'January 14th, 1990': '1400/11/05',
  Time: '16:24',
  "Left section's description":
    'بە یەکەم کڕینت لە ئانوبانی، دەتوانیت چێژ لە داشکاندنی تایبەتی 60% وەربگریت.',
  'Francis Hudson Brant': 'فرانسیس هادسۆن برانت',
  Value: 'بەها',
  Address:
    'آدرس: تهران، خیابان گاندی جنوبی، کوچه گاندی اول، پلاک ۲۲، طبقه اول - ۰۹۱۲۹۴۸۷۴۶۲',
  'We are with you for telephone support every day of the week (9 to 6 P.M) with the number: 02831286':
    'بۆ پشتگیری تەلەفۆنی، ئێمە هەموو ڕۆژێکی هەفتە لەگەڵتانین (لە کاتژمێر ٩:٠٠ی بەیانی تا ٦:٠٠ی ئێوارە) بە ژمارە تەلەفۆنی ٠٩١83355719.',
  'For telephone support, in all day week (from ':
    'بۆ پشتگیری تەلەفۆنی، لە هەموو ڕۆژەکانی هەفتە (لە ',
  Call: 'بە ژمارە',
  'We are with you': 'ئێمە لەگەڵ تۆین.',
  'Company Address:': 'ناونیشان:',
  'Select Problem': 'کێشە هەڵبژێرە',
  'Error in app usage or payment': 'هەڵە لە بەکارهێنانی ئەپ یان پارەدان',
  'Issue details':
    'ئەگەر دوای پارەدان و گەڕاندنەوەی کتێبەکەت لە بەشی کتێبەکانم نەدۆزیەوە، بچۆ بۆ بەشی کتێبخانە و دەست لە ئایکۆنی هاوکاتکردن بدە لە سەرەوەی لاپەڕەکە. دوای ئەوە کتێبەکە زیاد دەکرێت بۆ کتێبخانەکەت.',
  'Payment issue': 'کێشە لە پارەدان',
  'Buying issue': 'کێشە لە کڕین',
  'Book download issue': 'کێشەی دابەزاندنی کتێبەکە',
  'Book not added issue': 'کتێبی کڕدراو زیاد نەکراوە بۆ کتێبخانە',
  'Stock not added issue': 'کرێدیتی کڕدراو بۆ جزدانەکە زیاد نەکرا',
  'Invite code usage issue': 'پێشنیار یان ڕەخنە',
  'Others issue': 'پرسەکانی تر',
  'Home page': 'لاپەڕەی سەرەتایی',
  'Internet payment tip': '(پارەدان بە هەموو کارتەکانی ئەندامی شەتاب)',
  'Jack Toren': 'جاک تۆرێن',
  '1 week ago': 'هەفتەیەک لەمەوبەر',
  'Ofoqe Taban': 'ئاسۆی درەوشاوە',
  'Take a large library with you': 'کتێبخانەیەکی گەورە لەگەڵ خۆت ببە',
  'Everywhere, we always': 'لە هەموو شوێنێک، ئێمە هەمیشە',
  'Introduce the best harrow to you': 'باشترینەکانتان پێ دەناسێنین',
  'Travel with us to another world': 'گەشتکردن لەگەڵ ئێمە بۆ جیهانێکی تر',
  'The Eight Mass Market Paperback': 'شومیز هشت بازار انبوه',
  'Open Access journal': 'گۆڤاری دەستڕاگەیشتن بە کراوە',
  'See all formats and editions': 'هەموو فۆرمات و چاپەکان ببینە',
  'Electronic version': 'وەشان ئەلیکترۆنی',
  'E-book': 'کتێبی ئەلیکترۆنی',
  'September 20th, 2000': '۱ مهر ۱۳۷۹',
  'Ballantine Books': 'افق سرخ',
  Persian: 'فارسی',
  '624 pages': '۶۲۴ صفحه',
  'Review of the audio book No Friend Except for the Mountain by Behrouz Buchani':
    'نقد و بررسی کتاب صوتی هیچ دوستی جز کوه، اثر بهروز بوچانی',
  Review: 'پێداچوونەوە',
  'About Audio No friends except Mountain':
    'درباره کتاب صوتی هیچ دوستی به جز کوه',
  'Comments about audio book': 'سەرنجەکان دەربارەی کتێبی دەنگی',
  'In Your Dreams': 'ڕۆژی قەرز',
  'Erfan Kakaei': 'عرفان کاکائی',
  '1hr, 14min, 42s': '۱ ساعت و ۱۴ دقیقه و ۴۲ ثانیه',
  'October 16th, 2020': '۱۳ مهر ۱۴۰۰',
  '0 seconds': '۰ ثانیه',
  'Authentication Failed! Please Login Again':
    'ناساندن شکستی هێنا! تکایە دووبارە هەوڵ بدە',
  'Best Selling Books': 'پڕفرۆشترین کتێبەکان',
  Journals: 'گۆڤارەکان',
  Favorite: 'دڵخوازەکانی هەفتە',
  Status: 'دۆخ',
  Paid: 'پارە دراوە',
  'Not Paid': 'پارە نەدراوە',
  Failed: 'پارە نەدراوە',

  Payment: 'پارەدان چاوەڕوانکراوە',
  Checking: 'پشکنین',
  resendCode: 'دووبارە کۆدەکە بنێرەوە',
  error: 'سڕینەوە شکستی هێنا',
  notExist: 'بوونی نییە',
  downloadedBefor: 'پێشتر دابەزێنراوە',
  defaultTheme: 'تەوەرەی پێش‌وەختە',
  bookSettings: 'ڕێکخستنەکانی کتێب',
  fontSize: 'سایزی نووسین',
  lineSpace: 'دووریی هێڵەکان',
  fa: 'فارسی',
  en: 'ئینگلیزی',
  ar: 'کوردی سۆرانی',
  ku: 'کوردی کرمانجی',
  dinar: 'دینار',
  author: 'نووسەر',
  translator: 'وەرگێڕ',
  speaker: 'وێژەر',
  selectDate: 'ڕێکەوت هەڵبژێرە',
  selectDateTime: 'ڕێکەوت و کات هەڵبژێرە',
  week: 'حەوتوو',
  page: 'پەیج',
  favoriteVoice: 'دڵخوازترین کتێبی دەنگی',
  favoriteElectronic: 'دڵخوازترین کتێبی ئەلیکترۆنی',

  'Login as Marketer': 'چوونەژوورەوەی بەشی مارکتینگ',
  'No Contents Found': 'هیچ لیستێک نەدۆزرایەوە',
  'No Highlight Found': 'هیچ هایلایتێک نەدۆزرایەوە',
  'No Notes Found': 'هیچ تێبینییەک نەدۆزرایەوە',
  'No Items Found': 'هیچ شتێک نەدۆزراوەتەوە',
  'Enter at least 3 Characters': 'حبەلایەنی کەمەوە ٣ پیت دابنێ',
  'Select Font': 'هەڵبژاردنی فۆنت',
  'Add Highlight': 'زیادکردنی هایلایت',
  'Add Note': 'زیادکردنی تێبینی',
  Share: 'هاوبەشکردن',
  'Text Must be Less Than 600 Character!': 'دەقەکە لە 600 پیت کەمتر بێت!',
  'Text Copied!': 'دەقەکە کۆپی کرا!',

  'Product Created Successfully. After the Reviews of the Admins It will be Published':
    'بەرهەمەکە بە سەرکەوتوویی دروستکرا. دوای پێداچوونەوە لەلایەن کارگێڕانەوە بڵاودەکرێتەوە',
  'Product Updated Successfully. After the Reviews of the Admins It will be Published':
    'بەرهەمەکە بە سەرکەوتوویی دەستکاری کرا. دوای پێداچوونەوە لەلایەن کارگێڕانەوە بڵاودەکرێتەوە',

  'Gift Card': 'كارتی دیاری',
  'For Using Website, Please Accept the Rules':
    'بۆ بەکارهێنانی سایتەکە، تکایە یاساکان قبوڵ بکە!',
  'For This Purchase, First you Need To Login.':
    'بۆ ئەم کڕینە، پێویستە سەرەتا بچیتە ژوورەوە.',
  'Shopping Cart': 'عەرەبە',
  'Celebrity voices': 'دەنگی کەسایەتییە ناودارەکان',
  'Celebrity voices detail':
    'دەنگی ئەو کەسانە ببیستن کە بەشێکی گرنگ له مێژووی هونەر و ئەدەبی کوردین.',
  'Farhad Pirbal': 'فرهاد پیربال',
  'Yunus Rezaei': 'یونس ڕەزایی',
  'Nasser Razazi': 'ناسر ڕەزازی',
  'Shirko Bey': 'شیرکۆ بەی',
  'Hassan Darzi': 'حەسەن دەرزی',
  'Teacher Aso': 'مامۆستا ئاسۆ',
  'Manifestation of creativity': 'دەرکەوتنی داهێنان',
  'Hello everyone': 'هەموو دەنگەکان',
  Celebrities: 'کەسایەتییە ناودارەکان',
  'Seven days a week (9am to 6pm)':
    'حەوت ڕۆژ لە هەفتەیەکدا (٩ی بەیانی تا ٦ی ئێوارە)',
  'footer-description':
    ' هەموو مافی ئەم پلاتفۆرمە تایبەتە بە پەیمانگای تاک و مافی فیکری و ماددی بەرهەمەکان بۆ بڵاوکەرەوەکانی پارێزراوە.',
  'Discount Detail':
    'بە یەکەم کڕینت لە ئانوبانی، چێژ لە داشکاندنی تایبەتی %۶۰ وەربگرە.',
  'comment title': 'سەرنجەی تۆ سەبارەت بە پێنج ئاستی ڕێبەرایەتی چیە؟',
  'Comment Subtitle':
    'وەک خوێنەرێکی ئەم کتێبە دەتوانیت کەسانی دیکە لە چۆنیەتی کارکردنی ئەم کتێبە ئاگادار بکەیتەوە.',
  'Comment Note': 'لێرەش هەڵسەنگاندنی خۆت بە ئەستێرە نیشان بدە.',
  'Comment List Title': 'سەرنجەکان دەربارەی پێنج ئاستی ڕێبەرایەتی',
  'Comment List SubTitle': 'لێرە دا ۲۱ کەس سەبارەت بەم بەرهەمە قسەیان کردووە.',
  'Comment PlaceHolder': 'لێرە کۆمێنتەکەت بنووسە...',
  'List of sections': 'لیستی بەشەکان',
  'Number of Pages': 'ژمارەی لاپەڕەکان',
  'See how many pages': 'چەند لاپەڕەی ببینە',
  'Listening to examples': 'گوێ دان بە نمونە',
  Duration: 'ماوە',
  'Buy and Read': 'کرین و خوێندنەوە',
};

export default kuTranslations;
