import { useState } from "react";
import "./CollapsibleItem.scss";
import { Collapse } from "@mantine/core";

const CollapsibleItem = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={"collapsible-item"}>
      <p onClick={() => setIsOpen(!isOpen)}>
        {data.value}
      </p>

      <Collapse in={isOpen}>
        <p className={"description"}>
          {data.innerValue}
        </p>
      </Collapse>
    </div>
  );
};

export default CollapsibleItem;
