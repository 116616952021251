import anobaniLogo from '../assets/images/anobani-logo.png';
import fiveFeetApart from '../assets/images/five-feet-apart.jpg';
import mobilePhones from '../assets/images/mobile-phones.png';
import publisherDefaultPhoto from '../assets/images/publisher-default-photo.png';
import shetabLogo from '../assets/images/shetab-logo.png';

const images = {
  anobaniLogo,
  fiveFeetApart,
  mobilePhones,
  publisherDefaultPhoto,
  shetabLogo,
};

export default images;
