// StarRating.js
import React, { Fragment, useState } from 'react';
import icons from '../../../../utils/icons';

const Rating = ({ onRatingChange }) => {
  const [rating, setRating] = useState(0);

  const handleStarClick = (value) => {
    setRating(value);
    onRatingChange(value);
  };

  return (
    <Fragment>
      {[1, 2, 3, 4, 5].map((value) => (
        <div key={value} onClick={() => handleStarClick(value)}>
          {value <= rating ? icons.ratted : icons.unRatted}
        </div>
      ))}
    </Fragment>
  );
};

export default Rating;
