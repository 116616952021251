import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useClickOutside} from "@mantine/hooks";
import "./Footer.scss";
// Components common between navbar sections
import LanguageSelector from "../common/LanguageSelector";
// Sub components
import Menu from "./sub-components/menu/Menu";
import UserSection from "./sub-components/UserSection";
// Utils
import NavbarContext from "../../../../../utils/contexts/Navbar";

const Footer = () => {
    const {i18n} = useTranslation();
    const {modalsDisplayStatus, toggleModals} = useContext(NavbarContext);
    const [shouldNavbarFooterHide, setShouldNavbarFooterHide] = useState(false);
    const className = `navbar-footer ${
        shouldNavbarFooterHide === true
            ? "hidden"
            : shouldNavbarFooterHide === false
                ? "visible"
                : undefined
    } ${modalsDisplayStatus.slideMenu && "active"} ${
        i18n.language === "kuk" && "ltr"
    }`;
    // useEffect(() => {
    //     let lastScrollPosition = window.scrollY;
    //     const hideNavbarFooter = () => {
    //         console.log('scroll: ', window.scrollY);
    //         console.log('last scroll: ', lastScrollPosition);
    //         if (window.scrollY < 300) {
    //             setShouldNavbarFooterHide(false);
    //             return;
    //         }
    //         if (window.scrollY < lastScrollPosition) {
    //             setShouldNavbarFooterHide(false);
    //         } else {
    //             setShouldNavbarFooterHide(true);
    //         }
    //         lastScrollPosition = window.scrollY;
    //     };
    //     window.addEventListener("scroll", hideNavbarFooter);
    //     return () => window.removeEventListener("scroll", hideNavbarFooter);
    // }, []);
    const slideMenuRef = useClickOutside(() =>
        toggleModals({
            ...modalsDisplayStatus,
            slideMenu: false,
        })
    );

    return (
        <div className={className} ref={slideMenuRef}>
            <LanguageSelector forSlideMenu/>
            <Menu/>
            <UserSection navbarFooterDisplayStatus={shouldNavbarFooterHide}/>
        </div>
    );
};

export default Footer;
