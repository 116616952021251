import React, { useContext, useState } from 'react';
import './css/Details.scss';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
// Utils
import ProductInfoContext from '../../../../../utils/contexts/Product';
import AmountSelector from '../../../../home-related/gift-card/AmountSelector';
import { getDate } from '../../../../../utils/helpers';
import { getRouter } from '../../../../../utils/helpers';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Api from '../../../../../utils/api';
import { toast } from 'react-toastify';
import icons from '../../../../../utils/icons';
const Details = ({ demoData }) => {
  const { t } = useTranslation();
  const { product, prices, bookType, toggleBookType } =
    useContext(ProductInfoContext);
  const [showFullText, setShowFullText] = useState(false);
  const authors = product?.authors.map((author) => author?.name)?.join(', ');
  const translators = product?.translators
    .map((translator) => translator?.name)
    ?.join(', ');
  const speakers = product?.speakers
    .map((speaker) => speaker?.name)
    ?.join(', ');

  const [bookmarkCount, setBookmarkCount] = useState(0);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const client = useSelector((state) => state.client);

  const bookmarkProduct = async () => {
    try {
      const { data } = await axios.post(
        getRouter(Api.bookmarks.removeFromBookmark, {
          productId: product.id,
        })
      );

      if (data.success) {
        console.log(data);
        if (isBookmarked) {
          setBookmarkCount(bookmarkCount > 0 ? bookmarkCount - 1 : 0);
        } else {
          setBookmarkCount(bookmarkCount + 1);
        }
        setIsBookmarked(!isBookmarked);
      }
    } catch (e) {}
  };

  const copyShareLink = async () => {
    if (!navigator?.clipboard) return;
    try {
      await navigator.clipboard.writeText(product?.share_url);
      toast(t('Link Copied!'), { type: 'success' });
    } catch (e) {}
  };

  const shareLink = () => {
    const data = {
      title: product?.title,
      url: product?.share_url,
    };

    if (navigator?.canShare && navigator?.canShare(data)) {
      navigator.share(data);
    }
  };

  const demoHandler = () => {
    demoData(true);
  };

  return (
    <div className={'details'}>
      <div className='d-flex justify-content-between'>
        <div>
          <p className='title'>{product?.title}</p>
          <div className={'collaborators'}>
            {authors ? (
              <p>
                {t('Authors')}:<strong> {authors}</strong>
              </p>
            ) : null}
            {translators ? (
              <p>
                {t('Translators')}: <strong>{translators}</strong>
              </p>
            ) : null}
            {speakers ? (
              <p className='mb-3'>
                {t('Speakers')}: <strong> {speakers}</strong>
              </p>
            ) : (
              <p className='mb-3'>&nbsp;</p>
            )}
          </div>
        </div>
        <div className='align-item-center d-flex justify-content-end'>
          <div> {icons.star}</div>
          <p className='mt-1 pe-2' style={{ fontSize: '16px' }}>
            <strong style={{ fontSize: '18px' }}>۴.۲ </strong> (از ۳۶ خواننده)
          </p>
        </div>
      </div>
      {/* <div>
        <p>{getDate(product?.created_at)}</p>
        {product?.type === 'podcast' && (
          <p>
            {t('Episode')} {product.episode}
          </p>
        )}
      </div> */}

      {/* <div>
        <p>
          {product?.type === 'electronic_book' ||
          product?.type === 'audio_book' ||
          product?.secondary_type === 'audio_book'
            ? t('See all Formats And Versions')
            : t('Electronic Version')}
        </p>
        <p>{!prices?.length ? t('Free') : ''}</p>
      </div> */}

      {/* <div className={'book-type-toggle'}>
        {product?.type === 'electronic_book' && product?.has_epub && (
          <button
            type={'button'}
            className={`button toggle${
              bookType === 'electronic_book' ? ' active' : ''
            }`}
            onClick={() => toggleBookType('electronic_book')}
          >
            {t('E-book')}
          </button>
        )}

        {(product?.type === 'audio_book' ||
          product?.secondary_type === 'audio_book') &&
          product?.has_audio && (
            <button
              type={'button'}
              className={`button toggle${
                bookType === 'audio_book' ? ' active' : ''
              }`}
              onClick={() => toggleBookType('audio_book')}
            >
              {t('Audio book')}
            </button>
          )}
      </div> */}

      {/* <div style={{ marginBottom: 0 }}>
        {prices?.length ? <AmountSelector values={prices} readOnly /> : null}
      </div> */}

      <p className={'summary'}>
        {product?.description?.length <= 300 || showFullText
          ? product?.description
          : product?.description?.substr(0, 300) + '...'}
      </p>

      <div
        className={
          i18n.language === 'kuk'
            ? 'product-like-share-right d-flex justify-content-end'
            : 'd-flex  justify-content-end'
        }
      >
        <div
          onClick={() => {
            copyShareLink();
            shareLink();
          }}
          className='mx-1  border py-1 px-2 rounded'
        >
          {icons.share}
        </div>
        <div
          style={{ direction: 'ltr' }}
          className={
            isBookmarked
              ? 'bookmarked mt-2 mx-1 mx-lg-3'
              : ' mx-1  mx-lg-3 border p-1 rounded'
          }
          onClick={() =>
            client && client.role === 'user' ? bookmarkProduct(product) : null
          }
        >
          {icons.heart}
          <span style={{ margin: '0 5px' }}>{bookmarkCount}</span>
        </div>

        {product?.type === 'audio_book' && (
          <button className='page-btn' type='button' onClick={demoHandler}>
            {icons.smallPlay} {t('Listening to examples')}
          </button>
        )}

        {product?.type === 'electronic_book' && (
          <button className='page-btn' type='button' onClick={demoHandler}>
            {icons.eye} {t('See how many pages')}
          </button>
        )}
      </div>
    </div>
  );
};

export default Details;
