import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Footer.scss';
// Sub components
import RightSection from './sub-components/RightSection';
import LeftSection from './sub-components/LeftSection';
import footerConfigs from '../../../utils/configs/footer';
import Api from '../../../utils/api';
import axios from '../../../utils/http';
import icons from '../../../utils/icons';
import images from '../../../utils/images';
import { t } from 'i18next';

const Footer = () => {
  const { i18n } = useTranslation();
  const [info, setInfo] = useState({
    recommendedProducts: [],
    settings: {},
  });

  useEffect(() => {
    getSiteSettings();
  }, []);

  const getSiteSettings = async () => {
    try {
      const { data } = await axios.get(Api.public.siteSettings);
      setInfo({
        recommendedProducts: data.data.recommended_products,
        settings: data.data.settings,
      });
    } catch (e) {}
  };

  return (
    <div className={`footer ${i18n.language !== 'kuk' && 'ltr'}`}>
      <div className='footer-main footer-social mb-3'>
        <RightSection info={info} />
        <LeftSection />
      </div>
      <div className=' p-xs-3 p-sm-0 p-3'>
        <div className=' row  my-2 footer-bottom-social'>
          <div className='col-md-6  '>
            <div style={{ marginTop: 0 }} className='d-flex '>
              {footerConfigs.socialMedia.map((item, index) => (
                <div key={index}>
                  <a href={item.link}>{item.icon}</a>
                </div>
              ))}
            </div>
          </div>

          <div className='col-md-6  text-sm-center text-md-start'>
            <p>{t('Seven days a week (9am to 6pm)')}</p>
            <div className=''>
              <span>+۹۶-۸۵۵۶۷۷۸۳۴۵</span>
              <span>{icons.telephone}</span>
            </div>
          </div>
        </div>
      </div>
      {info.settings.footer_title || info.settings.footer_description ? (
        <>
          <div className='footer-description'>
            <p>{info.settings.footer_title}</p>
            <p>{info.settings.footer_description}</p>
          </div>
          <div className='footer-bottom-description text-center'>
            {/* <p>{t('footer-description')}</p> */}
            <p>{info.settings.footer_copyright_text}</p>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default Footer;
