import React from 'react';

const Price = () => {
  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <span
          style={{
            color: 'white',
            fontSize: ' 12px',
            fontFamily: 'IRANSans',
            fontWeight: '400',
            wordWrap: 'break-word',
          }}
        >
          ۵۰۰۰
          <br />
        </span>
        <span
          style={{
            color: 'white',
            fontSize: ' 8px',
            fontFamily: 'IRANSans',
            fontWeight: '400',
            wordWrap: 'break-word',
          }}
        >
          دینار
        </span>
      </div>
      <div style={{ textAlign: 'center' }}>
        <span
          style={{
            color: 'white',
            fontSize: ' 12px',
            fontFamily: 'IRANSans',
            fontWeight: '400',
            wordWrap: 'break-word',
          }}
        >
          ۶۰۰۰۰
          <br />
        </span>
        <span
          style={{
            color: 'white',
            fontSize: ' 8px',
            fontFamily: 'IRANSans',
            fontWeight: '400',
            wordWrap: 'break-word',
          }}
        >
          تمەن
        </span>
      </div>
      <div style={{ textAlign: 'center' }}>
        <span
          style={{
            color: 'white',
            fontSize: ' 12px',
            fontFamily: 'IRANSans',
            fontWeight: '400',
            wordWrap: 'break-word',
          }}
        >
          ۵<br />
        </span>
        <span
          style={{
            color: 'white',
            fontSize: ' 8px',
            fontFamily: 'IRANSans',
            fontWeight: '400',
            wordWrap: 'break-word',
          }}
        >
          دولار
        </span>
      </div>
    </>
  );
};

export default Price;
