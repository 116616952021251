import React, { useEffect, useState } from "react";
import "./PurchaseRecords.scss";
import { useTranslation } from "react-i18next";
import axios from "../../../utils/http";
import API from "../../../utils/api";
import { replace, startCase, upperFirst } from "lodash";
import { useParams } from "react-router-dom";
import { getRouter } from "../../../utils/helpers";
import Product from "../../common/others/Product";

const PurchaseRecord = () => {
  const { t } = useTranslation();
  const { orderNumber } = useParams();
  console.log(orderNumber);

  const [purchase, setPurchase] = useState(null);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getPurchase();
  }, []);

  const getPurchase = async () => {
    try {
      const { data } = await axios.get(getRouter(API.dashboard.purchaseHistory, {
        orderNumber
      }));

      if (data.success) {
        setPurchase(data.data.purchase_info);
        setProducts(data.data.products);
      }
    } catch (e) {

    }
  };

  return (
    <div className={"purchase-records product-records"}>
      <div className="table-responsive">
        <table>
          <thead>
          <tr>
            <th>{t("Payment date")}</th>
            <th>{t("Payment time")}</th>
            <th>{t("Amount")}</th>
            <th>{t("Status")}</th>
            <th>{t("Issue tracking")}</th>
          </tr>
          </thead>

          <tbody>
          <tr key={purchase?.id}>
            <td>{purchase?.paid_at_date}</td>
            <td>{purchase?.paid_at_time}</td>
            <td>
              {purchase?.total_price_format}
            </td>
            <td>{t(startCase(replace(purchase?.status, "_", " ")))}</td>
            <td>{purchase?.order_number}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div className={"products"}>
        {products.map((product) => <Product data={product} type={product.order_type} />)}
      </div>
    </div>
  );
};

export default PurchaseRecord;
