import React, { useEffect, useState } from 'react';
import './css/Products.scss';
import { useLocation, useParams } from 'react-router-dom';
import Filters from '../../components/categories-related/filters/Filters';
import queryString from 'query-string';
import API from '../../utils/api';
import axios from '../../utils/http';
import Product, {
  ProductSkeleton,
} from '../../components/common/others/Product';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import CircleLoading from '../../components/common/others/CircleLoading';
import { upperFirst } from 'lodash';

const Products = () => {
  const search = useLocation().search;
  const { slug } = useParams();
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const [filters, setFilters] = useState({
    categories: [],
    genres: [],
    publishers: [],
    collaborators: [],
    product_types: [
      {
        key: 'electronic_book',
        value: t('Electronic Book'),
      },
      {
        key: 'audio_book',
        value: t('Audio Book'),
      },
      {
        key: 'magazine',
        value: t('Magazine'),
      },
      {
        key: 'podcast',
        value: t('Podcast'),
      },
    ],
    sort_by: [
      {
        key: 'latest',
        value: t('The Latest'),
      },
      {
        key: 'oldest',
        value: t('The Oldest'),
      },
      {
        key: 'purchases',
        value: t('The Purchases'),
      },
      {
        key: 'populars',
        value: t('The Populars'),
      },
    ],
  });
  const [selectedFilter, setSelectedFilter] = useState({
    category: null,
    genre: null,
    publisher: [],
    collaborator: [],
    product_type: [],
    sort_by: null,
    ...queryString.parse(search, { arrayFormat: 'bracket' }),
  });
  const [loading, setLoading] = useState(false);
  const [moreLoading, setMoreLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [title, setTitle] = useState('');
  const [queryTitle, setQueryTitle] = useState('');
  const [searchedTitle, setSearchedTitle] = useState('');
  const [slugCategory, setSlugCategory] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    getFilters();
  }, []);
  useEffect(() => {
    if (slug) {
      setSelectedFilter({
        ...selectedFilter,
        category: slug,
      });
    }
  }, [slug]);
  useEffect(() => {
    if (selectedFilter.category) {
      setSelectedCategory(
        filters.categories.find((c) => c.key == selectedFilter.category)
      );
    }
    getProducts();
  }, [selectedFilter, queryTitle]);
  useEffect(() => {
    const query = queryString.parse(search);
    if (query?.title && query.title != queryTitle) {
      setQueryTitle(query?.title);
      setTitle(query.title);
    }
  }, [search]);

  const mapCategory = (categories) => {
    return categories?.map((category) => ({
      key: category.slug,
      value: category.title,
      sub_categories: mapCategory(category.sub_categories),
    }));
  };

  const getFilters = async () => {
    try {
      const { data } = await axios.get(API.products.filters);

      if (data.success) {
        let mappedCategory = [];
        if (slug) {
          setSelectedFilter({
            ...selectedFilter,
            category: slug,
          });
          let res = [];
          for (const category of data.data?.categories) {
            res =
              category?.sub_categories?.find((c) => c?.slug == slug)
                ?.sub_categories || [];
            if (res.length) break;
          }
          mappedCategory = mapCategory(res);
        } else {
          mappedCategory = mapCategory(data.data?.categories);
        }

        const categories = mappedCategory;
        const genres = data.data?.genres?.map((genre) => ({
          key: genre.id,
          value: genre.title,
        }));
        const collaborators = data.data?.collaborators?.map((collaborator) => ({
          key: collaborator.id,
          value: `${collaborator.name} (${t(upperFirst(collaborator.type))})`,
        }));
        const publishers = data.data?.publishers?.map((publisher) => ({
          key: publisher.id,
          value: publisher.name,
        }));

        setFilters({
          categories,
          genres,
          product_types: filters.product_types,
          publishers,
          collaborators,
          sort_by: filters.sort_by,
        });
      }
    } catch (e) {}
  };
  const getProducts = async (loadMore = false) => {
    if (moreLoading || loading) return;
    setMoreLoading(true);
    if (!loadMore) setLoading(true);

    const filter = {};

    for (const filterKey in selectedFilter) {
      if (
        (Array.isArray(selectedFilter[filterKey]) &&
          selectedFilter[filterKey]?.length) ||
        (!Array.isArray(selectedFilter[filterKey]) && selectedFilter[filterKey])
      ) {
        filter[filterKey] = selectedFilter[filterKey];
      }
    }
    if (title) filter.title = title;

    try {
      const { data } = await axios.get(API.products.products, {
        params: {
          page: hasMore && loadMore ? currentPage + 1 : 1,
          ...filter,
        },
      });

      setProducts(
        loadMore
          ? [...products, ...data.data.products.data]
          : data.data.products.data
      );
      setTotal(data.data.products.total);
      setCurrentPage(data.data.products.current_page);
      setHasMore(!!data.data.products.next_page_url);
    } catch (e) {}
    setMoreLoading(false);
    setLoading(false);
  };

  if (loading)
    return (
      <div className={'categories'}>
        <Filters />

        <div>
          <p>
            <Skeleton width={120} />
          </p>
          <p>
            <Skeleton width={300} />
          </p>
        </div>

        <div>
          <ProductSkeleton type={'electronic_book'} />
          <ProductSkeleton type={'electronic_book'} />
          <ProductSkeleton type={'electronic_book'} />
          <ProductSkeleton type={'electronic_book'} />
          <ProductSkeleton type={'electronic_book'} />
          <ProductSkeleton type={'electronic_book'} />
          <ProductSkeleton type={'electronic_book'} />
          <ProductSkeleton type={'electronic_book'} />
          <ProductSkeleton type={'electronic_book'} />
          <ProductSkeleton type={'electronic_book'} />
          <ProductSkeleton type={'electronic_book'} />
          <ProductSkeleton type={'electronic_book'} />
          <ProductSkeleton type={'electronic_book'} />
          <ProductSkeleton type={'electronic_book'} />
          <ProductSkeleton type={'electronic_book'} />
          <ProductSkeleton type={'electronic_book'} />
          <ProductSkeleton type={'electronic_book'} />
          <ProductSkeleton type={'electronic_book'} />
          <ProductSkeleton type={'electronic_book'} />
          <ProductSkeleton type={'electronic_book'} />
          <ProductSkeleton type={'electronic_book'} />
        </div>
      </div>
    );

  return (
    <>
      <div className={'categories'}>
        <Filters
          filters={filters}
          selectedFilter={selectedFilter}
          search={title}
          onSearchChange={(value) => setTitle(value)}
          onSearchSubmit={() => getProducts()}
          onFilterChange={(field, value) =>
            setSelectedFilter({ ...selectedFilter, [field]: value })
          }
        />

        {/* <div>
          <p>
            {slug
              ? `${slug?.replaceAll('-', ' ')}${selectedCategory ? ' / ' : ''} `
              : ''}
            {selectedCategory?.value}
          </p>
          <p>تعداد کتاب ‌ها: {total}</p>
        </div> */}

        <div>
          {slug && (
            <p>{`${slug?.replaceAll('-', ' ')}${
              selectedCategory ? '/' : ''
            } `}</p>
          )}
          <p> {selectedCategory?.value}</p>
          <p>تعداد کتاب ‌ها: {total}</p>
        </div>

        <div>
          {products.map((product) => (
            <Product key={product.id} type={product.type} data={product} />
          ))}
        </div>
      </div>
      {hasMore && (
        <div className='load-more-container'>
          <button onClick={() => getProducts(true)}>
            {moreLoading ? <CircleLoading /> : t('Load More')}
          </button>
        </div>
      )}
    </>
  );
};

export default Products;
