import React, {useEffect, useState} from "react";
import "./MyProducts.scss";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import axios from "../../../../utils/http";
import {getRouter} from "../../../../utils/helpers";
import ProductSlider, {ProductSliderSkeleton} from "../../../home-related/home/ProductSlider";
import Api from "../../../../utils/api";
import icons from "../../../../utils/icons";

const MyProducts = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const [productItem, setProductItem] = useState({
        electronic_book: [], audio_book: [], podcast: [], magazine: []
    });

    useEffect(() => {
        getProducts();
    }, []);

    const getProducts = async () => {
        setLoading(true);
        try {
            const {data} = await axios.get(Api.publisher.products.myProducts);

            if (data.success) {
                setProductItem(data.data);
            }

        } catch (e) {

        }
        setLoading(false);
    };
    const handleDeleteProduct = async (product) => {
        try {
            const {data} = await axios.delete(getRouter(Api.publisher.products.remove, {
                productId: product.id
            }));

            if (data.success) {
                await getProducts();
            }

        } catch (e) {

        }
    };

    if (loading) return (<>
        <div className={"my-products"}>
            <div className={"title-container"}>
                <p className={"title"}>{t("E-Books")}</p>
                <p>{t("See all")}</p>
            </div>
            <ProductSliderSkeleton type={"electronic_book"}/>
            <div className={"title-container"}>
                <p className={"title"}>{t("Magazines")}</p>
                <p>{t("See all")}</p>
            </div>
            <ProductSliderSkeleton type={"magazine"}/>
            <div className={"title-container"}>
                <p className={"title"}>{t("Podcasts")}</p>
                <p>{t("See all")}</p>
            </div>
            <ProductSliderSkeleton type={"podcast"}/>
            <div className={"title-container"}>
                <p className={"title"}>{t("Audio books")}</p>
                <p>{t("See all")}</p>
            </div>
            <ProductSliderSkeleton type={"audio_book"}/>
        </div>
    </>);

    return (<>
        <div className={"my-products"}>
            <div className={'create-products'}>
                <Link to={'/dashboard/publisher/products/create'} className={'btn-create-icon'}>{icons.plus}</Link>
                <Link to={'/dashboard/publisher/products/create'}
                      className={'btn-create'}>{t('Add New Products')}</Link>
            </div>
            {productItem.electronic_book.length ? <>
                <div className={"title-container-product"}>
                    <p className={"title"}>{t("E-Books")}</p>
                    <Link to={"/dashboard/publisher/products/electronic_book"}>{t("See all")}</Link>
                </div>
                <ProductSlider products={productItem.electronic_book} type={"electronic_book"}
                               onEdit={(product) => navigate(`/dashboard/publisher/products/${product.slug}/edit`)}
                               onDelete={(product) => handleDeleteProduct(product)}

                />
            </> : null}

            {productItem.magazine.length ? <>
                <div className={"title-container-product"}>
                    <p className={"title"}>{t("Magazines")}</p>
                    <Link to={"/dashboard/publisher/products/magazine"}>{t("See all")}</Link>
                </div>
                <ProductSlider products={productItem.magazine} type={"magazine"}
                               onEdit={(product) => navigate(`/dashboard/publisher/products/${product.slug}/edit`)}
                               onDelete={(product) => handleDeleteProduct(product)}
                />
            </> : null}

            {productItem.podcast.length ? <>
                <div className={"title-container-product"}>
                    <p className={"title"}>{t("Podcasts")}</p>
                    <Link to={"/dashboard/publisher/products/podcast"}>{t("See all")}</Link>
                </div>
                <ProductSlider products={productItem.podcast} type={"podcast"}
                               onEdit={(product) => navigate(`/dashboard/publisher/products/${product.slug}/edit`)}
                               onDelete={(product) => handleDeleteProduct(product)}
                />
            </> : null}

            {
                productItem.audio_book.length ?
                    <>
                        <div className={"title-container-product"}>
                            <p className={"title"}>{t("Audio books")}</p>
                            <Link to={"/dashboard/publisher/products/audio_book"}>{t("See all")}</Link>
                        </div>
                        <ProductSlider products={productItem.audio_book} type={"audio_book"}
                                       onEdit={(product) => navigate(`/dashboard/publisher/products/${product.slug}/edit`)}
                                       onDelete={(product) => handleDeleteProduct(product)}
                        />
                    </> : null
            }
        </div>
    </>);
};

export default MyProducts;
