import React, { useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import "./css/Welcome.scss";
// Common components
import ModalWrapper from "../common/others/ModalWrapper";
// Utils
import icons from "../../utils/icons";
import NavbarContext from "../../utils/contexts/Navbar";

const WelcomeModal = () => {
  const { t } = useTranslation();
  const { modalsDisplayStatus, toggleModals } = useContext(NavbarContext);
  function toggle() {
    toggleModals({ ...modalsDisplayStatus, welcome: false });
  }
  useEffect(() => {
    const timeOut = setTimeout(() => toggle(), 2000);
    return () => clearTimeout(timeOut);
  }, []);

  return (
    <ModalWrapper className={"welcome-modal"} toggle={toggle}>
      {icons.tick}

      <p className={"center"}>{t("Welcome to Anobani")}</p>
    </ModalWrapper>
  );
};

export default WelcomeModal;
