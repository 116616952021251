import { useClickOutside } from "@mantine/hooks";
import "./css/ModalWrapper.scss";
// Utils
import icons from "../../../utils/icons";

const ModalWrapper = ({
                        children,
                        className,
                        wrapperClass,
                        containerClass,
                        toggle,
                        xs = false,
                        md = false,
                        lg = false
                      }) => {
  const closeOnClickOutside = useClickOutside(() => toggle());

  return (
    <div className={`modal-wrapper ${wrapperClass ? wrapperClass : ""}`}>
      <div ref={closeOnClickOutside}
           className={`${containerClass ? containerClass : ""} ${xs ? "modal-xs" : ""} ${md ? "modal-md" : ""} ${lg ? "modal-lg" : ""}`}>
        {toggle && (
          <div className={"close"} onClick={() => toggle()}>
            {icons.cross}
          </div>
        )}

        <div className={`body ${className}`}>{children}</div>
      </div>
    </div>
  );
};

export default ModalWrapper;
